import type { PropsWithChildren } from "react";

import type { KlassList } from "../util/classnames";
import { klass, klassPropO } from "../util/classnames";

type AspectRatioU = "16:9" | "4:5";

export const AspectRatioContainer = (props: PropsWithChildren<{
  aspectRatio: AspectRatioU;
  klasses?: KlassList;
}>) => {
  return (
    <div {...klassPropO("aspect-ratio-container")(props.klasses)} data-ratio={props.aspectRatio}>
      <div {...klass("aspect-ratio-content")}>
        <div {...klass("flex-centered")}>
          {props.children}
        </div>
      </div>
    </div>
  );
};
