import type { PropsWithChildren } from "react";

import { FileViewer, type FileViewerProps } from "@scripts/react/components/FileViewer";

const FileViewerWrapper = (props: PropsWithChildren) =>
  <div className="file-viewer-container w-100 mt-1">
    {props.children}
  </div>;

export const FileViewerWrapped = (props: FileViewerProps) =>
  <FileViewerWrapper>
    <FileViewer url={props.url} />
  </FileViewerWrapper>;
