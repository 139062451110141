import type { FC, ReactElement, ReactNode } from "react";
import * as t from "io-ts";

import type { ContentSubGroup } from "@scripts/analytics";
import type { BLConfigWithLog } from "@scripts/bondlink";
import type { O } from "@scripts/fp-ts";
import type { PhotoEditableU } from "@scripts/generated/domaintables/pages";
import { homepage } from "@scripts/generated/domaintables/pages";
import type { Bank } from "@scripts/generated/models/bank";
import type { BankCalendarData, BankCalendarDataC } from "@scripts/generated/models/bankCalendar";
import type { DealViewData, DealViewDataC } from "@scripts/generated/models/dealView";
import * as V2Router from "@scripts/generated/routers/v2Router";
import type { CalendarQueryParams } from "@scripts/routes/routing/params/bondSaleCalendarParams";
import { calendarQueryParams, makeCalendarQueryParams } from "@scripts/routes/routing/params/bondSaleCalendarParams";
import type { RouteMeta } from "@scripts/routes/routing/ssr";

import { format } from "../base";

const calendarTag = "Calendar";
const notFoundTag = "NotFound";
const dealViewTag = "DealView";

export type BankSitesTagU = typeof calendarTag | typeof notFoundTag | typeof dealViewTag;

type BankSitesRouteMeta<A, Output> = Omit<RouteMeta<A, Output>, "_tag"> & {
  description: (bank: Bank) => string;
  pagePhoto: PhotoEditableU;
  title: () => string;
  _tag: BankSitesTagU;
};

export type PageComponentProps = {
  children: ReactNode;
  pagePhoto: PhotoEditableU;
  title: string;
  url: string;
  tag: BankSitesTagU;
};

export type PageComponent<A> = FC<A & PageComponentProps>;

export type BankSitesRouteMetaWithRender<A, Output> = BankSitesRouteMeta<A, Output> & {
  pageComponent: PageComponent<A>;
  render: (props: A) => ReactElement<A>;
};

export const calendarMatch = V2Router.bankSitesBankControllerCalendarRoute(calendarQueryParams).match;
export type CalendarUrlParams = typeof calendarMatch._A;

export const calendar = (reqParams: { bankId: number, bankSlug: string }, q: O.Option<Partial<CalendarQueryParams>>) => {
  const queryParams = makeCalendarQueryParams(q);
  return {
    _tag: calendarTag,
    propsCodec: V2Router.bankSitesBankControllerCalendarCodecs.ssrInput,
    title: () => "Bond Sale Calendar",
    url: () => format(calendarMatch, { ...reqParams, ...queryParams }),
    pagePhoto: homepage,
    description: (bank: Bank) => `View upcoming municipal bond offerings managed by ${bank.name}.`,
  } as const satisfies BankSitesRouteMeta<BankCalendarData, t.OutputOf<BankCalendarDataC>>;
};

export const dealViewMatch = V2Router.bankSitesDealViewControllerDealViewRoute().match;

export const dealView = (reqParams: V2Router.BankSitesDealViewControllerDealViewParams) => ({
  _tag: dealViewTag,
  propsCodec: V2Router.bankSitesDealViewControllerDealViewCodecs.ssrInput,
  title: () => "Deal View",
  url: () => format(dealViewMatch, reqParams),
  pagePhoto: homepage,
  description: (_) => `View statistics for this ${_.name} managed deal.`,
} as const satisfies BankSitesRouteMeta<DealViewData, t.OutputOf<DealViewDataC>>);

export const notFound = {
  _tag: notFoundTag,
  propsCodec: t.unknown,
  title: () => "Not Found",
  url: () => "/",
  description: () => "Not Found",
  pagePhoto: homepage,
} as const satisfies BankSitesRouteMeta<unknown, t.OutputOf<t.UnknownC>>;

export const pageTagToAnalyticsContentGroup = (pageTag: BankSitesTagU, config: BLConfigWithLog): ContentSubGroup => {
  switch (pageTag) {
    case "Calendar":
      return "Calendar Page";
    case "DealView":
      return "Deal View";
    case "NotFound":
      return "Not Found";
  }
  config.exhaustive(pageTag);
};
