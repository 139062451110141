import type { PropsWithChildren } from "react";
import * as O from "fp-ts/lib/Option";

import type { UserWithRoles } from "@scripts/generated/models/user";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { Anchor } from "@scripts/react/components/Anchor";
import { SubscriptionToggleBtn } from "@scripts/react/components/SubscriptionToggle";
import type { Klass } from "@scripts/react/util/classnames";
import { klass, klassPropO } from "@scripts/react/util/classnames";

import { ContactModalCallout } from "@scripts-ssr/components/ContactModal";

import { useIssuerSubscribe } from "../api/watchlist";
import { useIssuerSitesSelector } from "../state/store";

type SidebarActionSectionBaseProps = { containerKlass?: Klass, suppressBorder?: true };
type SidebarActionSectionProps = SidebarActionSectionBaseProps & {
  title: string;
  subtitle: string;
};

const SitesActionSection = (props: PropsWithChildren<SidebarActionSectionProps>) =>
  <section {...klassPropO([props.suppressBorder ? "pt-0" : "accent-border-top pt-05", "mb-0"])(props.containerKlass)}>
    <h4 {...klass("mb-025")}>{props.title}</h4>
    <p className="small">{props.subtitle}</p>
    {props.children}
  </section>;


export const GetAlertsActionSection = (props: SidebarActionSectionBaseProps) => {
  const isSubscribed = useIssuerSitesSelector("userSubscribed");
  const issuer = useIssuerSitesSelector("issuer");
  const user = useIssuerSitesSelector("user");

  const makeOnSubscribe = useIssuerSubscribe();

  return (
    <SitesActionSection
      containerKlass={props.containerKlass}
      suppressBorder={props.suppressBorder}
      title="Get Issuer Alerts"
      subtitle="Add this issuer to your watchlist to get alerts about important updates."
    >
      <SubscriptionToggleBtn
        disableTooltip={true}
        isSubscribed={isSubscribed}
        onSubscribe={makeOnSubscribe(
          !isSubscribed,
          issuer.id,
          user,
        )}
      />
    </SitesActionSection>
  );
};


export const ContactUsActionSection = (props: SidebarActionSectionBaseProps) => {
  const issuer = useIssuerSitesSelector("issuer");
  const user = useIssuerSitesSelector("user");

  const teamRoute = SR.issuersitesAboutControllerTeam({
    issuerId: issuer.id,
    issuerSlug: issuer.slug,
  });

  return (
    <SitesActionSection
      containerKlass={props.containerKlass}
      suppressBorder={props.suppressBorder}
      title="Talk to us"
      subtitle="Have questions? Reach out to us directly."
    >
      <ContactModalCallout
        bondOfferingId={O.none}
        btnText="Contact Us"
        header="Contact Us"
        introText={
          <div>
            <p>Your message will be sent securely and someone will reply to you shortly.</p>
            <p>
              Looking for direct contact information?{" "}
              <Anchor
                externalLinkLocation="none"
                route={{
                  title: "View the Team page for details.",
                  route: teamRoute,
                }}
                target="_self"
              />
            </p>
          </div>
        }
        issuer={issuer}
        klasses={"mt-0"}
        rfpId={O.none}
        user={O.map((withRoles: UserWithRoles) => withRoles.user)(user)}
      />
    </SitesActionSection>
  );
};
