import { O, pipe } from "@scripts/fp-ts";
import { rfpsPage } from "@scripts/generated/domaintables/pages";
import { modifyTaggedContent } from "@scripts/models/taggedContent";
import type { ContentItemCardSubscriptionProps } from "@scripts/react/components/card/ContentItemCard";
import { RfpCard, type RfpCardProps } from "@scripts/react/components/card/RfpCard";
import { getCustomTitleO } from "@scripts/syntax/pageTitles";
import { modifyWithStatus } from "@scripts/syntax/threadThrough";

import { useIssuerSitesSelector } from "../state/store";
import { bidSubmissionLens, type IssuerSitesRfp } from "../syntax/rfp";
import { IssuerSitesLeafIcon } from "./LeafIcon";
export type IssuerSitesRfpCardProps<R extends IssuerSitesRfp> = {
  rfp: R;
  setRfp: (rfp: R) => void;
} & Pick<RfpCardProps, "issuer"> & ContentItemCardSubscriptionProps;

export const IssuerSitesRfpCard = <R extends IssuerSitesRfp>(props: IssuerSitesRfpCardProps<R>) => {
  const pages = useIssuerSitesSelector("pages");
  const user = useIssuerSitesSelector("user");

  return <RfpCard
    issuer={props.issuer}
    displayIssuer={false}
    user={user}
    rfp={pipe(props.rfp.data, modifyWithStatus(modifyTaggedContent(_ => _.rfp)))}
    url={_ => _.url}
    customRfpsTitle={getCustomTitleO(rfpsPage)(pages)}
    roadshows={props.rfp.data.data.record.data.relatedContent.roadShows.map(_ => _.data)}
    bidSubmissionTemplate={props.rfp.data.data.record.data.relatedContent.bidSubmissionTemplate}
    bidSubmission={props.rfp.data.data.record.data.relatedContent.bidSubmission}
    setBidSubmission={s => props.setRfp(bidSubmissionLens<R>().set(O.some(s))(props.rfp))}
    links={["details", "roadshows", "bidSubmission"]}
    subscription={O.some({ isSubscribed: props.isSubscribed, onSubscribe: props.onSubscribe })}
    target="_self"
    leafIcon={IssuerSitesLeafIcon}
  />;
};
