import type { PropsWithChildren } from "react";

import type { O, RNEA } from "@scripts/fp-ts";
import { identity, RA } from "@scripts/fp-ts";
import type { BondProgramWithRelatedContent } from "@scripts/generated/models/bondProgram";
import { type FactType, makeFactO } from "@scripts/react/components/Facts";
import { type DocumentRelatedContent, type LinkRelatedContent } from "@scripts/react/components/offering-pages/RelatedContent";

import { OverviewSection } from "../../../components/Overview";

export const makeProgramFacts = (program: BondProgramWithRelatedContent) =>
  RA.compact([
    makeFactO("CUSIP-6", program.program.cusip6, identity),
    makeFactO("Sector", program.program.sector, s => s.name)]
  );

type ProgramOverviewAndFactsProps = {
  program: BondProgramWithRelatedContent;
  programFacts: O.Option<RNEA.ReadonlyNonEmptyArray<FactType>>;
};

type ProgramOverviewSectionProps = PropsWithChildren<Omit<ProgramOverviewAndFactsProps, "programFacts"> & {
  documentsDataO: O.Option<RNEA.ReadonlyNonEmptyArray<DocumentRelatedContent>>;
  linksDataO: O.Option<RNEA.ReadonlyNonEmptyArray<LinkRelatedContent>>;
  sectionId: string;
}>;

export const programOverviewSectionTitle = "Overview";
export const ProgramOverviewSection = (props: ProgramOverviewSectionProps) => {
  return (
    <OverviewSection
      facts={props.program.relatedContent.quickFacts.map(a => a.data.data.record)}
      additionalFacts={makeProgramFacts(props.program)}
      overview={props.program.program.overview}
      documentsDataO={props.documentsDataO}
      linksDataO={props.linksDataO}
      photos={props.program.relatedContent.photos.map(a => a.data)}
      sectionId={props.sectionId}
      title={programOverviewSectionTitle}
    />
  );
};
