import type { Dispatch, PropsWithChildren, ReactNode, SetStateAction } from "react";
import { useState } from "react";
import * as O from "fp-ts/lib/Option";

import { E } from "@scripts/fp-ts";

import chevronDown from "@svgs/chevron-down.svg";

import { klass, klassConditional } from "../util/classnames";
import { ButtonLinkIcon, ButtonsContainer } from "./Button";
import { LayoutSection } from "./layout/Section";

export type ToggleText = { hide: string, show: string };

export type ShowHideToggleProps = {
  isShowingMore: boolean;
  setIsShowingMore: Dispatch<SetStateAction<boolean>>;
  text: ToggleText;
  appendIcon?: true;
};

export const ShowHideToggle = (props: ShowHideToggleProps) =>
  <ButtonLinkIcon
    onClick={() => props.setIsShowingMore(s => !s)}
    {...klassConditional("chevron-open rotate", "rotate-ccw-180-svg")(props.isShowingMore)}
    icon={chevronDown}
    textOrAriaLabel={E.left(props.isShowingMore ? props.text.hide : props.text.show)}
    appendIcon={props.appendIcon}
  />;

type ShowMoreProps = {
  secondaryButton: ReactNode;
};

export const ShowMore = (props: PropsWithChildren<ShowMoreProps>) => {
  const [isShowingMore, setIsShowingMore] = useState(false);

  return <div {...klass(("show-more"))}>
    <LayoutSection title={O.none}>
      <div {...klass("body", isShowingMore ? "" : "is-showing-less")}>
        {props.children}
      </div>
    </LayoutSection>
    <LayoutSection title={O.none} klasses={"pt-0"}>
      <ButtonsContainer klasses={O.some("show-more-footer")}>
        <ShowHideToggle
          isShowingMore={isShowingMore}
          setIsShowingMore={setIsShowingMore}
          text={{ hide: "Show Less", show: "Show More" }}
        />
        {props.secondaryButton}
      </ButtonsContainer>
    </LayoutSection>
  </div>;
};
