import { O } from "@scripts/fp-ts";
import type { ClientTextItem } from "@scripts/generated/models/clientTextItem";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";

export const generalOfferingInfoSectionTitle = "General Offering Information";
export const GeneralOfferingInfo = (props: { offeringInfo: ClientTextItem, sidebarLinkHandle: string }) =>
  <AccentDividerSection
    sectionId={props.sidebarLinkHandle}
    title={O.some(generalOfferingInfoSectionTitle)}
  >
    <Markdown content={props.offeringInfo.data} klasses={["last-child-mb-0"]} />
  </AccentDividerSection>;
