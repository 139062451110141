import { O } from "@scripts/fp-ts";
import { contact as contactFF } from "@scripts/generated/domaintables/featureFlags";
import type { BankOfferingData } from "@scripts/generated/models/bondOffering";
import * as sitesRouter from "@scripts/generated/routers/sitesRouter";
import { DealViewOfferingDetails } from "@scripts/react/actions/offering-pages/BankOfferingContent";
import { findIssuerRatingsPage } from "@scripts/react/components/offering-pages/Ratings";
import type { DocumentDownloadRoute } from "@scripts/react/components/ratings/RatingBlock";
import { klass } from "@scripts/react/util/classnames";
import { isFFEnabled } from "@scripts/syntax/featureFlags";
import { isDrafted } from "@scripts/syntax/threadThrough";

import { useBondSubscribe } from "../../api/watchlist";
import { BankIssuerChrome } from "../../components/Chrome";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { HeaderWithSubscription } from "../../components/offering-pages/Header";
import { BondOfferingContactModal } from "../../components/offering-pages/RelatedContent";
import { useIssuerSitesSelector } from "../../state/store";
import { useOnSubscribe } from "../../syntax/onSubscribe";

export const BankOfferingPage = (props: BankOfferingData) => {
  const iffs = useIssuerSitesSelector("iffs");
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const makeOnSubscribe = useBondSubscribe();
  const [isSubscribed, onSubscribe] = useOnSubscribe(props.offering.subscribed, makeOnSubscribe, props.offering.data.data.id);

  const loginRedirect = sitesRouter.issuersitesBondOfferingsControllerOffering({
    issuerSlug: issuer.slug,
    issuerId: issuer.id,
    offeringId: props.offering.data.data.id,
  }).url;

  const documentDownloadRoute: DocumentDownloadRoute = (id) => sitesRouter.issuersitesReportsControllerDownloadRedirect({ mediaId: id, issuerId: issuer.id, issuerSlug: issuer.slug });

  return (
    <BankIssuerChrome
      bank={props.bank}
      loginRequired={props.offering.data.data.record.data.offering.loginRequired}
      loginRedirectUrl={loginRedirect}
      offeringId={props.offering.data.data.id}
    >
      <div {...klass("container")}>
        <DealViewOfferingDetails
          header={O.some(
            <HeaderWithSubscription
              isSubscribed={isSubscribed}
              onSubscribe={onSubscribe}
              taggedContent={O.some(props.offering.data.data.record)}
              title={props.offering.data.data.record.data.offering.name}
              viewAllRoute={O.none}
              subscribeDisabled={isDrafted(props.offering.data)}
            />
          )}
          issuer={issuer}
          data={props}
          documentDownloadRoute={documentDownloadRoute}
          issuerRatingsPageO={findIssuerRatingsPage(pages)}
          contactModal={O.fromPredicate(() => isFFEnabled(contactFF)(iffs))(<BondOfferingContactModal offering={props.offering} />)}
          leafIcon={IssuerSitesLeafIcon}
          summaryKlasses="accent-border-top"
        />
      </div>
    </BankIssuerChrome>
  );
};
