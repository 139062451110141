import { capitalize } from "voca";

import { O } from "@scripts/fp-ts";
import type { BLConfig } from "@scripts/generated/models/blConfig";
import type { Disclaimer } from "@scripts/generated/models/disclaimer";
import { ButtonsContainer, ButtonSecondary } from "@scripts/react/components/Button";
import { Markdown } from "@scripts/react/components/Markdown";
import type { ModalDismissable, ModalOpenable } from "@scripts/react/components/modal/Modal";
import { Modal } from "@scripts/react/components/modal/Modal";

export const DisclaimerModal = (props: ModalOpenable & ModalDismissable & {
  config: BLConfig;
  disclaimer: Disclaimer;
}) =>
  <Modal
    dismissAction={props.dismissAction}
    id="disclaimerModal"
    title={`${capitalize(props.disclaimer.disclaimerType.typeName)} Disclaimer`}
    icon={O.none}
    type={"primary"}
    open={props.modalOpen}
    size="modal-lg"
    body={
      <>
        <Markdown content={props.disclaimer.data} />
        <ButtonsContainer klasses={O.none} divider>
          <ButtonSecondary onClick={props.dismissAction}>
            Close
          </ButtonSecondary>
        </ButtonsContainer>
      </>
    }
  />;
