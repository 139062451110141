import { useState } from "react";
import { useStableO } from "fp-ts-react-stable-hooks";

import { formatS3CdnUrl } from "@scripts/bondlink";
import { constFalse, constVoid, E, O, pipe, RA } from "@scripts/fp-ts";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { Svg } from "@scripts/react/components/Svg";
import { useConfig } from "@scripts/react/context/Config";
import type { AudioPlayerRefs } from "@scripts/react/hooks/useAudioPlayer";
import { klass } from "@scripts/react/util/classnames";
import type { RoadshowPlayerData } from "@scripts/routes/routing/ssr/issuersites";
import { formatPlayerTime } from "@scripts/syntax/roadshow";

import checkmarkIcon from "@svgs/checkmark.svg";

import { useIssuerSitesSelector } from "../../state/store";
import { NoSkipTooltip } from "./NoSkipTooltip";
import { RelatedItemsButtonO } from "./RelatedDeals";
import type { SlidesState } from "./roadshowSyntax";

export const SlideList = (props: AudioPlayerRefs & SlidesState & {
  data: RoadshowPlayerData;
  slideListOpen: boolean;
  setSlideListOpen: (open: boolean) => void;
  viewedSlideIds: ReadonlySet<number>;
}) => {
  const config = useConfig();
  const issuer = useIssuerSitesSelector("issuer");
  const [noSkipSlideId, setNoSkipSlideId] = useStableO<number>(O.none);
  const [rfps, setRfps] = useState(props.data.rfps);

  return <div {...klass("slide-list-container d-flex flex-col", ...(props.slideListOpen ? ["open"] : []))}>
    <div className="offering-details">
      <div className="flex-col pr-1">
        <h4 className="issuer-name">{issuer.name}</h4>
        <h5 className="offering-name mt-025 inverted">{props.data.show.record.data.show.title}</h5>

        {pipe(props.data.offerings.length > 0 || rfps.length > 0, trueOrEmpty(
          <>
            <h4 className="mt-1 inverted">View Related Content:</h4>
            <RelatedItemsButtonO items={E.left(props.data.offerings)} setRfp={constVoid} />
            <RelatedItemsButtonO
              items={E.right(rfps)}
              setRfp={r => setRfps(rfps.map(_ => _.data.data.id === r.data.data.id ? r : _))}
            />
          </>
        ))}
      </div>
    </div>
    <div className="slide-list">
      <ul className="slides list-unstyled">
        {pipe(props.data.show.record.data.slides, RA.map(slide => {
          const isActive = pipe(props.activeSlide, O.exists(([_]) => _.id === slide.id));
          const isViewed = props.viewedSlideIds.has(slide.id);

          return <NoSkipTooltip key={slide.id} showTooltip={pipe(noSkipSlideId, O.fold(constFalse, _ => _ === slide.id))}>
            <li
              {...klass("slide", "inverted", ...(isActive ? ["active"] : []), ...(isViewed ? ["viewed"] : []))}
              onClick={() => pipe(
                props.seekToTime(slide.record.startTime),
                O.fold(
                  () => {
                    setNoSkipSlideId(O.some(slide.id));
                    window.setTimeout(() => setNoSkipSlideId(O.none), 5000);
                  },
                  constVoid,
                )
              )}
            >
              <div className="slide-thumbnail">
                <div className="slide-image">
                  <img
                    src={formatS3CdnUrl(config)(slide.record.media.uploadResponse.uri)}
                    alt={O.toUndefined(slide.record.media.altText)}
                  />
                </div>
              </div>
              <div className="slide-text">
                <span className="slide-name truncate truncate-2">{slide.record.media.uploadResponse.viewName}</span>
                {pipe(slide.record.participants, mapOrEmpty(ps =>
                  <span className="slide-secondary-text truncate truncate-2">{ps}</span>
                ))}
              </div>
              {pipe(props.data.show.record.data.show.audio, trueOrEmpty(
                <span className="time">{formatPlayerTime(slide.record.startTime)}</span>
              ))}
              {isViewed && <div className="viewed-mark-container text-center">
                <Svg src={checkmarkIcon} />
              </div>}
            </li>
          </NoSkipTooltip>;
        }))}
      </ul>
    </div>
  </div >;
};
