import escapeHtml from "escape-html";

import { O, pipe, RA } from "@scripts/fp-ts";
import { homepageC } from "@scripts/generated/domaintables/pages";
import type { ClientTextItem } from "@scripts/generated/models/clientTextItem";
import type { IssuerHomepageData } from "@scripts/generated/models/issuerPageData";
import { Badge } from "@scripts/react/components/Badge";
import { IssuerOverviewCard } from "@scripts/react/components/card/IssuerOverviewCard";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Image } from "@scripts/react/components/Image";
import { Markdown } from "@scripts/react/components/Markdown";
import { klass } from "@scripts/react/util/classnames";
import { coerceMarkdownAsString, coerceStringAsMarkdown } from "@scripts/syntax/markdown";

import issuerIcon from "@svgs/issuer.svg";

import { useIssuerSubscribe } from "../../api/watchlist";
import { useIssuerSitesSelector } from "../../state/store";

const WelcomeIssuerOverviewCard = (
  props: Pick<IssuerHomepageData, "issuerRatings" | "offerings" | "programRatings">
) => {
  const issuer = useIssuerSitesSelector("issuer");
  const isSubscribed = useIssuerSitesSelector("userSubscribed");
  const pages = useIssuerSitesSelector("pages");
  const user = useIssuerSitesSelector("user");

  const makeOnSubscribe = useIssuerSubscribe();

  return <IssuerOverviewCard
    header={<div {...klass("pt-1", "pl-1", "mb-025")}>
      <Badge
        color="accent-2-700"
        icon={O.some(issuerIcon)}
        text="Issuer Overview"
        variant="tall"
      />
    </div>}
    id={issuer.id}
    issuer={{
      issuer: issuer,
      offerings: props.offerings.length,
      issuerRatings: props.issuerRatings,
      programRatings: props.programRatings,
      subscribed: isSubscribed,
      media: O.none,
      outstandingDebt: O.none,
    }}
    klass={"issuer-site"}
    target="_self"
    onSubscribe={makeOnSubscribe(
      !isSubscribed,
      issuer.id,
      user,
    )}
    pages={pages}
  >
    <>
      <h5 {...klass("mb-025")}>{issuer.name}</h5>
      <p>Issuer Type: {issuer.issuerType.name}</p>
    </>
  </IssuerOverviewCard>;
};

export const Welcome = (props: {
  item: ClientTextItem;
} & Pick<IssuerHomepageData, "issuerRatings" | "officer" | "offerings" | "programRatings">) => {
  const pages = useIssuerSitesSelector("pages");

  const welcomeHeader = pipe(
    pages, RA.findFirst(_ => homepageC.is(_.page)),
    O.chain(_ => _.title),
    O.getOrElse(() => "Welcome to Our Investor Relations Site")
  );

  return <section {...klass("container mb-2 d-flex flex-wrap d-sm-block clear-fix")}>
    <div {...klass("w-100", "d-sm-none", "mb-1", "mt-1")}>
      <WelcomeIssuerOverviewCard
        issuerRatings={props.issuerRatings}
        offerings={props.offerings}
        programRatings={props.programRatings}
      />
    </div>
    <div {...klass("pb-1 pl-sm-15 pl-lg-2 flex-grow float-sm-right d-none-until-sm")}>
      <WelcomeIssuerOverviewCard
        issuerRatings={props.issuerRatings}
        offerings={props.offerings}
        programRatings={props.programRatings}
      />
    </div>
    <div {...klass("welcome-letter")}>
      <h2 {...klass("mt-0")}>{welcomeHeader}</h2>
      {pipe(
        props.officer,
        mapOrEmpty(o => pipe(
          o.data.record.media,
          mapOrEmpty(_ =>
            <div {...klass("welcome-letter-photo float-left d-lg-block mr-1 mb-05")}>
              <Image
                alt={pipe(_.altText, O.getOrElse(() => _.uploadResponse.viewName))}
                src={_.uploadResponse.uri}
              />
            </div>
          ))
        )
      )}
      <div {...klass("large")}>
        <Markdown
          content={pipe(
            props.officer,
            O.fold(
              () => props.item.data,
              _ => coerceStringAsMarkdown(
                coerceMarkdownAsString(props.item.data)
                + escapeHtml(`\n\n**${_.data.record.firstName} ${_.data.record.lastName}**, _${_.data.record.title}_`)
              ),
            )
          )}
        />
      </div>
    </div>
  </section>;
};
