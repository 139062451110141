import { pipe } from "fp-ts/lib/function";

import { O } from "@scripts/fp-ts";
import type { PhotoEditableU } from "@scripts/generated/domaintables/pages";
import type { Bank } from "@scripts/generated/models/bank";
import type { HeaderPhoto } from "@scripts/generated/models/headerPhoto";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { ImageBackground } from "@scripts/react/components/Image";
import { type KlassProp, klassPropO } from "@scripts/react/util/classnames";

export type MastheadProps = {
  title: string;
  imageO: O.Option<[PhotoEditableU, WithStatusU<HeaderPhoto>]>;
  bank: Bank;
  hasCalendar: boolean;
  variant: "bank" | "deal";
  hasAlert?: boolean;
  titleKlass?: KlassProp;
};

const getBreadcrumbs = (bank: Bank, hasCalendar: boolean) => pipe(
  ({
    webpage: V2Router.bankSitesBankControllerCalendar({ bankSlug: bank.slug, bankId: bank.id }).url,
    name: bank.name,
  }),
  O.fromPredicate(() => hasCalendar)
);

export const Masthead = (props: MastheadProps) => {
  const imageUrl = pipe(
    props.imageO,
    O.map(([, media]) => media.data.record.media.data.record.uploadResponse.uri),
  );

  const breadcrumbs = getBreadcrumbs(props.bank, props.hasCalendar);

  return (
    <ImageBackground
      klasses={["masthead", `${props.variant}-masthead`, "inverted", props.variant === "deal" ? "mb-1" : "mb-15", props.hasAlert ? "has-alert" : ""]}
      url={imageUrl}
    >
      <div className="page-name-container">
        <div className="page-name">
          {pipe(breadcrumbs, mapOrEmpty(b =>
            <span itemScope itemType="https://schema.org/BreadcrumbList">
              <span itemScope itemProp="itemListElement" itemType="https://schema.org/ListItem">
                <span itemScope itemProp="item" itemType="https://schema.org/WebPage" itemID={b.webpage}>
                  <meta itemProp="name" content={b.name} />
                </span>
                <meta itemProp="position" content="1" />
              </span>
            </span>
          ))}
          <h1 {...klassPropO("")(props.titleKlass)}>{props.title}</h1>
        </div>
      </div>
    </ImageBackground>
  );
};
