import type { PropsWithChildren, ReactElement } from "react";

import { type O, pipe } from "@scripts/fp-ts";

import { klass } from "../util/classnames";
import { mapOrEmpty, toEmpty } from "./Empty";

export type SidebarSectionProps = PropsWithChildren<{
  title: string;
  subtitle: O.Option<string>;
  text: O.Option<string>;
  imageElement: O.Option<ReactElement>;
}>;

export const SidebarSection = (props: SidebarSectionProps) =>
  <div {...klass("sidebar-section", "mb-15", "d-flex", "flex-col", "gap-1")}>
    {pipe(props.imageElement, toEmpty)}
    <h3 {...klass("my-0")}>{props.title}</h3 >
    {pipe(props.subtitle, mapOrEmpty(s => <h5 {...klass("mt-0")}>{s}</h5>))}
    {pipe(props.text, mapOrEmpty(t => <span {...klass("gray-800")}>{t}</span>))}
    {props.children}
  </div >;

export const SidebarSubSection = (props: PropsWithChildren<{
  title: O.Option<string>;
  text: O.Option<string>;
}>) => <div {...klass("gap-1 mb-1")}>
    {pipe(props.title, mapOrEmpty(s => <h5 {...klass("my-0")}>{s}</h5>))}
    {pipe(props.text, mapOrEmpty(t => <span {...klass("gray-800")}>{t}</span>))}
    {props.children}
  </div>;
