import type { ReactElement, ReactNode } from "react";
import { pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";

import { O } from "@scripts/fp-ts";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import type { KlassProp } from "@scripts/react/util/classnames";
import { klass, klassPropO, mergeKlassPropO } from "@scripts/react/util/classnames";
import { delProp } from "@scripts/util/delProp";

import { DividerSection, type SectionProps } from "../layout/Section";

type TitleGroupProps = {
  title: Option<string>;
  titleKlasses?: KlassProp;
  subtitle: Option<string>;
};

export const TitleGroup = (props: TitleGroupProps) => {
  return <div {...klass("title-group")}>
    {pipe(
      props.title,
      mapOrEmpty(
        (title: string) => <h3 {...klassPropO("title")(props.titleKlasses)}>{title}</h3>
      ))
    }
    {pipe(
      props.subtitle,
      mapOrEmpty(_ => <small {...klass("subtitle", "gray-500")}>{_}</small>)
    )}
  </div>;
};

export type FieldGroupProps = TitleGroupProps & {
  tip: Option<ReactElement>;
  children?: ReactNode;
  tipHeightAuto?: true;
};

export const FieldGroup = (props: FieldGroupProps) => {
  return (
    <div {...klass("field-group-container")}>
      <div {...klass("field-group")}>
        <TitleGroup {...props} />
        {props.children}
      </div>
      {pipe(
        props.tip,
        mapOrEmpty(
          t => <div {...klass("section-tip", (props.tipHeightAuto ? "h-auto" : ""))}>{t}</div>
        )
      )}
    </div>
  );
};

type FormSectionProps = Omit<SectionProps, "title" | "headerDivider">;
export const FormSection = (props: FormSectionProps) => <DividerSection
  {...delProp(props, "klasses")}
  title={O.none}
  klasses={mergeKlassPropO("form-section")(props.klasses)}
/>;

export const FieldGroupFormSection = (props: FormSectionProps & FieldGroupProps) =>
  <FormSection {...delProp(props, "children")}>
    <FieldGroup {...props}>
      {props.children}
    </FieldGroup>
  </FormSection>;
