import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import type { Dispatch } from "redux";

import { formatS3CdnUrl } from "@scripts/bondlink";
import { E } from "@scripts/fp-ts";
import type { Bank } from "@scripts/generated/models/bank";
import type { Flash } from "@scripts/generated/models/flash";
import type { UserWithRoles } from "@scripts/generated/models/user";
import * as AssetsRouter from "@scripts/generated/routers/assetsRouter";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { AnchorIcon } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { useConfig } from "@scripts/react/context/Config";
import type { BaseActions } from "@scripts/react/state/store";
import { klass } from "@scripts/react/util/classnames";
import { calendar } from "@scripts/routes/routing/ssr/banksites";
import { absoluteUrl } from "@scripts/util/url";

import { SitesAlerts } from "@scripts-ssr/components/Alert";
import { useStickyNav } from "@scripts-ssr/hooks/useStickyNav";

import BLSymbol from "@svgs/bondlink-symbol.svg";
import calendarIcon from "@svgs/calendar.svg";
import exitIcon from "@svgs/exit.svg";
import personIcon from "@svgs/person.svg";


type HeaderPrimaryKlass = "short" | "long" | "medium";
const nameLengthToKlass = (name: string): HeaderPrimaryKlass => {
  return name.length <= 20
    ? "short"
    : name.length <= 35
      ? "medium" : "long";
};

const LogoContainer = (props: {
  bank: Bank;
}) => {
  const config = useConfig();
  return <div {...klass("bank-logo-container")}>
    <div {...klass("bank-logo square")}>
      {pipe(
        props.bank.logoUrl,
        mapOrEmpty(logoUrl =>
          <div {...klass("image")}>
            <img
              src={formatS3CdnUrl(config)(logoUrl)}
              alt={`Logo for ${props.bank.name}`}
            />
          </div>
        )
      )}
      <div {...klass("bank-headers")}>
        <h3 {...klass("headers-primary", nameLengthToKlass(props.bank.name))}>
          {props.bank.name}
        </h3>
      </div>
    </div>
  </div>;
};

const AccountLinks = (props: {
  user: O.Option<UserWithRoles>;
  bank: Bank;
  hasCalendar: boolean;
}) => {
  const config = useConfig();
  const loginRoute = absoluteUrl(config)(V2Router.baseAuthControllerLogin({
    issuerId: O.none,
    bankId: O.some(props.bank.id),
    uhash: O.none,
    reason: O.none,
    redirect: O.none,
  }));
  const logoutRoute = V2Router.baseAuthControllerLogout();

  return <div id="account-links" {...klass("account-links font-sans-normal-500")}>
    {props.hasCalendar && <AnchorIcon
      klasses={"account-link"}
      target="_self"
      route={calendar({
        bankId: props.bank.id,
        bankSlug: props.bank.slug,
      }, O.none)}
      icon={calendarIcon}
      textOrAriaLabel={E.left("Calendar")}
    />}
    {pipe(
      props.user,
      O.map(() => (
        <>
          <AnchorIcon
            target="_self"
            route={{
              route: loginRoute,
              title: "My BondLink",
            }}
            klasses={"account-link"}
            icon={BLSymbol}
          />
          <AnchorIcon
            aria-label="Log Out"
            icon={exitIcon}
            klasses={"account-link log-out"}
            target="_self"
            route={{
              title: "Log out",
              route: logoutRoute,
            }}
          />
        </>
      )),
      O.getOrElse(() => (
        <>
          <AnchorIcon
            icon={BLSymbol}
            klasses={"account-link"}
            target="_self"
            route={{
              title: "Create Account",
              route: loginRoute,
            }}
          />
          <AnchorIcon
            klasses={"account-link"}
            icon={personIcon}
            target="_self"
            route={{
              title: "Log In",
              route: loginRoute,
            }}
          />
        </>
      ))
    )}
  </div>;
};

export const BondlinkBar = (props: {
  dispatch: Dispatch<BaseActions>;
  bank: Bank;
  user: O.Option<UserWithRoles>;
  flash: ReadonlyArray<Flash>;
  hasCalendar: boolean;
}) => {

  const [mainNavRef, mainNavKlass, stuck] = useStickyNav<HTMLDivElement>();

  return (
    <header {...klass("page-header")}>
      <SitesAlerts flash={props.flash} dispatch={props.dispatch} />
      <div {...klass("main-nav", mainNavKlass, stuck ? "sticky" : "")} ref={mainNavRef}>
        <div {...klass("bondlink-bar bank-bondlink-bar container")}>
          <LogoContainer bank={props.bank} />
          <div {...klass("actions")}>
            <div {...klass("d-flex")}>
              <img {...klass("blp-logo")} src={AssetsRouter.assetImgLogosLogoBondlinkPrimaryFullColorSvg().url} alt="BondLink" />
            </div>
            <AccountLinks bank={props.bank} user={props.user} hasCalendar={props.hasCalendar} />
            <hr {...klass("w-100 my-075 d-md-none")} />
          </div>
        </div>
      </div>
    </header>
  );
};
