import Skeleton from "react-loading-skeleton";

import type { SVGString } from "*.svg";

import { O, pipe } from "@scripts/fp-ts";
import type { ReactChild } from "@scripts/react/syntax/react";
import { klass, klassConditional } from "@scripts/react/util/classnames";
import { humanDateFull, type Joda, time as _time } from "@scripts/syntax/date/joda";

import { ContentTypeIcon, type ContentTypeIconProps } from "../activities/ContentTypeDetails";
import { constEmpty, mapOrEmpty } from "../Empty";

type DateProps = {
  isCompact: boolean;
  primary: string;
  secondary: O.Option<ReactChild>;
};

export const LeftDate = (props: DateProps) =>
  <div {...klass("date", props.isCompact ? "d-none" : "d-none-until-md", "d-flex", "flex-col", "align-items-end", "small", "mr-05")}>
    <div>{props.primary}</div>
    {pipe(props.secondary, mapOrEmpty(_ => <div {...klass("w-100")}>{_}</div>))}
  </div>;

type ActivityProps = DateProps & {
  content: ReactChild;
  header: ReactChild;
};

export const Activity = (props: ActivityProps) =>
  <div {...klass("activity")}>
    <div {...klass("date", props.isCompact ? "d-block" : "d-md-none", "small")}>
      {props.primary}{pipe(props.secondary, mapOrEmpty(_ => <>&nbsp;{_}</>))}
    </div>
    <div {...klass("activity-header")}>
      <div {...klass("activity-title font-sans-normal-700")}>{props.header}</div>
    </div>
    {props.content}
  </div>;

export type TimelineItemLargeProps = Pick<ContentTypeIconProps, "icon"> & ActivityProps & {
  iconBgColor: ContentTypeIconProps["backgroundColor"];
};

export const TimelineItemLarge = (props: TimelineItemLargeProps) =>
  <div {...klass("event-container", "d-flex")}>
    <LeftDate isCompact={props.isCompact} primary={props.primary} secondary={props.secondary} />
    <div {...klassConditional("compact", "event-large")(props.isCompact)}>
      <ContentTypeIcon
        backgroundColor={props.iconBgColor}
        icon={props.icon}
        klass={props.isCompact ? "compact" : ""}
      />
      <Activity
        content={props.content}
        header={props.header}
        isCompact={props.isCompact}
        primary={props.primary}
        secondary={pipe(props.secondary, O.map(_ => <>&bull;&nbsp;{_}</>))}
      />
    </div>
  </div>;

export const TimelineItemCompactSkeleton = () =>
  <TimelineItemLarge
    content={<Skeleton count={2} />}
    header={<Skeleton width={"50%"} />}
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    icon={constEmpty as unknown as SVGString}
    iconBgColor="green"
    isCompact={true}
    primary=""
    secondary={O.none}
  />;

const TimelineItemSkeletonBase = (props: Pick<TimelineItemLargeProps, "content" | "secondary">) =>
  <TimelineItemLarge
    content={props.content}
    header={<Skeleton width={"50%"} />}
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    icon={constEmpty as unknown as SVGString}
    iconBgColor="green"
    isCompact={false}
    primary=""
    secondary={props.secondary}
  />;

export const TimelineItemSkeleton = (props: Pick<TimelineItemLargeProps, "content">) =>
  <>
    <div {...klass("d-md-none")}>
      <TimelineItemSkeletonBase
        content={props.content}
        secondary={O.none}
      />
    </div>
    <div {...klass("d-none-until-md")}>
      <TimelineItemSkeletonBase
        content={props.content}
        secondary={O.some(<Skeleton count={2} />)}
      />
    </div>
  </>;

export type TimelineItemProps = Omit<TimelineItemLargeProps, "primary" | "secondary"> & {
  dateTime: Joda.LocalDateTime;
};

export const TimelineItem = (props: TimelineItemProps) => {
  const date = humanDateFull(props.dateTime);
  const time = _time(props.dateTime);

  return (
    <TimelineItemLarge
      primary={date}
      secondary={O.some(time)}
      header={props.header}
      content={props.content}
      icon={props.icon}
      iconBgColor={props.iconBgColor}
      isCompact={props.isCompact}
    />
  );
};

export type TimelineItemSmallProps = ActivityProps;

export const TimelineItemSmall = (props: TimelineItemSmallProps) =>
  <div {...klass("event-container", "d-flex")}>
    <LeftDate isCompact={false} primary={props.primary} secondary={props.secondary} />
    <div {...klass("event-small")}>
      <Activity
        content={props.content}
        header={props.header}
        isCompact={false}
        primary={props.primary}
        secondary={props.secondary}
      />
    </div>
  </div>;
