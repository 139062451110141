import { O, pipe, RA } from "@scripts/fp-ts";
import type { RoadShowSlide } from "@scripts/generated/models/roadshow";
import type { WithId } from "@scripts/generated/models/threadThrough";

export type SlidesState = {
  prevSlide: O.Option<WithId<RoadShowSlide>>;
  activeSlide: O.Option<[WithId<RoadShowSlide>, number]>;
  nextSlide: O.Option<WithId<RoadShowSlide>>;
};

export const prevActiveAndNextSlides = (
  allSlides: ReadonlyArray<WithId<RoadShowSlide>>,
  seekTime: number,
  hasAudio: boolean,
): O.Option<[O.Option<WithId<RoadShowSlide>>, [WithId<RoadShowSlide>, number], O.Option<WithId<RoadShowSlide>>]> =>
  // If the roadshow has audio and the audio hasn't started yet, prevent marking the first slide as active
  (hasAudio && seekTime === 0)
    ? O.none
    : pipe(
    allSlides,
    RA.findFirstMapWithIndex((i, s) => {
      const prevSlideO = RA.lookup(i - 1)(allSlides);
      const nextSlideO = RA.lookup(i + 1)(allSlides);
      return O.fromPredicate(() => seekTime >= s.record.startTime && pipe(
        nextSlideO,
        O.fold(() => true, nextSlide => seekTime < nextSlide.record.startTime),
      ))([prevSlideO, [s, i], nextSlideO]);
    }),
  );
