import { type ReactElement } from "react";
import { singular } from "pluralize";

import { E, O, pipe, RA, RNEA } from "@scripts/fp-ts";
import { type BidSubmissionData } from "@scripts/generated/models/bidSubmissions";
import type { BidSubmissionTemplateData } from "@scripts/generated/models/bidSubmissionTemplates";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { Rfp } from "@scripts/generated/models/rfpBase";
import type { RoadShowData } from "@scripts/generated/models/roadshow";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithId, WithModInfo, WithStatusU } from "@scripts/generated/models/threadThrough";
import type { UserWithRoles } from "@scripts/generated/models/user";
import { AnchorIconUnsafe } from "@scripts/react/components/Anchor";
import { type BadgeProps } from "@scripts/react/components/Badge";
import { useConfig } from "@scripts/react/context/Config";
import { rfpBadgeProps, rfpBidSubmissionBadgeProps, useRfpBidSubmissionState } from "@scripts/react/rfp/rfpBadge";
import { useModal } from "@scripts/react/util/useModal";
import type { UrlInterface } from "@scripts/routes/urlInterface";
import { dateTBDConst, humanDateFull } from "@scripts/syntax/date/joda";
import { dateWithOptionalTimeToString } from "@scripts/syntax/dateWithOptionalTime";
import { rfpBidSubmissionBtnText, rfpUrl } from "@scripts/syntax/rfp";
import { modifyWithStatus } from "@scripts/syntax/threadThrough";
import { currency } from "@scripts/util/currency";

import rfpIcon from "@svgs/rfp.svg";

import { ButtonLinkIcon } from "../Button";
import { mapOrEmpty } from "../Empty";
import { FactsRow, makeFactO } from "../Facts";
import { BidSubmissionModal } from "../form/submit-bid/BidSubmissionModal";
import type { SetBidSubmission } from "../form/submit-bid/BidSubmissionSyntax";
import { ContentItemCardLinks, type ContentItemCardLinksProps, type ContentItemCardSubscriptionPropsO } from "./ContentItemCard";
import { DealCard, type DealCardProps, dealCardRoadshowBadge, dealCardRoadshowsLink } from "./DealCard";

export type RfpCardLink = "details" | "roadshows" | "bidSubmission";

export type RfpCardProps = {
  url: (sitesUrl: UrlInterface<"GET">) => string;
  issuer: Issuer;
  displayIssuer: boolean;
  user: O.Option<UserWithRoles>;
  rfp: WithStatusU<TaggedContent<Rfp>>;
  customRfpsTitle: O.Option<string>;
  roadshows: ReadonlyArray<WithId<TaggedContent<RoadShowData>>>;
  bidSubmissionTemplate: O.Option<WithStatusU<WithModInfo<BidSubmissionTemplateData>>>;
  bidSubmission: O.Option<WithStatusU<WithModInfo<BidSubmissionData>>>;
  setBidSubmission: SetBidSubmission;
  links: ReadonlyArray<RfpCardLink>;
  footer?: ReactElement;
} & Pick<DealCardProps, "klass" | "target" | "leafIcon"> & ContentItemCardSubscriptionPropsO;

export const RfpCard = (props: RfpCardProps) => {
  const config = useConfig();

  const roadshowsNEA = RNEA.fromReadonlyArray(props.roadshows);
  const rUrl = rfpUrl(props.rfp.data.id, props.issuer, config);

  const [modalOpen, openModal, closeModal] = useModal("Bid Submission Modal");

  const showBidSubmissionData = props.links.includes("bidSubmission");
  const bidSubmissionState = useRfpBidSubmissionState(props.rfp.data.id, props.rfp.data.record.data, props.bidSubmissionTemplate, props.bidSubmission);
  const bidSubmissionLinkText = rfpBidSubmissionBtnText(bidSubmissionState);

  return <>
    <DealCard
      klass={props.klass}
      name={props.rfp.data.record.data.name}
      issuer={O.fromPredicate(() => props.displayIssuer)(props.issuer)}
      url={props.url(rUrl)}
      target={props.target}
      leftBadges={RA.compact<BadgeProps>([
        O.some({ text: O.fold(() => "RFP", singular)(props.customRfpsTitle), icon: O.some(rfpIcon), variant: "tall", color: "accent-2-700" }),
        dealCardRoadshowBadge(roadshowsNEA),
        pipe(O.some(bidSubmissionState), O.filter(() => showBidSubmissionData), O.chain(rfpBidSubmissionBadgeProps)),
      ])}
      rightBadge={rfpBadgeProps(props.rfp.data.record.data.biddingState)}
      taggedContent={props.rfp.data.record}
      leafIcon={props.leafIcon}
      subscription={props.subscription}
    >
      <div className="series-wrapper">
        <FactsRow
          items={pipe(
            [
              {
                title: "Bids Due",
                value: pipe(
                  props.rfp.data.record.data.bidsDue,
                  O.fold(dateTBDConst, E.fold(dateWithOptionalTimeToString(humanDateFull), _ => _.format)),
                ),
              },
            ],
            RA.concat(RA.compact([
              makeFactO("Total Loan Amount", props.rfp.data.record.data.loanAmount, currency),
              makeFactO("Service Type", props.rfp.data.record.data.typeOfServicing, _ => _.name),
            ])),
          )}
        />
      </div>
      <ContentItemCardLinks
        links={pipe(
          props.links,
          RA.filterMap((key): O.Option<ContentItemCardLinksProps["links"][number]> => {
            switch (key) {
              case "details":
                return O.some({
                  key,
                  link: <AnchorIconUnsafe
                    key={key}
                    href={rUrl.url}
                    icon={rfpIcon}
                    target={props.target}
                    textOrAriaLabel={E.left("View details")}
                  />,
                });

              case "roadshows":
                return dealCardRoadshowsLink(config, props.issuer, roadshowsNEA, rUrl, props.target);

              case "bidSubmission":
                return pipe(
                  bidSubmissionLinkText,
                  O.map(text => ({
                    key,
                    link: <ButtonLinkIcon
                      key={key}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openModal();
                      }}
                      icon={rfpIcon}
                      textOrAriaLabel={E.left(text)}
                    />,
                  })),
                );

              default: return config.exhaustive(key);
            }
          }),
        )}
      />
      {props.footer}
    </DealCard>
    {pipe(bidSubmissionLinkText, mapOrEmpty(() => <BidSubmissionModal
      dismissAction={closeModal}
      modalOpen={modalOpen}
      successAction={closeModal}
      user={props.user}
      rfp={pipe(props.rfp, modifyWithStatus(_ => _.data))}
      issuer={props.issuer}
      bidSubmissionTemplate={props.bidSubmissionTemplate}
      bidSubmission={props.bidSubmission}
      setBidSubmission={props.setBidSubmission}
      bidSubmissionState={bidSubmissionState}
      subscription={props.subscription}
    />))}
  </>;
};
