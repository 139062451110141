

import { O, pipe } from "@scripts/fp-ts";
import type { Target } from "@scripts/react/components/Anchor";
import { Card, CardBody, type CardProps, handleCardClickWithInternalLinksOrButtons } from "@scripts/react/components/card/Card";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Image } from "@scripts/react/components/Image";
import { type KlassList, klassPropO } from "@scripts/react/util/classnames";
import { openInNewTab, openInSameTab } from "@scripts/routes/router";

type ThumbnailCardProps = Pick<CardProps, "onClick" | "children"> & {
  cardUrlPropsO: O.Option<{ url: string, target: Target }>;
  imgPropsO: O.Option<{ imgUrl: string, altText: string }>;
  cardKlasses?: KlassList;
  bodyKlasses?: KlassList;
};

export const ThumbnailCard = (props: ThumbnailCardProps) => {
  return (
    <Card
      klasses={klassPropO("card-img-small")(props.cardKlasses).className}
      onClick={pipe(
        props.cardUrlPropsO,
        O.map(urlProps =>
          handleCardClickWithInternalLinksOrButtons(() => urlProps.target === "_blank"
            ? openInNewTab(urlProps.url)
            : openInSameTab(urlProps.url)
          )
        ),
        O.toUndefined
      )}
    >
      <CardBody klass={klassPropO("d-block")(props.bodyKlasses).className}>
        {pipe(
          props.imgPropsO,
          mapOrEmpty(imgProps =>
            <Image alt={imgProps.altText} src={imgProps.imgUrl} klasses={["float-right"]} />
          )
        )}
        {props.children}
      </CardBody>
    </Card>
  );
};
