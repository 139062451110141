import type { Key, PropsWithChildren, ReactNode } from "react";
import { pipe } from "fp-ts/lib/function";

import { formatS3CdnUrl } from "@scripts/bondlink";
import { O, RNEA } from "@scripts/fp-ts";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import { AnchorUnsafe, type Target } from "@scripts/react/components/Anchor";
import { Card, handleCardClickWithInternalLinksOrButtons } from "@scripts/react/components/card/Card";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Image } from "@scripts/react/components/Image";
import type { LeafIconAsProp } from "@scripts/react/components/LeafIcon";
import type { SubscriptionToggleBaseProps } from "@scripts/react/components/SubscriptionToggle";
import { SubscriptionToggleIconCard } from "@scripts/react/components/SubscriptionToggle";
import { tooltipDelayTransition } from "@scripts/react/components/Tooltip";
import { useConfig } from "@scripts/react/context/Config";
import { klass, klassNullableToList, type KlassProp, klassPropO } from "@scripts/react/util/classnames";

import { Badge, type BadgeProps } from "../Badge";


export type ContentItemCardSubscriptionProps = {
  isSubscribed: boolean;
  onSubscribe: SubscriptionToggleBaseProps["onSubscribe"];
};

export type ContentItemCardSubscriptionPropsO = {
  subscription: O.Option<ContentItemCardSubscriptionProps>;
};

export type ContentItemCardBadgeProps = BadgeProps & { wrapperKlass?: KlassProp };

export type ContentItemCardProps = PropsWithChildren<{
  klass?: KlassProp;
  link: O.Option<{ url: string, target: Target }>;
  header: {
    leftBadges: ReadonlyArray<ContentItemCardBadgeProps>;
    rightContent: ReactNode;
  };
  issuer: O.Option<Issuer>;
  name: O.Option<string>;
  taggedContent: O.Option<{ content: TaggedContent<unknown> } & LeafIconAsProp>;
} & ContentItemCardSubscriptionPropsO>;

const ItemName = (props: Pick<ContentItemCardProps, "link" | "name" | "taggedContent">) =>
  pipe(
    props.name,
    mapOrEmpty(name => <h5 {...klass("mb-0")}>
      {pipe(
        props.link,
        O.fold(
          (): ReactNode => name,
          ({ url, target }) => <AnchorUnsafe
            href={url}
            title={name}
            target={target}
            externalLinkLocation="none"
          />
        ),
      )}
      {pipe(props.taggedContent, mapOrEmpty(({ content, leafIcon }) => leafIcon({ taggedContent: content, klass: "ml-025" })))}
    </h5>)
  );

const IssuerLogo = (props: { klass?: KlassProp, issuer: Issuer }) => {
  const config = useConfig();

  return pipe(
    props.issuer.logoUrl,
    mapOrEmpty(logoUrl =>
      <Image
        alt={`${props.issuer.name} Logo`}
        src={formatS3CdnUrl(config)(logoUrl)}
        klasses={[...klassNullableToList(props.klass), "deal-issuer-logo"]}
      />
    )
  );
};

export const ContentItemCard = (props: ContentItemCardProps) => {
  const hasNameOrIssuer = O.isSome(props.name) || O.isSome(props.issuer);
  const onClickProps: { onClick?: React.MouseEventHandler } = pipe(
    props.link,
    O.fold(
      () => ({}),
      ({ url, target }) => ({ onClick: handleCardClickWithInternalLinksOrButtons(() => window.open(url, target)) }),
    ),
  );

  return <Card
    klasses={klassPropO(["card-content-item", pipe(props.link, O.map(() => "card-link"))])(props.klass).className}
    {...onClickProps}
  >
    <div className="d-flex justify-content-between">
      <div className="mb-05 d-flex justify-content-between flex-grow">
        <div className="d-flex flex-wrap">
          {props.header.leftBadges.map(b =>
            <span key={b.text} {...klassPropO("d-inline-block mb-05 mr-1")(b.wrapperKlass)}>{Badge(b)}</span>
          )}
        </div>
        <div className="mb-05 flex-no-shrink">{props.header.rightContent}</div>
      </div>
      {pipe(props.subscription, mapOrEmpty(s => <SubscriptionToggleIconCard
        class="bond-offering-toggle"
        containerKlass="ml-05 not-absolute"
        delay={tooltipDelayTransition}
        isSubscribed={s.isSubscribed}
        onSubscribe={s.onSubscribe}
      />))}
    </div>
    {hasNameOrIssuer && <div className="mb-1 d-none d-md-flex justify-content-between align-items-center">
      <div>
        {pipe(props.issuer, mapOrEmpty(i => <h4 className="accent-2-700 mb-05">{i.name}</h4>))}
        <ItemName link={props.link} name={props.name} taggedContent={props.taggedContent} />
      </div>
      {pipe(props.issuer, mapOrEmpty(i => <IssuerLogo issuer={i} />))}
    </div>}
    {hasNameOrIssuer && <div className="mb-1 d-md-none">
      {pipe(
        props.issuer,
        mapOrEmpty(i =>
          <div className="mb-05">
            <IssuerLogo klass="float-right" issuer={i} />
            <h4 className="accent-2-700">{i.name}</h4>
          </div>
        )
      )}
      <ItemName link={props.link} name={props.name} taggedContent={props.taggedContent} />
    </div>}
    {props.children}
  </Card>;
};

export type ContentItemCardLinksProps = {
  links: ReadonlyArray<{ key: Key, link: React.ReactElement }>;
};

export const ContentItemCardLinks = (props: ContentItemCardLinksProps) => pipe(
  props.links,
  RNEA.fromReadonlyArray,
  mapOrEmpty(links =>
    <div className="actions-links">{links.map(({ key, link }) => <div key={key} className="mr-15">{link}</div>)}</div>
  ),
);
