/* eslint-disable react/button-has-type */
import type { Dispatch, JSX, PropsWithChildren, ReactNode, SetStateAction } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import type { ReadonlyNonEmptyArray } from "fp-ts/lib/ReadonlyNonEmptyArray";

import { Static } from "@scripts/bondlinkStatic";
import { constVoid, O, pipe, RNEA } from "@scripts/fp-ts";
import { exitDisclaimer } from "@scripts/generated/domaintables/featureFlags";
import { relatedIssuers as relatedIssuersPage } from "@scripts/generated/domaintables/pages";
import type { Bank } from "@scripts/generated/models/bank";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { LinkableU, PageLink } from "@scripts/generated/models/linkable";
import type { RelatedIssuerWithIssuer } from "@scripts/generated/models/relatedIssuer";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import type { User, UserWithRoles } from "@scripts/generated/models/user";
import * as AssetsRouter from "@scripts/generated/routers/assetsRouter";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { relatedIssuersMeta } from "@scripts/meta/dataMeta";
import { Anchor, AnchorWithChildren } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { NotificationPortal } from "@scripts/react/components/layout/Notifications";
import { Svg } from "@scripts/react/components/Svg";
import { Tooltip } from "@scripts/react/components/Tooltip";
import { useConfig } from "@scripts/react/context/Config";
import type { Klass } from "@scripts/react/util/classnames";
import { klass } from "@scripts/react/util/classnames";
import { useElementSize } from "@scripts/react/util/useElementSize";
import { useModal } from "@scripts/react/util/useModal";
import { useScrollPositionEffect } from "@scripts/react/util/useScrollPosition";
import { getEditableTitleOrDefault } from "@scripts/routes/routing/ssr";
import type { UrlInterface } from "@scripts/routes/urlInterface";
import { urlInterface } from "@scripts/routes/urlInterface";
import { isFFEnabled } from "@scripts/syntax/featureFlags";
import { issuerHomeUrl } from "@scripts/syntax/issuer";
import { getUrl, toAnchor } from "@scripts/syntax/linkable";
import { rotateElement } from "@scripts/util/rotateElement";
import { isServerSide } from "@scripts/util/ssr";
import { absoluteUrl } from "@scripts/util/url";

import { SitesAlerts } from "@scripts-ssr/components/Alert";
import { BondlinkLogoForNav, IssuerLogo, nameLengthToClass } from "@scripts-ssr/components/IssuerLogo";

import menuIcon from "@svgs/menu.svg";
import triangleSmallDownSvg from "@svgs/triangle-small-down.svg";

import { useIssuerSitesDispatch, useIssuerSitesSelector } from "../state/store";
import { ExitDisclaimerModal } from "./disclaimer/exitDisclaimerModal";

const NavLists = (props: {
  containerKlass: Klass;
  closeNav: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  openNav: () => void;
}) => {
  const config = useConfig();
  const relatedIssuers = useIssuerSitesSelector("relatedIssuers");
  const navLinks: ReadonlyNonEmptyArray<[PageLink, ReadonlyArray<LinkableU>]> =
    useIssuerSitesSelector("navLinks");

  return (
    <div {...klass("nav-lists-container", props.containerKlass)}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <div className="nav nav-lists">
        <div className={`container nav-menu ${relatedIssuers.length > 0 ? "related" : ""}`}>
          <nav className="navigation">
            {navLinks.map(([pageLink, links]) =>
              <div key={pageLink.url} className={pageLink.machineName}>
                {toAnchor(config)(pageLink, { klasses: "nav-link-primary" })}
                <ul>
                  {links.map((linkable) =>
                    <li
                      key={getUrl(config)(linkable)}
                      className="nav-link-secondary"
                      onFocus={props.openNav}
                      onBlur={props.closeNav}
                    >{toAnchor(config)(linkable, { klasses: "nav-link-secondary" })}</li>
                  )}
                </ul>
              </div>
            )}
          </nav>
        </div>
      </div>
    </div >
  );
};

export const MainNav = () => {
  const config = useConfig();
  const issuer = useIssuerSitesSelector("issuer");
  const bankO = useIssuerSitesSelector("bank");
  const flash = useIssuerSitesSelector("flash");
  const notifications = useIssuerSitesSelector("notifications");
  const dispatch = useIssuerSitesDispatch();
  const navLinks: ReadonlyNonEmptyArray<[PageLink, ReadonlyArray<LinkableU>]> = useIssuerSitesSelector("navLinks");
  const userWithRolesO = useIssuerSitesSelector("user");
  const bankIdO = O.map<Bank, number>(bank => bank.id)(bankO);
  const userO: O.Option<User> = O.map((userWithRoles: UserWithRoles) => userWithRoles.user)(userWithRolesO);
  const loginParams = {
    bankId: bankIdO,
    issuerId: O.some(issuer.id),
    uhash: O.none,
    reason: O.none,
    redirect: O.none,
  };
  const loginUrl = absoluteUrl(config)(V2Router.baseAuthControllerLogin(loginParams));
  const signupUrl = absoluteUrl(config)(V2Router.baseAuthControllerSignup(loginParams));
  const logoutUrl = V2Router.baseAuthControllerLogout();
  const isBlpIssuer = O.isSome(issuer.bankId);

  const [navIsOpen, setNavIsOpen] = useState(false);
  const [hoverNavHeaders, setHoverNavHeaders] = useState(false);
  const [hoverNavListsContainer, setHoverNavListsContainer] = useState(false);
  const [blBarElement, blBarSize] = useElementSize();
  const [origBlBarHeight, setOrigBlBarHeight] = useState(blBarSize.height);
  const [mainNavStuck, setMainNavStuck] = useState(false);
  const [isPreSticky, setIsPreSticky] = useState<boolean>(false);

  const toggleNav = () => {
    setNavIsOpen(!navIsOpen);
  };

  const openNav = useCallback(() => {
    if (!navIsOpen) {
      setNavIsOpen(true);
    }
  }, [navIsOpen]);

  const closeNav = useCallback(() => {
    if (navIsOpen) {
      setNavIsOpen(false);
    }
  }, [navIsOpen]);

  useEffect(() => {
    if (hoverNavHeaders || hoverNavListsContainer) {
      openNav();
    } else {
      closeNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoverNavHeaders, hoverNavListsContainer]);

  useEffect(() => {
    setOrigBlBarHeight(blBarSize.height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blBarSize.width]);

  const scrollHandler = useCallback((scrollPos: number) => {
    if (scrollPos > origBlBarHeight && !mainNavStuck) {
      stick(setMainNavStuck, setIsPreSticky, navIsOpen, setNavIsOpen);
    } else if (scrollPos < origBlBarHeight && mainNavStuck) {
      unstick(setMainNavStuck, setIsPreSticky);
    }
  }, [origBlBarHeight, mainNavStuck, navIsOpen]);

  useScrollPositionEffect(scrollHandler, 0);

  return <header className="page-header">
    <NotificationPortal notifications={notifications.notifications} dispatch={dispatch} />
    <SitesAlerts flash={flash} dispatch={dispatch} />
    <div
      {...klass(
        "main-nav",
        navIsOpen ? "open" : "",
        isPreSticky ? "pre-sticky" : "",
        mainNavStuck ? "sticky" : ""
      )}
    >
      <div className="bondlink-bar issuer-bondlink-bar container" ref={blBarElement}>
        <LogoContainer />
        <div className="sticky-headers">
          <h2 className="headers-primary">
            <div>
              {O.fold<Bank, ReactNode>(
                () => <div className="issuer-name">{issuer.name}</div>,
                (bank) => <div className={"bank-name"}>{bank.name}</div>
              )(bankO)}
            </div>
          </h2>
          {isBlpIssuer ? null : <nav className="navigation">
            {navLinks.map(([pageLink]) =>
              <div key={pageLink.url} className={pageLink.machineName}>
                {toAnchor(config)(pageLink, { klasses: "nav-link-primary" })}
              </div>
            )}
          </nav>}
        </div>
        <div className={`actions ${isBlpIssuer ? "pb-0" : ""}`}>
          {isBlpIssuer ? null : <div className="home-link mr-05 mr-md-0"><BondlinkLogoForNav /></div>}
          <div className="account-links">
            <LoginLogoutButtons loginUrl={loginUrl} signupUrl={signupUrl} logoutUrl={logoutUrl} user={userO} bank={bankO} />
          </div>
        </div>
        <div className="menu-mobile inverted" onClick={toggleNav}>
          <button className="btn-link menu-mobile-container" onClick={toggleNav}>
            <span className="menu-mobile-text">Menu</span>
            <Svg src={menuIcon} />
          </button>
        </div>
      </div>
      {isBlpIssuer ? null : <>
        <div className="nav nav-headers inverted">
          <div
            className="container nav-menu"
            onMouseEnter={() => setHoverNavHeaders(true)}
            onMouseLeave={() => setHoverNavHeaders(false)}
          >
            <nav className="navigation">
              {navLinks.map(([pageLink]) =>
                <div className={pageLink.machineName} key={pageLink.url}>
                  {toAnchor(config)(pageLink, { klasses: "nav-link-primary" })}
                </div>
              )}
            </nav>
          </div>
        </div>
        <NavLists
          containerKlass="d-none-until-lg"
          onMouseEnter={() => setHoverNavListsContainer(true)}
          onMouseLeave={() => setHoverNavListsContainer(false)}
          openNav={openNav}
          closeNav={closeNav}
        />
        <NavLists
          containerKlass="d-lg-none"
          onMouseEnter={constVoid}
          onMouseLeave={constVoid}
          openNav={openNav}
          closeNav={closeNav}
        />
      </>}
    </div >
  </header >;
};

const stick = (
  setIsStuck: Dispatch<SetStateAction<boolean>>,
  setIsPreSticky: Dispatch<SetStateAction<boolean>>,
  navIsOpen: boolean,
  setNavOpen: Dispatch<SetStateAction<boolean>>
) => {
  const _stick = () => {
    globalThis.setTimeout(() => {
      setIsPreSticky(false);
      setIsStuck(true);
    }, Static.baseTransitionDelay);
  };

  setIsPreSticky(true);

  if (navIsOpen) {
    globalThis.setTimeout(() => {
      setNavOpen(false);
      _stick();
    }, Static.baseTransitionDelay);
  } else {
    _stick();
  }
};

const unstick = (
  setIsStuck: Dispatch<SetStateAction<boolean>>,
  setIsPreSticky: Dispatch<SetStateAction<boolean>>,
) => {
    setIsPreSticky(true);

    globalThis.setTimeout(() => {
      setIsPreSticky(false);
      setIsStuck(false);
    }, Static.baseTransitionDelay);
};


const LogoutButton = (props: { logoutUrl: UrlInterface<"GET"> }) =>
  <Anchor
    target="_self"
    route={{
      title: "Log Out",
      route: props.logoutUrl,
    }}
    klasses="account-link log-out"
  />;

const LoginButton = (props: { loginUrl: UrlInterface<"GET"> }) =>
  <AnchorWithChildren
    target="_self"
    route={{
      title: "Login",
      route: props.loginUrl,
    }}
    klasses="account-link font-sans-normal-700"
  >
    My BondLink
  </AnchorWithChildren>;

const CreateAccountAndLoginButtons = (props: { loginUrl: UrlInterface<"GET">, signupUrl: UrlInterface<"GET"> }) => <>
  <Anchor
    target="_self"
    route={{
      title: "Create Account",
      route: props.signupUrl,
    }}
    klasses="account-link font-sans-normal-700"
  />
  <Anchor
    target="_self"
    route={{
      title: "Login",
      route: props.loginUrl,
    }}
    klasses="account-link"
  />
</>;

const LoginLogoutButtons = (props: {
  user: O.Option<User>;
  bank: O.Option<Bank>;
  loginUrl: UrlInterface<"GET">;
  signupUrl: UrlInterface<"GET">;
  logoutUrl: UrlInterface<"GET">;
}) => O.isSome(props.user)
    ? (
      <>
        <LoginButton loginUrl={props.loginUrl} />
        <LogoutButton logoutUrl={props.logoutUrl} />
      </>
    ) : (
      <CreateAccountAndLoginButtons
        loginUrl={props.loginUrl}
        signupUrl={props.signupUrl}
      />
    );

const LogoContainer = () => {
  const issuer = useIssuerSitesSelector("issuer");
  const bankO = useIssuerSitesSelector("bank");
  const relatedIssuers = useIssuerSitesSelector("relatedIssuers");
  const prefs = useIssuerSitesSelector("prefs");
  const primaryOfficer = useIssuerSitesSelector("primaryOfficer");

  return <div className="issuer-logo-container">
    {pipe(
      bankO,
      O.fold(
        () =>
          <IssuerLogo issuer={issuer} />,
        (bank) =>
          <div className="bank-logo square">
            {mapOrEmpty<string>(
              (logoUrl) => <>
                <div className="image"><img src={logoUrl} alt={`${bank.name} - Official Seal or Logo`} /></div>
                <div className="issuer-headers">
                  <h2 className={`headers-primary ${nameLengthToClass(bank.name, "bank")}`}>{bank.name}</h2>
                  <div className="blp-subheader d-flex"><img src={AssetsRouter.assetImgLogosLogoBondlinkPrimarySvg().url} alt="BondLink" /></div>
                </div>
              </>
            )(bank.logoUrl)}
          </div>
      )
    )}
    {pipe(
      relatedIssuers,
      RNEA.fromReadonlyArray,
      mapOrEmpty((relIssuers) => <RelatedIssuersDropdown relatedIssuers={relIssuers} />),
    )}
    <div className="primary-officer">
      {pipe(
        prefs,
        O.chain(_ => _.customHeaderText1),
        O.fold(
          () => pipe(
            primaryOfficer,
            O.filter(_ => _.data.record.officerPageVisibility),
            mapOrEmpty(_ => <>
              <span className="primary-officer-name">{_.data.record.firstName} {_.data.record.lastName},</span>
              {" "}
              <span className="primary-officer-title">{_.data.record.title}</span>
            </>)
          ),
          _ => <span className="primary-officer-name">{_}</span>
        ),
      )}
    </div>
  </div>;
};

type RelatedIssuersDropdownProps = {
  relatedIssuers: RNEA.ReadonlyNonEmptyArray<WithStatusU<RelatedIssuerWithIssuer>>;
};

const RelatedIssuersDropdown = (props: RelatedIssuersDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const [isFocused, setIsFocused] = useState(false);
  const [selectedIssuer, setSelectedIssuer] = useState<O.Option<WithStatusU<RelatedIssuerWithIssuer>>>(O.none);
  const relatedIssuersDomRef = useRef<HTMLDivElement>(null);
  const currentIssuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const relatedIssuersTitle = getEditableTitleOrDefault(relatedIssuersPage, relatedIssuersMeta)(pages);

  useEffect(() => {
    function globalClickListener(e: Event) {
      const target = e.target as Node | null; // eslint-disable-line @typescript-eslint/consistent-type-assertions
      if (
        target != null
        && !relatedIssuersDomRef.current?.contains(target)
      ) {
        setIsOpen(false);
      }
    }
    document.body.addEventListener("click", globalClickListener);
    return () => {
      document.body.removeEventListener("click", globalClickListener);
    };
  }, []);

  return <div className="related-issuers-filter" ref={relatedIssuersDomRef}>
    <div>
      <div className={`custom-select-wrapper ${isOpen ? "show" : ""}`}>
        <div
          className={`custom-select ${isFocused ? "focus" : ""}`}
          tabIndex={0}
          onClick={toggleOpen}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        >
          <div>
            <span>{relatedIssuersTitle}</span>
            <Svg src={triangleSmallDownSvg} {...rotateElement(isOpen)} style={{ pointerEvents: "none" }} />
          </div>
        </div>
        <div className="custom-select-options">
          <div hidden={true} className={`custom-option ${O.isNone(selectedIssuer) ? "selected" : ""}`}>
            <span>{relatedIssuersTitle}</span>
            <Svg src={triangleSmallDownSvg} {...rotateElement(isOpen)} />
          </div>
          {props.relatedIssuers.map(relatedIssuer =>
            <RelatedIssuerListItem
              currentIssuer={currentIssuer}
              key={relatedIssuer.data.id}
              relatedIssuer={relatedIssuer}
              onClick={() => { toggleOpen(); setSelectedIssuer(O.some(relatedIssuer)); }}
            />
          )}
        </div>
      </div>
    </div>
  </div>;
};

const RelatedIssuerListItem = (props: {
  currentIssuer: Issuer;
  relatedIssuer: WithStatusU<RelatedIssuerWithIssuer>;
  onClick: (relatedIssuer: WithStatusU<RelatedIssuerWithIssuer>) => void;
}) => {
  const relatedIssuerName = props.relatedIssuer.data.record.related.name;
  const outsideLinkO = props.relatedIssuer.data.record.related.outsideLink;

  return <WithRelatedIssuerTooltipOrAnchor
    currentIssuer={props.currentIssuer}
    relatedIssuer={props.relatedIssuer}
    relatedIssuerName={relatedIssuerName}
    outsideLink={outsideLinkO}
  >
    {(action) => <div
      key={props.relatedIssuer.data.id}
      data-value={props.relatedIssuer.data.record.related.name}
      hidden={false}
      className={`custom-option`}
      style={{ position: "relative" }}
      onClick={action}
      data-disabled={O.fold(
        () => O.isNone(outsideLinkO) ? true : null,
        (issuer: Issuer) => !(issuer.published || !props.currentIssuer.published) ? true : null,
      )(props.relatedIssuer.data.record.issuer)}
    >
      <div className="related-issuer" onClick={() => props.onClick(props.relatedIssuer)}>
        {relatedIssuerName}
      </div>
    </div>}
  </WithRelatedIssuerTooltipOrAnchor>;
};

const WithRelatedIssuerTooltipOrAnchor = (props: {
  currentIssuer: Issuer;
  relatedIssuer: WithStatusU<RelatedIssuerWithIssuer>;
  relatedIssuerName: string;
  outsideLink: O.Option<string>;
  children: (action?: () => void) => JSX.Element;
}) => {
  const config = useConfig();

  // If the related issuer has a BondLink site, we'll link to it if either of the following is true:
  //   - The related issuer is published
  //   - The current issuer is not published
  // If the related issuer does not have a BondLink site, we'll link to the `outsideLink` if given.

  return O.fold(
    () =>
      O.fold(
        () =>
          <RelatedIssuerDisabledTooltip
            relatedIssuerName={props.relatedIssuerName}
          >
            {props.children()}
          </RelatedIssuerDisabledTooltip>,
        (outsideLink: string) =>
          <RelatedIssuerExitDisclaimerOrAnchor
            relatedIssuerName={props.relatedIssuerName}
            outsideLink={urlInterface("GET", outsideLink)}
          >
            {props.children}
          </RelatedIssuerExitDisclaimerOrAnchor>
      )(props.outsideLink),
    (issuer: Issuer) =>
      issuer.published || !props.currentIssuer.published
        ? <RelatedIssuerExitDisclaimerOrAnchor
          relatedIssuerName={props.relatedIssuerName}
          outsideLink={issuerHomeUrl(config)(issuer)}
        >
          {props.children}
        </RelatedIssuerExitDisclaimerOrAnchor>
        : <RelatedIssuerDisabledTooltip
          relatedIssuerName={props.relatedIssuerName}
        >
          {props.children()}
        </RelatedIssuerDisabledTooltip>
  )(props.relatedIssuer.data.record.issuer);
};

const RelatedIssuerExitDisclaimerOrAnchor = (props: {
  relatedIssuerName: string;
  outsideLink: UrlInterface<"GET">;
  children: (action?: () => void) => JSX.Element;
}) => {
  const iffs = useIssuerSitesSelector("iffs");
  const [modalOpen, openModal, closeModal] = useModal("Exit Disclaimer Modal");

  return isFFEnabled(exitDisclaimer)(iffs)
    ? <>
      {props.children(openModal)}
      <ExitDisclaimerModal
        modalOpen={modalOpen}
        dismissAction={closeModal}
        outsideLink={props.outsideLink.url}
      />
    </>
    : <AnchorWithChildren
      aria-label={`Visit ${props.relatedIssuerName}`}
      target={"_blank"}
      route={{
        title: `Visit ${props.relatedIssuerName}`,
        route: props.outsideLink,
      }}
      externalLinkLocation={"none"}
    >
      {props.children()}
    </AnchorWithChildren>;
};

const RelatedIssuerDisabledTooltip = (props: PropsWithChildren<{
  relatedIssuerName: string;
}>) =>
  <Tooltip
    appendTo={isServerSide() ? "parent" : document.body}
    delay="default"
    controlOptions={{
      type: "TooltipControlDefault",
      trigger: "mouseenter",
      hideOnClick: true,
    }}
    placement="bottom"
    description={{
      type: "DescriptionContent",
      text: `${props.relatedIssuerName} is a related issuer but does not currently have a public BondLink website.`,
    }}
  >
    {props.children}
  </Tooltip>;
