// This file was generated by postcss-transpiler. Do not edit by hand.

export const icons = {
  ".icon": {
    css: ".icon",
    html: "icon",
    ".background": {
      css: ".background",
      html: "background"
    }
  },
  ".btn": {
    css: ".btn",
    html: "btn",
    attrs: {
      ".icon-right": {
        css: ".icon-right",
        html: "icon-right"
      },
      ".btn-social": {
        css: ".btn-social",
        html: "btn-social"
      }
    },
    ".icon": {
      css: ".icon",
      html: "icon"
    }
  },
  ".btn-small": {
    css: ".btn-small",
    html: "btn-small",
    ".icon": {
      css: ".icon",
      html: "icon"
    }
  },
  ".btn-primary": {
    css: ".btn-primary",
    html: "btn-primary",
    attrs: {
      ".btn-silhouette": {
        css: ".btn-silhouette",
        html: "btn-silhouette"
      }
    },
    ".icon": {
      css: ".icon",
      html: "icon"
    }
  },
  ".icon-large": {
    css: ".icon-large",
    html: "icon-large",
    ".icon": {
      css: ".icon",
      html: "icon"
    }
  },
  ".data-points": {
    css: ".data-points",
    html: "data-points",
    ".icon": {
      css: ".icon",
      html: "icon"
    }
  },
  ".card-media": {
    css: ".card-media",
    html: "card-media",
    ".icon-play": {
      css: ".icon-play",
      html: "icon-play",
      ".icon": {
        css: ".icon",
        html: "icon"
      }
    }
  },
  ".icon-watermark": {
    css: ".icon-watermark",
    html: "icon-watermark"
  },
  ".icon-watermark-hover": {
    css: ".icon-watermark-hover",
    html: "icon-watermark-hover"
  },
  ".icon-circle": {
    css: ".icon-circle",
    html: "icon-circle"
  },
  ".icon-circle-title": {
    css: ".icon-circle-title",
    html: "icon-circle-title"
  }
};
