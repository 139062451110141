import type { ComponentType, RefObject } from "react";
import { PureComponent } from "react";
import { Action, Plugin, PluginHost } from "@devexpress/dx-react-core";
import type { Column, GridProps } from "@devexpress/dx-react-grid";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { downloadExcelExport, makeExportRows } from "@scripts/react/util/export";
import { localDateNow } from "@scripts/syntax/date/joda";
import { exportToCsv } from "@scripts/util/csvExporter";
import type { ExcelExportColumn } from "@scripts/util/xlsx/syntax";

import type { ExportType } from "./DownloadDataButton";
import type { GetCellValue, TableRowMeta } from "./tableSyntax";

export type ExporterProps = Omit<GridProps, "columns" | "rootComponent"> & {
  columns: ReadonlyArray<Column & ExcelExportColumn>;
  fileName: string;
  ref?: RefObject<unknown>;
};

export type ExporterState = {
  isExporting: boolean;
};

export const exportTableCsv = <A, MetaData>(
  rows: ReadonlyArray<A & TableRowMeta<MetaData>>,
  columns: ReadonlyArray<Omit<Column, "getCellValue"> & { getCellValue?: GetCellValue<A, MetaData> } & ExcelExportColumn>,
  fileName: string,
) => {
  const rowsData: unknown[][] = makeExportRows(rows, columns, "csv");
  const csvCols = columns.map(c => pipe(O.fromNullable(c.title), O.getOrElse(() => "")));
  exportToCsv(`${localDateNow().toString()} - ${fileName}.csv`, rowsData, csvCols);
};

class TableExporterBase extends PureComponent<ExporterProps, ExporterState> {
  state = {
    isExporting: false,
    selectedOnly: false,
  };

  export = (config: BLConfigWithLog, type: ExportType) => {
    this.setState({
      isExporting: true,
    });

    switch (type) {
      case "xlsx": {

        return downloadExcelExport(
          this.props.rows,
          this.props.columns,
          this.props.fileName,
          this.props.columns
        );
      }
      case "csv":
        return exportTableCsv(this.props.rows, this.props.columns, this.props.fileName);
    }

    return config.exhaustive(type);
  };

  finishExport = () => {
    // NOTE: we can't update state during the render phase
    window.setTimeout(() => {
      this.setState({
        isExporting: false,
      });
    }, 0);
  };

  render() {
    const { isExporting } = this.state;
    if (!isExporting) return null;
    return (
      <PluginHost>
        <Plugin name="export">
          <Action name="finishExport" action={this.finishExport} />
        </Plugin>
      </PluginHost>
    );
  }
}

export const TableExporter: ComponentType<ExporterProps> = TableExporterBase;

export type TableExporterRef = typeof TableExporter & { export: (config: BLConfigWithLog, type: ExportType) => void };
