import type { ReactElement, ReactNode } from "react";
import { Fragment } from "react";
import type { Dispatch } from "redux";

import { type O, RA } from "@scripts/fp-ts";
import type { Bank } from "@scripts/generated/models/bank";
import type { Flash } from "@scripts/generated/models/flash";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { UserWithRoles } from "@scripts/generated/models/user";
import { toEmpty } from "@scripts/react/components/Empty";
import { NotificationPortal } from "@scripts/react/components/layout/Notifications";
import type { NotificationBaseProps } from "@scripts/react/components/Notification";
import type { NotificationAction } from "@scripts/react/state/notifications";
import type { BaseActions } from "@scripts/react/state/store";
import { klass } from "@scripts/react/util/classnames";

import { Footer } from "../Footer";
import { BondlinkBar } from "./BondLinkBar";
import type { MastheadProps } from "./Masthead";
import { Masthead } from "./Masthead";

type PrimaryChromeProps = Omit<MastheadProps, "variant"> & {
  bank: Bank;
  children: ReactNode;
  dispatch: Dispatch<NotificationAction | BaseActions>;
  flash: ReadonlyArray<Flash>;
  issuerFooter: O.Option<ReactElement>;
  notifications: NotificationBaseProps[];
  user: O.Option<UserWithRoles>;
  issuer: O.Option<Issuer>;
  hasCalendar: boolean;
};

export const PrimaryChrome = (props: PrimaryChromeProps) =>
  <Fragment>
    <BondlinkBar
      dispatch={props.dispatch}
      bank={props.bank}
      user={props.user}
      flash={props.flash}
      hasCalendar={props.hasCalendar}
    />
    <main {...klass("content-container")}>
      <Masthead
        hasAlert={RA.isNonEmpty(props.flash)}
        title={props.title}
        imageO={props.imageO}
        bank={props.bank}
        hasCalendar={props.hasCalendar}
        variant="bank"
      />
      {props.children}
    </main>
    {toEmpty(props.issuerFooter)}
    <Footer disclaimerType="BLP" />
    <NotificationPortal notifications={props.notifications} dispatch={props.dispatch} />
  </Fragment>;
