import { flow } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as t from "io-ts";

// @see https://stackoverflow.com/a/18650828
export function formatBytes(bytes: number, decimals: number = 2): string {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const abbreviateNumber = (value: number): string => {
  return new Intl.NumberFormat(
    "en-US",
    {
      // Allow decimals only when value is >= to 1B. 500m or 1.2b
      minimumFractionDigits: value >= 1_000_000_000 ? 1 : undefined, // eslint-disable-line no-undefined
      notation: "compact",
      compactDisplay: "short",
    })
    .format(value).toLocaleLowerCase();
};

export const sign: (n: number) => O.Option<"−" | "+"> = flow(
  Math.sign,
  (v: number) => (
    v === 0
      ? O.none
      : (v > 0 ? O.some("+") : O.some("−"))
  )
);


export const onlyNumbersToFixed = (decimalPlaces: number) => <A>(value: A) =>
  !t.number.is(value) || value % 1 === 0 ? value : parseFloat(value.toFixed(decimalPlaces));

export const formatPercentage = (decimalPlaces: number) => (value: number) => `${value.toFixed(decimalPlaces)}%`;

export const formatPercentageSingleDecimal = (value: number) => formatPercentage(1)(value);

export const roundO = O.map(Math.round);
