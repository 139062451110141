
import type { PropsWithChildren } from "react";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

const TableBodySortable = (props: PropsWithChildren<{ ids: ReadonlyArray<number> }>) => {
  return (
    <tbody>
      <SortableContext items={props.ids.map(rId => rId.toString())} strategy={verticalListSortingStrategy}>
        {props.children}
      </SortableContext>
    </tbody>
  );
};

export const TableBody = (p: PropsWithChildren<object>) => <tbody>{p.children}</tbody>;

export const TableBodyDraggable = (ids: ReadonlyArray<number>) =>
  (p: PropsWithChildren<object>) => <TableBodySortable {...p} ids={ids} />;
