import type * as O from "fp-ts/lib/Option";

import type { SVGString } from "*.svg";

import { Svg } from "@scripts/react/components/Svg";
import type { Color } from "@scripts/ui/colorTypes";

import { type Klass, klass } from "../util/classnames";
import { mapOrEmpty } from "./Empty";

export type BadgeProps = {
  text: string;
  icon: O.Option<SVGString>;
  variant: "tall" | "short";
} & ({
  color: Color;
} | {
  backgroundKlass: Klass;
});

export const Badge = (props: BadgeProps) => {
  return (
    <span {...klass("badge", "color" in props ? `${props.color}-bg` : props.backgroundKlass, props.variant)}>
      {mapOrEmpty((s: SVGString) => <Svg src={s} />)(props.icon)}
      {props.text}
    </span>
  );
};
