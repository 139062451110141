// This file was generated by postcss-transpiler. Do not edit by hand.

export const headerBase = {
  ".breadcrumb": {
    css: ".breadcrumb",
    html: "breadcrumb",
    ".breadcrumb-li": {
      css: ".breadcrumb-li",
      html: "breadcrumb-li"
    }
  },
  ".header-content-flex": {
    css: ".header-content-flex",
    html: "header-content-flex"
  }
};
