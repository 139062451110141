import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import { esgProgram } from "@scripts/generated/domaintables/featureFlags";
import { aboutPage, type PageU } from "@scripts/generated/domaintables/pages";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { AnchorButton } from "@scripts/react/components/Anchor";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import { klass } from "@scripts/react/util/classnames";
import {
  about,
  type AboutPageData,
  esgTitle as esgTitleFn,
  newsPressReleasesSectionTitle,
  projectsTitle as projectsTitleFn,
  teamTitle as teamTitleFn,
} from "@scripts/routes/routing/ssr/issuersites";
import { aboutSectionJumplinkId, esgProgramJumplinkId, relatedNewsJumplinkId, relatedProjectsJumplinkId, teamJumplinkId } from "@scripts/routes/routing/ssr/issuersitesJumplinkIds";
import { isFFEnabled } from "@scripts/syntax/featureFlags";
import { tagEq } from "@scripts/util/compare";

import { CustomPageSection, makeCustomPageJumplinkId } from "../../../components/CustomPageSection";
import { DirectSitesPageDescriptionLayout } from "../../../components/DirectSitesPageLayout";
import { ProjectsSection } from "../../../components/related-content/ProjectsSection";
import { SidebarLinksSites } from "../../../components/sidebar/SidebarLinks";
import { makeJumplinkO } from "../../../components/sidebar/sidebarLinksSyntax";
import { ContactUsActionSection } from "../../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../../state/store";
import { AboutSection, makeQuickFactsO } from "./AboutSection";
import { ESGSection } from "./ESGSection";
import { NewsSection } from "./NewsSection";
import { TeamSection } from "./TeamSection";

const sidebarLinkIds = {
  aboutSection: aboutSectionJumplinkId,
  esgSection: esgProgramJumplinkId,
  newsSection: relatedNewsJumplinkId,
  projectsSection: relatedProjectsJumplinkId,
  teamSection: teamJumplinkId,
};

const makeAboutSectionTitle = (issuer: Issuer, pages: ReadonlyArray<PageConfig<PageU>>) => pipe(
  pages,
  RA.findFirst(_ => tagEq().equals(_.page, aboutPage)),
  O.chain(_ => _.title),
  O.getOrElse(() => `About ${issuer.name}`),
);

const setupSidebarLinkSections = (
  linkIds: typeof sidebarLinkIds,
  show: { [K in keyof typeof sidebarLinkIds]: boolean },
  aboutTitle: string,
  esgTitle: string,
  newsTitle: string,
  projectsTitle: string,
  teamTitle: string,
  customPageJumpLinks: ReadonlyArray<O.Option<JumpLink>>
): ReadonlyArray<JumpLink> => RA.compact([
  makeJumplinkO(aboutTitle, linkIds.aboutSection, show.aboutSection),
  makeJumplinkO(esgTitle, linkIds.esgSection, show.esgSection),
  makeJumplinkO(newsTitle, linkIds.newsSection, show.newsSection),
  makeJumplinkO(projectsTitle, linkIds.projectsSection, show.projectsSection),
  makeJumplinkO(teamTitle, linkIds.teamSection, show.teamSection),
  ...customPageJumpLinks,
]);

export const AboutPage = (props: AboutPageData) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");
  const aboutPageRouteMeta = about({ issuerId: issuer.id, issuerSlug: issuer.slug });

  const description = aboutPageRouteMeta.description(pages, iffs, issuer);

  const quickFactsDataO = makeQuickFactsO(props.callouts);
  const newsDataO = RNEA.fromReadonlyArray(props.news);
  const projectsDataO = RNEA.fromReadonlyArray(props.projects);
  const teamDataO = pipe(
    props.team,
    RA.filter(_ => _.data.record.officerPageVisibility),
    RNEA.fromReadonlyArray
  );

  const showAboutSection = O.isSome(props.about) || RA.isNonEmpty(props.photos) || O.isSome(quickFactsDataO);
  const showESGSection = isFFEnabled(esgProgram)(iffs);
  const showNewsSection = O.isSome(newsDataO);
  const showProjectsSection = O.isSome(projectsDataO);
  const showTeamSection = O.isSome(teamDataO);

  const customPageJumplinks = pipe(
    props.customPages,
    RA.map(cp => makeJumplinkO(cp.title, makeCustomPageJumplinkId(cp), true))
  );

  const aboutSectionTitle = makeAboutSectionTitle(issuer, pages);
  const esgSectionTitle = esgTitleFn(pages);
  const newsSectionTitle = newsPressReleasesSectionTitle(pages, "News");
  const projectSectionTitle = projectsTitleFn(pages);
  const teamSectionTitle = teamTitleFn(pages);

  const sidebarLinks = setupSidebarLinkSections(
    sidebarLinkIds,
    {
      aboutSection: showAboutSection,
      esgSection: showESGSection,
      newsSection: showNewsSection,
      projectsSection: showProjectsSection,
      teamSection: showTeamSection,
    },
    aboutSectionTitle,
    esgSectionTitle,
    newsSectionTitle,
    projectSectionTitle,
    teamSectionTitle,
    customPageJumplinks
  );

  const isPageEmpty = !showAboutSection
    && !showESGSection
    && !showNewsSection
    && !showProjectsSection
    && !showTeamSection
    && RA.isEmpty(props.customPages);

  return (
    <DirectSitesPageDescriptionLayout
      description={description}
      sidebarContent={
        <>
          <SidebarLinksSites
            headerLinkCustomAnchorContent={O.none}
            jumpLinks={sidebarLinks}
            routeMeta={aboutPageRouteMeta}
          />
          <ContactUsActionSection containerKlass={"d-none-until-md"} />
        </>
      }
    >
      <div {...klass("accent-border-top", "pt-0")}>
        {isPageEmpty ? <div {...klass("mt-1")}>This issuer has not yet added any information to this section.</div> : (
          <>
            {pipe(
              showAboutSection,
              trueOrEmpty(
                <AboutSection
                  about={props.about}
                  photos={props.photos}
                  quickFacts={quickFactsDataO}
                  sectionId={sidebarLinkIds.aboutSection}
                  title={aboutSectionTitle}
                />
              )
            )}
            {pipe(
              showESGSection,
              trueOrEmpty(<ESGSection sectionId={sidebarLinkIds.esgSection} />)
            )}
            {pipe(
              newsDataO,
              mapOrEmpty(_ =>
                <NewsSection
                  news={_}
                  sectionId={sidebarLinkIds.newsSection}
                  title={newsSectionTitle}
                />
              )
            )}
            {pipe(
              projectsDataO,
              mapOrEmpty(_ =>
                <ProjectsSection
                  projects={_}
                  sectionId={sidebarLinkIds.projectsSection}
                  title={projectSectionTitle}
                  truncateLength={4}
                >
                  <AnchorButton
                    externalLinkLocation="none"
                    klasses={"mt-15"}
                    route={{
                      title: `View All ${projectSectionTitle}`,
                      route: SR.issuersitesAboutControllerProjects({
                        issuerId: issuer.id,
                        issuerSlug: issuer.slug,
                      }),
                    }}
                    target="_self"
                    variant="primary"
                  />
                </ProjectsSection>
              )
            )}
            {pipe(
              teamDataO,
              mapOrEmpty(_ => <TeamSection sectionId={sidebarLinkIds.teamSection} team={_} />)
            )}
            {pipe(
              props.customPages,
              RA.map(customPage => <CustomPageSection customPage={customPage} key={customPage.page.name} />)
            )}
          </>
        )}
        <ContactUsActionSection containerKlass={"d-md-none"} />
      </div>
    </DirectSitesPageDescriptionLayout>
  );
};
