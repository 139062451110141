import { useCallback, useState } from "react";
import type { Lazy } from "fp-ts/lib/function";
import { constVoid } from "fp-ts/lib/function";

export type ReloadFn = Lazy<void> & {
  readonly ReloadFn: unique symbol;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const reloadFn = (fn: Lazy<void>) => fn as ReloadFn;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const timeStampNow = () => Date.now() as TimeStamp;

export const constVoidReload = reloadFn(constVoid);

export type TimeStamp = number & {
  readonly TimeStamp: unique symbol;
};

export type ReloadProps = {
  ts: TimeStamp;
  fn: ReloadFn;
};

export const useReload = (): ReloadProps => {
  const [ts, setTs] = useState(timeStampNow());
  const fn = useCallback(() => setTs(timeStampNow()), []);

  return {
    ts,
    fn: reloadFn(fn),
  };
};

const reloadPropsVoid: ReloadProps = {
  ts: timeStampNow(),
  fn: constVoidReload,
};

export const reloadPropsVoidConst = (): ReloadProps => reloadPropsVoid;
