import type { Applicative1 } from "fp-ts/lib/Applicative";
import type { Apply1 } from "fp-ts/lib/Apply";
import type { Lazy } from "fp-ts/lib/function";
import { pipe } from "fp-ts/lib/function";
import type { Functor1 } from "fp-ts/lib/Functor";
import type { Monad1 } from "fp-ts/lib/Monad";
import type { Option } from "fp-ts/lib/Option";
import { none, some } from "fp-ts/lib/Option";
import type { Pointed1 } from "fp-ts/lib/Pointed";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { tagEq } from "@scripts/util/compare";

/**
 * @category model
*/
export type LoadingStatus = { _tag: "loading" };
export type FailedStatus = { _tag: "failed" };
export type SuccessStatus<A> = { _tag: "success", data: A };
export type ResponseStatus<A> = LoadingStatus | FailedStatus | SuccessStatus<A>;

export const loading: LoadingStatus = { _tag: "loading" };
export const failed: FailedStatus = { _tag: "failed" };
export const success: <A>(a: A) => SuccessStatus<A> = <A>(a: A) => ({ _tag: "success", data: a });

/**
 * @category guards
 */
export const isLoading = <A>(fa: ResponseStatus<A>): fa is LoadingStatus => tagEq().equals(fa, loading);

/**
* @category guards
*/
export const isFailed = <A>(fa: ResponseStatus<A>): fa is FailedStatus => tagEq().equals(fa, failed);

/**
* @category guards
*/
export const isSuccess = <A>(fa: ResponseStatus<A>): fa is SuccessStatus<A> => fa._tag === "success";

/**
 * @category destructors
 */
export const matchW = (config: BLConfigWithLog) => <B, A, C>(
  onLoading: Lazy<B>,
  onFailed: Lazy<B>,
  onSuccess: (a: A) => C
) => (r: ResponseStatus<A>): B | C => {
  switch (r._tag) {
    case "failed":
      return onFailed();
    case "loading":
      return onLoading();
    case "success":
      return onSuccess(r.data);
  }
  return config.exhaustive(r);
};

/**
 * @category destructors
 */
export const match = (config: BLConfigWithLog): <A, B>(
  onLoading: Lazy<B>,
  onFailed: Lazy<B>,
  onSuccess: (a: A) => B
) => (r: ResponseStatus<A>) => B => matchW(config);

/**
 * Alias of [`matchW`](#matchw).
 * @category destructors
 */
export const foldW = matchW;

/**
* Alias of [`match`](#match).
* @category destructors
*/
export const fold = match;

/**
 * @category Functor
 */
export const map: <A, B>(f: (a: A) => B) => (r: ResponseStatus<A>) => ResponseStatus<B> =
  (f) => (r) => isSuccess(r) ? success(f(r.data)) : r;

/**
* Apply a function to an argument under a type constructor.
*
* @category Apply
*/
export const ap: <A>(fa: ResponseStatus<A>) => <B>(fab: ResponseStatus<((a: A) => B)>) => ResponseStatus<B> =
  (fa) => (fab) => isSuccess(fa) ? isSuccess(fab) ? success(fab.data(fa.data)) : fab : fa;


/**
* Returns an `A` value if possible
* @category destructors
*/
export const getSuccess: <A>(fa: ResponseStatus<A>) => Option<A> = (fa) => isSuccess(fa) ? some(fa.data) : none;

/**
* @category instances
*/
export const URI = "ResponseStatus";

/**
 * @category instances
 */

export type URI = typeof URI;

declare module "fp-ts/lib/HKT" {
  interface URItoKind<A> {
    readonly [URI]: ResponseStatus<A>;
  }
}

/**
* @category Pointed
*/
export const of: Pointed1<URI>["of"] = success;

// -------------------------------------------------------------------------------------
// non-pipeables
//
const _map: Monad1<URI>["map"] = (fa, f) => pipe(fa, map(f));
const _ap: Monad1<URI>["ap"] = (fab, fa) => pipe(fab, ap(fa));

/**
* @category instances
*/
export const Functor: Functor1<URI> = {
  URI,
  map: _map,
};

/**
* @category instances
*/
export const Pointed: Pointed1<URI> = {
  URI,
  of,
};

/**
* @category instances
*/
export const Apply: Apply1<URI> = {
  URI,
  map: _map,
  ap: _ap,
};

/**
* @category instances
*/
export const Applicative: Applicative1<URI> = {
  URI,
  map: _map,
  ap: _ap,
  of,
};
