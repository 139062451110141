import { useMemo } from "react";

import { O } from "@scripts/fp-ts";
import { type JumpLink, type SidebarJumpLinks, SidebarLinks } from "@scripts/react/components/SidebarLinks";
import type { IssuerSitesRouteMeta } from "@scripts/routes/routing/ssr/issuersites";

import { useIssuerSitesSelector } from "../../state/store";
import {
  makePageHeaderSideBarLinkHandle,
} from "./sidebarLinksSyntax";


export const SidebarLinksSites = <A, O>(props: SidebarJumpLinks & {
  headerLinkCustomAnchorContent: O.Option<string>;
  routeMeta: IssuerSitesRouteMeta<A, O>;
}) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");

  const headerLink: JumpLink = useMemo(() => ({
    link: `#${makePageHeaderSideBarLinkHandle(props.routeMeta)}`,
    anchorContent: O.getOrElse(() => props.routeMeta.title(pages, issuer))(props.headerLinkCustomAnchorContent),
  }), [issuer, pages, props.headerLinkCustomAnchorContent, props.routeMeta]);

  return <SidebarLinks {...props} defaultNavOffset={132} headerLink={headerLink} />;
};
