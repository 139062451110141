import type { PropsWithChildren } from "react";

import { O, pipe } from "@scripts/fp-ts";
import type { Issuer } from "@scripts/generated/models/issuer";

import { Image } from "./Image";
import { SidebarSection } from "./SidebarSection";

export const SidebarAboutSection = (props: PropsWithChildren<{ issuer: Issuer }>) =>
  <SidebarSection
    title="About This Issuer"
    subtitle={O.none}
    text={O.none}
    imageElement={pipe(
      props.issuer.logoUrl,
      O.map(img => (
        <div key={img}>
          <Image
            alt={`Logo for ${props.issuer.name}`}
            klasses={"about-issuer-logo mb-1"}
            src={img}
          />
        </div>
      ))
    )}
  >
    {props.children}
  </SidebarSection>;

