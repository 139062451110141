import type { Markdown } from "@scripts/codecs/markdown";
import { O, pipe } from "@scripts/fp-ts";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Markdown as MarkdownContent } from "@scripts/react/components/Markdown";
import { Modal, type ModalDismissable, type ModalOpenable } from "@scripts/react/components/modal/Modal";

import infoIcon from "@svgs/information.svg";


export const RatingNotesModal = (props: ModalOpenable & ModalDismissable & { notes: O.Option<Markdown> }) => {
  return pipe(
    props.notes,
    mapOrEmpty(n => <Modal
      dismissAction={props.dismissAction}
      id="rating-notes"
      title="Rating Notes"
      icon={O.some(infoIcon)}
      type="primary"
      open={props.modalOpen}
      size="modal-lg"
      body={<MarkdownContent content={n} />}
    />)
  );
};
