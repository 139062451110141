import { elem } from "fp-ts/lib/ReadonlySet";

import type { ContentTagTypeU } from "@scripts/generated/domaintables/contentTagTypes";
import { contentTagTypeOrd } from "@scripts/generated/domaintables/contentTagTypes";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";

export const hasTag = (tag: ContentTagTypeU) => <A>(tc: TaggedContent<A>): boolean =>
  elem(contentTagTypeOrd)(tag)(tc.tags);

export const modifyTaggedContent = <A, B>(f: (a: A) => B) => (tc: TaggedContent<A>): TaggedContent<B> =>
  ({ ...tc, data: f(tc.data) });
