import type { BLConfigWithLog } from "@scripts/bondlink";
import { Eq, flow, N, O, s } from "@scripts/fp-ts";
import type { Media, MediaUploadResponse } from "@scripts/generated/models/media";
import { dateQualifierEq } from "@scripts/syntax/date/dateQualifier";
import { LocalDateEq } from "@scripts/syntax/date/jodaSyntax";

import { withStatusValueEq } from "./threadThrough";

export const mediaUploadResponseEq = Eq.struct<MediaUploadResponse>({
  uri: s.Eq,
  fileSize: N.Eq,
  viewName: s.Eq,
});

export const mediaEq = (config: BLConfigWithLog) => Eq.struct<Media>({
  caption: O.getEq(s.Eq),
  altText: O.getEq(s.Eq),
  mediaDate: dateQualifierEq(config),
  mediaOrder: N.Eq,
  documentTypeId: O.getEq(N.Eq),
  uploadResponse: mediaUploadResponseEq,
  archived: O.getEq(LocalDateEq),
  mediaCategoryId: N.Eq,
});

export const mediaWithStatusEq = flow(mediaEq, withStatusValueEq);
