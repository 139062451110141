import * as O from "fp-ts/lib/Option";

const oneBillion = 1.0e+9;
const oneMillion = 1.0e+6;

export const outstandingDebt = (n: number): string => {
  return Math.abs(n) >= oneBillion
    ? `$${Math.abs(n) / oneBillion} Billion`
    : Math.abs(n) >= oneMillion
      ? `$${Math.abs(n) / 1.0e+6} Million`
      : `${Math.abs(n)}`;
};

export const positiveNonZeroNumberO = (a: number): O.Option<number> => a > 0 ? O.some(a) : O.none;

export const toMillions = (n: number): number => n * 1000000;

export const abbreviate = (n: number): string => n > 999 ? n % 1000 === 0 ? (n / 1000).toFixed(0) + "k" : (n / 1000).toFixed(1) + "k" : `${n}`;
