import { type Dispatch, type SetStateAction, useMemo, useState } from "react";

import type { Markdown } from "@scripts/codecs/markdown";
import { constVoid, E, O, pipe, R, RA, RNEA } from "@scripts/fp-ts";
import type { RatingAgencyU } from "@scripts/generated/domaintables/ratingAgencies";
import type { RatingOutlookU } from "@scripts/generated/domaintables/ratingOutlook";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { RatingRelatedContent, RatingWithRelatedContent } from "@scripts/generated/models/rating";
import type { RatingU } from "@scripts/generated/models/ratingBase";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { AnchorIcon, AnchorUnsafe } from "@scripts/react/components/Anchor";
import { ButtonLinkIcon } from "@scripts/react/components/Button";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Modal, type ModalDismissable, type ModalOpenable } from "@scripts/react/components/modal/Modal";
import { Svg } from "@scripts/react/components/Svg";
import { EmptyCell } from "@scripts/react/components/table/cells/NoDataCell";
import { Table } from "@scripts/react/components/table/Table";
import type { TableColumnRow } from "@scripts/react/components/table/tableSyntax";
import { TabRow, type TabRowItem } from "@scripts/react/components/TabRow";
import { TooltipAnchor } from "@scripts/react/components/Tooltip";
import { klass } from "@scripts/react/util/classnames";
import { type OpenModalO, useModalStableO } from "@scripts/react/util/useModal";
import { type Joda, shortMonthAndYear } from "@scripts/syntax/date/joda";
import type { CommonRatingsByAgency } from "@scripts/syntax/rating";

import documentIcon from "@svgs/document.svg";
import emailIcon from "@svgs/email.svg";
import graphIcon from "@svgs/graph.svg";
import infoIcon from "@svgs/information.svg";
import phoneIcon from "@svgs/telephone.svg";

import { RatingNotesModal } from "./RatingNotesModal";

const makeDocumentLink = (issuer: Issuer, mediaId: number) => SitesRouter.issuersitesReportsControllerDownloadRedirect({ issuerSlug: issuer.slug, issuerId: issuer.id, mediaId });

type ReportsAndNotes = {
  reports: RatingRelatedContent["documents"];
  notes: O.Option<Markdown>;
};

type RatingDetailsTableModel = TableColumnRow<{
  rating: string;
  outlook: RatingOutlookU;
  date: Joda.LocalDate;
  reportsAndNotes: ReportsAndNotes;
  analysts: RatingRelatedContent["analysts"];
}, RatingWithRelatedContent<RatingU>, []>;

const mapRatings = (rating: WithStatusU<RatingWithRelatedContent<RatingU>>): RatingDetailsTableModel["Row"] => ({
  rating: rating.data.record.rating.rating,
  outlook: rating.data.record.rating.ratingOutlook,
  date: rating.data.record.rating.ratingDate,
  reportsAndNotes: {
    reports: rating.data.record.relatedContent.documents,
    notes: rating.data.record.rating.ratingNotes,
  },
  analysts: rating.data.record.relatedContent.analysts,
  __rowId: rating.data.id,
  __klass: O.none,
  __metadata: rating.data.record,
});

const ReportsAndNotesCell = (props: { issuer: Issuer, reportsAndNotes: ReportsAndNotes, openNotesModal: OpenModalO<Markdown> }) =>
  props.reportsAndNotes.reports.length <= 0 && O.isNone(props.reportsAndNotes.notes)
    ? <EmptyCell />
    : <div {...klass("d-flex flex-col gap-05")}>
      {
        props.reportsAndNotes.reports.map(doc =>
          <AnchorIcon
            key={doc.data.data.id}
            icon={documentIcon}
            textOrAriaLabel={E.left(doc.data.data.record.data.uploadResponse.viewName)}
            target="_blank"
            route={{ title: doc.data.data.record.data.uploadResponse.viewName, route: makeDocumentLink(props.issuer, doc.data.data.id) }}
          />
        )
      }
      {pipe(props.reportsAndNotes.notes, mapOrEmpty(n =>
        <ButtonLinkIcon icon={infoIcon} onClick={() => props.openNotesModal(n)} textOrAriaLabel={E.left("Rating Notes")} />
      ))}
    </div>;

const AnalystsCell = (props: { analysts: RatingRelatedContent["analysts"] }) =>
  RA.isEmpty(props.analysts) ? <EmptyCell /> : <div {...klass("d-flex flex-col gap-05")}>
    {props.analysts.map(analyst => <div key={analyst.data.data.id} {...klass("white-space-nowrap")}>
      {analyst.data.data.record.firstName} {analyst.data.data.record.lastName}{
        pipe(
          analyst.data.data.record.phoneNumber,
          mapOrEmpty(n =>
            <TooltipAnchor
              delay="table"
              description={{
                type: "DescriptionContent",
                text: n,
              }}
              headerBar={{
                type: "HeaderBarNoButton",
                title: "Phone",
              }}
              addClassToTargetNode="ml-05"
            >
              <Svg src={phoneIcon} />
            </TooltipAnchor>
          )
        )
      }{
        pipe(
          analyst.data.data.record.email,
          mapOrEmpty(e =>
            <TooltipAnchor
              delay="table"
              description={{
                type: "DescriptionContentElement",
                element: <AnchorUnsafe externalLinkLocation="none" target="_blank" href={`mailto:${e}`} title={e} />,
              }}
              headerBar={{
                type: "HeaderBarNoButton",
                title: "Email",
              }}
              addClassToTargetNode="ml-05"
            >
              <Svg src={emailIcon} />
            </TooltipAnchor>
          )
        )
      }
    </div>)}
  </div>;

const columns = (issuer: Issuer, openNotesModal: OpenModalO<Markdown>): RatingDetailsTableModel["Columns"] => ({
  rating: {
    title: "Rating",
    headerComponent: "title",
    dataCellKlass: O.some("cell-primary"),
    columnExtension: { width: "6rem" },
  },
  outlook: {
    title: "Outlook",
    headerComponent: "title",
    dataCellComponent: r => r.outlook.ratingOutlook,
    dataCellKlass: O.none,
    columnExtension: { width: "8rem" },
  },
  date: {
    title: "Date",
    headerComponent: "title",
    dataCellComponent: r => shortMonthAndYear(r.date),
    dataCellKlass: O.some("white-space-normal word-break-normal"),
  },
  reportsAndNotes: {
    title: "Reports & Notes",
    headerComponent: "title",
    dataCellKlass: O.none,
    dataCellComponent: r => <ReportsAndNotesCell reportsAndNotes={r.reportsAndNotes} openNotesModal={openNotesModal} issuer={issuer} />,
    columnExtension: { width: "12rem" },
  },
  analysts: {
    title: "Analysts",
    headerComponent: () => <div {...klass("text-left")}>Analysts</div>,
    dataCellKlass: O.some("text-left"),
    dataCellComponent: AnalystsCell,
    columnExtension: { width: "12rem" },
  },
});

const makeAgencyOptions = (setCurrentAgency: Dispatch<SetStateAction<RatingAgencyU["_tag"]>>) => RNEA.map(
  (a: RatingAgencyU): TabRowItem<RatingAgencyU["_tag"]> => ({
    title: a.shortName,
    value: a._tag,
    onClick: () => setCurrentAgency(a._tag),
    icon: O.none,
  })
);

const RatingDetailsModalBody = (props: { ratings: RNEA.ReadonlyNonEmptyArray<CommonRatingsByAgency[number]>, name: string, defaultAgency: O.Option<RatingAgencyU>, issuer: Issuer }) => {
  const [currentAgency, setCurrentAgency] = useState(pipe(props.defaultAgency, O.fold(() => props.ratings[0][0]._tag, a => a._tag)));
  const [notesModalOpen, openNote, openNotesModal, closeNotesModal] = useModalStableO<Markdown>("rating notes modal");
  const tabRowItems = makeAgencyOptions(setCurrentAgency)(pipe(props.ratings, RNEA.map(a => a[0])));
  const tableData = useMemo(() => pipe(
    props.ratings,
    RNEA.map((r): [RatingAgencyU["_tag"], CommonRatingsByAgency[number][1]] => [r[0]._tag, r[1]]),
    RA.toArray,
    R.fromEntries,
    R.map(a => a.map(mapRatings))
  ), [props.ratings]);
  return <div>
    <h3 {...klass("mb-15")}>{props.name}</h3>
    <TabRow variant="default" orientation="horizontal" isSm={true} items={tabRowItems} current={currentAgency} klasses={"mb-md-05"} />
    <Table
      data={tableData[currentAgency] ?? []}
      detailCell={O.none}
      exporter={O.none}
      columns={columns(props.issuer, openNotesModal)}
      searchable={O.none}
      sortable={O.none}
      paginate={O.none}
      tableAction={O.none}
      onParamsChanged={constVoid}
      store={O.none}
      variant="issuersites"
    />
    <RatingNotesModal modalOpen={notesModalOpen} dismissAction={closeNotesModal} notes={openNote} />
  </div>;
};

export const RatingDetailsModal = (props: ModalOpenable & ModalDismissable & { ratings: CommonRatingsByAgency, name: string, defaultAgency: O.Option<RatingAgencyU>, issuer: Issuer }) => {
  return RA.isNonEmpty(props.ratings) && <Modal
    dismissAction={props.dismissAction}
    id="rating-details"
    title="Rating Details & History"
    icon={O.some(graphIcon)}
    type="primary"
    open={props.modalOpen}
    size="modal-lg"
    body={<RatingDetailsModalBody issuer={props.issuer} ratings={props.ratings} name={props.name} defaultAgency={props.defaultAgency} />}
  />;
};
