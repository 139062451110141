import type { Dispatch, ReactElement, SetStateAction } from "react";
import { useState } from "react";
import type { Lazy } from "fp-ts/lib/function";
import type * as O from "fp-ts/lib/Option";
import type * as t from "io-ts";

import type { ApiData } from "@scripts/api/methods";
import { toEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import type { ModalDismissable, ModalInitializer } from "@scripts/react/components/modal/Modal";
import { ModalWithMetaDiscard } from "@scripts/react/components/modal/ModalWithDiscard";
import type { TooltipProps } from "@scripts/react/components/Tooltip";
import type { DataCodec, FormState, FormSuccessAction, ResponseCodec, UnsafeFormData } from "@scripts/react/form/form";

export type FormModalDeleteButtonProps = {
  deleteAction: Lazy<void>;
  disabledTooltip: O.Option<TooltipProps>;
  onClick: () => void;
};

export type FormModalDeleteProps = {
  deleteButton: O.Option<FormModalDeleteButtonProps>;
};
export type FormModalActionsDeleteButton = ModalDismissable & FormModalDeleteProps;

export type FormModalSuccessAction<PC extends DataCodec, RC extends ResponseCodec> = (d: UnsafeFormData<PC>, a: ApiData<t.TypeOf<RC>>) => void;

export type CustomProps = object;

export type FormModalFormProps<PC extends DataCodec, RC extends ResponseCodec, CP extends CustomProps = CustomProps> =
  FormModalActionsDeleteButton & CP & { onSuccess: FormSuccessAction<PC, RC> } & {
    state: FormState<PC>;
    setState: Dispatch<SetStateAction<FormState<PC>>>;
  };

export type FormModalProps<PC extends DataCodec, RC extends ResponseCodec, FormProps, CP extends CustomProps = CustomProps> = {
  form: (props: FormModalFormProps<PC, RC, CP>) => ReactElement;
  modalProps: ModalInitializer;
  customFormProps: CP;
  customBody?: ReactElement;
  formHeader: O.Option<ReactElement>;
  formProps: FormProps & { onSuccess: FormSuccessAction<PC, RC> } & FormModalActionsDeleteButton;
  suppressUnsavedChangesModal?: true;
};

export const FormModalDataLoaderBase = <PC extends DataCodec, RC extends ResponseCodec, CP extends CustomProps>(props: FormModalDataLoaderWithStateProps<PC, RC, CP>) =>
  <ModalWithMetaDiscard
    {...props.modalProps}
    open={props.modalProps.open}
    dismissAction={props.formProps.dismissAction}
    initialState={props.initialState}
    confirmDiscardBody={props.customBody}
    suppressUnsavedChangesModal={props.suppressUnsavedChangesModal}
  >
    {({ state, setState }, handleDismiss) => <>
      {toEmpty(props.formHeader)}
      <props.form
        {...props.formProps}
        {...props.customFormProps}
        dismissAction={handleDismiss}
        state={state}
        setState={setState}
        onSuccess={(a, s) => {
          props.formProps.onSuccess(a, s);
          props.formProps.dismissAction();
        }}
      />
    </>}
  </ModalWithMetaDiscard>;

export type FormModalWithResetProps<PC extends DataCodec, RC extends ResponseCodec, CP extends CustomProps> = FormModalDataLoaderProps<PC, RC, CP>;

export type FormModalDataLoaderWithStateProps<PC extends DataCodec, RC extends ResponseCodec, CP extends CustomProps> = FormModalProps<PC, RC, object, CP> & { initialState: FormState<PC> };

export type FormModalDataLoaderProps<PC extends DataCodec, RC extends ResponseCodec, CP extends CustomProps> = FormModalProps<PC, RC, {
  state: FormState<PC>;
  setState: Dispatch<SetStateAction<FormState<PC>>>;
}, CP>;

const FormModalDataLoaderWithStateBase = <PC extends DataCodec, RC extends ResponseCodec, CP extends CustomProps>(props: FormModalDataLoaderWithStateProps<PC, RC, CP>) => {
  const [state, setState] = useState<FormState<PC>>(props.initialState);

  return <FormModalDataLoaderBase
    {...props}
    formProps={{
      ...props.formProps,
      ...props.customFormProps,
      state,
      setState,
    }}
  />;
};

export const FormModalDataLoaderWithState = <PC extends DataCodec, RC extends ResponseCodec, CP extends CustomProps>(props: FormModalDataLoaderWithStateProps<PC, RC, CP>) =>
  trueOrEmpty(<FormModalDataLoaderWithStateBase {...props} />)(props.modalProps.open);

