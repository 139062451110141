import type { AllHTMLAttributes, HTMLAttributes } from "react";
import { untupled } from "fp-ts/lib/function";

import { identity } from "@scripts/fp-ts";

export type WithHTMLAttrs<T, U extends HTMLAttributes<HTMLElement> = HTMLAttributes<HTMLElement>> = T & U;

export type WithAllHTMLAttrs<T, U extends AllHTMLAttributes<HTMLElement> = AllHTMLAttributes<HTMLElement>> = T & U;

export type TagName = keyof HTMLElementTagNameMap;

export type EmptyTagsFromTagName<Tag extends TagName> = readonly [`<${Tag}>`, `</${Tag}>`];

export const emptyTagsFromTagName = <Tag extends TagName>(tag: Tag): EmptyTagsFromTagName<Tag> =>
  [`<${tag}>`, `</${tag}>`] as const;

export const declareTagNames: <Tags extends ReadonlyArray<TagName>>(...tags: Tags) => Tags =
  untupled(identity);

export const isAudioTag = (el: Element): el is HTMLAudioElement => el.nodeName === "AUDIO";
export const isVideoTag = (el: Element): el is HTMLVideoElement => el.nodeName === "VIDEO";
export const isSourceTag = (el: Element): el is HTMLSourceElement => el.nodeName === "SOURCE";
