import { type ReactNode } from "react";
import * as O from "fp-ts/lib/Option";

import type { Disclaimer } from "@scripts/generated/models/disclaimer";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { AnchorButton } from "@scripts/react/components/Anchor";
import { ButtonsContainer, ButtonSecondary } from "@scripts/react/components/Button";
import { Markdown } from "@scripts/react/components/Markdown";
import type { ModalDismissable, ModalOpenable } from "@scripts/react/components/modal/Modal";
import { Modal, modalTitle } from "@scripts/react/components/modal/Modal";
import { useConfig } from "@scripts/react/context/Config";
import { urlInterface } from "@scripts/routes/urlInterface";

import { useIssuerSitesSelector } from "../../state/store";


type ExitDisclaimerModalProps = {
  outsideLink: string;
} & ModalOpenable & ModalDismissable;

export const ExitDisclaimerModal = (props: ExitDisclaimerModalProps) => {
  const config = useConfig();

  return <Modal
    type="primary"
    size="modal-lg"
    id="exit-disclaimer-modal"
    title={modalTitle(config)("You are leaving this site", true)("none")}
    icon={O.none}
    body={<ExitDisclaimerForm {...props} />}
    dismissAction={props.dismissAction}
    open={props.modalOpen}
  />;
};

const exitDisclaimerDefault = "By clicking continue, you confirm you are leaving the site.";

const ExitDisclaimerForm = (props: ExitDisclaimerModalProps) => {
  const exitDisclaimerO = useIssuerSitesSelector("exitDisclaimer");

  return <div className="exit-disclaimer-form">
    <div className="exit-disclaimer-form-body">{
      O.fold(
        (): ReactNode => exitDisclaimerDefault,
        (d: WithStatusU<Disclaimer>) => <Markdown content={d.data.record.data} />,
      )(exitDisclaimerO)}
    </div>
    <ButtonsContainer klasses={O.none} divider>
      <div>
        <AnchorButton
          externalLinkLocation="none"
          onClick={props.dismissAction}
          target={"_blank"}
          route={{
            title: `Confirm`,
            route: urlInterface("GET", props.outsideLink),
          }}
          variant="primary"
        />
        <ButtonSecondary onClick={props.dismissAction}>Cancel</ButtonSecondary>
      </div>
    </ButtonsContainer>
  </div>;
};
