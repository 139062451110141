import { useCallback, useRef } from "react";
import ReCaptcha from "react-google-recaptcha";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as TE from "fp-ts/lib/TaskEither";
import { type Lens } from "monocle-ts";

import { Form } from "@scripts/react/components/form/Form";
import { useConfig } from "@scripts/react/context/Config";
import type { BeforeSubmit, DataCodec, FormProps, FormState, ResponseCodec, UnsafeFormProp } from "@scripts/react/form/form";
import { formDataLens } from "@scripts/react/form/form";
import type { ResponseErrors } from "@scripts/react/form/responseCodecs";

export const CaptchaForm = <PC extends DataCodec, RC extends ResponseCodec>(p:
  Omit<FormProps<PC, RC>, "beforeSubmit">
  & { captchaL: Lens<FormState<PC>["data"], UnsafeFormProp<string>> }
) => {
  const config = useConfig();
  const captchaRef = useRef<ReCaptcha>(null);

  const addReCAPTCHA: BeforeSubmit<PC> = useCallback((s: FormState<PC>) => pipe(
    O.fromNullable(captchaRef.current),
    O.fold(
      () => TE.right(s),
      (current) => pipe(
        O.fromNullable(current.getValue()),
        O.filter(cv => cv.length > 0),
        O.fold(
          () => TE.tryCatch(
            async () => await current.executeAsync(),
            (): ResponseErrors["errors"] => [{ error: { _tag: "RECAPTCHA_ERROR" }, field: "", extra: "" }]
          ),
          (v: string) => TE.right(v),
        ),
        TE.map(
          (v: string | null) => { return formDataLens<PC>().compose(p.captchaL).set((v || ""))(s); }
        )
      )
    ),
  ), [p.captchaL]);

  return <Form {...p} beforeSubmit={addReCAPTCHA}>
    {p.children}
    <ReCaptcha
      ref={captchaRef}
      size="invisible"
      sitekey={config.recaptchaKey}
      badge="inline"
      className="mt-1"
    />
  </Form>;
};
