import { useCallback, useState } from "react";

import { A, O, pipe, RNEA } from "@scripts/fp-ts";
import { submitRfpBid } from "@scripts/generated/domaintables/loginReasons";
import type { DirectRfpSitesData, GenRfpWithRelatedContent, RfpSitesRelatedContent } from "@scripts/generated/models/rfp";
import type { Subscribed } from "@scripts/generated/models/subscribed";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { questionAndAnswerSectionTitle, RFPQuestionsAndAnswers } from "@scripts/react/components/Faq";
import { Participants, participantsSectionTitle } from "@scripts/react/components/offering-pages/OfferingParticipants";
import { makeRoadshowDataO, parseRoadshows, RoadshowsSection, roadshowsSectionTitle } from "@scripts/react/components/offering-pages/RelatedContent";
import { WinningBidSectionSites, winningBidSectionTitle } from "@scripts/react/components/rfp-pages/WinningBidOverview";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import { RfpBadges, useRfpBidSubmissionState } from "@scripts/react/rfp/rfpBadge";
import { openModalFn, useModal } from "@scripts/react/util/useModal";
import { rfp as rfpRouting, type RfpUrlParams } from "@scripts/routes/routing/ssr/issuersites";
import {
  offeringParticipantsJumplinkId,
  questionsJumplinkId,
  relatedContentJumplinkId,
  roadshowsJumplinkId,
  summaryJumplinkId,
  winningBidJumplinkId,
} from "@scripts/routes/routing/ssr/issuersitesJumplinkIds";
import { showQuestionsAndAnswersSection } from "@scripts/syntax/rfp";
import { isDrafted, modifyWithStatus } from "@scripts/syntax/threadThrough";
import { prop } from "@scripts/util/prop";

import { ContactModalCallout } from "@scripts-ssr/components/ContactModal";
import { LoginWallModal } from "@scripts-ssr/components/LoginWall";

import { useRfpSubscribe } from "../../api/watchlist";
import { BidSubmissionSection } from "../../components/BidSubmissionSection";
import { Chrome as DirectIssuerChrome } from "../../components/Chrome";
import { DirectSitesPageLayoutJumpLinks } from "../../components/DirectSitesPageLayout";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { HeaderWithSubscription } from "../../components/offering-pages/Header";
import {
  makeEventsDataO,
  makeNewsDataO,
  makeProjectsDataO,
  RelatedContentSection,
  relatedContentSectionTitle,
  SharedRelatedContent,
} from "../../components/offering-pages/RelatedContent";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { makeJumplinkO } from "../../components/sidebar/sidebarLinksSyntax";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";
import { useOnSubscribe } from "../../syntax/onSubscribe";
import { bidSubmissionLens } from "../../syntax/rfp";
import {
  RfpSummary,
  rfpSummarySectionTitle,
} from "./RfpRelatedContent";

type BankRfp = Subscribed<WithStatusU<TaggedContent<GenRfpWithRelatedContent<RfpSitesRelatedContent>>>>;

type RfpSidebarLinkHandles = {
  summary: string;
  winningBid: string;
  roadshows: string;
  participants: string;
  questions: string;
  related: string;
};

export const setupSidebarLinkSections = (
  sidebarLinkIds: RfpSidebarLinkHandles,
  show: {
    winningBid: boolean;
    roadshows: boolean;
    participants: boolean;
    questions: boolean;
    relatedContent: boolean;
  }
): RNEA.ReadonlyNonEmptyArray<JumpLink> => [
    { link: `#${sidebarLinkIds.summary}`, anchorContent: rfpSummarySectionTitle },
    ...A.compact([
      makeJumplinkO(winningBidSectionTitle, sidebarLinkIds.winningBid, show.winningBid),
      makeJumplinkO(roadshowsSectionTitle, sidebarLinkIds.roadshows, show.roadshows),
      makeJumplinkO(participantsSectionTitle, sidebarLinkIds.participants, show.participants),
      makeJumplinkO(questionAndAnswerSectionTitle, sidebarLinkIds.questions, show.questions),
      makeJumplinkO(relatedContentSectionTitle, sidebarLinkIds.related, show.relatedContent),
    ]),
  ];

export type DirectRfpProps = Required<RfpUrlParams> & DirectRfpSitesData;

const DirectRfp = (props: DirectRfpProps) => {
  const issuer = useIssuerSitesSelector("issuer");
  const iffs = useIssuerSitesSelector("iffs");
  const user = useIssuerSitesSelector("user");
  const makeOnSubscribe = useRfpSubscribe();
  const page = rfpRouting({ issuerSlug: issuer.slug, issuerId: issuer.id, rfpId: props.rfpId });
  const [rfp, setRfp] = useState(props.rfp);
  const rfpProps = rfp.data.data.record.data;

  const [isSubscribed, onSubscribe, setIsSubscribed] = useOnSubscribe(props.rfp.subscribed, makeOnSubscribe, props.rfpId);
  const [loginWallOpen, openLoginWall] = useModal("Login Wall Modal");
  const [modalOpen, openModal, closeModal] = useModal("Bid Submission Modal");

  const roadshowDataO = makeRoadshowDataO(iffs, parseRoadshows(rfpProps.relatedContent.roadShows));
  const participantsDataO = RNEA.fromReadonlyArray(props.participants);
  const projectsDataO = makeProjectsDataO(iffs, rfpProps.relatedContent.projects);
  const eventsDataO = makeEventsDataO(iffs, rfpProps.relatedContent.events);
  const newsDataO = makeNewsDataO(iffs, rfpProps.relatedContent.news);
  const documentsDataO = RNEA.fromReadonlyArray(rfpProps.relatedContent.documents.map(_ => _.data));
  const linksDataO = RNEA.fromReadonlyArray(rfpProps.relatedContent.externalLinks);
  const programsDataO = rfpProps.relatedContent.program;

  const bidSubmissionState = useRfpBidSubmissionState(
    rfp.data.data.id,
    rfp.data.data.record.data.rfp,
    rfp.data.data.record.data.relatedContent.bidSubmissionTemplate,
    rfp.data.data.record.data.relatedContent.bidSubmission,
  );

  const setSubscribedToRfp = useCallback(() => {
    if (O.isSome(user)) {
      setIsSubscribed(true);
    }
  }, [setIsSubscribed, user]);

  const showQuestionsAndClarificationsSection = showQuestionsAndAnswersSection(rfpProps.rfp, rfpProps.relatedContent.faqs);
  const showRelatedContent = O.isSome(projectsDataO) || O.isSome(eventsDataO) || O.isSome(newsDataO);

  const sidebarLinkIds = {
    summary: summaryJumplinkId,
    winningBid: winningBidJumplinkId,
    roadshows: roadshowsJumplinkId,
    participants: offeringParticipantsJumplinkId,
    questions: questionsJumplinkId,
    related: relatedContentJumplinkId,
  };

  const sidebarLinks = setupSidebarLinkSections(
    sidebarLinkIds,
    {
      winningBid: rfpProps.rfp.showWinningBid,
      roadshows: O.isSome(roadshowDataO),
      participants: O.isSome(participantsDataO),
      questions: showQuestionsAndClarificationsSection,
      relatedContent: showRelatedContent,
    }
  );

  const loginRedirect = SR.issuersitesRfpsControllerRfp({
    issuerSlug: issuer.slug,
    issuerId: issuer.id,
    rfpId: rfp.data.data.id,
  }).url;

  const openBidModal = openModalFn(() => {
    pipe(
      user,
      O.fold(
        openLoginWall,
        openModal
      )
    );
  });

  return <DirectSitesPageLayoutJumpLinks
    headerComponent={<>
      <RfpBadges
        rfp={rfp.data.data.record.data.rfp}
        bidSubmissionState={bidSubmissionState}
      />
      <HeaderWithSubscription
        isSubscribed={isSubscribed}
        onSubscribe={onSubscribe}
        taggedContent={O.some(rfp.data.data.record)}
        title={rfpProps.rfp.name}
        viewAllRoute={O.some({
          urlInterface: SR.issuersitesRfpsControllerRfps({ issuerSlug: issuer.slug, issuerId: issuer.id }),
          itemType: O.none,
        })}
        subscribeDisabled={isDrafted(rfp.data)}
      />
    </>}
    sidebarContent={
      <>
        <SidebarLinksSites
          headerLinkCustomAnchorContent={O.some(rfp.data.data.record.data.rfp.name)}
          routeMeta={page}
          jumpLinks={sidebarLinks}
        />
        <GetAlertsActionSection containerKlass={"d-none-until-md"} />
      </>
    }
  >
    <RfpSummary
      documentsO={documentsDataO}
      linksO={linksDataO}
      rfp={rfp}
      programsO={programsDataO}
      sidebarLinkHandle={sidebarLinkIds.summary}
    />
    <BidSubmissionSection
      user={user}
      rfp={pipe(rfp.data, modifyWithStatus(_ => _.data.rfp))}
      bidSubmissionTemplate={rfp.data.data.record.data.relatedContent.bidSubmissionTemplate}
      bidSubmission={rfp.data.data.record.data.relatedContent.bidSubmission}
      setBidSubmission={s => setRfp(bidSubmissionLens<BankRfp>().set(O.some(s))(rfp))}
      issuer={issuer}
      bidSubmissionState={bidSubmissionState}
      subscription={O.some({ isSubscribed, onSubscribe })}
      setSubscribedToRfp={setSubscribedToRfp}
      modalOpen={modalOpen}
      openModal={openBidModal}
      closeModal={closeModal}
    />
    <LoginWallModal
      modalOpen={loginWallOpen}
      redirect={O.some(loginRedirect)}
      reason={O.some(submitRfpBid)}
    />
    <WinningBidSectionSites
      rfp={rfp.data.data.record.data.rfp}
      sidebarLinkHandle={O.some(winningBidJumplinkId)}
    />
    <RoadshowsSection
      issuer={issuer}
      roadshows={roadshowDataO}
      sidebarLinkHandle={sidebarLinkIds.roadshows}
      leafIcon={IssuerSitesLeafIcon}
    />
    {pipe(
      participantsDataO,
      mapOrEmpty(participants =>
        <Participants
          participants={participants}
          title="Participants"
          sidebarLinkHandle={sidebarLinkIds.participants}
        />
      )
    )}
    <RFPQuestionsAndAnswers
      rfpData={rfp.data.data.record.data}
      contactCallout={<ContactModalCallout
        bondOfferingId={O.none}
        btnText="Contact Us"
        header="Contact Us"
        introText={<>Use this form to ask questions or get more information from <strong>{issuer.name}</strong> about “<strong>{rfp.data.data.record.data.rfp.name}</strong>.”</>}
        issuer={issuer}
        klasses={"mt-0"}
        rfpId={O.some(props.rfpId)}
        user={O.map(prop("user"))(user)}
      />}
    />
    {pipe(
      showRelatedContent,
      trueOrEmpty(
        <RelatedContentSection sectionId={sidebarLinkIds.related}>
          <SharedRelatedContent
            events={eventsDataO}
            news={newsDataO}
            projects={projectsDataO}
          />
        </RelatedContentSection>
      )
    )}
  </DirectSitesPageLayoutJumpLinks>;

};

export const DirectRfpWithChrome = (props: DirectRfpProps) => {
  const issuer = useIssuerSitesSelector("issuer");
  const page = rfpRouting({ issuerSlug: issuer.slug, issuerId: issuer.id, rfpId: props.rfp.data.data.id });

  return <DirectIssuerChrome routeMeta={page}><DirectRfp {...props} /></DirectIssuerChrome>;
};
