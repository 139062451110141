import type { DataTypeProviderProps } from "@devexpress/dx-react-grid";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import DOMPurify from "isomorphic-dompurify";

import { UnsafeContent } from "../../UnsafeContent";

const WbrFormatter = ({ value }: DataTypeProvider.ValueFormatterProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return typeof value === "string"
    ? <UnsafeContent content={DOMPurify.sanitize(value).replace(/_/g, "_<wbr />")} />
    : value;
};

export const WbrDataTypeProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={WbrFormatter}
    {...props}
  />
);
