import { gtagClickEvent } from "@scripts/analytics";
import { type Breakpoint } from "@scripts/bondlinkStatic";
import type { Markdown } from "@scripts/codecs/markdown";
import { E, type O, pipe, RNEA } from "@scripts/fp-ts";
import type { RatingAgencyU } from "@scripts/generated/domaintables/ratingAgencies";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { Media } from "@scripts/generated/models/media";
import { ButtonLinkIcon } from "@scripts/react/components/Button";
import { DataHorizontal, DataPointSmall } from "@scripts/react/components/Data";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { klass } from "@scripts/react/util/classnames";
import type { OpenModalO } from "@scripts/react/util/useModal";
import { useModalStableO } from "@scripts/react/util/useModal";
import type { UrlInterface } from "@scripts/routes/urlInterface";
import { shortMonthAndYear } from "@scripts/syntax/date/joda";
import type { CommonRatingsByAgency } from "@scripts/syntax/rating";

import documentIcon from "@svgs/document.svg";
import ratingIcon from "@svgs/graph.svg";
import infoIcon from "@svgs/information.svg";

import { AnchorIcon, AnchorIconLayout } from "../Anchor";
import { Tooltip } from "../Tooltip";
import { RatingDetailsModal } from "./RatingDetailsModal";
import { RatingNotesModal } from "./RatingNotesModal";

export type DocumentDownloadRoute = (mediaId: number, media: Media) => UrlInterface<"GET">;

export type RatingBlockProps = {
  documentDownloadRoute: DocumentDownloadRoute;
};

export const RatingBlock = (props: {
  rating: CommonRatingsByAgency[number];
  openDetailsModal: OpenModalO<RatingAgencyU>;
  openNotesModal: OpenModalO<Markdown>;
} & RatingBlockProps) => {
  return <div>
    <h5 {...klass("truncate truncate-1")}>{props.rating[0].shortName}</h5>
    <div
      {...klass(
        "accent-2-700-bg inverted font-sans-normal-700 pb-1 pt-1 border-radius-1 data-point-large-text text-center"
      )}
    >
      {props.rating[1][0].data.record.rating.rating}
    </div>
    <div {...klass("mb-05 mt-05")}>
      <DataHorizontal>
        <DataPointSmall
          title="Outlook"
          value={props.rating[1][0].data.record.rating.ratingOutlook.ratingOutlook}
        />
        <DataPointSmall
          title="Date"
          value={shortMonthAndYear(props.rating[1][0].data.record.rating.ratingDate)}
        />
      </DataHorizontal>
    </div>
    <div className="d-flex flex-col gap-05">
      {pipe(
        props.rating[1][0].data.record.relatedContent.documents,
        RNEA.fromReadonlyArray,
        mapOrEmpty(_ =>
          _.length > 1
            ? <Tooltip
              delay="default"
              headerBar={{ type: "HeaderBarNoButton", title: "Rating Reports" }}
              description={{
                type: "DescriptionContentElement",
                element:
                  <div {...klass("bl-tooltip-content", "mb-05", "d-flex", "flex-col", "gap-05")}>
                    {_.map((report) =>
                      <AnchorIcon
                        key={report.data.data.id}
                        icon={documentIcon}
                        onClick={() => gtagClickEvent("download")(`Ratings Document Download - ${report.data.data.record.data.uploadResponse.viewName}`)}
                        route={{
                          title: report.data.data.record.data.uploadResponse.viewName,
                          route: props.documentDownloadRoute(report.data.data.id, report.data.data.record.data),
                        }}
                        target="_blank"
                      />
                    )}
                  </div>,
              }}
            >
              <AnchorIconLayout
                disabled={false}
                icon={documentIcon}
                textOrAriaLabel={E.left("Rating Reports")}
              />
            </Tooltip>
            : <AnchorIcon
              icon={documentIcon}
              onClick={() => gtagClickEvent("download")(`Ratings Document Download - ${_[0].data.data.record.data.uploadResponse.viewName}`)}
              route={{
                title: "Rating Report",
                route: props.documentDownloadRoute(_[0].data.data.id, _[0].data.data.record.data),
              }}
              target="_blank"
            />
        )
      )}
      {pipe(
        props.rating[1][0].data.record.rating.ratingNotes,
        mapOrEmpty(_ =>
          <ButtonLinkIcon
            onClick={() => props.openNotesModal(_)}
            icon={infoIcon}
            textOrAriaLabel={E.left("Rating Notes")}
          />
        )
      )}
      <ButtonLinkIcon
        onClick={() => props.openDetailsModal(props.rating[0])}
        icon={ratingIcon}
        textOrAriaLabel={E.left("Rating Details")}
      />
    </div>
  </div>;
};

export const defaultRatingBlockBreakpoint: Breakpoint = "lg";

export const RatingBlockSection = (props: {
  ratings: CommonRatingsByAgency;
  name: O.Option<string>;
  modalName: string;
  issuer: Issuer;
  breakpoint?: Breakpoint;
} & RatingBlockProps) => {
  const [detailsModalOpen, defaultRatingAgency, openDetailsModal, closeDetailsModal] = useModalStableO<RatingAgencyU>("ratings block section details modal");
  const [notesModalOpen, openNote, openNotesModal, closeNotesModal] = useModalStableO<Markdown>("rating notes modal");
  return <div>
    {pipe(props.name, mapOrEmpty(n => <h3 {...klass("mb-1")}>{n}</h3>))}
    {props.ratings.length <= 0
      ? <p>There are currently no ratings available.</p>
      : <div {...klass("grid grid-sx-2 rg-2")}>
        {props.ratings.map(rating =>
          <div key={rating[1][0].data.id} {...klass(`c-xl-6 c-${props.breakpoint ?? defaultRatingBlockBreakpoint}-8 c-sm-12 c-24`)}>
            <RatingBlock documentDownloadRoute={props.documentDownloadRoute} rating={rating} openDetailsModal={openDetailsModal} openNotesModal={openNotesModal} />
          </div>
        )}
        <RatingDetailsModal
          modalOpen={detailsModalOpen}
          defaultAgency={defaultRatingAgency}
          dismissAction={closeDetailsModal}
          ratings={props.ratings}
          name={props.modalName}
          issuer={props.issuer}
        />
        <RatingNotesModal
          modalOpen={notesModalOpen}
          dismissAction={closeNotesModal}
          notes={openNote}
        />
      </div>
    }
  </div >;
};
