import { O, pipe, RA } from "@scripts/fp-ts";
import type { PageU } from "@scripts/generated/domaintables/pages";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import { type PageTitles, pageTitlesC } from "@scripts/generated/models/pageTitles";
import { tagEq } from "@scripts/util/compare";

import { getPage } from "./pageConfig";

const isPageTitles = (x: PageTitles | ReadonlyArray<PageConfig<PageU>>): x is PageTitles => pipe(
  RA.head<unknown>(x),
  O.fold(() => true, pageTitlesC.type.is),
);

export const getCustomTitleO = (page: PageU) => (pages: PageTitles | ReadonlyArray<PageConfig<PageU>>): O.Option<string> =>
  isPageTitles(pages)
    ? pipe(pages, RA.findFirstMap(([p, t]) => tagEq().equals(page, p) ? O.some(t) : O.none))
    : pipe(pages, getPage(page), O.chain(p => p.title));
