import { formatS3CdnUrl } from "@scripts/bondlink";
import type { MarkdownTag } from "@scripts/codecs/markdown";
import { identity, O, pipe, RA, RNEA } from "@scripts/fp-ts";
import type { BondProgramWithRelatedContent } from "@scripts/generated/models/bondProgram";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { AnchorButton } from "@scripts/react/components/Anchor";
import { ButtonsContainer } from "@scripts/react/components/Button";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { FactsRow, makeFactO } from "@scripts/react/components/Facts";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import { useConfig } from "@scripts/react/context/Config";
import { klass, type KlassList, klassPropO } from "@scripts/react/util/classnames";
import { program as programRoute, programs, type ProgramsPageDataC } from "@scripts/routes/routing/ssr/issuersites";
import { makeJumplinkId } from "@scripts/routes/routing/ssr/issuersitesJumplinkIds";

import bondIcon from "@svgs/bond.svg";
import rfpIcon from "@svgs/rfp.svg";

import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { makeJumplink } from "../../components/sidebar/sidebarLinksSyntax";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

const makeProgramFacts = (program: WithStatusU<BondProgramWithRelatedContent>) => RA.compact([
  makeFactO(
    "CUSIP-6",
    program.data.record.program.cusip6,
    identity
  ),
  makeFactO(
    "Sector",
    program.data.record.program.sector,
    sector => sector.name
  ),
  makeFactO(
    "Bonds",
    RNEA.fromReadonlyArray(program.data.record.relatedContent.offerings),
    bs => bs.length.toString(),
    bondIcon
  ),
  makeFactO(
    "RFPs",
    RNEA.fromReadonlyArray(program.data.record.relatedContent.rfps),
    rs => rs.length.toString(),
    rfpIcon,
  ),
]);

const makeProgramJumplinkId = (program: WithStatusU<BondProgramWithRelatedContent>) => makeJumplinkId(`program-${program.data.id}`);

const ProgramFacts = (props: { program: WithStatusU<BondProgramWithRelatedContent>, klasses: KlassList }) => pipe(
  makeProgramFacts(props.program),
  RNEA.fromReadonlyArray,
  mapOrEmpty(facts =>
    <div {...klassPropO("mb-1")(props.klasses)}>
      <FactsRow
        items={facts}
        variant="accent"
      />
    </div>
  )
);

const ProgramOverviewPreview = (props: {
  overview: MarkdownTag;
  sizeVariant: "sm" | "lg";
}) => {
  const variantKlasses = props.sizeVariant === "sm"
    ? ["truncate-4", "d-md-none"]
    : ["truncate-3", "d-none-until-md"];
  return (
    <Markdown
      content={props.overview}
      klasses={[
        "last-child-mb-0",
        "truncate",
        "mb-1",
      ].concat(variantKlasses)}
    />
  );
};

const ProgramPhoto = (props: {
  klasses: KlassList;
  previewPhoto: BondProgramWithRelatedContent["relatedContent"]["photos"][number];
  programName: string;
}) => {
  const config = useConfig();

  return (
    <div {...klassPropO("program-image")(props.klasses)}>
      <img
        src={formatS3CdnUrl(config)(props.previewPhoto.data.data.record.uploadResponse.uri)}
        alt={pipe(
          props.previewPhoto.data.data.record.altText,
          O.alt(() => props.previewPhoto.data.data.record.caption),
          O.getOrElse(() => `Preview image for ${props.programName}.`)
        )}
      />
    </div>
  );
};

const ProgramSection = (props: { program: WithStatusU<BondProgramWithRelatedContent> }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const previewPhoto = RA.head(props.program.data.record.relatedContent.photos);
  const overviewO = props.program.data.record.program.overview;

  const programRouteMeta = programRoute({
    issuerSlug: issuer.slug,
    issuerId: issuer.id,
    programId: props.program.data.id,
  });

  const programTitle = programRouteMeta.title(pages);

  return (
    <AccentDividerSection
      title={O.some(props.program.data.record.program.name)}
      klasses={"accent-border-top"}
      sectionId={makeProgramJumplinkId(props.program)}
    >
      <ProgramFacts program={props.program} klasses={["d-md-none"]} />
      <div {...klass("d-flex")}>
        <div>
          <ProgramFacts program={props.program} klasses={["d-none-until-md"]} />
          {pipe(
            overviewO,
            O.fold(
              () => pipe(
                previewPhoto,
                mapOrEmpty(_ =>
                  <div {...klass("d-flex")}>
                    <ProgramPhoto
                      klasses={["mb-1"]}
                      previewPhoto={_}
                      programName={props.program.data.record.program.name}
                    />
                  </div>
                )
              ),
              overview =>
                <>
                  <ProgramOverviewPreview overview={overview} sizeVariant="sm" />
                  <ProgramOverviewPreview overview={overview} sizeVariant="lg" />
                </>
            )
          )}
          <ButtonsContainer klasses={["pt-0", "mt-0"]}>
            <AnchorButton
              externalLinkLocation="none"
              target="_self"
              route={{
                title: `View ${programTitle}`,
                route: SR.issuersitesBondProgramsControllerBondProgram({
                  issuerSlug: issuer.slug,
                  issuerId: issuer.id,
                  programId: props.program.data.id,
                }),
              }}
              variant="primary"
              klasses={"mt-0"}
            />
          </ButtonsContainer>
        </div>
        {O.isSome(overviewO) && pipe(
          previewPhoto,
          mapOrEmpty(_ =>
            <ProgramPhoto
              klasses={["ml-1", "ml-lg-2"]}
              previewPhoto={_}
              programName={props.program.data.record.program.name}
            />
          )
        )}
      </div>
    </AccentDividerSection>
  );
};

export const ProgramsPage = (props: { programs: ProgramsPageDataC["_A"] }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");
  const programsRouteMeta = programs({ issuerId: issuer.id, issuerSlug: issuer.slug });

  const jumplinks = pipe(
    props.programs,
    RA.map(program =>
      makeJumplink(
        program.data.record.program.name,
        makeProgramJumplinkId(program)
      )
    )
  );

  return (
    <DirectSitesPageDescriptionLayout
      description={programsRouteMeta.description(pages, iffs, issuer)}
      sidebarContent={
        <>
          <SidebarLinksSites
            headerLinkCustomAnchorContent={O.none}
            jumpLinks={jumplinks}
            routeMeta={programsRouteMeta}
          />
          <GetAlertsActionSection containerKlass={"d-none-until-md"} />
        </>
      }
    >
      {pipe(
        props.programs,
        RA.map(program => <ProgramSection key={program.data.id} program={program} />)
      )}
    </DirectSitesPageDescriptionLayout>
  );
};
