import { pipe } from "fp-ts/lib/function";
import type { CreativeWork, Event, Organization, Thing, WebSite, WithContext } from "schema-dts";

import { type BLConfigWithLog, formatS3CdnUrl } from "@scripts/bondlink";
import type { MarkdownTag } from "@scripts/codecs/markdown";
import { O } from "@scripts/fp-ts";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { Joda } from "@scripts/syntax/date/joda";

import { coerceHtmlAsString } from "./html";
import { issuerHomeUrl } from "./issuer";
import { markdownToHtml } from "./markdown";

export type SchemaOrgOutput<T extends Thing = Thing> = ReadonlyArray<WithContext<T>>;

export const makeSchemaOrgWebsiteWithContext = (siteName: string, alternateName: ReadonlyArray<string>): WithContext<WebSite> => ({
  "@context": "https://schema.org",
  "@type": "WebSite",
  "name": siteName,
  "alternateName": alternateName,
});

export const makeSchemaOrgEventWithContext = (eventProps: Omit<Event, "@type">): WithContext<Event> => ({
  "@context": "https://schema.org",
  "@type": "Event",
  ...eventProps,
});

export const issuerToSchemaOrgOrganization = (issuer: Issuer, config: BLConfigWithLog): Organization => ({
  "@type": "Organization",
  name: issuer.name,
  url: issuerHomeUrl(config)(issuer).url,
  logo: pipe(
    issuer.logoUrl,
    O.map(formatS3CdnUrl(config)),
    O.toUndefined
  ),
});

export const makeSchemaOrgCreativeWork = (creativeWorkProps: Omit<CreativeWork, "@type">): WithContext<CreativeWork> => ({
  "@context": "https://schema.org",
  "@type": "CreativeWork",
  isAccessibleForFree: false,
  ...creativeWorkProps,
});

export const getSchemaDescription = (
  config: BLConfigWithLog
) => O.fold(
  (): Partial<{ description: string }> => ({}),
  (_: MarkdownTag) => ({ description: pipe(_, markdownToHtml(config), coerceHtmlAsString) })
);

export const getSchemaEndDate = O.fold(
  (): Partial<{ endDate: string }> => ({}),
  (_: Joda.LocalDate) => ({ endDate: _.toString() }),
);
