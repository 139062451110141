import { useState } from "react";

import { O } from "@scripts/fp-ts";

import type { MakeDealOnSubscribe } from "../api/watchlist";
import { useIssuerSitesSelector } from "../state/store";

export const useOnSubscribe = (subscribed: boolean, makeOnSubscribe: MakeDealOnSubscribe, subscriptionId: number) => {
  const issuer = useIssuerSitesSelector("issuer");
  const user = useIssuerSitesSelector("user");
  const userSubscribedToIssuer = useIssuerSitesSelector("userSubscribed");
  const [isSubscribed, setIsSubscribed] = useState(subscribed);

  const onSubscribe = makeOnSubscribe(
    { issuerId: O.some(issuer.id), bankId: O.none },
    !isSubscribed,
    subscriptionId,
    setIsSubscribed,
    user,
    userSubscribedToIssuer
  );
  return [isSubscribed, onSubscribe, setIsSubscribed] as const;
};
