import { pipe } from "fp-ts/lib/function";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { makeDeepLinkModalWithDataUrl } from "@scripts/codecs/deepLinkTarget";
import { E, flow, O } from "@scripts/fp-ts";
import { sitesEventModal } from "@scripts/generated/domaintables/deepLinkTypes";
import type { BondOfferingWithRelatedContent } from "@scripts/generated/models/bondOffering";
import type { EventWithRelatedContent } from "@scripts/generated/models/eventWithRelatedContent";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { urlInterface } from "@scripts/routes/urlInterface";
import { issuerSiteAbsUrl } from "@scripts/syntax/issuer";
import type { RfpWithCardRelatedContent } from "@scripts/syntax/rfp";
import { getSchemaDescription, getSchemaEndDate, issuerToSchemaOrgOrganization, makeSchemaOrgEventWithContext } from "@scripts/syntax/schemaOrg";
import { deepLink } from "@scripts/util/url";

import { upcomingEventsJumplinkId } from "./issuersitesJumplinkIds";

export const makeBondSchemaOrgEventO = (
  data: WithStatusU<TaggedContent<BondOfferingWithRelatedContent>>,
  config: BLConfigWithLog,
  issuer: Issuer
) => pipe(
  data.data.record.data.offering.firstSaleDate,
  O.chain(O.getLeft),
  O.map(firstSaleDate =>
    makeSchemaOrgEventWithContext({
      ...getSchemaDescription(config)(data.data.record.data.offering.notes),
      eventStatus: "EventScheduled",
      eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
      keywords: "bond offering,credit,offering,bond sale,issuance,municipal bond,muni bond,muni issuer,government bond,municipal transaction,municipal debt,tax-free bonds,municipal securities",
      location: {
        "@type": "VirtualLocation",
        url: issuerSiteAbsUrl(config)(issuer, O.none)(SR.issuersitesBondOfferingsControllerOffering)({ offeringId: data.data.id }).url,
      },
      name: data.data.record.data.offering.name,
      organizer: issuerToSchemaOrgOrganization(issuer, config),
      startDate: firstSaleDate.date.toString(),
      ...pipe(
        data.data.record.data.offering.lastClosingDate,
        O.map(dq => dq.date),
        getSchemaEndDate
      ),
      url: issuerSiteAbsUrl(config)(issuer, O.none)(SR.issuersitesBondOfferingsControllerOffering)({ offeringId: data.data.id }).url,
    })
  )
);

export const makeRfpSchemaOrgEventO = (
  data: WithStatusU<TaggedContent<RfpWithCardRelatedContent>>,
  config: BLConfigWithLog,
  issuer: Issuer
) => pipe(
  data.data.record.data.rfp.dateListed,
  O.map(dateListed =>
    makeSchemaOrgEventWithContext({
      ...getSchemaDescription(config)(data.data.record.data.rfp.projectDescription),
      eventStatus: "EventScheduled",
      eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
      keywords: "RFP,RFQ,RFI,request for proposal,request for qualification,credit,municipal contract,government contract,rfp bids,direct loans,municipal loans,private placements,project proposals,municipal financial services,municipal banking services,municipal notes,revenue notes,municipal proposal",
      location: {
        "@type": "VirtualLocation",
        url: issuerSiteAbsUrl(config)(issuer, O.none)(SR.issuersitesRfpsControllerRfp)({ rfpId: data.data.id }).url,
      },
      name: data.data.record.data.rfp.name,
      organizer: issuerToSchemaOrgOrganization(issuer, config),
      startDate: dateListed.toString(),
      ...pipe(
        data.data.record.data.rfp.bidsDue,
        O.chain(flow(E.swap, O.fromEither)),
        O.map(bidsDue => bidsDue.date),
        getSchemaEndDate,
      ),
      url: issuerSiteAbsUrl(config)(issuer, O.none)(SR.issuersitesRfpsControllerRfp)({ rfpId: data.data.id }).url,
    })
  )
);

export const makeEventSchemaOrgEventO = (
  data: WithStatusU<EventWithRelatedContent>,
  config: BLConfigWithLog,
  issuer: Issuer
) => {
  const makeEventUrlInterface = pipe(
    data.data.record.event.eventBlurb,
    O.fold(
      () => deepLink({
        route: SR.issuersitesAboutControllerNewsEvents,
        type: "Basic",
        selector: upcomingEventsJumplinkId,
      }),
      () => (params: { issuerSlug: string, issuerId: number }) =>
        urlInterface(
          "GET",
          makeDeepLinkModalWithDataUrl(SR.issuersitesAboutControllerNewsEvents(params))({
            modalId: sitesEventModal,
            data: data,
          }, [])
        ),
    ),
    issuerSiteAbsUrl(config)(issuer, O.none)
  );

  return pipe(
    data.data.record.event.eventDate,
    O.map(eventDate =>
      makeSchemaOrgEventWithContext({
        ...getSchemaDescription(config)(data.data.record.event.eventBlurb),
        eventStatus: "EventScheduled",
        eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
        keywords: "investor conference,investor call",
        location: {
          "@type": "VirtualLocation",
          url: makeEventUrlInterface({}).url,
        },
        name: data.data.record.event.eventTitle,
        organizer: issuerToSchemaOrgOrganization(issuer, config),
        startDate: eventDate.toString(),
        ...getSchemaEndDate(data.data.record.event.endDate),
        url: makeEventUrlInterface({}).url,
      })
    )
  );
};
