import { type Dispatch, type SetStateAction, useCallback, useMemo, useState } from "react";
import type { CarouselProps as RRCarouselProps } from "react-responsive-carousel";
import { Carousel } from "react-responsive-carousel";
import pluralize from "pluralize";

import { formatS3CdnUrl } from "@scripts/bondlink";
import type { RNEA } from "@scripts/fp-ts";
import { E, O, pipe, RA } from "@scripts/fp-ts";
import type { Media } from "@scripts/generated/models/media";
import { emptyStrConst } from "@scripts/syntax/none";

import camera from "@svgs/camera.svg";
import chevronNext from "@svgs/chevron-next.svg";
import chevronPrevious from "@svgs/chevron-previous.svg";
import closeX from "@svgs/close-x.svg";

import { useConfig } from "../context/Config";
import { klass, klassConditional } from "../util/classnames";
import type { CloseModalFn } from "../util/useModal";
import { useModal } from "../util/useModal";
import { ButtonLinkIcon } from "./Button";
import { Empty } from "./Empty";
import { Image } from "./Image";
import { Portal } from "./Portal";
import { Svg } from "./Svg";

const customCarouselDefaultProps = {
  infiniteLoop: true,
  showThumbs: false,
  showIndicators: false,
  showStatus: false,
  showArrows: false,
} satisfies Partial<RRCarouselProps>;

const SlideImage = (props: {
  image: Media;
}) => <Image src={props.image.uploadResponse.uri} alt={O.getOrElse(emptyStrConst)(props.image.altText)} />;


const CarouselModal = (props: {
  images: RNEA.ReadonlyNonEmptyArray<Media>;
  isOpen: boolean;
  closeModal: CloseModalFn;
  currentSlide: number;
  setCurrentSlide: Dispatch<SetStateAction<number>>;
}) => {

  const changeSlide = useCallback((n: number, current: number) => pipe(props.images, RA.lookup(n), O.fold(() => current, () => n)), [props.images]);
  const closeAndReset = () => {
    props.setCurrentSlide(0);
    props.closeModal();
  };

  const images = useMemo(() => props.images.map((s) => <SlideImage key={s.mediaOrder} image={s} />), [props.images]);
  const thumbs = useMemo(() =>
    <div {...klass("thumbnails")}>
      {props.images.map((i, idx) => <div
        key={i.uploadResponse.uri}
        {...klassConditional("current", ["thumbnail", "d-flex", "flex-col"])(idx === props.currentSlide)}
        onClick={() => props.setCurrentSlide((s) => changeSlide(idx, s))}
      >
        <SlideImage image={i} />
      </div>)}
    </div>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeSlide, props.currentSlide, props.setCurrentSlide, props.images]);

  return (
    <Portal id={"image-carousel-modal"}>
      <div {...klassConditional("show", ["image-carousel-modal", "d-flex", "inverted"])(props.isOpen)}>
        <div {...klass("d-flex", "justify-content-between", "p-1", "carousel-controls")}>
          <div {...klass("d-flex", "gap-05", "align-items-center")}>
            <ButtonLinkIcon
              {...klass("control-arrow")}
              onClick={() => props.setCurrentSlide((s) => changeSlide(s - 1, s))}
              icon={chevronPrevious}
              textOrAriaLabel={E.right("Previous slide")}
            />
            <span {...klass("control-text")}>
              {`${props.currentSlide + 1} of ${props.images.length}`}
            </span>
            <ButtonLinkIcon
              {...klass("control-arrow")}
              onClick={() => props.setCurrentSlide((s) => changeSlide(s + 1, s))}
              icon={chevronNext}
              textOrAriaLabel={E.right("Next slide")}
            />
          </div>
          <ButtonLinkIcon
            {...klass("control-arrow")}
            onClick={closeAndReset}
            icon={closeX}
            textOrAriaLabel={E.right("Close carousel")}
          />
        </div>
        <Carousel
          {...customCarouselDefaultProps}
          selectedItem={props.currentSlide}
          onChange={props.setCurrentSlide}
        >
          {images}
        </Carousel>
        {thumbs}
      </div>
    </Portal>
  );
};

export type ImageCarouselModalProps = {
  images: RNEA.ReadonlyNonEmptyArray<Media>;
  isMulti?: true;
};
export const ImageCarouselModal = (props: ImageCarouselModalProps) => {
  const config = useConfig();
  const [isOpen, open, close] = useModal("Image Carousel Modal");

  const [currentSlide, setCurrentSlide] = useState(0);

  return <div {...klassConditional("multi", "image-gallery-container")(props.isMulti ?? false)}>
    {(props.isMulti ? props.images : props.images.toSpliced(1)).map((image, index) =>
      <div
        key={image.uploadResponse.uri}
        {...klass("image-gallery-preview", "inverted", "mb-0")}
        style={{
          backgroundImage: `url(${formatS3CdnUrl(config)(image.uploadResponse.uri)})`,
          backgroundPosition: "center center",
        }}
        onClick={() => { setCurrentSlide(index); open(); }}
      >
        {!props.isMulti && <div {...klass("gallery-link")}>
          <Svg src={camera} />
          {`View ${props.images.length} ${pluralize("photo", props.images.length)}`}
        </div>}
      </div>
    )}
    {isOpen
      ? <CarouselModal
        isOpen={isOpen}
        closeModal={close}
        images={props.images}
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
      />
      : <Empty />
    }
  </div>;
};
