import { O, pipe, RA, type RNEA } from "@scripts/fp-ts";
import type { Issuer } from "@scripts/generated/models/issuer";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { Anchor, AnchorButton, AnchorIcon } from "@scripts/react/components/Anchor";
import { Grid, GridCol } from "@scripts/react/components/layout/Grid";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { OfficerInfo } from "@scripts/react/components/OfficerSection";
import { ThumbnailCard } from "@scripts/react/components/ThumbnailCard";
import { klass } from "@scripts/react/util/classnames";
import { type AboutPageData, teamTitle } from "@scripts/routes/routing/ssr/issuersites";
import { phoneWithExtension } from "@scripts/syntax/phoneNumber";
import { deepLink } from "@scripts/util/url";

import userIcon from "@svgs/person.svg";

import { useIssuerSitesSelector } from "../../../state/store";

const teamMemberDefaultAltText = (teamMember: AboutPageData["team"][number]["data"]["record"], issuer: Issuer) =>
  `Image of ${teamMember.firstName} ${teamMember.lastName}, ${teamMember.title} for ${issuer.name}.`;

const TeamCard = (props: { teamMember: AboutPageData["team"][number] }) => {
  const issuer = useIssuerSitesSelector("issuer");

  const teamMemberRecord = props.teamMember.data.record;

  const teamMemberRoute = deepLink({
    route: SR.issuersitesAboutControllerTeam,
    type: "Basic",
    selector: `anchor-team-member-${props.teamMember.data.id}`,
  })({ issuerId: issuer.id, issuerSlug: issuer.slug });

  return (
    <ThumbnailCard
      bodyKlasses={["h-100"]}
      cardKlasses={["card-link"]}
      cardUrlPropsO={O.some({
        target: "_self",
        url: teamMemberRoute.url,
      })}
      imgPropsO={pipe(
        teamMemberRecord.media,
        O.map(_ => ({
          imgUrl: _.uploadResponse.uri,
          altText: pipe(
            _.altText,
            O.getOrElse(() => teamMemberDefaultAltText(teamMemberRecord, issuer))
          ),
        }))
      )}
    >
      <div {...klass("d-flex", "flex-col", "justify-content-between", "h-100")}>
        <div>
          <h5 {...klass("m-0")}>
            <Anchor
              target={"_self"}
              route={{
                title: `${teamMemberRecord.firstName} ${teamMemberRecord.lastName}`,
                route: teamMemberRoute,
              }}
            />
          </h5>
          <OfficerInfo
            title={teamMemberRecord.title}
            phoneWithExtension={phoneWithExtension(teamMemberRecord.phoneNumber, teamMemberRecord.phoneExtension)}
            email={teamMemberRecord.email}
          />
        </div>
        <AnchorIcon
          icon={userIcon}
          target={"_self"}
          route={{
            title: "View Bio",
            route: teamMemberRoute,
          }}
          klasses={["align-self-start"]}
        />
      </div>
    </ThumbnailCard>
  );
};


type TeamSectionProps = {
  team: RNEA.ReadonlyNonEmptyArray<AboutPageData["team"][number]>;
  sectionId: string;
};

export const TeamSection = (props: TeamSectionProps) => {
  const pages = useIssuerSitesSelector("pages");
  const issuer = useIssuerSitesSelector("issuer");
  const sectionTitle = teamTitle(pages);

  return <AccentDividerSection title={O.some(sectionTitle)} sectionId={props.sectionId}>
    <Grid klasses={"card-grid"} attrs={O.none}>
      {pipe(
        props.team,
        RA.map(_ =>
          <GridCol cols={[".c-24", ".c-lg-12"]} klasses={[]} key={_.data.id}>
            <TeamCard teamMember={_} />
          </GridCol>
        )
      )}
    </Grid>
    <AnchorButton
      externalLinkLocation="none"
      target="_self"
      route={{
        title: `View ${sectionTitle}`,
        route: SR.issuersitesAboutControllerTeam({
          issuerId: issuer.id,
          issuerSlug: issuer.slug,
        }),
      }}
      variant="primary"
      klasses={"mt-2"}
    />
  </AccentDividerSection>;
};
