import { useState } from "react";

import { O, pipe, RNEA } from "@scripts/fp-ts";
import type { BondsSsrData } from "@scripts/generated/models/bondsSsrData";
import type { Issuer } from "@scripts/generated/models/issuer";
import * as ISR from "@scripts/generated/routers/sitesRouter";
import { AnchorButton } from "@scripts/react/components/Anchor";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { klass } from "@scripts/react/util/classnames";

import { useBondSubscribe } from "../../api/watchlist";
import { IssuerSitesBondCard } from "../../components/BondCard";
import { useIssuerSitesSelector } from "../../state/store";

const NoActiveBonds = (props: { isBondArchiveEnabled: boolean }) =>
  <div>
    There are currently no upcoming bond sales listed
    {props.isBondArchiveEnabled
      ? ", but you can view past offerings in the Bond Archive."
      : "."
    }{" "}
    Add this issuer to your watchlist to get alerts about important updates.
  </div>;

const BondCard = (props: { bond: BondsSsrData["offerings"][number] }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const user = useIssuerSitesSelector("user");
  const userSubscribedToIssuer = useIssuerSitesSelector("userSubscribed");

  const makeOnSubscribe = useBondSubscribe();

  const [isSubscribed, setIsSubscribed] = useState(props.bond.subscribed);

  return (
    <div  {...klass("c-24")}>
      <IssuerSitesBondCard
        issuer={issuer}
        bond={props.bond}
        isSubscribed={isSubscribed}
        onSubscribe={makeOnSubscribe(
          { issuerId: O.some(issuer.id), bankId: O.none },
          !isSubscribed,
          props.bond.data.data.id,
          setIsSubscribed,
          user,
          userSubscribedToIssuer
        )}
      />
    </div>
  );
};

const ArchivedBondsAnchor = (props: { issuer: Issuer }) =>
  <AnchorButton
    target={"_self"}
    route={{
      title: "View Bond Archive",
      route: ISR.issuersitesBondOfferingsControllerArchivedBonds({
        issuerId: props.issuer.id,
        issuerSlug: props.issuer.slug,
      }),
    }}
    variant="primary"
  />;

export const bondSalesSectionTitle = "Bond Sales";
export const BondSales = (props: {
  activeBonds: BondsSsrData["offerings"];
  sidebarLinkId: string;
  sectionTitle: string;
  isBondArchiveEnabled: boolean;
}) => {
  const issuer = useIssuerSitesSelector("issuer");

  return (
    <AccentDividerSection
      klasses={"accent-border-top"}
      sectionId={props.sidebarLinkId}
      title={O.some(props.sectionTitle)}
    >
      {pipe(
        RNEA.fromReadonlyArray(props.activeBonds),
        O.fold(
          () => <NoActiveBonds isBondArchiveEnabled={props.isBondArchiveEnabled} />,
          _ =>
            <div {...klass("grid", "card-grid")}>
              {pipe(
                _,
                RNEA.map(bond => <BondCard bond={bond} key={bond.data.data.id} />
                )
              )}
            </div>
        )
      )}
      {props.isBondArchiveEnabled && <ArchivedBondsAnchor issuer={issuer} />}
    </AccentDividerSection>
  );
};
