import { Fragment, type PropsWithChildren } from "react";

import type { SVGString } from "*.svg";

import { O, pipe } from "@scripts/fp-ts";

import { klass, klassNullableToList } from "../util/classnames";
import { AnchorUnsafe } from "./Anchor";
import type { DataHorizontalVariant, DataPointProps, DataVerticalVariant } from "./Data";
import { DataHorizontal, DataPoint, DataSidebar, DataTitle, DataValue, DataVertical } from "./Data";
import type { TooltipProps } from "./Tooltip";

export type FactType = Omit<DataPointProps, "variant">;

type FactsProps = { items: ReadonlyArray<FactType> };

export const makeFactTooltipLinkPropsO = (fieldUrlO: O.Option<string>, linkTextO: O.Option<string>) => pipe(
  fieldUrlO,
  O.map((url: string): PropsWithChildren<TooltipProps> => ({
    description: {
      type: "DescriptionContentElement",
      element: <>
        <div {...klass("mb-05")}>
          <strong>Source</strong>
        </div>
        <AnchorUnsafe
          externalLinkLocation="none"
          target={"_blank"}
          href={url}
          title={pipe(linkTextO, O.getOrElse(() => url))}
        />
      </>,
    },
    delay: "default",
  })),
);

export const makeFactO = <A,>(
  title: string,
  valO: O.Option<A>,
  getValue: (a: A) => string,
  icon?: SVGString
) => O.map((a: A): FactType => ({
  title,
  value: getValue(a),
  icon,
}))(valO);

export const FactsRow = (props: FactsProps & Pick<DataHorizontalVariant, "variant">) =>
  <DataHorizontal variant={props.variant}>
    {props.items.map(_ =>
      <DataPoint
        key={_.title}
        klasses={klassNullableToList(_.klasses).concat(_.reverse ?? false ? ["d-flex", "flex-col-reverse"] : [])}
        title={_.title}
        tooltipProps={_.tooltipProps}
        value={_.value}
        valueKlasses={_.valueKlasses}
        icon={_.icon}
      />
    )}
  </DataHorizontal>;

export const FactsColumn = (props: FactsProps & Pick<DataVerticalVariant, "variant">) =>
  <DataVertical variant={props.variant}>
    {props.items.map(_ =>
      <Fragment key={_.title}>
        <DataTitle title={_.title} tooltipProps={_.tooltipProps} />
        <DataValue value={_.value} valueKlasses={_.valueKlasses} />
      </Fragment>
    )}
  </DataVertical>;

export const FactsSideBar = (props: FactsProps & Pick<DataVerticalVariant, "variant">) =>
  <DataSidebar variant={props.variant}>
    {props.items.map(_ =>
      <DataPoint
        key={_.title}
        title={_.title}
        tooltipProps={_.tooltipProps}
        value={_.value}
        valueKlasses={_.valueKlasses}
      />
    )}
  </DataSidebar>;
