import type { Dispatch, PropsWithChildren, ReactElement, RefObject, SetStateAction } from "react";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import * as E from "fp-ts/lib/Either";
import * as Eq from "fp-ts/lib/Eq";
import { constVoid, pipe } from "fp-ts/lib/function";
import * as n from "fp-ts/lib/number";
import * as O from "fp-ts/lib/Option";
import * as R from "fp-ts/lib/Record";
import * as S from "fp-ts/lib/string";
import { useStableMemo } from "fp-ts-react-stable-hooks";

import type { SVGString } from "*.svg";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { Static } from "@scripts/bondlinkStatic";
import { allC, anyC, type FilterOperationType, noneC } from "@scripts/codecs/filter";
import { ButtonIcon, ButtonLinkIcon } from "@scripts/react/components/Button";
import { Svg } from "@scripts/react/components/Svg";
import type { KlassProp } from "@scripts/react/util/classnames";
import { isKlassesList, klass, klassConditional, klassPropO } from "@scripts/react/util/classnames";
import { isTagged, tagEq } from "@scripts/util/compare";
import type { NonOption } from "@scripts/util/nonOption";
import { rotateSVG, rotateSVGWithDefaults } from "@scripts/util/rotateElement";

import { portalTable as pt } from "@styles/components/_portal-table";

import chevronDown from "@svgs/chevron-down.svg";
import triangleSmallDown from "@svgs/triangle-small-down.svg";

import type { DataCodec, FormState } from "../../form/form";
import { mapOrEmpty } from "../Empty";
import type { SelectProps } from "../form/Select";
import type { TooltipProps } from "../Tooltip";
import { Tooltip } from "../Tooltip";

export type TableFilterOperation<T> = FilterOperationType | ((rows: ReadonlyArray<T>, filters: ReadonlyArray<(t: T) => boolean>) => ReadonlyArray<T>);

type ArrayFilterFn<T> = (l: ReadonlyArray<(t: T) => boolean>, fn: (f: (t: T) => boolean) => boolean) => boolean;

export const getArrayFilter = (config: BLConfigWithLog) => <T,>(operation: FilterOperationType): ArrayFilterFn<T> => {
  switch (operation) {
    case allC.value:
      return (l, fn) => l.every(fn);
    case anyC.value:
      return (l, fn) => l.some(fn);
    case noneC.value:
      return (l, fn) => !l.some(fn);
  }
  return config.exhaustive(operation);
};

export const allAnyFilter = (config: BLConfigWithLog) => <T,>
  (operation: Extract<TableFilterOperation<T>, FilterOperationType>, filterList: ReadonlyArray<((t: T) => boolean)>) =>
  (rows: ReadonlyArray<T>): ReadonlyArray<T> =>
    filterList.length > 0
      ? rows.filter((m: T) => {
        const filterFn = getArrayFilter(config)<T>(operation);
        return filterFn(filterList, (f: (t: T) => boolean) => f(m));
      })
      : rows;

export function getFiltersAppliedCount<FD extends DataCodec>(
  defaultState: FormState<FD>,
  filterState: FormState<FD>,
): number {
  return pipe(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
    filterState.data as unknown as any,
    R.filterMapWithIndex((k, val) => pipe(
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
      O.fromNullable((defaultState.data as unknown as any)[k]),
      O.filter(dv => Array.isArray(val) && Array.isArray(dv) ? val.length !== dv.length : (isTagged(dv) && isTagged(val)) ? !tagEq().equals(dv, val) : dv !== val)
    )),
    R.size,
  );
}

export type OpenState = "startOpen" | "open" | "closed";

export type FilterButtonProps = {
  klasses: KlassProp;
  secondaryActions: O.Option<ReactElement>;
  startOpen: boolean;
  subHeader: O.Option<{ klasses: KlassProp, component: ReactElement }>;
  filtersAppliedCount: O.Option<number>;
  tooltip: O.Option<TooltipProps>;
  disabled?: boolean;
  onToggle?: (state: OpenState) => void;
  variant?: "default" | "activity";
  appearance?: "button" | "link";
};

const tableFilterCss = pt[".portal-table-filter"];

export const FilterButton = (props: PropsWithChildren<FilterButtonProps>) => {
  const filtersContainer: RefObject<HTMLDivElement> = useRef(document.querySelector(".filter-type-list"));
  const toolbarContainer: RefObject<HTMLDivElement> = useRef(null);

  const [open, setOpen] = useState<OpenState>(props.startOpen ? "startOpen" : "closed");
  const [transitioning, setTransitioning] = useState(!props.startOpen);

  const onToggle = props.onToggle;

  const handleToggle = useCallback(() => {
    const toggleState = open === "closed" ? "open" : "closed";

    setOpen(toggleState);
    setTransitioning(true);
    if (onToggle) {
      onToggle(toggleState);
    }
  }, [open, onToggle]);

  const handleCloseButtonClicked = useCallback(() => {
    handleToggle();
    pipe(O.fromNullable(toolbarContainer.current), O.map(el => el.scrollIntoView()));
  }, [handleToggle]);

  const FilterButtonToggle = useStableMemo(() => (
    <ButtonIcon
      aria-label="Toggle Filters"
      {...rotateSVGWithDefaults([tableFilterCss[".filters-button"], "align-self-start"])(open === "open")}
      icon={chevronDown}
      variant={"secondary"}
      onClick={handleToggle}
      disabled={props.disabled}
    >
      <Fragment>
        {props.variant === "activity" && (open === "open" ? "Hide" : "Show")} Filters {pipe(props.filtersAppliedCount, mapOrEmpty((c) => <Fragment>({c})</Fragment>))}
      </Fragment>
    </ButtonIcon>
  ), [props.filtersAppliedCount, props.variant, props.disabled, handleToggle, open], Eq.tuple(O.getEq(n.Eq), Eq.eqStrict, Eq.eqStrict, Eq.eqStrict, S.Eq));

  const FilterButtonLinkToggle = useStableMemo(() => (
    <ButtonLinkIcon
      {...rotateSVG(open === "open")}
      icon={chevronDown}
      onClick={handleToggle}
      disabled={props.disabled}
      textOrAriaLabel={E.left(
        `${props.variant === "activity" ? (open === "open" ? "Hide" : "Show") : ""} Filters${pipe(props.filtersAppliedCount, O.map(count => ` (${count})`), O.getOrElse(() => ""))}`
      )}
    />
  ), [props.filtersAppliedCount, props.variant, props.disabled, handleToggle, open], Eq.tuple(O.getEq(n.Eq), Eq.eqStrict, Eq.eqStrict, Eq.eqStrict, S.Eq));

  const ChooseFilterToggle = useStableMemo(() => props.appearance === "link" ? FilterButtonLinkToggle : FilterButtonToggle, [props.appearance, FilterButtonToggle, FilterButtonLinkToggle], Eq.tuple(Eq.eqStrict, Eq.eqStrict, Eq.eqStrict));

  const FilterButtonToggleWithTooltip = useStableMemo(
    () => pipe(props.tooltip, O.fold(() => ChooseFilterToggle, (tt) => <Tooltip {...tt}>{ChooseFilterToggle}</Tooltip>)),
    [ChooseFilterToggle, props.tooltip],
    Eq.tuple(Eq.eqStrict, O.getEq(Eq.eqStrict))
  );

  const Buttons = useStableMemo(() => (
    <Fragment>
      {FilterButtonToggleWithTooltip}
      {O.toNullable(props.secondaryActions)}
    </Fragment>
  ), [FilterButtonToggleWithTooltip, props.secondaryActions], Eq.tuple(Eq.eqStrict, O.getEq(Eq.eqStrict)));

  const handleTransitionEnd = () => {
    setTransitioning(false);
  };

  useEffect(() => {
    return pipe(
      O.fromNullable(filtersContainer.current),
      O.fold(
        () => constVoid,
        (currentFilterContainer) => {
          currentFilterContainer.addEventListener("transitionend", handleTransitionEnd, { capture: true });
          return () => {
            currentFilterContainer.removeEventListener("transitionend", handleTransitionEnd);
          };
        })
    );
  }, []);

  return (
    <div {...klassPropO(pt[".portal-table-header"])(props.klasses)}>
      <div
        ref={toolbarContainer}
        {...klassPropO(tableFilterCss[".toolbar"])(
          pipe(
            props.subHeader,
            O.fold(
              () => O.none,
              (sh): KlassProp => pipe(
                [tableFilterCss[".toolbar"].attrs[".header"]],
                (defaultKlasses) => isKlassesList(sh.klasses) ? [...defaultKlasses, ...sh.klasses] : [...defaultKlasses, sh.klasses]
              )
            )
          )
        )}
      >
        {pipe(
          props.subHeader,
          O.fold(
            () => Buttons,
            h => <Fragment>
              {h.component}
              <div {...klass(tableFilterCss[".toolbar"][".actions"])}>
                {Buttons}
              </div>
            </Fragment>
          )
        )}
      </div>
      <div
        ref={filtersContainer}
        {...klass("filter-type-list", tableFilterCss[".checkbox-group"],
          open === "open" && transitioning ? "transitioning"
            : open !== "closed" && !transitioning ? "transitioned"
              : ""
        )}
        style={open === "closed" ? { maxHeight: "0" } : {}}
      >
        {props.children}
        <ButtonLinkIcon
          {...rotateSVGWithDefaults(tableFilterCss[".checkbox-group"][".close-filters-button"])(open === "open")}
          icon={chevronDown}
          onClick={handleCloseButtonClicked}
          textOrAriaLabel={E.left("Close filters")}
        />
      </div>
    </div>
  );
};

type FilterGroupLabelProps = { klasses: KlassProp, header: string, icon: O.Option<SVGString> };

export const FilterGroupLabel = (props: FilterGroupLabelProps) => {
  return (
    <h5 {...klassPropO("group-header")(props.klasses)}>
      {pipe(props.icon, mapOrEmpty((icon) => <Svg src={icon} />))}
      {props.header}
    </h5>
  );
};

export type FilterInputBaseProps<PC extends DataCodec> = {
  className: KlassProp;
  state: FormState<PC>;
  setState: Dispatch<SetStateAction<FormState<PC>>>;
  analyticsScope: O.Option<string>;
};

export type FilterSelectProps<PC extends DataCodec, KV> = FilterInputBaseProps<PC>
  & SelectProps<PC, NonOption<KV>> & { header: string };

export const FilterButtonSites = (props: PropsWithChildren<{ title: string }>) => {
  const [contentHeight, setContentHeight] = useState(0);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const [styles, api] = useSpring<{
    from: {
      maxHeight: number | string;
    };
    config: {
      delay: number;
      duration: number;
    };
  }>(
    () => ({
      from: { maxHeight: 0 },
      config: { delay: Static.baseTransitionDelay, duration: Static.baseTransitionDelay },
    }),
    []
  );

  const onToggle = useCallback(() => {
    if (filtersOpen) {
      setFiltersOpen(false);
      api.start({ maxHeight: 0 });
    } else {
      setFiltersOpen(true);
      api.start({ maxHeight: contentHeight || "max-content" });
    }
  }, [filtersOpen, api, contentHeight]);

  return <div {...klassConditional("show open", "filters")(filtersOpen)}>
    <div onClick={onToggle} {...klass("filter-toggle")}>
      <span>{props.title}</span>
      <Svg src={triangleSmallDown} />
    </div>
    <animated.div style={styles} {...klass("filter-options-container")}>
      <div ref={a => { a && setContentHeight(a.offsetHeight); }}>
        {props.children}
      </div>
    </animated.div>
  </div>;
};
