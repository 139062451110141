import { constVoid, O, pipe } from "@scripts/fp-ts";
import type { LoginReasonU } from "@scripts/generated/domaintables/loginReasons";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { Anchor, AnchorButton } from "@scripts/react/components/Anchor";
import { constEmpty } from "@scripts/react/components/Empty";
import { Modal } from "@scripts/react/components/modal/Modal";
import { klass } from "@scripts/react/util/classnames";
import { closeModalFn, type ModalOpen } from "@scripts/react/util/useModal";

import { BLLogoContainer } from "./MinimalLayout";

const LoginWallBody = (props: {
  redirect: O.Option<string>;
  reason: O.Option<LoginReasonU>;
}) => {
  const loginParams: V2Router.BaseAuthControllerLoginParams = {
    issuerId: O.none,
    bankId: O.none,
    uhash: O.none,
    reason: pipe(props.reason, O.map(_ => _.machineReadable)),
    redirect: props.redirect,
  };

  const loginRoute = {
    title: "Log In To BondLink",
    route: V2Router.baseAuthControllerLogin(loginParams),
  };

  const createAccountRoute = {
    title: "Create a free account",
    route: V2Router.baseAuthControllerSignup(loginParams),
  };

  return <div {...klass("d-flex flex-col align-items-center login-wall")}>
    <BLLogoContainer />
    <h3>Log in to view all our muni resources</h3>
    <div>Log in or create a free BondLink account for access to documents, roadshows, custom alerts, and more at all BondLink-powered sites.</div>
    <AnchorButton target="_self" route={loginRoute} variant="primary" klasses={"mt-15 mb-1"} />
    <div>
      New to BondLink? <Anchor target="_self" route={createAccountRoute} />
    </div>
  </div>;
};

export const LoginWallModal = (props: {
  modalOpen: ModalOpen;
  redirect: O.Option<string>;
  reason: O.Option<LoginReasonU>;
}) =>
  <Modal
    open={props.modalOpen}
    dismissAction={closeModalFn(constVoid)}
    customHeader={constEmpty}
    id="ssr-login-wall"
    title="Log In"
    icon={O.none}
    type="primary"
    size="modal-sm"
    body={<LoginWallBody redirect={props.redirect} reason={props.reason} />}
  />;
