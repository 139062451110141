import { type ReactElement } from "react";

import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import type { BankOfferingData } from "@scripts/generated/models/bondOffering";
import type { ClientTextItem } from "@scripts/generated/models/clientTextItem";
import type { Issuer } from "@scripts/generated/models/issuer";
import { mapOrEmpty, toEmpty } from "@scripts/react/components/Empty";
import { Grid } from "@scripts/react/components/layout/Grid";
import { MainGridLeft, SideBarGridRightWithBorder } from "@scripts/react/components/layout/PageGrid";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { LeafIcon, type LeafIconAsProp } from "@scripts/react/components/LeafIcon";
import { Markdown } from "@scripts/react/components/Markdown";
import { type ParticipantArray, Participants } from "@scripts/react/components/offering-pages/OfferingParticipants";
import { OfferingSummary, type OfferingSummaryProps } from "@scripts/react/components/offering-pages/OfferingSummary";
import { type IssuerRatingsPageO, RatingsBlockSection } from "@scripts/react/components/offering-pages/Ratings";
import { type RoadshowRelatedContent, RoadshowsSection } from "@scripts/react/components/offering-pages/RelatedContent";
import type { RatingBlockProps } from "@scripts/react/components/ratings/RatingBlock";
import { SidebarAboutSection } from "@scripts/react/components/SidebarAbout";
import { type OfficerArray, type ProjectsArray, ProjectSidebarSection, TeamSidebarSection } from "@scripts/react/components/SidebarModalLinks";
import { relatedRatingsToCommonRatings } from "@scripts/syntax/rating";
import { prop } from "@scripts/util/prop";

type DetailsContentProps = LeafIconAsProp & {
  header: O.Option<ReactElement>;
  issuer: Issuer;
  officers: O.Option<OfficerArray>;
  projects: O.Option<ProjectsArray>;
  participants: O.Option<{ arr: ParticipantArray, title: string }>;
  appendedContent: O.Option<ReactElement>;
  issuerAboutText: O.Option<ClientTextItem>;
  summary: ReactElement;
  ratings: O.Option<ReactElement>;
  roadshowsDataO: O.Option<RNEA.ReadonlyNonEmptyArray<RoadshowRelatedContent["data"]>>;
};

type DealViewOfferingDetailsProps = {
  issuer: Issuer;
  data: BankOfferingData;
  issuerRatingsPageO: IssuerRatingsPageO;
  summaryKlasses?: OfferingSummaryProps["klasses"];
} & Pick<DetailsContentProps, "leafIcon" | "header"> & Pick<OfferingSummaryProps, "contactModal"> & RatingBlockProps;

export const DealViewOfferingDetails = (props: DealViewOfferingDetailsProps & RatingBlockProps) => {
  const {
    offering,
    officers,
    projects,
    participants,
    issuerAboutText,
    issuerRatings,
  } = props.data;

  const {
    roadShows,
    ratings,
    program,
    documents,
    externalLinks,
  } = offering.data.data.record.data.relatedContent;
  const bondRatings = relatedRatingsToCommonRatings(ratings);

  return <DealViewDetailsBase
    header={props.header}
    appendedContent={O.none}
    issuer={props.issuer}
    leafIcon={LeafIcon}
    officers={RNEA.fromReadonlyArray(officers)}
    projects={RNEA.fromReadonlyArray(projects)}
    participants={pipe(
      participants,
      RNEA.fromReadonlyArray,
      O.map((a) => ({
        arr: a,
        title: "Offering Participants",
      }))
    )}
    roadshowsDataO={pipe(roadShows, RA.map(prop("data")), RNEA.fromReadonlyArray)}
    issuerAboutText={issuerAboutText}
    summary={<AccentDividerSection title={O.none} suppressDivider="xs">
      <OfferingSummary
        contactModal={props.contactModal}
        documentsO={pipe(documents, RA.map(prop("data")), RNEA.fromReadonlyArray)}
        issuer={props.issuer}
        linksO={RNEA.fromReadonlyArray(externalLinks)}
        offering={offering}
        leafIcon={props.leafIcon}
        klasses={props.summaryKlasses}
      />
    </AccentDividerSection>}
    ratings={O.some(<RatingsBlockSection
      documentDownloadRoute={props.documentDownloadRoute}
      isBLP
      bondRatings={bondRatings}
      program={program}
      issuer={props.issuer}
      issuerRatingsPageO={props.issuerRatingsPageO}
      issuerRatings={issuerRatings}
    />)}
  />;
};

export const DealViewDetailsBase = (props: DetailsContentProps) => {
  return (
    <Grid klasses={[]} attrs={O.none}>
      <MainGridLeft>
        {toEmpty(props.header)}
        {props.summary}
        {toEmpty(props.ratings)}
        <RoadshowsSection
          issuer={props.issuer}
          leafIcon={props.leafIcon}
          roadshows={props.roadshowsDataO}
        />
        {mapOrEmpty(({ arr, title }: { arr: ParticipantArray, title: string }) =>
          <Participants
            title={title}
            participants={arr}
          />
        )(props.participants)}
        {toEmpty(props.appendedContent)}
      </MainGridLeft >
      <SideBarGridRightWithBorder>
        {mapOrEmpty((text: ClientTextItem) =>
          <SidebarAboutSection issuer={props.issuer}>
            <Markdown content={text.data} />
          </SidebarAboutSection>
        )(props.issuerAboutText)}
        {mapOrEmpty((team: OfficerArray) => <TeamSidebarSection team={team} />)(props.officers)}
        {mapOrEmpty((projects: ProjectsArray) => <ProjectSidebarSection projects={projects} />)(props.projects)}
      </SideBarGridRightWithBorder>
    </Grid >
  );
};
