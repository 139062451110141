import { not } from "fp-ts/lib/Predicate";

import { O, pipe } from "@scripts/fp-ts";
import { isServerSide } from "@scripts/util/ssr";

export const remsToPx = (rems: number): number => {
  const DEFAULT_FONT_SIZE = 16;

  const currentRootFontSize = pipe(
    "", // O.fromPredicate needs to be passed a value, but in this case, we do not care about said value
    O.fromPredicate(not(isServerSide)),
    O.chain(() => O.fromNullable(document.documentElement)),
    O.map((htmlElem) => parseInt(window.getComputedStyle(htmlElem).fontSize)),
    O.chain(O.fromPredicate(not(isNaN))),
    O.getOrElse(() => DEFAULT_FONT_SIZE)
  );

  return rems * currentRootFontSize;
};
