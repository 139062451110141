import p from "pluralize";

import { O, pipe, RR } from "@scripts/fp-ts";

export function pluralize<A = string>(singular: A, plural: A): (n: number) => A {
  return (n: number): A => n === 1 ? singular : plural;
}

export const pluralizeWithS = (text: string) => (n: number) => {
  return pluralize(text, `${text}s`)(n);
};

export const pluralizeDataMeta = (type: string) => {
  const customRules = {
    "RFP": "RFPs",
  };

  return pipe(
    RR.lookup(type)(customRules),
    O.getOrElse(() => p(type))
  );
};
