import { useState } from "react";

import { E, flow, O, pipe, RNEA } from "@scripts/fp-ts";
import { rfpsPage } from "@scripts/generated/domaintables/pages";
import { modifyTaggedContent } from "@scripts/models/taggedContent";
import { Button, ButtonLink } from "@scripts/react/components/Button";
import { BondCard } from "@scripts/react/components/card/BondCard";
import { RfpCard } from "@scripts/react/components/card/RfpCard";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Modal } from "@scripts/react/components/modal/Modal";
import { klassPropO } from "@scripts/react/util/classnames";
import { useModal } from "@scripts/react/util/useModal";
import type { RoadshowPlayerData } from "@scripts/routes/routing/ssr/issuersites";
import { getCustomTitleO } from "@scripts/syntax/pageTitles";
import { modifyWithId, modifyWithStatus } from "@scripts/syntax/threadThrough";

import { ContactModalCallout } from "@scripts-ssr/components/ContactModal";

import bondIcon from "@svgs/bond.svg";
import rfpIcon from "@svgs/rfp.svg";

import { useBondSubscribe, useRfpSubscribe } from "../../api/watchlist";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { useIssuerSitesSelector } from "../../state/store";
import { bidSubmissionLens } from "../../syntax/rfp";

const RelatedBondCard = (props: { offering: RoadshowPlayerData["offerings"][number] }) => {
  const user = useIssuerSitesSelector("user");
  const issuer = useIssuerSitesSelector("issuer");
  const userSubscribedToIssuer = useIssuerSitesSelector("userSubscribed");
  const [isSubscribed, setIsSubscribed] = useState(props.offering.subscribed);
  const makeOnSubscribe = useBondSubscribe();

  return <BondCard
    key={props.offering.data.data.id}
    url={_ => _.url}
    issuer={issuer}
    displayIssuer={false}
    offering={pipe(props.offering.data.data, modifyWithId(_ => ({ ..._, data: _.data.offering })))}
    roadshows={[]}
    documents={props.offering.data.data.record.data.documents.map(_ => _.data.data)}
    links={["details", "documents"]}
    target="_blank"
    leafIcon={IssuerSitesLeafIcon}
    subscription={O.some({
      isSubscribed,
      onSubscribe: makeOnSubscribe(
        { issuerId: O.some(issuer.id), bankId: issuer.bankId },
        !isSubscribed,
        props.offering.data.data.id,
        setIsSubscribed,
        user,
        userSubscribedToIssuer
      ),
    })}
  />;
};

const bondCards = (offerings: RoadshowPlayerData["offerings"]) =>
  offerings.map(o => <RelatedBondCard key={o.data.data.id} offering={o} />);

type SetRfp = (rfp: RoadshowPlayerData["rfps"][number]) => void;

const RelatedRfpCard = (props: { rfp: RoadshowPlayerData["rfps"][number], setRfp: SetRfp }) => {
  const user = useIssuerSitesSelector("user");
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const userSubscribedToIssuer = useIssuerSitesSelector("userSubscribed");
  const [isSubscribed, setIsSubscribed] = useState(props.rfp.subscribed);
  const makeOnSubscribe = useRfpSubscribe();

  return <RfpCard
    key={props.rfp.data.data.id}
    url={_ => _.url}
    issuer={issuer}
    user={user}
    displayIssuer={false}
    rfp={pipe(props.rfp.data, modifyWithStatus(modifyTaggedContent(_ => _.rfp)))}
    customRfpsTitle={getCustomTitleO(rfpsPage)(pages)}
    roadshows={[]}
    bidSubmissionTemplate={props.rfp.data.data.record.data.relatedContent.bidSubmissionTemplate}
    bidSubmission={props.rfp.data.data.record.data.relatedContent.bidSubmission}
    setBidSubmission={s => props.setRfp(bidSubmissionLens().set(O.some(s))(props.rfp))}
    links={["details", "bidSubmission"]}
    target="_blank"
    leafIcon={IssuerSitesLeafIcon}
    subscription={O.some({
      isSubscribed,
      onSubscribe: makeOnSubscribe(
        { issuerId: O.some(issuer.id), bankId: issuer.bankId },
        !isSubscribed,
        props.rfp.data.data.id,
        setIsSubscribed,
        user,
        userSubscribedToIssuer
      ),
    })}
  />;
};

const rfpCards = (rfps: RoadshowPlayerData["rfps"], setRfp: SetRfp) =>
  rfps.map(r => <RelatedRfpCard key={r.data.data.id} rfp={r} setRfp={setRfp} />);

type ToNel<A> = A extends ReadonlyArray<infer B> ? RNEA.ReadonlyNonEmptyArray<B> : never;

type RelatedItemsButtonProps = {
  items: E.Either<RoadshowPlayerData["offerings"], RoadshowPlayerData["rfps"]>;
  setRfp: SetRfp;
};

type RelatedItemsButtonPropsNel = {
  items: E.Either<ToNel<RoadshowPlayerData["offerings"]>, ToNel<RoadshowPlayerData["rfps"]>>;
  setRfp: SetRfp;
};

const RelatedItemsButton = (props: RelatedItemsButtonPropsNel) => {
  const user = useIssuerSitesSelector("user");
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const type = E.fold(() => "bonds", () => "RFPs")(props.items);
  const text = E.fold(() => "Bonds", () => O.getOrElse(() => "RFPs")(getCustomTitleO(rfpsPage)(pages)))(props.items);
  const [modalOpen, openModal, closeModal] = useModal(`Roadshow player ${type} button`);

  return <>
    <Button
      {...klassPropO("mt-05")(E.fold(() => ["mr-1"], () => [])(props.items))}
      variant="primary"
      onClick={openModal}
    >{E.fold(
      () => "Bonds",
      () => O.getOrElse(() => "RFPs")(getCustomTitleO(rfpsPage)(pages)),
    )(props.items)}</Button>
    <Modal
      id={`roadshow-related-${type.toLowerCase()}-modal`}
      title={`Related ${text}`}
      body={<>
        <div className="mb-1">
          {pipe(props.items, E.fold(bondCards, rs => rfpCards(rs, props.setRfp)))}
        </div>
        Have questions or want to learn more about these {E.fold(() => "offerings", () => text)(props.items)}?{" "}
        <ContactModalCallout
          bondOfferingId={pipe(props.items, E.swap, O.fromEither, O.map(_ => _[0].data.data.id))}
          rfpId={pipe(props.items, O.fromEither, O.map(_ => _[0].data.data.id))}
          btnText="Contact us"
          header="Request More Information"
          introText="Contact us to get more information about this roadshow."
          issuer={issuer}
          user={pipe(user, O.map(_ => _.user))}
          buttonComponent={ButtonLink}
        />
      </>}
      dismissAction={closeModal}
      icon={O.some(E.fold(() => bondIcon, () => rfpIcon)(props.items))}
      type="primary"
      open={modalOpen}
      size="modal-lg"
    />
  </>;
};

export const RelatedItemsButtonO = (props: RelatedItemsButtonProps) =>
  pipe(
    props.items,
    E.fold(
      flow(RNEA.fromReadonlyArray, mapOrEmpty(items => RelatedItemsButton({ ...props, items: E.left(items) }))),
      flow(RNEA.fromReadonlyArray, mapOrEmpty(items => RelatedItemsButton({ ...props, items: E.right(items) }))),
    ),
  );
