import { O, pipe } from "@scripts/fp-ts";

const formatExtension = (phoneNumber: string, extension: string) => `${phoneNumber} Ext. ${extension}`;
export const phoneWithExtension = (phoneNumber: O.Option<string>, extension: O.Option<string>) => pipe(
  phoneNumber,
  O.map((n) => O.fold(
    () => n,
    (ext: string) => formatExtension(n, ext),
  )(extension))
);
