import { pipe, RNEA } from "@scripts/fp-ts";
import type { IssuerHomepageData } from "@scripts/generated/models/issuerPageData";
import { mapOrEmpty } from "@scripts/react/components/Empty";

import { FeaturedItems } from "./FeaturedItems";
import { QuickLinks } from "./QuickLinks";
import { RelatedIssuers } from "./RelatedIssuers";
import { Welcome } from "./Welcome";

export const Home = (props: { data: IssuerHomepageData }) => {
  return <>
    {pipe(props.data.featuredItems, RNEA.fromReadonlyArray, mapOrEmpty(_ => <FeaturedItems featuredItems={_} />))}
    {pipe(props.data.welcome, mapOrEmpty(_ => <Welcome
      item={_}
      issuerRatings={props.data.issuerRatings}
      offerings={props.data.offerings}
      officer={props.data.officer}
      programRatings={props.data.programRatings}
    />))}
    <RelatedIssuers />
    <QuickLinks />
  </>;
};
