
import type { ReactElement } from "react";
import { constVoid, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import { Svg } from "@scripts/react/components/Svg";
import type { Klass } from "@scripts/react/util/classnames";
import { klass } from "@scripts/react/util/classnames";
import { rotateSVG } from "@scripts/util/rotateElement";

import chevronDown from "@svgs/chevron-down.svg";

interface ExpandControlCellProps {
  onToggle: () => void;
  expanded: boolean;
  disabled?: boolean;
}

export const ExpandControlCell = (props: ExpandControlCellProps): ReactElement => {
  return (
    <td {...klass("portal-table-cell", "cell-control")} onClick={props.disabled ? constVoid : props.onToggle}>
      <span {...klass("control-icon", "icon", props.disabled ? "disabled" : "control-expand")}>
        <Svg src={chevronDown} {...rotateSVG(props.expanded)} />
      </span>
    </td>
  );
};

export const rowExpandedKlass = (expandedRowIds: ReadonlyArray<string | number>, rowId?: string | number): ReadonlyArray<Klass> => {
  return pipe(
    O.fromNullable(rowId),
    O.fold(
      () => [],
      (r) => expandedRowIds.some(id => id === r) ? ["row-expanded"] : []
    )
  );
};
