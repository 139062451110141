import { type ReactElement, useState } from "react";
import { useStableO } from "fp-ts-react-stable-hooks";
import { singular } from "pluralize";

import { flow, O, pipe, R, RA, RNEA } from "@scripts/fp-ts";
import { pick } from "@scripts/fp-ts/struct";
import type { BondOfferingWithRoadshowsAndDocs } from "@scripts/generated/models/bondOffering";
import type { Subscribed } from "@scripts/generated/models/subscribed";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { TabRow, type TabRowItems } from "@scripts/react/components/TabRow";
import { klass } from "@scripts/react/util/classnames";
import type { RfpWithCardRelatedContent } from "@scripts/syntax/rfp";

import { useBondSubscribe, useRfpSubscribe } from "../../api/watchlist";
import { useIssuerSitesSelector } from "../../state/store";
import { IssuerSitesBondCard } from "../BondCard";
import { IssuerSitesRfpCard } from "../RfpCard";

type DealTabU = "active" | "archived";

export const getNonEmptyItemsRecordO: <K extends string, V>(r: Record<K, ReadonlyArray<V>>) => O.Option<Record<K, O.Option<RNEA.ReadonlyNonEmptyArray<V>>>> = flow(
  R.map(RNEA.fromReadonlyArray),
  O.fromPredicate(R.some(O.isSome))
);

type ActiveAndArchivedDeals<A> = {
  active: O.Option<RNEA.ReadonlyNonEmptyArray<A>>;
  archived: O.Option<RNEA.ReadonlyNonEmptyArray<A>>;
};

type DealSectionProps<A> = {
  title?: string;
  itemName: string;
  getId: (item: A) => number;
  mapFn: (item: A, setItem: (a: A) => void) => ReactElement;
  sectionId: string;
  deals: ActiveAndArchivedDeals<A>;
};

const getItemsLength = flow(
  O.map((_: RNEA.ReadonlyNonEmptyArray<unknown>) => _.length),
  O.getOrElse(() => 0)
);

export const DealSection = <A,>(props: DealSectionProps<A>) => {
  const [currentTab, setCurrentTab] = useState<DealTabU>("active");
  const [active, setActive] = useStableO(props.deals.active);
  const [archived, setArchived] = useStableO(props.deals.archived);

  const updateDeals = (deals: O.Option<RNEA.ReadonlyNonEmptyArray<A>>, set: (deals: O.Option<RNEA.ReadonlyNonEmptyArray<A>>) => void) => (a: A) => {
    pipe(
      deals,
      O.map(flow(RNEA.map(d => props.getId(d) === props.getId(a) ? a : d), O.some, set)),
    );
  };

  const tabRowItems: TabRowItems<DealTabU> = [
    {
      title: `Active ${props.itemName} (${getItemsLength(active)})`,
      value: "active",
      onClick: () => setCurrentTab("active"),
      icon: O.none,
    },
    {
      title: `Archived ${props.itemName} (${getItemsLength(archived)})`,
      value: "archived",
      onClick: () => setCurrentTab("archived"),
      icon: O.none,
    },
  ];

  return (
    <AccentDividerSection title={O.some(props.title ?? props.itemName)} sectionId={props.sectionId} klasses="accent-border-top">
      {(O.isSome(archived)) && (
        <>
          <TabRow
            orientation="horizontal"
            isSm={true}
            variant="default"
            items={tabRowItems}
            current={currentTab}
            klasses={"d-none-until-sm"}
          />
          <TabRow
            orientation="vertical"
            isSm={false}
            variant="default"
            items={tabRowItems}
            current={currentTab}
            klasses={["d-sm-none", "mb-15"]}
          />
        </>
      )}
      {currentTab === "active" && pipe(
        active,
        O.fold(
          () =>
            <div>
              There are currently no active {props.itemName.toLowerCase()}.
            </div>,
          (activeItems) =>
            <div {...klass("grid", "card-grid")}>
              {pipe(activeItems, RA.map(a => props.mapFn(a, updateDeals(active, setActive))))}
            </div>,
        )
      )}
      {currentTab === "archived" && pipe(
        archived,
        mapOrEmpty(archivedItems =>
          <div {...klass("grid", "card-grid")}>
            {pipe(archivedItems, RA.map(a => props.mapFn(a, updateDeals(archived, setArchived))))}
          </div>,
        )
      )}
    </AccentDividerSection>
  );
};

const modOfferingOrRfp = <R, A>(
  a: Subscribed<WithStatusU<TaggedContent<R>>>,
  mkObj: (r: R) => A,
): Subscribed<WithStatusU<TaggedContent<A>>> => ({
  ...a,
  data: {
    ...a.data,
    data: {
      ...a.data.data,
      record: {
        ...a.data.data.record,
        data: mkObj(a.data.data.record.data),
      },
    },
  },
});

export type RelatedOffering = Subscribed<WithStatusU<TaggedContent<BondOfferingWithRoadshowsAndDocs>>>;

const BondCard = (props: { bond: RelatedOffering }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const user = useIssuerSitesSelector("user");
  const userSubscribedToIssuer = useIssuerSitesSelector("userSubscribed");

  const makeOnSubscribe = useBondSubscribe();

  const [isSubscribed, setIsSubscribed] = useState(props.bond.subscribed);

  return (
    <div  {...klass("c-24")}>
      <IssuerSitesBondCard
        issuer={issuer}
        bond={modOfferingOrRfp(props.bond, _ => ({ offering: _.offering, relatedContent: pick("roadShows", "documents")(_) }))}
        isSubscribed={isSubscribed}
        onSubscribe={makeOnSubscribe(
          { issuerId: O.some(issuer.id), bankId: O.none },
          !isSubscribed,
          props.bond.data.data.id,
          setIsSubscribed,
          user,
          userSubscribedToIssuer
        )}
      />
    </div>
  );
};

const bondMapFn = (offering: RelatedOffering) =>
  <BondCard
    bond={offering}
    key={`bond-${offering.data.data.id}`}
  />;

export const BondsSection = (props: Omit<DealSectionProps<RelatedOffering>, "mapFn" | "getId">) =>
  <DealSection
    deals={props.deals}
    mapFn={bondMapFn}
    getId={_ => _.data.data.id}
    sectionId={props.sectionId}
    title={props.title}
    itemName={props.itemName}
  />;

export type RelatedRfp = Subscribed<WithStatusU<TaggedContent<RfpWithCardRelatedContent>>>;

export const RfpCard = (props: {
  rfp: RelatedRfp;
  setRfp: (r: RelatedRfp) => void;
  itemName: string;
}) => {
  const user = useIssuerSitesSelector("user");
  const issuer = useIssuerSitesSelector("issuer");
  const userSubscribedToIssuer = useIssuerSitesSelector("userSubscribed");

  const [isSubscribed, setIsSubscribed] = useState(props.rfp.subscribed);

  const makeOnSubscribe = useRfpSubscribe();

  return <div  {...klass("c-24")}>
    <IssuerSitesRfpCard
      issuer={issuer}
      rfp={modOfferingOrRfp(props.rfp, _ => ({
        rfp: _.rfp,
        relatedContent: pick("roadShows", "bidSubmissionTemplate", "bidSubmission")(_.relatedContent),
      }))}
      setRfp={props.setRfp}
      isSubscribed={isSubscribed}
      onSubscribe={makeOnSubscribe(
        { issuerId: O.some(issuer.id), bankId: O.none },
        !isSubscribed,
        props.rfp.data.data.id,
        setIsSubscribed,
        user,
        userSubscribedToIssuer
      )}
    />
  </div>;
};

const rfpMapFn = (itemName: string) => (rfp: RelatedRfp, setRfp: (r: RelatedRfp) => void) =>
  <RfpCard
    key={`rfp-${rfp.data.data.id}`}
    rfp={rfp}
    setRfp={setRfp}
    itemName={itemName}
  />;

export const RfpsSection = (props: Omit<DealSectionProps<RelatedRfp>, "mapFn" | "getId">) =>
  <DealSection
    deals={props.deals}
    mapFn={rfpMapFn(singular(props.itemName))}
    getId={_ => _.data.data.id}
    sectionId={props.sectionId}
    title={props.title}
    itemName={props.itemName}
  />;
