import { E, RA } from "@scripts/fp-ts";
import { ButtonLinkIcon, ButtonLinkMultiline } from "@scripts/react/components/Button";
import { Card } from "@scripts/react/components/card/Card";
import { DataHorizontal, DataPointLarge } from "@scripts/react/components/Data";
import { klass } from "@scripts/react/util/classnames";
import type { OpenModalO } from "@scripts/react/util/useModal";
import type { CommonRatingsByAgency } from "@scripts/syntax/rating";

import ratingIcon from "@svgs/graph.svg";

type RatingCardProps = {
  ratings: CommonRatingsByAgency;
  openDetailsModal: OpenModalO<CommonRatingsByAgency>;
  programName: string;
};

export const RatingCard = (props: RatingCardProps) =>
  <Card klasses={["card-border", "h-100", "justify-content-between", "align-items-start"]}>
    <div>
      <h5 {...klass("mb-1")}>
        <ButtonLinkMultiline
          {...klass("font-sans-normal-500", "text-left")}
          onClick={() => props.openDetailsModal(props.ratings)}
          text={props.programName}
        />
      </h5>
      <div {...klass("mb-1")}>
        <DataHorizontal>
          {RA.takeLeft(3)<CommonRatingsByAgency[number]>(props.ratings).map(([agency, ratings]) =>
            <DataPointLarge
              key={agency.id}
              title={agency.shortName}
              value={ratings[0].data.record.rating.rating}
            />
          )}
        </DataHorizontal>
      </div>
    </div>
    <ButtonLinkIcon
      onClick={() => props.openDetailsModal(props.ratings)}
      icon={ratingIcon}
      textOrAriaLabel={E.left("Rating Details")}
    />
  </Card>;
