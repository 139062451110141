import { constFalse, constTrue, E, N, O, pipe, RA } from "@scripts/fp-ts";
import type { MediaCategoryU } from "@scripts/generated/domaintables/mediaCategories";
import type { DocumentType } from "@scripts/generated/models/document";
import { type FilterModel } from "@scripts/react/components/filters/Filter";
import { idEq, tagEq } from "@scripts/util/compare";

import type { DocumentsTableModel } from "./DocumentsTable";

export type Category = E.Either<DocumentType, MediaCategoryU>;

type DocumentsFilterModel = FilterModel<DocumentsTableModel["Row"], { category: ReadonlyArray<Category>, year: O.Option<number> }>;

export const categoryEq = E.getEq(idEq(), tagEq());

export const documentsFilters: DocumentsFilterModel = {
  category: {
    filterFn: (categories) => (row) => pipe(
      categories,
      RA.some(
        E.fold(
          ({ id }) => pipe(row.__metadata.data.record.data.docType, O.fold(constFalse, tpe => tpe.id === id)),
          category => tagEq().equals(row.__metadata.data.record.data.category, category),
        )
      )
    ),
    eq: RA.getEq(categoryEq),
  },
  year: {
    filterFn: (year) => (row) => pipe(
      year,
      O.fold(
        constTrue,
        yr => row.date.date.year() === yr
      )
    ),
    eq: O.getEq(N.Eq),
  },
};
