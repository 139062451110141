import type { Dispatch, SetStateAction } from "react";
import { useState } from "react";
import type { Dispatch as ReduxDispatch } from "redux";
import V from "voca";

import { constVoid, O, pipe, RNEA } from "@scripts/fp-ts";
import type { AcceptDisclaimerPostC, Disclaimer } from "@scripts/generated/models/disclaimer";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { ButtonSubmit } from "@scripts/react/components/Button";
import { Form } from "@scripts/react/components/form/Form";
import { DividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import type { ModalDismissable, ModalOpenable } from "@scripts/react/components/modal/Modal";
import { Modal } from "@scripts/react/components/modal/Modal";
import type { FormState } from "@scripts/react/form/form";
import { emptyFormState, formDataLens } from "@scripts/react/form/form";

import { BondLinkDisclaimer } from "@scripts-ssr/components/DisclaimerModal";

import type { DueDisclaimerAction } from "../../state/dueDisclaimer";
import { dueDisclaimerRemove } from "../../state/dueDisclaimer";
import { useIssuerSitesSelector } from "../../state/store";

export type DueDisclaimers = RNEA.ReadonlyNonEmptyArray<[string, WithStatusU<Disclaimer>]>;

type DueDisclaimerModalProps = ModalOpenable & ModalDismissable & {
  dueDisclaimers: DueDisclaimers;
  dispatch: ReduxDispatch<DueDisclaimerAction>;
};

const disclaimerDataLens = formDataLens<AcceptDisclaimerPostC>();

const DueDisclaimerForm = (props: Pick<DueDisclaimerModalProps, "dispatch" | "dismissAction" | "dueDisclaimers"> & {
  state: FormState<AcceptDisclaimerPostC>;
  setState: Dispatch<SetStateAction<FormState<AcceptDisclaimerPostC>>>;
}) => {
  const issuer = useIssuerSitesSelector("issuer");
  return (
    <Form
      url={SitesRouter.issuersitesDisclaimerControllerAccept({ issuerSlug: issuer.slug, issuerId: issuer.id })}
      state={props.state}
      setState={props.setState}
      onSuccess={() => {
        props.dueDisclaimers.forEach(dd => props.dispatch(dueDisclaimerRemove(dd[1].data.id)));
        props.dismissAction();
      }}
      onFailure={O.none}
      headers={O.none}
    >
      <ButtonSubmit
        text="Accept"
        variant="primary"
        loading={props.state.loading}
        disabled={false}
        loadingText="Accepting"
        onClick={constVoid}
      />
    </Form>
  );
};

export const DueDisclaimerModal = (props: DueDisclaimerModalProps) => {
  const [formState, setFormState] = useState<FormState<AcceptDisclaimerPostC>>(pipe(
    emptyFormState<AcceptDisclaimerPostC>(),
    disclaimerDataLens.set({ disclaimerCookieNames: props.dueDisclaimers.map(dd => dd[0]) })
  ));

  return (
    <Modal
      className="disclaimer-modal"
      dismissAction={props.dismissAction}
      id="disclaimerModal"
      title="Disclaimer"
      icon={O.none}
      type={"primary"}
      escapable={false}
      open={props.modalOpen}
      size="modal-lg"
      body={
        <>
          <DividerSection title={O.none} klasses={"disclaimer-content"}>
            {pipe(
              props.dueDisclaimers,
              RNEA.map(dd => (
                <div key={`disclaimer-${dd[1].data.id}`}>
                  <h2>
                    {V.capitalize(dd[1].data.record.disclaimerType.typeName)} Disclaimer
                  </h2>
                  <Markdown content={dd[1].data.record.data} />
                </div>
              ))
            )}
            <BondLinkDisclaimer header={true} />
          </DividerSection>
          <DividerSection title={O.none} klasses={"pt-05"}>
            <DueDisclaimerForm
              dismissAction={props.dismissAction}
              dispatch={props.dispatch}
              dueDisclaimers={props.dueDisclaimers}
              state={formState}
              setState={setFormState}
            />
          </DividerSection>
        </>
      }
    />
  );
};
