import type { BLConfigWithLog } from "@scripts/bondlink";
import type { LinkableU } from "@scripts/generated/models/linkable";
import type { AnchorProps } from "@scripts/react/components/Anchor";
import { AnchorWithChildren } from "@scripts/react/components/Anchor";
import { klassPropO } from "@scripts/react/util/classnames";
import type { PageRouteLink, PageRouting } from "@scripts/routes/routing/base";

export const getUrl = (config: BLConfigWithLog) => (linkable: LinkableU): string => {
  switch (linkable._tag) {
    case "DownloadLink": { return linkable.downloadUrl; }
    case "PageLink":
    case "ExternalLink": { return linkable.url; }
  }
  return config.exhaustive(linkable);
};

export const toPageRouting = (config: BLConfigWithLog) => (linkable: LinkableU): PageRouting => ({
  _tag: linkable._tag,
  title: () => linkable.displayName,
  url: () => getUrl(config)(linkable),
});

export const toPageRouteLinkBasic = (config: BLConfigWithLog) => (linkable: LinkableU): PageRouteLink => ({
  title: linkable.displayName,
  route: {
    method: "GET",
    url: getUrl(config)(linkable),
  },
});

export const toAnchor = (config: BLConfigWithLog) => (linkable: LinkableU, anchorTagProps?: Omit<AnchorProps, "target" | "route">): React.ReactNode => {
  const route = toPageRouteLinkBasic(config)(linkable);
  const props = anchorTagProps == null ? {} : anchorTagProps;
  const propsWithMergedKlasses = { ...props, klasses: klassPropO("login-to-download")(props.klasses).className };

  switch (linkable._tag) {
    case "PageLink": {
      return <AnchorWithChildren
        target="_self"
        route={route}
        {...props}
      >
        {linkable.displayName}
      </AnchorWithChildren>;
    }
    case "ExternalLink": {
      return <AnchorWithChildren
        target="_blank"
        route={route}
        {...props}
      >
        {linkable.displayName}
      </AnchorWithChildren>;
    }
    case "DownloadLink": {
      return <AnchorWithChildren
        target="_self"
        route={route}
        aria-label={`Download ${linkable.displayName}`}
        {...propsWithMergedKlasses}
      >
        {linkable.displayName}
      </AnchorWithChildren>;
    }
  }
  return config.exhaustive(linkable);
};
