import { useStore } from "react-redux";

import { O } from "@scripts/fp-ts";
import { Table, type TableProps } from "@scripts/react/components/table/Table";
import type * as tableSyntax from "@scripts/react/components/table/tableSyntax";

import type { IssuerSitesState } from "../state/state";
import type { IssuerSitesActions } from "../state/store";

export const IssuerSitesTable = <
  SB extends tableSyntax.SortBy<keyof A>,
  A,
  MetaData,
  RowParserOutput,
  ExtraColumns extends string[],
  ExportExtraColumns extends string[],
  Params extends object,
>(props: Omit<TableProps<SB, A, MetaData, RowParserOutput, ExtraColumns, ExportExtraColumns, Params>, "store">) => {
  const store = useStore<IssuerSitesState, IssuerSitesActions>();
  return <Table {...props} store={O.some(store)} pageSize={50} />;
};
