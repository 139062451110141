import { type PropsWithChildren } from "react";

import { O, pipe } from "@scripts/fp-ts";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import { Anchor } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { type OnSubscribe, SubscriptionToggleIcon } from "@scripts/react/components/SubscriptionToggle";
import { klass } from "@scripts/react/util/classnames";
import type { UrlInterface } from "@scripts/routes/urlInterface";

import { IssuerSitesLeafIcon } from "../LeafIcon";

type HeaderBaseProps = {
  title: string;
  viewAllRoute: O.Option<{ urlInterface: UrlInterface<"GET">, itemType: O.Option<string> }>;
  taggedContent: O.Option<TaggedContent<unknown>>;
};

const HeaderBase = (props: PropsWithChildren<HeaderBaseProps>) =>
  <div {...klass("pb-1")}>
    <h2 {...klass("title mt-0 d-flex justify-content-between mb-0")}>
      <div className="d-flex z-1">
        {props.title}
        {pipe(
          props.taggedContent,
          mapOrEmpty(tc =>
            <IssuerSitesLeafIcon
              klass={"ml-025"}
              taggedContent={tc}
            />
          )
        )}
      </div>
      {props.children}
    </h2>
    {pipe(
      props.viewAllRoute,
      mapOrEmpty(route =>
        <div {...klass("mt-05")}>
          <Anchor
            arrowType="right"
            route={{
              title: `View All${pipe(route.itemType, O.fold(() => "", (itemType) => ` ${itemType}`))}`,
              route: route.urlInterface,
            }}
            target="_self"
          />
        </div>
      )
    )}
  </div>;

export const HeaderWithSubscription = (props: HeaderBaseProps & {
  isSubscribed: boolean;
  subscribeDisabled: boolean;
  onSubscribe: OnSubscribe;
}) => {
  return (
    <HeaderBase
      taggedContent={props.taggedContent}
      title={props.title}
      viewAllRoute={props.viewAllRoute}
    >
      <SubscriptionToggleIcon
        delay="table"
        isSubscribed={props.isSubscribed}
        onSubscribe={props.onSubscribe}
        class="ml-auto relative z-3 add-drop-shadow"
        disabled={props.subscribeDisabled}
      />
    </HeaderBase>
  );
};

export const HeaderWithoutSubscription = (props: HeaderBaseProps) => <HeaderBase {...props} />;
