import { pipe } from "fp-ts/lib/function";

import { esgProgram } from "@scripts/generated/domaintables/featureFlags";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { Anchor } from "@scripts/react/components/Anchor";
import { falseOrEmpty } from "@scripts/react/components/Empty";
import type { LeafIconBaseProps } from "@scripts/react/components/LeafIcon";
import { LeafIconBase } from "@scripts/react/components/LeafIcon";
import { isFFDisabled } from "@scripts/syntax/featureFlags";

import { useIssuerSitesSelector } from "../state/store";

export const IssuerSitesLeafIcon = (props: LeafIconBaseProps) => {
  const iffs = useIssuerSitesSelector("iffs");
  const issuer = useIssuerSitesSelector("issuer");

  return pipe(
    isFFDisabled(esgProgram)(iffs),
    falseOrEmpty(
      <LeafIconBase
        klass={props.klass}
        taggedContent={props.taggedContent}
        delay={props.delay}
        description={{
          type: "DescriptionContentElement",
          element: <div>
            This content is related to our ESG program. <Anchor
              target={"_self"}
              route={{
                title: "Learn More",
                route: SitesRouter.issuersitesAboutControllerEsgProgram({
                  issuerSlug: issuer.slug,
                  issuerId: issuer.id,
                }),
              }}
            />
          </div>,
        }}
      />
    )
  );
};
