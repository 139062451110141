const documents = ["pdf", "ps", "eps", "doc", "dot", "docx", "docm", "xls", "xlt", "xlsx", "xlsm", "xlw", "ppt", "pps", "pptx", "pptm", "ppxs", "ppsm", "sldx", "sldm", "csv", "tsv", "txt", "odt", "ods", "odp", "numbers", "pages", "kth", "key"] as const;
const videos = ["mp4"] as const;

export const extensions = {
  documents,
  videos,
  images: ["bmp", "jpeg", "jpg", "jpeg2000", "gif", "png", "svg", "webp"],
  audio: ["mp3"],
  bulkUpload: ["csv", "json"],
  pdf: ["pdf"],
  customReports: ["pdf", "csv", "xls", "xlsx"],
  adminFiles: [...documents, ...videos],
} as const;

export type Extensions = keyof typeof extensions;

const extKeys = Object.keys(extensions);
export const isExtensions = (s: string): s is Extensions => extKeys.includes(s);
