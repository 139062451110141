import { identity, O, pipe, RA, RNEA } from "@scripts/fp-ts";
import type { BondsSsrData } from "@scripts/generated/models/bondsSsrData";
import { programsMeta } from "@scripts/meta/dataMeta";
import { Anchor, AnchorButton, AnchorIcon } from "@scripts/react/components/Anchor";
import { Card } from "@scripts/react/components/card/Card";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { FactsRow, makeFactO } from "@scripts/react/components/Facts";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { klass } from "@scripts/react/util/classnames";
import { program as programRoute, programs, rfps } from "@scripts/routes/routing/ssr/issuersites";

import bondIcon from "@svgs/bond.svg";
import rfpIcon from "@svgs/rfp.svg";

import { useIssuerSitesSelector } from "../../state/store";

type ProgramCardProps = {
  program: BondsSsrData["programs"][number];
};

const makeProgamFactsO = (program: BondsSsrData["programs"][number]["data"]["record"], rfpsTitle: string) => pipe(
  RA.compact([
    makeFactO("CUSIP-6", program.program.cusip6, identity),
    makeFactO("Sector", program.program.sector, s => s.name),
    makeFactO("Bonds", RNEA.fromReadonlyArray(program.relatedContent.offerings), bs => bs.length.toString(), bondIcon),
    makeFactO(rfpsTitle, RNEA.fromReadonlyArray(program.relatedContent.rfps), rs => rs.length.toString(), rfpIcon),
  ]),
  RNEA.fromReadonlyArray
);

const ProgramCard = (props: ProgramCardProps) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");

  const programRouteMeta = programRoute({ issuerId: issuer.id, issuerSlug: issuer.slug, programId: props.program.data.id });
  const programTitle = programRouteMeta.title(pages);

  const rfpsTitle = rfps({ issuerId: issuer.id, issuerSlug: issuer.slug }).title(pages);
  const factsO = makeProgamFactsO(props.program.data.record, rfpsTitle);

  return (
    <Card klasses={["card-border", "h-100", "justify-content-between", "align-items-start"]}>
      <div>
        <h5 {...klass("mb-1")}>
          <Anchor
            target={"_self"}
            route={{ ...programRouteMeta, title: () => props.program.data.record.program.name }}
          />
        </h5>
        {pipe(
          factsO,
          mapOrEmpty(_ =>
            <div {...klass("mb-1")}>
              <FactsRow items={_} />
            </div>
          )
        )}
      </div>
      <AnchorIcon
        icon={programsMeta.svg}
        target={"_self"}
        route={{ ...programRouteMeta, title: () => `View ${programTitle}` }}
      />
    </Card>
  );
};

type ProgramsSectionProps = {
  sectionId: string;
  programs: RNEA.ReadonlyNonEmptyArray<BondsSsrData["programs"][number]>;
};

export const ProgramsSection = (props: ProgramsSectionProps) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const programsRouteMeta = programs({ issuerId: issuer.id, issuerSlug: issuer.slug });
  const programsTitle = programsRouteMeta.title(pages);

  return (
    <AccentDividerSection title={O.some(programsTitle)} sectionId={props.sectionId}>
      <div {...klass("grid grid-sx-2 rg-2")}>
        {pipe(
          props.programs,
          RNEA.map((_) =>
            <div {...klass("c-24 c-lg-12")} key={`program-${_.data.id}`}>
              <ProgramCard program={_} />
            </div>
          )
        )}
      </div>
      <AnchorButton
        klasses="mt-2"
        target="_self"
        route={{ ...programsRouteMeta, title: () => `View All ${programsTitle}` }}
        variant="primary"
      />
    </AccentDividerSection>
  );
};
