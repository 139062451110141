import { O, pipe, RA } from "@scripts/fp-ts";
import type { Officer } from "@scripts/generated/models/officer";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { getOfficerFullName, makeOfficerJumplink, OfficerSection } from "@scripts/react/components/OfficerSection";
import { klass } from "@scripts/react/util/classnames";
import { team, type TeamPageData } from "@scripts/routes/routing/ssr/issuersites";

import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { makeJumplink } from "../../components/sidebar/sidebarLinksSyntax";
import { ContactUsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

export const TeamPage = (props: {
  officers: TeamPageData;
}) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");
  const teamRouteMeta = team({ issuerId: issuer.id, issuerSlug: issuer.slug });

  const visibleOfficers = props.officers.filter(_ => _.data.record.officerPageVisibility);
  const officerJumplinks = pipe(
    visibleOfficers,
    RA.map((officer) => makeJumplink(getOfficerFullName(officer.data.record), makeOfficerJumplink(officer)))
  );

  return <DirectSitesPageDescriptionLayout
    description={teamRouteMeta.description(pages, iffs, issuer)}
    sidebarContent={<>
      <SidebarLinksSites
        headerLinkCustomAnchorContent={O.none}
        jumpLinks={officerJumplinks}
        routeMeta={teamRouteMeta}
      />
      <ContactUsActionSection containerKlass={"d-none d-md-block"} />
    </>
    }
  >
    <div {...klass("accent-border-top", "pt-0", "team-officer-cards")}>
      {RA.map((officerData: WithStatusU<Officer>) =>
        <OfficerSection
          key={officerData.data.id}
          officer={officerData}
        />
      )(visibleOfficers)}
    </div>
    <ContactUsActionSection containerKlass={"d-md-none"} />
  </DirectSitesPageDescriptionLayout>;
};
