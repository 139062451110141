import type { CSSProperties, PropsWithChildren } from "react";
import { Fragment } from "react";
import type { Table as TableGrid } from "@devexpress/dx-react-grid-bootstrap4";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import md5 from "md5";

import type { Lazy } from "@scripts/fp-ts";
import { constNull, O, pipe, RA } from "@scripts/fp-ts";
import { portalTable } from "@scripts/generated/assets/stylesheets/components/_portal-table";
import { rowExpandedKlass } from "@scripts/react/components/table/cells/ExpandControlCell";
import type { Klass } from "@scripts/react/util/classnames";
import { klass, klassPropO } from "@scripts/react/util/classnames";
import type { GetRowStatus } from "@scripts/table/disableTableRows";
import { rowStatusFold } from "@scripts/table/disableTableRows";

import { ContactBondLinkAnchor } from "../Anchor";
import type { TableRowModel } from "./tableSyntax";

export type RowError = { rowId: number, messages: ReadonlyArray<string> };
export type TableRowErrors = ReadonlyArray<RowError>;
export type TableRowProps<A, MetaData> = PropsWithChildren<Omit<TableGrid.CellProps, "tableColumn"> & { row: TableRowModel<A, MetaData> }>;

const DefaultRowError = () => <Fragment>We encountered an error trying to complete this action. <ContactBondLinkAnchor title="Contact BondLink" /> if the problem persists.</Fragment>;
const ErrorText = (props: PropsWithChildren<unknown>) => <p {...klass("error-message")}><span {...klass("font-sans-italic-400")}>{props.children}</span></p>;

const ErrorRow = (props: { error: RowError, columnsCount: number }) => (
  <tr {...klass(portalTable[".table-md-breakpoint"][".portal-table-row"].attrs[".row-expanded-content"], portalTable[".table-md-breakpoint"][".portal-table-row"].attrs[".row-error"])}>
    <td {...klass("error", "error-detail-cell")} colSpan={props.columnsCount}>
      {RA.isEmpty(props.error.messages)
        ? <ErrorText><DefaultRowError /></ErrorText>
        : props.error.messages.map(eMsg => <ErrorText key={`${props.error.rowId}-${md5(eMsg)}`}>{eMsg}</ErrorText>)
      }
    </td>
    <td {...klass("error", "p-0")} />
  </tr>
);

const errorKlasses = () => [portalTable[".table-md-breakpoint"][".portal-table-row"].attrs[".row-expanded"], portalTable[".table-md-breakpoint"][".portal-table-row"].attrs[".row-error"]];
const disabledKlass = () => [portalTable[".table-md-breakpoint"][".portal-table-row"].attrs[".disabled"], portalTable[".table-md-breakpoint"][".portal-table-row"].attrs[".on-req"]];
const defaultRowExpandedKlass = () => [portalTable[".table-md-breakpoint"][".portal-table-row"].attrs[".row-expanded-content"]];
const rowExpandedErrorKlasses = () => [portalTable[".table-md-breakpoint"][".portal-table-row"].attrs[".row-expanded-content"], portalTable[".table-md-breakpoint"][".portal-table-row"].attrs[".row-error"]];

type RowProps = {
  expandedRowIds: ReadonlyArray<number>;
  columnsCount: number;
  getRowStatus: GetRowStatus;
  style?: CSSProperties;
  setNodeRef?: (node: HTMLElement | null) => void;
};

export const Row = <A, MetaData>(p: PropsWithChildren<TableRowProps<A, MetaData> & RowProps>) => {
  const rowStatusKlass: Lazy<ReadonlyArray<Klass>> = () => rowStatusFold(
    () => rowExpandedKlass(p.expandedRowIds, p.tableRow.rowId),
    disabledKlass,
    errorKlasses
  )(p.getRowStatus(p.row.__rowId));

  return (
    <Fragment key={p.row.__rowId}>
      <tr {...klassPropO([
        portalTable[".table-md-breakpoint"][".portal-table-row"],
        ...rowStatusKlass()]
      )(p.row.__klass)}
        style={p.style}
        ref={p.setNodeRef}
      >
        {p.children}
      </tr>
      {pipe(p.getRowStatus(p.row.__rowId), rowStatusFold(constNull, constNull, (e: RowError) => <ErrorRow error={e} columnsCount={p.columnsCount} />))}
    </Fragment>
  );
};

export const RowDraggable = <A, MetaData>(p: PropsWithChildren<TableRowProps<A, MetaData> & RowProps>) => {
  const { setNodeRef, transform, transition } = useSortable({ id: p.row.__rowId.toString() });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  return (
    <Row
      setNodeRef={setNodeRef}
      style={style}
      tableRow={p.tableRow}
      row={p.row}
      expandedRowIds={p.expandedRowIds}
      columnsCount={p.columnsCount}
      getRowStatus={p.getRowStatus}
    >
      {p.children}
    </Row>
  );
};

export const TableRow = (
  expandedRowIds: ReadonlyArray<number>,
  columnsCount: number,
  getRowStatus: GetRowStatus = O.zero,
) => <A, MetaData>(p: React.PropsWithChildren<TableRowProps<A, MetaData>>) =>
    <Row key={p.row.__rowId} {...p} expandedRowIds={expandedRowIds} columnsCount={columnsCount} getRowStatus={getRowStatus} />;

export const TableRowDraggable = (
  expandedRowIds: ReadonlyArray<number>,
  columnsCount: number,
  getRowStatus: GetRowStatus = O.zero,
) => <A, MetaData>(p: PropsWithChildren<TableRowProps<A, MetaData>>) =>
    <RowDraggable key={p.row.__rowId} {...p} expandedRowIds={expandedRowIds} columnsCount={columnsCount} getRowStatus={getRowStatus} />;


export const TableRowExpanded = (getRowStatus: GetRowStatus = O.zero) => <A, MetaData>(p: React.PropsWithChildren<TableRowProps<A, MetaData>>) =>
  <tr {...klassPropO(rowStatusFold(defaultRowExpandedKlass, disabledKlass, rowExpandedErrorKlasses)(getRowStatus(p.row.__rowId)))(p.row.__klass)}>{p.children}</tr>;
