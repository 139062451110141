import type { Column } from "@devexpress/dx-react-grid";
import { Workbook } from "exceljs";

import { O, pipe, R, type RNEA } from "@scripts/fp-ts";
import type { UserWithRoles } from "@scripts/generated/models/user";
import { localDateNow } from "@scripts/syntax/date/joda";
import { onlyNumbersToFixed } from "@scripts/syntax/number";
import { downloadSpreadsheet } from "@scripts/util/xlsx/excel";
import type { ExcelExportColumn, FilterSection } from "@scripts/util/xlsx/syntax";

import { parseExcelColumns } from "../actions/user-activity/reports/syntax";
import { emptyCellStr } from "../components/table/cells/NoDataCell";
import type { ExportType } from "../components/table/DownloadDataButton";
import type { GetCellValue, TableColumnsExport, TableRowMeta } from "../components/table/tableSyntax";
import { makeFilteredReportCoverSheet, makeReportSheet } from "./excel";

export const makeExportRows = <A, MetaData>(
  rows: ReadonlyArray<A & TableRowMeta<MetaData>>,
  columns: ReadonlyArray<Omit<Column, "getCellValue"> & { getCellValue?: GetCellValue<A, MetaData> } & ExcelExportColumn>,
  type: ExportType
) => {
  const csvRows: unknown[][] = rows.map(row =>
    columns.map(col => {
      const decimalPlaces = pipe(
        col.decimalPlaces,
        O.fromNullable,
        O.getOrElse(() => 2)
      );

      return pipe(
        O.fromNullable(col.getCellValue),
        O.fold(
          () => pipe(R.lookup(col.name, row), O.getOrElseW(() => "")),
          (getCellValue: GetCellValue<A, MetaData>) => {
            const val = getCellValue(row, col.name, type);
            // [AD 11/30/22] Convert emdash ("—") to empty string for CSV export
            return val === emptyCellStr ? "" : val;
          },
        ),
        onlyNumbersToFixed(decimalPlaces),
      );
    })
  );

  return csvRows;
};

const makeFilename = (title: string) => `${localDateNow().toString()} - ${title}.xlsx`;

export const downloadExcelExport = <A, MetaData, ExportExtra extends string[], ExportIgnore extends Array<keyof A>>(
  filteredRows: ReadonlyArray<A & TableRowMeta<MetaData>>,
  reportColumns: TableColumnsExport<A, MetaData, ExportExtra, ExportIgnore>,
  reportName: string,
  tableColumnDefs: ReadonlyArray<Column & ExcelExportColumn>,
) => {
  const columnDefinitions = parseExcelColumns(reportColumns);
  const rowsData = makeExportRows(filteredRows, tableColumnDefs, "xlsx");
  const workbook = new Workbook();

  makeReportSheet(
    workbook,
    columnDefinitions,
    rowsData,
    reportName
  );

  downloadSpreadsheet(workbook, makeFilename(reportName));
};

export const downloadExcelExportWithCoversheet = <A, MetaData, ExportExtra extends string[], ExportIgnore extends Array<keyof A>>(
  entityName: string,
  filtersApplied: RNEA.ReadonlyNonEmptyArray<FilterSection>,
  filteredRows: ReadonlyArray<A & TableRowMeta<MetaData>>,
  reportColumns: TableColumnsExport<A, MetaData, ExportExtra, ExportIgnore>,
  reportName: string,
  tableColumnDefs: ReadonlyArray<Column & ExcelExportColumn>,
  user: UserWithRoles
) => {
  const columnDefinitions = parseExcelColumns(reportColumns);
  const rowsData = makeExportRows(filteredRows, tableColumnDefs, "xlsx");
  const workbook = new Workbook();

  makeFilteredReportCoverSheet(
    entityName,
    filtersApplied,
    reportName,
    user,
    workbook
  );

  makeReportSheet(
    workbook,
    columnDefinitions,
    rowsData,
    reportName
  );

  downloadSpreadsheet(workbook, makeFilename(reportName));
};
