import { useCallback, useEffect, useRef, useState } from "react";
import { useStableO } from "fp-ts-react-stable-hooks";
import { Howl } from "howler";

import { O, pipe } from "@scripts/fp-ts";
import { cancelAnimFrame, requestAnimFrame } from "@scripts/util/requestAnimFrame";

import { useConfig } from "../context/Config";

export type AudioPlayerRefs = {
  load: () => void;
  duration: O.Option<number>;
  playing: boolean;
  setPlaying: (b: boolean) => void;
  speed: number;
  setSpeed: (s: number) => void;
  volume: number;
  setVolume: (v: number) => void;
  seekTime: number;
  seekToTime: (t: number) => O.Option<number>;
};

export type AudioErrorAction = "loading" | "playing";

export const useAudioPlayer = (props: {
  audioUrl: O.Option<string>;
  initFurthestSeek: O.Option<number>;
  canSeekForward: boolean;
  reportProgress: (seekTime: number) => void;
  errorHandler: (action: AudioErrorAction) => void;
}): AudioPlayerRefs => {
  const config = useConfig();
  const { audioUrl, initFurthestSeek, canSeekForward, reportProgress, errorHandler } = props;
  const [shouldLoad, setShouldLoad] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [howlO, setHowl] = useStableO<Howl>(O.none);
  const [duration, setDuration] = useStableO<number>(O.none);
  const [playing, setPlaying] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [volume, setVolume] = useState(0.75);
  const [seekTime, setSeekTime] = useState(0);
  const furthestSeek = useRef(O.getOrElse(() => 0)(initFurthestSeek));

  const load = useCallback(() => {
    if (!shouldLoad) {
      setShouldLoad(true);
    }
  }, [shouldLoad]);

  /*
  Initialize and load howler when requested (`shouldLoad`), but only once -- when `isLoaded` is `false`.

  Deferring the initialization of howler is necessary to prevent warnings in Chrome (https://developer.chrome.com/blog/autoplay):
    "The AudioContext was not allowed to start. It must be resumed (or created) after a user gesture on the page."

  We wait to set `shouldLoad` to `true` until the user has clicked to accept the roadshow disclaimer.
  */
  useEffect(() => {
    if (shouldLoad && !isLoaded) {
      setIsLoaded(true);

      pipe(
        audioUrl,
        O.map(u => {
          const howl = new Howl({
            html5: true,
            src: u,
            volume,
            rate: speed,
          });

          const togglePlayAndReportProgress = (p: boolean) => {
            setPlaying(p);
            reportProgress(howl.seek());
          };

          const handleError = (action: AudioErrorAction) => (_id: number, err: unknown) => {
            config.log.error(`Error ${action} audio: ${JSON.stringify(err)}`);
            errorHandler(action);
          };

          howl
            .on("play", () => togglePlayAndReportProgress(true))
            .on("pause", () => togglePlayAndReportProgress(false))
            .on("stop", () => togglePlayAndReportProgress(false))
            .on("end", () => togglePlayAndReportProgress(false))
            .on("load", () => setDuration(pipe(O.some(howl.duration()), O.filter(_ => !isNaN(_)))))
            .on("loaderror", handleError("loading"))
            .on("playerror", handleError("playing"));

          // Howler doesn't properly handle play/pause using the keyboard media keys, this accounts for them
          // https://github.com/goldfire/howler.js/issues/1175
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (window?.navigator?.mediaSession) {
            window.navigator.mediaSession.setActionHandler("play", () => togglePlayAndReportProgress(true));
            window.navigator.mediaSession.setActionHandler("pause", () => togglePlayAndReportProgress(false));
          }

          setHowl(O.some(howl));
          howl.load();
        }),
      );
    }
  }, [shouldLoad, isLoaded, audioUrl, volume, speed, reportProgress, setDuration, config.log, errorHandler, setHowl]);

  const safeSeek = useCallback((t: number): O.Option<number> => {
    // Prevent seeking forward if `canSeekForward` is `false`, but allow seeking backward
    if (canSeekForward || t <= furthestSeek.current) {
      pipe(
        audioUrl,
        O.fold(
          () => {
            setSeekTime(t);
            reportProgress(t);
          },
          () => pipe(howlO, O.map(_ => _.seek(t))),
        ),
      );
      return O.some(t);
    } else {
      return O.none;
    }
  }, [canSeekForward, audioUrl, reportProgress, howlO]);

  // Handle play/pause. If `playing` is `true` and howler hasn't been initialized/loaded yet, load it
  useEffect(() => {
    if (playing) {
      pipe(howlO, O.fold(load, _ => _.play()));
    } else {
      pipe(howlO, O.map(_ => _.pause()));
    }
  }, [playing, howlO, load]);

  // Handle speed change
  useEffect(() => {
    pipe(howlO, O.map(_ => _.rate(speed)));
  }, [howlO, speed]);

  // Handle volume change
  useEffect(() => {
    pipe(howlO, O.map(_ => _.volume(volume)));
  }, [howlO, volume]);

  // Handle updating progress
  const frameRef = useRef<number>();
  const lastSeekUpd = useRef(0);
  const lastProgressUpd = useRef(0);
  useEffect(() => {
    pipe(howlO, O.map(howl => {
      const go = (now: number) => {
        const pos = howl.seek();
        // Debounce calls to `setSeekTime` so they only happen every 250 millis
        if (lastSeekUpd.current === 0 || (now - lastSeekUpd.current) >= 250) {
          lastSeekUpd.current = now;
          setSeekTime(pos);
          if (pos >= furthestSeek.current) {
            furthestSeek.current = pos;
          }
        }
        // Debounce calls to `reportProgress` so they only happen every 5 seconds
        if (lastProgressUpd.current === 0) {
          lastProgressUpd.current = now;
        } else if ((now - lastProgressUpd.current) >= 5000) {
          reportProgress(pos);
          lastProgressUpd.current = now;
        }
        frameRef.current = requestAnimFrame(go);
      };

      frameRef.current = requestAnimFrame(go);
    }));

    return () => {
      if (frameRef.current) {
        cancelAnimFrame(frameRef.current);
      }
    };
  }, [howlO, reportProgress]);

  return {
    load,
    duration,
    playing,
    setPlaying,
    speed,
    setSpeed,
    volume,
    setVolume,
    seekTime,
    seekToTime: safeSeek,
  };
};
