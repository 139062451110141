import type { PropsWithChildren } from "react";

import type { Markdown as MarkdownType } from "@scripts/codecs/markdown";
import { flow, O, pipe, RA, RNEA } from "@scripts/fp-ts";
import type { Media } from "@scripts/generated/models/media";
import type { QuickFact } from "@scripts/generated/models/quickFact";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { FactsRow, type FactType, makeFactTooltipLinkPropsO } from "@scripts/react/components/Facts";
import { ImageCarouselModal } from "@scripts/react/components/ImageCarouselModal";
import { AccentDividerSection, DividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import { type DocumentRelatedContent, DocumentsRelatedContent, type LinkRelatedContent, LinksRelatedContent } from "@scripts/react/components/offering-pages/RelatedContent";
import { klass } from "@scripts/react/util/classnames";

import { useIssuerSitesSelector } from "../state/store";
import { IssuerSitesLeafIcon } from "./LeafIcon";

const OverviewGallery = (props: { photos: ReadonlyArray<WithStatusU<Media>> }) => pipe(
  props.photos,
  RA.map(photo => photo.data.record),
  RNEA.fromReadonlyArray,
  mapOrEmpty(photos =>
    <DividerSection title={O.some("Image Gallery")}>
      <ImageCarouselModal images={photos} isMulti />
    </DividerSection>
  )
);

const makeQuickFacts = (additionalFacts: ReadonlyArray<FactType>) =>
  flow(
    RA.map((fact: QuickFact): FactType => ({
      title: fact.fieldHeader,
      value: fact.fieldValue,
      tooltipProps: O.toUndefined(makeFactTooltipLinkPropsO(fact.fieldUrl, fact.linkText)),
    })),
    arr => RA.concat(arr)(additionalFacts),
    RNEA.fromReadonlyArray
  );

type OverviewAndFactsProps = {
  overview: O.Option<MarkdownType>;
  facts: O.Option<RNEA.ReadonlyNonEmptyArray<FactType>>;
};

const OverviewAndFacts = (props: OverviewAndFactsProps) => {
  const showSection = O.isSome(props.facts) || O.isSome(props.overview);

  return pipe(
    showSection,
    trueOrEmpty(
      <DividerSection title={O.none}>
        {pipe(
          props.facts,
          mapOrEmpty((facts) =>
            <div {...klass("mb-1")}>
              <FactsRow variant="bold" items={facts} />
            </div>
          )
        )}
        {pipe(
          props.overview,
          mapOrEmpty(overview => <Markdown content={overview} />)
        )}
      </DividerSection>
    )
  );
};

type OverviewSectionProps = PropsWithChildren<Omit<OverviewAndFactsProps, "facts"> & {
  documentsDataO: O.Option<RNEA.ReadonlyNonEmptyArray<DocumentRelatedContent>>;
  facts: ReadonlyArray<QuickFact>;
  additionalFacts: ReadonlyArray<FactType>;
  linksDataO: O.Option<RNEA.ReadonlyNonEmptyArray<LinkRelatedContent>>;
  photos: ReadonlyArray<WithStatusU<Media>>;
  sectionId: string;
  title: string;
}>;

export const OverviewSection = (props: OverviewSectionProps) => {
  const issuer = useIssuerSitesSelector("issuer");
  return (
    <AccentDividerSection
      title={O.some(props.title)}
      sectionId={props.sectionId}
      klasses="accent-border-top"
    >
      <OverviewAndFacts
        facts={makeQuickFacts(props.additionalFacts)(props.facts)}
        overview={props.overview}
      />
      <OverviewGallery photos={props.photos} />
      {pipe(
        props.documentsDataO,
        mapOrEmpty(d =>
          <DocumentsRelatedContent
            documents={d}
            downloadRoute={(issuerId, mediaId) =>
              SR.issuersitesReportsControllerDownloadRedirect({
                issuerId,
                mediaId,
                issuerSlug: issuer.slug,
              })
            }
            headline={"Documents"}
            issuer={issuer}
            variant="page"
            leafIcon={IssuerSitesLeafIcon}
          />
        )
      )}
      {pipe(
        props.linksDataO,
        mapOrEmpty(l => <LinksRelatedContent issuer={issuer} links={l} variant="page" />)
      )}
    </AccentDividerSection>
  );
};
