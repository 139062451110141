import * as n from "fp-ts/lib/number";
import * as O from "fp-ts/lib/Option";
import type { Predicate } from "fp-ts/lib/Predicate";
import * as RM from "fp-ts/lib/ReadonlyMap";

import { constFalse, pipe } from "@scripts/fp-ts";
import type { BLDealPortalSession } from "@scripts/generated/models/blWindow";
import type { Issuer } from "@scripts/generated/models/issuer";

import type { FeatureU } from "../generated/domaintables/featureFlags";
import type { ClientFeatureFlags } from "../generated/models/clientFeatureFlags";

export type ValidatedFF<FF extends FeatureU> = FF & { enabled: boolean };

export function getValidatedFF<FF extends FeatureU>(ff: FF, iffs: ClientFeatureFlags): ValidatedFF<FF> {
  return ({ ...ff, enabled: iffs.ffs.get(ff.id) ?? ff.default });
}

export const flipped = {
  isFFEnabled: (iffs: ClientFeatureFlags): Predicate<FeatureU> => (ff: FeatureU) => isFFEnabled(ff)(iffs),
};

export const isFFEnabled = (ff: FeatureU) => (iffs: ClientFeatureFlags): boolean =>
  iffs.ffs.get(ff.id) ?? ff.default;

export const isFFDisabled = (ff: FeatureU) => (iffs: ClientFeatureFlags): boolean =>
  !isFFEnabled(ff)(iffs);

export const ffAllEnabled = (ffs: FeatureU[]) => (iffs: ClientFeatureFlags): boolean =>
  ffs.every((f: FeatureU) => isFFEnabled(f)(iffs));

export const ffAllDisabled = (ffs: FeatureU[]) => (iffs: ClientFeatureFlags): boolean =>
  ffs.every((f: FeatureU) => isFFDisabled(f)(iffs));

export const ifAnyEnabled = (ffs: FeatureU[]) => (iffs: ClientFeatureFlags): boolean =>
  ffs.some((f: FeatureU) => isFFEnabled(f)(iffs));

export const ifAnyDisabled = (ffs: FeatureU[]) => (iffs: ClientFeatureFlags): boolean =>
  ffs.some((f: FeatureU) => isFFDisabled(f)(iffs));

/**
 * This function is necessary to unify all the variations of `FeatureU["dependsOn"]` as
 * `O.Option<FeatureU>` instead of `O.None | O.Some<never> | O.Some<...>` from the generated
 * type.
*/
export const getParent = (ff: FeatureU): O.Option<FeatureU> => ff.dependsOn;

export type BankerFeatureFlags = BLDealPortalSession["issuerFeatureFlags"];

export const isIssFFEnabled = (issuer: Issuer, iff: FeatureU, iffs: BankerFeatureFlags) => pipe(
  iffs,
  RM.lookup(n.Eq)(issuer.id),
  O.chain(_ => RM.lookup(n.Eq)(iff.id)(_.ffs)),
  O.getOrElse(constFalse)
);
