import { O } from "@scripts/fp-ts";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { AnchorButton } from "@scripts/react/components/Anchor";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import { Svg } from "@scripts/react/components/Svg";
import { esgDescription, esgTitle } from "@scripts/routes/routing/ssr/issuersites";

import leafIcon from "@svgs/leaf.svg";

import { useIssuerSitesSelector } from "../../../state/store";


type ESGSectionProps = {
  sectionId: string;
};

export const ESGSection = (props: ESGSectionProps) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");

  return (
    <AccentDividerSection title={O.none} sectionId={props.sectionId}>
      <h2>{esgTitle(pages)} <Svg src={leafIcon} /></h2>
      <Markdown content={esgDescription(pages, iffs, issuer)} klasses={["last-child-mb-0"]} />
      <AnchorButton
        externalLinkLocation="none"
        target="_self"
        route={{
          title: `View Details`,
          route: SR.issuersitesAboutControllerEsgProgram({
            issuerId: issuer.id,
            issuerSlug: issuer.slug,
          }),
        }}
        variant="primary"
        klasses={"mt-2"}
      />
    </AccentDividerSection>
  );
};
