import type { ReactElement } from "react";

import { ESG } from "@scripts/generated/domaintables/contentTagTypes";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import { hasTag } from "@scripts/models/taggedContent";
import { Empty } from "@scripts/react/components/Empty";
import { Svg } from "@scripts/react/components/Svg";
import type { Description, TooltipDelay } from "@scripts/react/components/Tooltip";
import { TooltipAnchor } from "@scripts/react/components/Tooltip";

import leafIcon from "@svgs/leaf.svg";

import type { Klass } from "../util/classnames";

export type LeafIconAsProp = {
  leafIcon: (props: LeafIconBaseProps) => ReactElement;
};

export type LeafIconBaseProps = {
  taggedContent: TaggedContent<unknown>;
  delay?: TooltipDelay;
  klass?: Klass;
};

export const LeafIconBase = (props: LeafIconBaseProps & { description: Description }) =>
  hasTag(ESG)(props.taggedContent)
    ? (
      <TooltipAnchor addClassToTargetNode={props.klass} delay={props.delay ?? "default"} description={props.description}>
        <Svg src={leafIcon} />
      </TooltipAnchor>
    ) : <Empty />;

export const LeafIcon = (props: LeafIconBaseProps) =>
  <LeafIconBase
    klass={props.klass}
    taggedContent={props.taggedContent}
    description={{
      type: "DescriptionContent",
      text: `This content is related to this issuer’s ESG program.`,
    }}
  />;
