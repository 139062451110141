import { type PropsWithChildren, type ReactElement } from "react";
import * as O from "fp-ts/lib/Option";
import { takeLeft } from "fp-ts/lib/ReadonlyArray";
import type { ReadonlyNonEmptyArray } from "fp-ts/lib/ReadonlyNonEmptyArray";

import { A, type Lazy, pipe } from "@scripts/fp-ts";
import { E } from "@scripts/fp-ts";
import { contact, news, type PageU } from "@scripts/generated/domaintables/pages";
import type { RatingAgencyU } from "@scripts/generated/domaintables/ratingAgencies";
import type { IssuerOverviewCard as IssuerCard } from "@scripts/generated/models/issuerOverviewCard";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import type { RatingU } from "@scripts/generated/models/ratingBase";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { AnchorIconUnsafe, AnchorUnsafe, type Target } from "@scripts/react/components/Anchor";
import { Badge } from "@scripts/react/components/Badge";
import { Card } from "@scripts/react/components/card/Card";
import { DataHorizontal, DataPointLarge } from "@scripts/react/components/Data";
import { trueOrEmpty } from "@scripts/react/components/Empty";
import type { OnSubscribe } from "@scripts/react/components/SubscriptionToggle";
import { SubscriptionToggleIconCard } from "@scripts/react/components/SubscriptionToggle";
import { Svg } from "@scripts/react/components/Svg";
import { useConfig } from "@scripts/react/context/Config";
import { klass, type KlassProp, klassPropO } from "@scripts/react/util/classnames";
import { issuerSiteAbsUrl } from "@scripts/syntax/issuer";
import { getCustomTitleO } from "@scripts/syntax/pageTitles";
import { deepLink } from "@scripts/util/url";

import bondIcon from "@svgs/bond.svg";
import chevNextIcon from "@svgs/chevron-next.svg";
import chevPrevIcon from "@svgs/chevron-previous.svg";
import documentIcon from "@svgs/document.svg";
import contactIcon from "@svgs/email.svg";
import newsIcon from "@svgs/newspaper.svg";

import { CarouselControlled, useCarouselCurrentIndex } from "../CarouselControlled";
import { findIssuerRatingsPage, issuerRatingPageToTitle } from "../offering-pages/Ratings";

type PaginationProps = {
  currentIndex: number;
  for: string;
  pageLength: number;
  hasNext: boolean;
  hasPrev: boolean;
  onNext: Lazy<void>;
  onPrev: Lazy<void>;
};

const Pagination = (props: PaginationProps) => (
  <div className="p8n-control-simple" data-for={props.for}>
    <button
      aria-label="Previous"
      type="button"
      className="btn-arrow prev"
      disabled={!props.hasPrev}
      onClick={props.onPrev}
    >
      <Svg src={chevPrevIcon} />
    </button>
    <span className="pages">
      <span className="page">{props.currentIndex + 1}</span> of <span className="of">{props.pageLength}</span>
    </span>
    <button
      aria-label="Next"
      type="button"
      className="btn-arrow next"
      disabled={!props.hasNext}
      onClick={props.onNext}
    >
      <Svg src={chevNextIcon} />
    </button>
  </div>
);

type IssuerOverviewCardProps = {
  header: ReactElement;
  issuer: IssuerCard;
  id: number;
  klass?: KlassProp;
  onSubscribe: OnSubscribe;
  target: Target;
  pages: ReadonlyArray<PageConfig<PageU>>;
};

const RatingsPage = <R extends RatingU>(props: {
  name: string;
  ratingsByAgency: ReadonlyArray<[RatingAgencyU, ReadonlyNonEmptyArray<WithStatusU<R>>]>;
}) =>
  <>
    <p className="bond-name">{props.name}</p>
    <div className="bond-ratings">
      <DataHorizontal>
        {takeLeft(3)(props.ratingsByAgency).map(([agency, ratings]) =>
          <DataPointLarge
            key={agency.id}
            title={agency.shortName}
            value={ratings[0].data.record.rating}
          />
        )}
      </DataHorizontal>
    </div>
  </>;

export const IssuerOverviewCard = (props: PropsWithChildren<IssuerOverviewCardProps>) => {
  const config = useConfig();
  const hasIssuerRatings = props.issuer.issuerRatings.length > 0;
  const ratingsPages = (hasIssuerRatings ? 1 : 0) + props.issuer.programRatings.length;
  const noRatings = ratingsPages === 0;
  const multipleRatings = ratingsPages > 1;

  const [currentIndex, prev, next, hasPrev, hasNext] = useCarouselCurrentIndex(ratingsPages);

  return (
    <Card klasses="card-issuer-overview">
      <SubscriptionToggleIconCard
        isSubscribed={props.issuer.subscribed}
        onSubscribe={props.onSubscribe}
        class="bond-offering-toggle"
      />
      {props.header}
      <div {...klass(klassPropO("overview-body")(props.klass).className)}>
        <div {...klass("section")}>
          {props.children}
        </div>
        <div {...klass("section", noRatings ? "no-ratings" : "")}>
          <div className="bonds-header">
            <h4 {...klass("mt-0", noRatings ? "no-ratings" : "")}>
              {
                ratingsPages > 0
                  ? <AnchorUnsafe
                    href={issuerSiteAbsUrl(config)(props.issuer.issuer, O.none)(SitesRouter.issuersitesBondOfferingsControllerRatings)({}).url}
                    title="Ratings"
                    target={props.target}
                    externalLinkLocation="none"
                  />
                  : <span>Ratings</span>
              }
            </h4>
            {multipleRatings
              && <Pagination
                currentIndex={currentIndex}
                for={`#ratings-container-${props.id}`}
                hasNext={hasNext}
                hasPrev={hasPrev}
                onNext={next}
                onPrev={prev}
                pageLength={ratingsPages}
              />
            }
          </div>
          <div id={`ratings-container-${props.id}`}>
            {ratingsPages > 0
              ? <CarouselControlled
                currentItem={currentIndex}
              >
                {...pipe(
                  hasIssuerRatings
                    ? [<div key={-1}><RatingsPage name={pipe(props.pages, findIssuerRatingsPage, issuerRatingPageToTitle)} ratingsByAgency={props.issuer.issuerRatings} /></div>]
                    : [],
                  A.concat(props.issuer.programRatings.map(([program, ratingsByAgency]) =>
                    <div key={program.id} {...klass("slide-container clear-fix")}><RatingsPage name={program.record.name} ratingsByAgency={ratingsByAgency} /></div>
                  )),
                )}
              </CarouselControlled>
              : (
                <p>
                  This issuer has not published any bond ratings. <AnchorUnsafe
                    externalLinkLocation="none"
                    href={issuerSiteAbsUrl(config)(props.issuer.issuer, O.none)(deepLink({
                      type: "Modal",
                      route: SitesRouter.issuersitesResourcesControllerIndex,
                      selector: "anchor-contact-us",
                      itemId: O.none,
                    }))({}).url}
                    title="Contact the issuer"
                    target={props.target}
                  /> for more information.
                </p>
              )
            }
          </div>
        </div>
        <div className="links">
          <div className="d-flex">
            <AnchorIconUnsafe
              href={issuerSiteAbsUrl(config)(props.issuer.issuer, O.none)(SitesRouter.issuersitesBondOfferingsControllerIndex)({}).url}
              icon={bondIcon}
              target={props.target}
              textOrAriaLabel={E.left("Bonds")}
              klasses="mr-025"
            />
            {pipe(
              props.issuer.offerings > 0,
              trueOrEmpty(
                <Badge
                  text={props.issuer.offerings.toString()}
                  icon={O.none}
                  color="red-700"
                  variant="short"
                />
              ))
            }
          </div>
          <div>
            <AnchorIconUnsafe
              href={issuerSiteAbsUrl(config)(props.issuer.issuer, O.none)(SitesRouter.issuersitesReportsControllerIndex)({}).url}
              icon={documentIcon}
              target={props.target}
              textOrAriaLabel={E.left("Documents")}
            />
          </div>
        </div>
        <div className="links">
          <div>
            <AnchorIconUnsafe
              href={issuerSiteAbsUrl(config)(props.issuer.issuer, O.none)(SitesRouter.issuersitesAboutControllerNewsEvents)({}).url}
              icon={newsIcon}
              target={props.target}
              textOrAriaLabel={E.left(O.getOrElse(() => "News & Events")(getCustomTitleO(news)(props.pages)))}
            />
          </div>
          <div>
            <AnchorIconUnsafe
              href={issuerSiteAbsUrl(config)(props.issuer.issuer, O.none)(deepLink({
                type: "Basic",
                route: SitesRouter.issuersitesResourcesControllerIndex,
                selector: "anchor-contact-us",
              }))({}).url}
              icon={contactIcon}
              target={props.target}
              textOrAriaLabel={E.left(O.getOrElse(() => "Contact Us")(getCustomTitleO(contact)(props.pages)))}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};
