import type { TableColumnProperties, Workbook } from "exceljs";

import { b, constFalse, O, pipe, RA, RNEA } from "@scripts/fp-ts";
import type { UserWithRoles } from "@scripts/generated/models/user";
import { humanDateFullAtTime, localDateTimeNow } from "@scripts/syntax/date/joda";
import { applyBold, applyBorder, applyColorScale, applyDarkGrayFill, applyDataBar, applyFont, applyTextWrap, coordsToCellAddress } from "@scripts/util/xlsx/excel";
import { black, darkGrayFill, defaultFont, type ExcelExportColumn, excelNumberFormats, filterBorderBottom, type FilterSection, gray200, gray400, gray600, white, widthConversionFactor } from "@scripts/util/xlsx/syntax";

import bondlinkLogo from "../../../../assets/img/logos/bondlink-2019.png";

const defaultColumnWidth = 48;

export const makeFilteredReportCoverSheet = (
  entityName: string,
  filtersApplied: RNEA.ReadonlyNonEmptyArray<FilterSection>,
  reportName: string,
  user: UserWithRoles,
  workbook: Workbook
) => {
  const logoId = workbook.addImage({ base64: bondlinkLogo, extension: "png" });

  const coverSheet = workbook.addWorksheet("Cover Sheet", {
    views: [{
      showGridLines: false,
    }],
  });

  coverSheet.getColumn("A").width = 16 * widthConversionFactor;
  coverSheet.getColumn("B").width = 70 * widthConversionFactor;
  coverSheet.getColumn("C").width = 16 * widthConversionFactor;

  coverSheet.addRow([`${entityName}: ${reportName}`]);

  const originalWidth = 1621;
  const originalHeight = 253;
  const titleRowHeight = 40;
  // Couldn't determine the exact reasoning behind this but it looks good - BS
  const imgSizeConversionFactor = 2 / 3;

  coverSheet.addImage(logoId, {
    // I haven't figured out exactly how this config works but I think it must be bottom left rather than top - BS
    tl: { col: 2, row: 0.7 },
    ext: {
      height: titleRowHeight * imgSizeConversionFactor,
      width: (originalWidth / originalHeight) * titleRowHeight * imgSizeConversionFactor,
    },
  });

  const titleBorderBottom = {
    bottom: {
      color: black,
      style: "thin" as const,
    },
  };

  coverSheet.getCell("A1").border = titleBorderBottom;
  coverSheet.getCell("B1").border = titleBorderBottom;
  coverSheet.getCell("C1").border = titleBorderBottom;

  coverSheet.getRow(1).alignment = {
    vertical: "middle",
  };

  coverSheet.getRow(1).height = titleRowHeight;

  coverSheet.addRow([]);

  const rowsBeforeFilters = 2;
  const titleAndEmptyRowCount = 2;
  const contentColumns = [0, 1, 2];

  const filterTitleRows = pipe(
    filtersApplied,
    RNEA.reduceWithIndex<FilterSection, RNEA.ReadonlyNonEmptyArray<number>>(
      RNEA.fromNativeReadonlyNonEmptyArray([rowsBeforeFilters]),
      (filtersAppliedIndex, acc, section) => {
        const lastTitleRow = pipe(
          acc,
          RNEA.last
        );

        coverSheet.addRow([section.title]);

        section.filters.map((filter, filtersIndex) => {
          const coverSheetRow: [string, string] = [filter.name, O.getOrElse(() => "All")(filter.value)];

          coverSheet.addRow(coverSheetRow);

          const rowIndex = lastTitleRow + filtersIndex + 1;

          const cellAddress = coordsToCellAddress(0, rowIndex);

          applyBold(coverSheet, cellAddress);
          applyTextWrap(coverSheet, cellAddress);

          contentColumns.map(column => applyBorder(coverSheet, coordsToCellAddress(column, rowIndex), filterBorderBottom));
        });

        coverSheet.addRow([]);

        return filtersAppliedIndex === filtersApplied.length - 1 ? acc : pipe(
          acc,
          RNEA.concat([lastTitleRow + section.filters.length + titleAndEmptyRowCount])
        );
      }
    )
  );

  filterTitleRows.map(row => {
    const titleRowCells = contentColumns.map(_ => coordsToCellAddress(_, row));

    titleRowCells.map(_ => applyDarkGrayFill(coverSheet, _));

    coverSheet.getCell(coordsToCellAddress(0, row)).font = {
      ...coverSheet.getCell("A3").font,
      bold: true,
      color: white,
    };
  });

  const exportedOnRow = coverSheet.addRow([`Exported on ${humanDateFullAtTime(localDateTimeNow())} by ${user.user.firstName} ${user.user.lastName}`]);

  exportedOnRow.eachCell(cell => {
    cell.style = {
      ...cell.style,
      font: {
        ...cell.style.font,
        color: gray600,
      },
    };
  });

  coverSheet.getRows(2, exportedOnRow.number)?.map(row => {
    applyTextWrap(coverSheet, coordsToCellAddress(1, row.number - 1));
  });

  applyFont(coverSheet);

  const titleCell = coverSheet.getCell("A1");

  coverSheet.getCell("A1").style = {
    ...titleCell.style,
    font: {
      ...titleCell.style.font,
      bold: true,
      size: 16,
    },
  };
};

const includeTotalsRow = (columnDefinitions: ExcelExportColumn[]) => pipe(
  columnDefinitions,
  RA.some(_ => pipe(_.conditionalFormatting, O.fromNullable, O.isSome))
);

const _makeTotalsRowLabel = (columnDefinitions: ExcelExportColumn[]) => pipe(
  includeTotalsRow(columnDefinitions),
  b.fold<(i: number) => string>(
    () => () => "",
    () => (rowIndex: number) => rowIndex === 0 ? "Total" : ""
  )
);

export const makeReportSheet = (
  workbook: Workbook,
  columnDefinitions: ExcelExportColumn[],
  rowsData: unknown[][],
  reportName: string,
) => {
  const reportSheet = workbook.addWorksheet(reportName, {
    views: [{
      showGridLines: false,
      state: "frozen",
      topLeftCell: "A2",
      ySplit: 1,
    }],
  });

  const makeTotalsRowLabel = _makeTotalsRowLabel(columnDefinitions);

  const columnsData: TableColumnProperties[] = columnDefinitions.map((_, i) => ({
    filterButton: true,
    name: _.title,
    style: {
      font: defaultFont,
    },
    totalsRowFunction: pipe(
      _.conditionalFormatting,
      O.fromNullable,
      O.fold(
        constFalse,
        __ => __ === "colorScale"
      )
      // eslint-disable-next-line no-undefined
    ) ? "sum" as const : undefined,
    totalsRowLabel: makeTotalsRowLabel(i),
  }));

  reportSheet.addTable({
    name: "Table1",
    ref: "A1",
    headerRow: true,
    totalsRow: includeTotalsRow(columnDefinitions),
    columns: columnsData,
    rows: rowsData,
  });

  // This needs to happen after the table is added or the style gets clobbered.
  columnDefinitions.map((_, i) => {
    pipe(
      _.conditionalFormatting,
      O.fromNullable,
      O.map(__ => {
        switch (__) {
          case "colorScale":
            applyColorScale(reportSheet, i, 1, rowsData.length);
            break;
          case "dataBar":
            applyDataBar(reportSheet, i, 1, rowsData.length);
            break;
        }
      })
    );

    pipe(
      _.numberFormat,
      O.fromNullable,
      O.map(numberFormat => {
        reportSheet.getColumn(i + 1).eachCell(cell => {
          cell.style = {
            ...cell.style,
            numFmt: excelNumberFormats[numberFormat],
          };
        });
      })
    );
  });

  const headerRow = reportSheet.getRow(1);

  headerRow.height = 34;

  headerRow.eachCell(cell => {
    cell.border = {
      ...cell.border,
      right: {
        color: black,
        style: "thin",
      },
    };

    cell.style = {
      ...cell.style,
      fill: darkGrayFill,
    };

    cell.font = {
      ...cell.font,
      color: white,
    };
  });

  const xBorder = {
    style: "thin" as const,
    color: gray200,
  };

  reportSheet.getRows(2, rowsData.length)?.map(row => {
    row.eachCell((cell, colNumber) => {
      cell.border = {
        ...cell.border,
        bottom: {
          style: "thin" as const,
          color: gray400,
        },
        // eslint-disable-next-line no-undefined
        left: colNumber !== 1 ? xBorder : undefined,
        right: colNumber !== columnDefinitions.length ? xBorder : {
          color: black,
          style: "thin",
        },
      };
    });
  });

  if (includeTotalsRow(columnDefinitions)) {
    const lastRow = rowsData.length + 2;

    reportSheet.getRow(lastRow).eachCell({ includeEmpty: true }, (cell, colNum) => {
      cell.border = {
        ...cell.border,
        bottom: {
          color: black,
          style: "thin",
        },
        right: colNum === columnDefinitions.length ? {
          color: black,
          style: "thin",
          // eslint-disable-next-line no-undefined
        } : undefined,
        top: {
          color: black,
          style: "medium",
        },
      };
    });
  }

  columnDefinitions.map((_, i) => {
    reportSheet.getColumn(i + 1).width = pipe(_.width, O.fromNullable, O.getOrElse(() => defaultColumnWidth));
  });

  reportSheet.eachRow(row => {
    row.eachCell({ includeEmpty: true }, cell => {
      applyTextWrap(reportSheet, coordsToCellAddress(parseInt(cell.col) - 1, row.number - 1));
    });
  });

  return workbook;
};
