import { type ReactNode } from "react";

import { constVoid, E, O, pipe } from "@scripts/fp-ts";
import { allUserPersona } from "@scripts/generated/domaintables/userPersonas";
import type { ContactPost, ContactPostC } from "@scripts/generated/models/contact";
import type { EmptyObjectC } from "@scripts/generated/models/emptyObject";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { User } from "@scripts/generated/models/user";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { ButtonPrimary, ButtonSubmit, type ButtonVariantProps } from "@scripts/react/components/Button";
import { CaptchaForm } from "@scripts/react/components/form/CaptchaForm";
import { FormModalDataLoaderWithState, type FormModalFormProps } from "@scripts/react/components/form/FormModal";
import { Select, type SelectOption } from "@scripts/react/components/form/Select";
import { Input, TextArea } from "@scripts/react/components/form/TextInput";
import {
  Modal,
  type ModalDismissable,
  type ModalOpenable,
  type ModalSuccessActions,
} from "@scripts/react/components/modal/Modal";
import { optionC, stringC } from "@scripts/react/form/codecs";
import { emptyFormState, formDataLens, formLens } from "@scripts/react/form/form";
import type { KlassProp } from "@scripts/react/util/classnames";
import { klass, klassPropO } from "@scripts/react/util/classnames";
import { useModal } from "@scripts/react/util/useModal";
import { organization } from "@scripts/util/organization";

import emailIcon from "@svgs/email.svg";

type ContactModalProps = ModalOpenable
  & Omit<ModalSuccessActions, "deleteAction">
  & Omit<ContactModalCalloutProps, "btnText">;

const contactL = formLens<ContactPostC>();
const contactDL = formDataLens<ContactPostC>();

const titleOptions: ReadonlyArray<SelectOption<string>> = allUserPersona.map(p => ({
  label: p.personaName,
  value: p.personaName,
}));

const emptyState = emptyFormState<ContactPostC>();

const makeInitialState = (data: Pick<ContactModalCalloutProps, "user" | "bondOfferingId" | "rfpId">) => {
  return pipe(
    data.user,
    O.fold(
      () => emptyState,
      u => pipe(
        emptyState,
        contactDL.compose(contactL("firstName")).set(u.firstName),
        contactDL.compose(contactL("lastName")).set(u.lastName),
        contactDL.compose(contactL("email")).set(u.email),
        contactDL.compose(contactL("phoneNumber")).set(u.phoneNumber),
        contactDL.compose(contactL("company")).set(u.company),
        contactDL.compose(contactL("title")).set(pipe(u.persona, O.map(p => p.personaName))),
      )
    ),
    contactDL.compose(contactL("rfpId")).set(data.rfpId),
    contactDL.compose(contactL("bondOfferingId")).set(data.bondOfferingId),
  );
};

type ContactFormProps = FormModalFormProps<ContactPostC, EmptyObjectC>
  & Pick<ContactModalCalloutProps, "issuer" | "introText">;

const ContactForm = (props: ContactFormProps) =>
  <>
    <div {...klass("mb-15")}>
      {props.introText}
    </div>
    <CaptchaForm
      headers={O.none}
      onFailure={O.none}
      onSuccess={props.onSuccess}
      setState={props.setState}
      state={props.state}
      url={SitesRouter.issuersitesIssuerControllerContactPost({ issuerSlug: props.issuer.slug, issuerId: props.issuer.id })}
      captchaL={contactL("captcha")}
    >
      <div {...klass("grid grid-sx-2")}>
        <div {...klass("c-md-12")}>
          <Input
            codec={stringC}
            labelOrAriaLabel={E.left("First Name")}
            lens={contactL("firstName")}
            placeholder={O.none}
            setState={props.setState}
            state={props.state}
            type="text"
          />
        </div>
        <div {...klass("c-md-12")}>
          <Input
            codec={stringC}
            labelOrAriaLabel={E.left("Last Name")}
            lens={contactL("lastName")}
            placeholder={O.none}
            setState={props.setState}
            state={props.state}
            type="text"
          />
        </div>
      </div>
      <div {...klass("grid grid-sx-2")}>
        <div {...klass("c-md-12")}>
          <Input
            codec={stringC}
            labelOrAriaLabel={E.left("Email Address")}
            lens={contactL("email")}
            placeholder={O.none}
            setState={props.setState}
            state={props.state}
            type="text"
          />
        </div>
        <div {...klass("c-md-12")}>
          <Input
            codec={optionC(stringC)}
            labelOrAriaLabel={E.left("Phone Number")}
            lens={contactL("phoneNumber")}
            placeholder={O.none}
            setState={props.setState}
            state={props.state}
            type="text"
          />
        </div>
      </div>
      <div {...klass("grid grid-sx-2")}>
        <div {...klass("c-md-12")}>
          <Input
            codec={optionC(stringC)}
            labelOrAriaLabel={E.left(organization)}
            lens={contactL("company")}
            placeholder={O.none}
            setState={props.setState}
            state={props.state}
            type="text"
          />
        </div>
        <div {...klass("c-md-12")}>
          <Select
            codec={optionC(stringC)}
            isClearable={false}
            isSearchable={false}
            labelOrAriaLabel={E.left("Which term best describes you")}
            lens={contactL("title")}
            options={titleOptions}
            placeholder={O.none}
            setState={props.setState}
            state={props.state}
          />
        </div>
      </div>
      <TextArea
        codec={stringC}
        labelOrAriaLabel={E.left("Your Message")}
        lens={contactL("body")}
        placeholder={O.none}
        requiredOverride={true}
        setState={props.setState}
        state={props.state}
      />
      <ButtonSubmit
        disabled={!props.state.modified}
        loading={props.state.loading}
        loadingText={"Sending"}
        onClick={constVoid}
        text={"Send your message"}
      />
    </CaptchaForm>
  </>;

const ContactConfirmationModal = (props: ModalOpenable & ModalDismissable & Pick<ContactModalCalloutProps, "issuer">) =>
  <Modal
    dismissAction={props.dismissAction}
    id={"contact-confirmation-modal"}
    title={"Thank you for your message"}
    icon={O.none}
    type={"primary"}
    open={props.modalOpen}
    body={`Your message has been sent to ${props.issuer.name}.`}
    size={"modal-sm"}
  />;

const ContactModal = (props: ContactModalProps) => {
  const [confirmationModalOpen, openConfirmationModal, closeConfirmationModal] =
    useModal("Contact Confirmation Modal");

  return (
    <>
      <FormModalDataLoaderWithState
        customFormProps={{
          introText: props.introText,
          issuer: props.issuer,
        }}
        form={ContactForm}
        formHeader={O.none}
        formProps={{
          deleteButton: O.none,
          dismissAction: props.dismissAction,
          onSuccess: (s, a) => {
            props.successAction(s, a);
            openConfirmationModal();
          },
        }}
        initialState={makeInitialState({
          user: props.user,
          rfpId: props.rfpId,
          bondOfferingId: props.bondOfferingId,
        })}
        modalProps={{
          dataMeta: {
            customDisplayName: O.none,
            svg: emailIcon,
            type: props.header,
          },
          size: "modal-lg",
          open: props.modalOpen,
          action: "none",
        }}
      />
      <ContactConfirmationModal
        dismissAction={closeConfirmationModal}
        issuer={props.issuer}
        modalOpen={confirmationModalOpen}
      />
    </>
  );
};

type ContactModalCalloutProps = Pick<ContactPost, "rfpId" | "bondOfferingId"> & {
  btnText: string;
  header: string;
  introText: ReactNode;
  issuer: Issuer;
  user: O.Option<User>;
  klasses?: KlassProp;
  buttonComponent?: (props: ButtonVariantProps) => ReactNode;
};

export const ContactModalCallout = (props: ContactModalCalloutProps) => {
  const [modalOpen, openModal, closeModal] = useModal("Contact Modal");
  const buttonProps: ButtonVariantProps = {
    ...klassPropO([])(props.klasses),
    onClick: openModal,
    children: props.btnText,
  };

  return <>
    {props.buttonComponent ? <props.buttonComponent {...buttonProps} /> : <ButtonPrimary {...buttonProps} />}
    <ContactModal
      bondOfferingId={props.bondOfferingId}
      dismissAction={closeModal}
      header={props.header}
      introText={props.introText}
      issuer={props.issuer}
      modalOpen={modalOpen}
      rfpId={props.rfpId}
      successAction={closeModal}
      user={props.user}
    />
  </>;
};
