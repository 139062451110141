import type { PropsWithChildren, ReactElement } from "react";
import { Fragment } from "react";
import type { Table, TableRowDetail } from "@devexpress/dx-react-grid-bootstrap4";
import * as b from "fp-ts/lib/boolean";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import { portalTable } from "@scripts/generated/assets/stylesheets/components/_portal-table";
import { klass } from "@scripts/react/util/classnames";
import { fromNullableOrOption } from "@scripts/util/fromNullableOrOption";

import type { CustomTableComponent, TableColumnDataCell, TableRowModel } from "../tableSyntax";
import { DragHandleCell } from "./DragHandleCell";
import { draggableColumn } from "./TableHeaderCell";


export type DataCellProps<A, MetaData> = PropsWithChildren<Omit<Table.DataCellProps, "row"> & { row: TableRowModel<A, MetaData> }>;

export const TableDataCell = (expandedRowIds: number[]) => <A, MetaData>(props: DataCellProps<A, MetaData>): ReactElement => {
  // This needs to be cast because of the type that dev express does not know about the extra data on DataCellProps
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const cell = props as DataCellProps<A, MetaData> & { column: CustomTableComponent<A, MetaData> & Partial<TableColumnDataCell<A, MetaData>> };

  return (
    <td {...klass(portalTable[".table-md-breakpoint"][".portal-table-cell"], fromNullableOrOption(cell.column.dataCellKlass))} data-title={cell.column.title}>
      {pipe(
        O.fromNullable(cell.column.dataCellComponent),
        O.fold(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          () => cell.children ? cell.children : cell.value,
          (comp) => comp(cell.row, expandedRowIds)
        )
      )}
    </td>
  );
};

export const TableDataCellDraggable = (expandedRowIds: number[]) => <A, MetaData>(props: DataCellProps<A, MetaData>): ReactElement => {
  // This needs to be cast because of the type that dev express does not know about the extra data on DataCellProps
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const cell = props as DataCellProps<A, MetaData> & { column: CustomTableComponent<A, MetaData> & Partial<TableColumnDataCell<A, MetaData>> };
  const TableDataCellWithRows = TableDataCell(expandedRowIds);

  return pipe(
    cell.column.name === draggableColumn.name,
    b.fold(
      () => <TableDataCellWithRows {...props} />,
      () => <DragHandleCell {...props} rowId={props.row.__rowId} />
    )
  );
};

export const DetailDataCell = (isDraggable: boolean) => (props: PropsWithChildren<TableRowDetail.CellProps>) => (
  <Fragment>
    <td colSpan={isDraggable ? 2 : 1} />
    <td {...klass("detail-cell")} colSpan={100}><div {...klass("content")}>{props.children}</div></td>
  </Fragment>
);
