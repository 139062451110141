/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-explicit-any */
let lastTime = 0;

export const requestAnimFrame: (f: FrameRequestCallback) => number =
  (globalThis.requestAnimationFrame
    || (<any>globalThis).webkitRequestAnimationFrame
    || (<any>globalThis).mozRequestAnimationFrame
    || (<any>globalThis).msRequestAnimationFrame
    || (<any>globalThis).oRequestAnimationFrame
    || ((cb: FrameRequestCallback) => {
      const currTime = (new Date()).getTime();
      const timeToCall = Math.max(0, 16 - (currTime - lastTime));
      const id = globalThis.setTimeout(() => cb(currTime + timeToCall), timeToCall);
      lastTime = currTime + timeToCall;
      return id;
    })).bind(globalThis);

export const cancelAnimFrame: (handle: number) => void =
  (globalThis.cancelAnimationFrame
    || (<any>globalThis).webkitCancelAnimationFrame
    || (<any>globalThis).webkitCancelRequestAnimationFrame
    || (<any>globalThis).mozCancelAnimationFrame
    || (<any>globalThis).mozCancelRequestAnimationFrame
    || (<any>globalThis).msCancelAnimationFrame
    || (<any>globalThis).msCancelRequestAnimationFrame
    || (<any>globalThis).oCancelAnimationFrame
    || (<any>globalThis).oCancelRequestAnimationFrame
    || ((handle: number) => globalThis.clearTimeout(handle))).bind(globalThis);
