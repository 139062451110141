import type { MutableRefObject } from "react";
import { useEffect, useRef, useState } from "react";
import useResizeObserver from "@react-hook/resize-observer";
import ResizeObserver from "resize-observer-polyfill";

export type ElementSize = { height: number, width: number };

export const useElementSize = <T extends HTMLElement = HTMLDivElement>(): [MutableRefObject<T | null>, ElementSize] => {
  const target = useRef<T | null>(null);
  const [size, setSize] = useState<ElementSize>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    if (target.current != null) {
      const data = target.current.getBoundingClientRect();
      setSize({ width: Math.round(data.width), height: Math.round(data.height) });
    }
  }, [target]);
  useResizeObserver(target, (resize) => {
    const sz = {
      height: Math.round(resize.contentRect.height),
      width: Math.round(resize.contentRect.width),
    };
    setSize(sz);
  }, { polyfill: ResizeObserver });
  return [target, size];
};
