/* eslint-disable curly-quotes/no-straight-quotes */
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as R from "fp-ts/lib/Record";

export const downloadBlob = (fileName: string, blob: Blob) => {
  const link = document.createElement("a");
  if (typeof link.download === "undefined") {
    return;
  }
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", fileName);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const formatCell = (cell: unknown) => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const formattedCell = cell instanceof Date ? cell.toLocaleDateString() : `${cell}`.replace(/"/g, '""');
  return formattedCell.search(/([",\n])/) >= 0 ? `"${formattedCell}"` : formattedCell;
};

const cellFormatter = (row: Record<PropertyKey, unknown>, formatter: (cell: unknown) => string) => (k: string): string => pipe(
  R.lookup(k)(row),
  O.map(formatter),
  O.getOrElse(() => "")
);

export const exportLines = (
  fileName: string,
  lines: ReadonlyArray<string>,
): void => {
  const blob = new Blob([lines.join("\n")], { type: "text/csv;charset=utf-8;" });
  downloadBlob(fileName, blob);
};

export const exportToCsv = (
  fileName: string,
  rows: unknown[][] | undefined,
  headers?: ReadonlyArray<string>,
  separator: string = ",",
  formatter: (cell: unknown) => string = formatCell,
): void => {
  if (!rows || !Array.isArray(rows)) {
    return;
  }
  const keys = O.fold(() => [], Object.keys)(A.head(rows));

  const columHeaders = (headers ? headers : keys).map(formatter);
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const rowsList = rows.map(_row => keys.map(cellFormatter(_row as unknown as Record<PropertyKey, unknown>, formatter)).join(separator));

  exportLines(fileName, [columHeaders.join(separator), ...rowsList]);
};
