import type { Key } from "react";
import * as O from "fp-ts/lib/Option";

import { b, type Eq, identity, pipe, RA } from "@scripts/fp-ts";
import { ButtonLink } from "@scripts/react/components/Button";
import { CustomInput } from "@scripts/react/components/form/CustomIndicator";
import { Grid, GridCol } from "@scripts/react/components/layout/Grid";
import type { KlassList, KlassProp } from "@scripts/react/util/classnames";
import { klass, klassPropO } from "@scripts/react/util/classnames";

export type ChecklistItem<ID> = {
  id: ID;
  klasses?: KlassList;
  label: string;
};

export type OnItemChange<ID> = (item: ChecklistItem<ID>, checked: boolean) => void;

export const variants = ["column", "row"] as const;

export type CheckListProps<ID> = {
  items: ReadonlyArray<ChecklistItem<ID>>;
  isChecked: (item: ChecklistItem<ID>) => boolean;
  onItemChange: OnItemChange<ID>;
  onAllItems: (select: boolean, items: ReadonlyArray<ChecklistItem<ID>>) => void;
  variant: typeof variants[number];
  listKlasses?: KlassProp;
  disabled?: boolean;
};

export const SelectButtons = (props: { onSelectAll: () => void, onDeselectAll: () => void, klassName?: KlassProp }) =>
  <div {...klassPropO("select-buttons")(props.klassName)}>
    <div {...klass("select-all")}>
      <ButtonLink onClick={props.onSelectAll}>Select All</ButtonLink>
    </div>
    <div {...klass("deselect-all")}>
      <ButtonLink onClick={props.onDeselectAll}>Deselect All</ButtonLink>
    </div>
  </div>;

const ChecklistBase = <ID,>(props: CheckListProps<ID> & { getKey: (item: ID) => Key, showSelectButtons: boolean }) =>
  <div {...klass("checklist")}>
    {props.showSelectButtons && <SelectButtons
      onSelectAll={() => props.onAllItems(true, props.items)}
      onDeselectAll={() => props.onAllItems(false, props.items)}
      klassName="mb-1"
    />}
    <div />
    <div {...klassPropO(O.none)(props.listKlasses)}>
      {props.variant === "column" && props.items.map(_ =>
        <CustomInput
          ariaUId={O.none}
          checked={props.isChecked(_)}
          key={props.getKey(_.id)}
          label={O.some(_.label)}
          labelKlasses={klassPropO("form-input")(_.klasses).className}
          name={_.label}
          type="checkbox"
          onChange={e => props.onItemChange(_, e.currentTarget.checked)}
          disabled={props.disabled}
        />
      )}
      {props.variant === "row" && <Grid attrs={O.some(".grid-sx-1")} klasses={O.none}>
        {props.items.map(_ =>
          <GridCol key={props.getKey(_.id)} cols={[".c-24", ".c-sm-12", ".c-md-6"]} klasses={O.none}>
            <CustomInput
              ariaUId={O.none}
              checked={props.isChecked(_)}
              label={O.some(_.label)}
              labelKlasses={klassPropO("form-input")(_.klasses).className}
              name={_.label}
              type="checkbox"
              onChange={e => props.onItemChange(_, e.currentTarget.checked)}
              disabled={props.disabled}
            />
          </GridCol>
        )}
      </Grid>}
    </div>
  </div>;

export const Checklist = <ID extends Key>(props: CheckListProps<ID>) =>
  <ChecklistBase
    {...props}
    getKey={identity}
    showSelectButtons
  />;

export type CheckListWithEqProps<KV> = {
  items: ReadonlyArray<ChecklistItem<KV>>;
  selectedItems: ReadonlyArray<KV>;
  setSelectedItems: (newItems: ReadonlyArray<KV>) => void;
  variant: typeof variants[number];
  showSelectButtons: boolean;
  eq: Eq.Eq<KV>;
  getKey: (item: KV) => Key;
  listKlasses?: KlassProp;
  disabled?: boolean;
};

const isChecked = <KV,>(selectedItems: ReadonlyArray<KV>, eq: Eq.Eq<KV>) => (item: ChecklistItem<KV>) => pipe(
  selectedItems,
  RA.exists(_ => eq.equals(_, item.id))
);

const onItemChange = <KV,>(selectedItems: ReadonlyArray<KV>, eq: Eq.Eq<KV>, setSelectedItems: (newItems: ReadonlyArray<KV>) => void) =>
  (item: ChecklistItem<KV>, checked: boolean) => pipe(
    checked,
    b.fold(
      () => pipe(
        selectedItems,
        RA.findIndex(_ => eq.equals(_, item.id)),
        O.chain(idx => RA.deleteAt(idx)(selectedItems)),
        O.getOrElse(() => selectedItems)
      ),
      () => pipe(
        selectedItems,
        RA.append(item.id),
        RA.uniq(eq)
      )
    ),
    setSelectedItems
  );

const onAllItems = <KV,>(setSelectedItems: (newItems: ReadonlyArray<KV>) => void) => (checked: boolean, items: ReadonlyArray<ChecklistItem<KV>>) =>
  pipe(
    checked,
    b.fold(
      () => [],
      () => items.map(a => a.id)
    ),
    setSelectedItems
  );


export const ChecklistWithEq = <KV,>(props: CheckListWithEqProps<KV>) =>
  <ChecklistBase
    {...props}
    isChecked={isChecked(props.selectedItems, props.eq)}
    onItemChange={onItemChange(props.selectedItems, props.eq, props.setSelectedItems)}
    onAllItems={onAllItems(props.setSelectedItems)}
  />;
