import { toUnion } from "fp-ts/lib/Either";
import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { E, Eq, N, RA, s } from "@scripts/fp-ts";
import { dateQualifierOrd } from "@scripts/generated/domaintables/dateQualifiers";
import { bondOfferings, showArchivedBonds } from "@scripts/generated/domaintables/featureFlags";
import { methodOfSaleOrd } from "@scripts/generated/domaintables/methodsOfSale";
import { offeringTypeOrd } from "@scripts/generated/domaintables/offeringTypes";
import type { BondOffering, BondOfferingSeries } from "@scripts/generated/models/bondOfferingBase";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { Issuer, WithIssuer } from "@scripts/generated/models/issuer";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithId, WithModInfo, WithStatusU } from "@scripts/generated/models/threadThrough";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { dateQualifierRangeOrd, qualifierFormat } from "@scripts/syntax/date/dateQualifier";

import { type DateFormatter, dateTBDConst, humanDateLong } from "./date/joda";
import { LocalDateEq } from "./date/jodaSyntax";
import { isFFEnabled } from "./featureFlags";
import { issuerSiteAbsUrl } from "./issuer";
import { withStatusValueEq } from "./threadThrough";

export type BondOfferingWithIssuer = WithIssuer<WithStatusU<WithModInfo<BondOffering>>>;

export const offeringUrl =
  (config: BLConfigWithLog) => (o: WithId<TaggedContent<BondOffering>>, issuer: Issuer) =>
    issuerSiteAbsUrl(config)(issuer, O.none)(
      SitesRouter.issuersitesBondOfferingsControllerOffering
    )({ offeringId: o.id });

export const saleDateFormat = (config: BLConfigWithLog) => (
  d: BondOfferingSeries["saleDate"],
  formatFn: DateFormatter = humanDateLong,
): string =>
  pipe(d, O.fold(dateTBDConst, flow(toUnion, _ => qualifierFormat(config)(_, formatFn))));

export const offeringToFF = (bond: BondOffering) => bond.isArchived ? showArchivedBonds : bondOfferings;

export const isOfferingViewable = (iffs: ClientFeatureFlags) => (bond: BondOffering) =>
  isFFEnabled(offeringToFF(bond))(iffs);

export const bondOfferingSeriesEq = (config: BLConfigWithLog) => Eq.struct<BondOfferingSeries>({
  order: N.Eq,
  name: s.Eq,
  parAmount: O.getEq(N.Eq),
  taxStatus: O.getEq(s.Eq),
  creditEnhancement: O.getEq(s.Eq),
  offeringType: O.getEq(offeringTypeOrd),
  methodOfSale: O.getEq(methodOfSaleOrd),
  saleDate: O.getEq(E.getEq(
    dateQualifierRangeOrd(config),
    Eq.eqStrict
  )),
  closingDate: O.getEq(dateQualifierRangeOrd(config)),
  retailOrderDate: O.getEq(Eq.struct({
    qualifier: dateQualifierOrd,
    date: LocalDateEq,
  })),
  datedDate: O.getEq(LocalDateEq),
  callDate: O.getEq(LocalDateEq),
  firstInterestDate: O.getEq(LocalDateEq),
  firstPrincipalDate: O.getEq(LocalDateEq),
  freeToTradeDate: O.getEq(LocalDateEq),
  callFeatures: O.getEq(s.Eq),
});

export const seriesArrayEq = flow(bondOfferingSeriesEq, withStatusValueEq, RA.getEq);
