import type { ReadonlyNonEmptyArray } from "fp-ts/lib/ReadonlyNonEmptyArray";

import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { FactsRow, type FactType, makeFactTooltipLinkPropsO } from "@scripts/react/components/Facts";
import { ImageCarouselModal } from "@scripts/react/components/ImageCarouselModal";
import { AccentDividerSection, DividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import { klass } from "@scripts/react/util/classnames";
import type { AboutPageData } from "@scripts/routes/routing/ssr/issuersites";

export const makeQuickFactsO = (callouts: AboutPageData["callouts"]): O.Option<ReadonlyNonEmptyArray<FactType>> => pipe(
  callouts,
  RA.map((callout) => ({
    title: callout.fieldHeader,
    value: callout.fieldValue,
    tooltipProps: O.toUndefined(makeFactTooltipLinkPropsO(callout.fieldUrl, callout.linkText)),
  })),
  RNEA.fromReadonlyArray
);

type AboutSectionProps = Pick<AboutPageData, "about" | "photos"> & {
  sectionId: string;
  quickFacts: O.Option<ReadonlyNonEmptyArray<FactType>>;
  title: string;
};

const AboutTextAndQuickFacts = (props: Pick<AboutSectionProps, "about" | "quickFacts">) => {
  const showSection = O.isSome(props.about);
  return pipe(
    showSection,
    trueOrEmpty(
      <DividerSection title={O.none}>
        {pipe(
          props.quickFacts,
          mapOrEmpty((facts) =>
            <div {...klass("mb-1")}>
              <FactsRow variant="bold" items={facts} />
            </div>
          )
        )}
        {pipe(
          props.about,
          mapOrEmpty(about => <Markdown content={about.data} />)
        )}
      </DividerSection>
    )
  );
};

const AboutGallery = (props: Pick<AboutSectionProps, "photos">) => pipe(
  props.photos,
  RA.map(photo => photo.data.record),
  RNEA.fromReadonlyArray,
  mapOrEmpty(photos =>
    <DividerSection title={O.some("Image Gallery")}>
      <ImageCarouselModal images={photos} isMulti />
    </DividerSection>
  )
);

export const AboutSection = (props: AboutSectionProps) => {
  return (
    <AccentDividerSection
      title={O.some(props.title)}
      sectionId={props.sectionId}
    >
      <AboutTextAndQuickFacts about={props.about} quickFacts={props.quickFacts} />
      <AboutGallery photos={props.photos} />
    </AccentDividerSection>
  );
};
