import { O, pipe, R, RNEA } from "@scripts/fp-ts";
import type { EventWithRelatedContent } from "@scripts/generated/models/eventWithRelatedContent";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { useConfig } from "@scripts/react/context/Config";
import { type OpenModalO } from "@scripts/react/util/useModal";
import { dateTBDConst, humanDateLongO } from "@scripts/syntax/date/joda";
import { issuerSiteAbsUrl } from "@scripts/syntax/issuer";
import { delProp } from "@scripts/util/delProp";

import eventIcon from "@svgs/calendar.svg";

import { ContentTypeDetails } from "../activities/ContentTypeDetails";
import { ButtonLink, ButtonPrimary } from "../Button";
import { mapOrEmpty } from "../Empty";
import { DividerSection } from "../layout/Section";
import type { LeafIconAsProp } from "../LeafIcon";
import { Markdown } from "../Markdown";
import { Modal, type ModalDismissable, type ModalOpenable } from "../modal/Modal";
import { BondsRelatedContent, ProgramsRelatedContent, ProjectsRelatedContent, RfpsRelatedContent, SummaryRelatedContent } from "../offering-pages/RelatedContent";

export const EventModalViewDetailsButton = (props: {
  data: WithStatusU<EventWithRelatedContent>;
  openModal: OpenModalO<WithStatusU<EventWithRelatedContent>>;
}) =>
  <ButtonLink onClick={() => props.openModal(props.data)}>
    View Details
  </ButtonLink>;

const hasRelatedContent = (event: WithStatusU<EventWithRelatedContent>) => pipe(
  delProp(event.data.record.relatedContent, "documents", "externalLinks"),
  R.some(a => a.length > 0)
);

type EventModalProps = LeafIconAsProp & ModalOpenable & ModalDismissable & { data: O.Option<WithStatusU<EventWithRelatedContent>>, issuer: Issuer, rfpsTitle: string };
export const EventModal = (props: EventModalProps) => {
  const config = useConfig();
  return pipe(
    props.data,
    mapOrEmpty(event =>
      <Modal
        body={
          <div>
            <DividerSection title={O.none}>
              <ContentTypeDetails
                accentColor="default"
                subheader={"Event"}
                date={humanDateLongO(dateTBDConst)(event.data.record.event.eventDate)}
                icon={eventIcon}
                iconBackgroundColor="green"
              />
              {pipe(event.data.record.event.eventBlurb, mapOrEmpty(blurb => <Markdown content={blurb} klasses="mb-1" />))}
              <SummaryRelatedContent
                documentsO={RNEA.fromReadonlyArray(event.data.record.relatedContent.documents.map(_ => _.data))}
                linksO={RNEA.fromReadonlyArray(event.data.record.relatedContent.externalLinks)}
                documentsHeadline="Documents"
                issuer={props.issuer}
                documentDownloadRoute={(_, mediaId) => issuerSiteAbsUrl(config)(props.issuer, O.none)(SitesRouter.issuersitesReportsControllerViewFile)({ mediaId })}
                variant="modal"
                leafIcon={props.leafIcon}
              />
            </DividerSection>
            {hasRelatedContent(event)
              && <DividerSection title={O.some("Related Content")}>
                {pipe(RNEA.fromReadonlyArray(event.data.record.relatedContent.offerings), mapOrEmpty(offerings =>
                  <BondsRelatedContent bonds={offerings} issuer={props.issuer} variant="modal" leafIcon={props.leafIcon} />
                ))}
                {pipe(RNEA.fromReadonlyArray(event.data.record.relatedContent.programs), mapOrEmpty(programs =>
                  <ProgramsRelatedContent programs={programs} issuer={props.issuer} variant="modal" />
                ))}
                {pipe(RNEA.fromReadonlyArray(event.data.record.relatedContent.rfps), mapOrEmpty(rfps =>
                  <RfpsRelatedContent rfps={rfps} issuer={props.issuer} rfpsTitle={props.rfpsTitle} variant="modal" leafIcon={props.leafIcon} />
                ))}
                {pipe(RNEA.fromReadonlyArray(event.data.record.relatedContent.projects), mapOrEmpty(projects =>
                  <ProjectsRelatedContent projects={projects} issuer={props.issuer} variant="modal" leafIcon={props.leafIcon} />
                ))}
              </DividerSection>
            }
            <DividerSection title={O.none}>
              <ButtonPrimary onClick={props.dismissAction}>Close</ButtonPrimary>
            </DividerSection>
          </div>
        }
        dismissAction={props.dismissAction}
        icon={O.none}
        id="EventModal"
        open={props.modalOpen}
        size="modal-lg"
        title={event.data.record.event.eventTitle}
        type="primary"
      />
    )
  );
};
