import type { Breakpoint } from "@scripts/bondlinkStatic";
import { O, pipe } from "@scripts/fp-ts";
import type { Media } from "@scripts/generated/models/media";
import type { OfferingParticipant } from "@scripts/generated/models/offeringParticipant";
import { AnchorIcon, AnchorIconMailTo } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import type { PageRouteLink } from "@scripts/routes/routing/base";

import phoneIcon from "@svgs/telephone.svg";
import websiteIcon from "@svgs/website.svg";

import { klass, klassConditional } from "../util/classnames";
import { Image, type ImageProps } from "./Image";
import { Svg } from "./Svg";

export const ParticipantLogo = (props: Pick<ImageProps, "src"> & {
  participantName: string;
}) =>
  <Image
    alt={`Logo for ${props.participantName}.`}
    src={props.src}
    klasses={"participant-logo"}
  />;

// NOTE: If changing this make sure to change _participant-row.scss
export type ParticipantRowBreakpoint = Extract<Breakpoint, "lg" | "xl">;

export const ParticipantRow = (props: { participant: OfferingParticipant, breakpoint: ParticipantRowBreakpoint }) => {
  const participant = props.participant;
  const logoUrl: O.Option<string> = O.map((data: { record: Media }) => data.record.uploadResponse.uri)(participant.photo);
  const pageRouteLink: O.Option<PageRouteLink> = O.map<string, PageRouteLink>((url: string) => ({
    title: "Website",
    route: {
      method: "GET",
      url: url,
    },
  }))(participant.url);

  const hasContactInfo = O.isSome(participant.contactName) || O.isSome(participant.contactPhone) || O.isSome(participant.contactEmail) || O.isSome(pageRouteLink);

  return (
    <div {...klass("participant-row")}>
      <div {...klass("d-flex flex-row justify-content-between pb-1 w-100", `break-${props.breakpoint}`)}>
        <div {...klass("d-flex flex-col")} >
          <h5 {...klassConditional("mb-05", "mb-0")(hasContactInfo)}>{participant.name}</h5>
          <div {...klass("contact-info d-flex flex-col", `flex-${props.breakpoint}-row`)}>
            {mapOrEmpty<string>((personName) => <div>{personName}</div>)(participant.contactName)}
            {mapOrEmpty<string>((phoneNumber) => <div {...klass("flex-no-shrink")}><Svg src={phoneIcon} /> {phoneNumber}</div>)(participant.contactPhone)}
            {mapOrEmpty<string>((emailAddress) => <div><AnchorIconMailTo email={emailAddress} /></div>)(participant.contactEmail)}
            {mapOrEmpty<PageRouteLink>(
              (route) =>
                <div {...klass("flex-no-shrink")}>
                  <AnchorIcon
                    target="_blank"
                    route={route}
                    icon={websiteIcon}
                  />
                </div>,
            )(pageRouteLink)}
          </div>
        </div>
        {pipe(
          logoUrl,
          mapOrEmpty(_ => <ParticipantLogo
            participantName={participant.name}
            src={_}
          />)
        )}
      </div>
    </div>
  );
};
