import { O, pipe } from "@scripts/fp-ts";
import type { CustomPageDataO } from "@scripts/generated/models/customPages";
import * as ISR from "@scripts/generated/routers/sitesRouter";
import { AnchorButton } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import type { Klass } from "@scripts/react/util/classnames";
import { makeJumplinkId } from "@scripts/routes/routing/ssr/issuersitesJumplinkIds";

import { useIssuerSitesSelector } from "../state/store";

export const makeCustomPageJumplinkId = (customPage: CustomPageDataO) =>
  makeJumplinkId(customPage.page.tt.typeName);

export const CustomPageSection = (props: { customPage: CustomPageDataO, klasses?: Klass }) => {
  const issuer = useIssuerSitesSelector("issuer");
  return (
    <AccentDividerSection
      title={O.some(props.customPage.title)}
      sectionId={makeCustomPageJumplinkId(props.customPage)}
      klasses={props.klasses}
    >
      {pipe(
        props.customPage.data,
        mapOrEmpty(data =>
          <Markdown
            content={data.data.record.data}
            klasses={["truncate", "truncate-5", "w-md-65"]}
          />
        )
      )}
      <AnchorButton
        target={"_self"}
        route={{
          title: `View ${props.customPage.title}`,
          route: ISR.issuersitesIssuerControllerCustom({
            issuerId: issuer.id,
            issuerSlug: issuer.slug,
            i: props.customPage.page.index,
          }),
        }}
        variant="primary"
        klasses={["mt-2"]}
      />
    </AccentDividerSection>
  );
};
