import { pipe } from "fp-ts/lib/function";

import type { RNEA } from "@scripts/fp-ts";
import { O } from "@scripts/fp-ts";
import type { BondProgramWithRatings } from "@scripts/generated/models/bondProgramWithRatings";
import type { Rfp } from "@scripts/generated/models/rfpBase";
import type { Subscribed } from "@scripts/generated/models/subscribed";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import type { UserWithRoles } from "@scripts/generated/models/user";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { RfpFacts } from "@scripts/react/actions/rfp-pages/RfpSummary";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection, DividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import { type DocumentRelatedContent, type LinkRelatedContent, ProgramWithRatingRelatedContent, SummaryRelatedContent } from "@scripts/react/components/offering-pages/RelatedContent";
import { type RfpWithSitesRelatedContent } from "@scripts/syntax/rfp";

import { ContactModalCallout } from "@scripts-ssr/components/ContactModal";

import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { useIssuerSitesSelector } from "../../state/store";

export const rfpSummarySectionTitle = "Summary";
export const RfpSummary = (props: {
  rfp: Subscribed<WithStatusU<TaggedContent<RfpWithSitesRelatedContent>>>;
  documentsO: O.Option<RNEA.ReadonlyNonEmptyArray<DocumentRelatedContent>>;
  linksO: O.Option<RNEA.ReadonlyNonEmptyArray<LinkRelatedContent>>;
  programsO: O.Option<WithStatusU<BondProgramWithRatings>>;
  sidebarLinkHandle: string;
}) => {
  const rfp = props.rfp.data.data.record.data.rfp;
  const issuer = useIssuerSitesSelector("issuer");

  return (
    <AccentDividerSection
      klasses={["accent-border-top"]}
      sectionId={props.sidebarLinkHandle}
      title={O.some(rfpSummarySectionTitle)}
    >
      <DividerSection title={O.none}>
        <RfpFacts rfp={props.rfp.data.data.record.data.rfp} />
      </DividerSection>
      {pipe(
        rfp.projectDescription,
        mapOrEmpty(_ =>
          <DividerSection
            title={O.some("Project Description")}
          >
            <Markdown content={_} klasses={["last-child-mb-0"]} />
          </DividerSection>
        ))}
      {pipe(
        rfp.submissionRequirements,
        mapOrEmpty(_ =>
          pipe(
            rfp.biddingState.name !== "BidAwarded",
            trueOrEmpty(
              <DividerSection
                klasses={["bt-0125", "pt-1"]}
                title={O.some("Submission Requirements")}
              >
                <Markdown content={_} klasses={["last-child-mb-0"]} />
              </DividerSection>
            )
          )
        ))
      }
      <SummaryRelatedContent
        documentsO={props.documentsO}
        documentsHeadline="Documents"
        documentDownloadRoute={(issuerId, mediaId) => V2Router.investorPortalRfpsControllerDownloadDocument({ issuerId, rfpId: props.rfp.data.data.id, mediaId })}
        issuer={issuer}
        linksO={props.linksO}
        variant="page"
        leafIcon={IssuerSitesLeafIcon}
      />
      {pipe(
        props.programsO,
        mapOrEmpty(_ =>
          <ProgramWithRatingRelatedContent
            program={_}
            issuer={issuer}
            variant={"page"}
          />
        )
      )}
    </AccentDividerSection>
  );
};

export const RfpQuestionsAndAnswersCallout = (props: {
  rfp: Rfp;
  rfpId: number;
}) => {
  const issuer = useIssuerSitesSelector("issuer");
  const user = useIssuerSitesSelector("user");

  return <ContactModalCallout
    btnText="Contact Us"
    header="Contact Us"
    klasses={"mt-0"}
    bondOfferingId={O.none}
    introText={<>Use this form to ask questions or get more information from <strong>{issuer.name}</strong> about “<strong>{props.rfp.name}</strong>.”</>}
    issuer={issuer}
    rfpId={O.some(props.rfpId)}
    user={O.map((withRoles: UserWithRoles) => withRoles.user)(user)}
  />;
};
