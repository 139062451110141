import { O } from "@scripts/fp-ts";
import { newsC } from "@scripts/generated/domaintables/postTypes";
import type { IssuerNews } from "@scripts/generated/models/issuerNewsBase";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { AnchorIcon } from "@scripts/react/components/Anchor";
import { ContentItemCard, ContentItemCardLinks } from "@scripts/react/components/card/ContentItemCard";
import { Markdown } from "@scripts/react/components/Markdown";
import { dateTBDConst, humanDateLongO } from "@scripts/syntax/date/joda";

import pressReleaseIcon from "@svgs/bullhorn.svg";
import newsIcon from "@svgs/newspaper.svg";

import { useIssuerSitesSelector } from "../state/store";
import { IssuerSitesLeafIcon } from "./LeafIcon";

export const NewsCard = (props: { news: WithStatusU<TaggedContent<IssuerNews>> }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const isNews = newsC.is(props.news.data.record.data.postType);
  const itemRoute = SitesRouter.issuersitesAboutControllerNewsItem({ issuerSlug: issuer.slug, issuerId: issuer.id, newsId: props.news.data.id });
  const icon = isNews ? newsIcon : pressReleaseIcon;

  return <ContentItemCard
    link={O.some({ url: itemRoute.url, target: "_self" })}
    header={{
      leftBadges: [{
        text: isNews ? "News" : "Press Release",
        icon: O.some(icon),
        variant: "tall",
        color: "accent-2-700",
      }],
      rightContent: <div>{humanDateLongO(dateTBDConst)(props.news.data.record.data.newsDate)}</div>,
    }}
    issuer={O.none}
    name={O.some(props.news.data.record.data.newsTitle)}
    taggedContent={O.some({ content: props.news.data.record, leafIcon: IssuerSitesLeafIcon })}
    subscription={O.none}
  >
    <Markdown
      klasses={["mb-1", "truncate truncate-3"]}
      content={props.news.data.record.data.newsBlurb}
    />
    <ContentItemCardLinks
      links={[{
        key: "view",
        link: <AnchorIcon
          icon={icon}
          route={{ route: itemRoute, title: `Read ${isNews ? "article" : "press release"}` }}
          target="_self"
        />,
      }]}
    />
  </ContentItemCard>;
};
