import * as Monoid from "fp-ts/lib/Monoid";
import type { Predicate } from "fp-ts/lib/Predicate";
import { getMonoidAll, getMonoidAny, not } from "fp-ts/lib/Predicate";

import { RA } from "@scripts/fp-ts";


type PredicateMonoid = <A>(as: ReadonlyArray<Predicate<A>>) => Predicate<A>;

export const applyPredicatesAll: PredicateMonoid = Monoid.concatAll(getMonoidAll());

export const applyPredicatesAny: PredicateMonoid = Monoid.concatAll(getMonoidAny());

export const applyPredicatesNone: PredicateMonoid = <A>(filters: ReadonlyArray<Predicate<A>>) =>
  not(Monoid.concatAll(getMonoidAny<A>())(filters));

export const emptyOrAll = <A>(a: ReadonlyArray<A>) => RA.isEmpty(a) || a[0] === "All";
