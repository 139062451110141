import type {
  ChangeEvent,
  Dispatch,
  JSX,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
} from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type {
  ComputedFn,
  Getters,
} from "@devexpress/dx-react-core";
import {
  Getter,
  Plugin,
} from "@devexpress/dx-react-core";
import type {
  Sorting,
  TableColumn as GridTableColumn,
} from "@devexpress/dx-react-grid";
import {
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  RowDetailState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  PagingPanel,
  Table as TableGrid,
  TableColumnVisibility,
  TableHeaderRow,
  TableRowDetail,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import * as b from "fp-ts/lib/boolean";
import * as E from "fp-ts/lib/Either";
import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as Th from "fp-ts/lib/These";
import { useStableEffect, useStableO } from "fp-ts-react-stable-hooks";
import type { Store } from "redux";

import { Eq } from "@scripts/fp-ts";
import type { SortDocCategoriesC } from "@scripts/generated/models/document";
import type { SortItemsC } from "@scripts/generated/models/sortItems";
import { ExpandControlCell } from "@scripts/react/components/table/cells/ExpandControlCell";
import { WbrDataTypeProvider } from "@scripts/react/components/table/dataTypeProviders/WbrDataTypeProvider";
import { useConfig } from "@scripts/react/context/Config";
import type { ReactChild } from "@scripts/react/syntax/react";
import { klass, klassConditional } from "@scripts/react/util/classnames";
import type { GetRowStatus } from "@scripts/table/disableTableRows";

import { portalTable } from "@styles/components/_portal-table";

import searchIcon from "@svgs/magnifying-glass.svg";

import { Empty, mapOrEmpty, trueOrEmpty } from "../Empty";
import { tableSearchFilterFn } from "../filters/filterFns";
import type { SelectValueOnChange } from "../form/Select";
import { SelectRaw } from "../form/Select";
import { iconWithAction, InputRaw } from "../form/TextInput";
import { defaultPageSize } from "../paginator/Paginator";
import { NoDataCell, NoDataRow } from "./cells/NoDataCell";
import type { DataCellProps } from "./cells/TableDataCell";
import { DetailDataCell, TableDataCell, TableDataCellDraggable } from "./cells/TableDataCell";
import { draggableColumn, TableHeaderCell } from "./cells/TableHeaderCell";
import { TableBody, TableBodyDraggable } from "./TableBody";
import type { TableExporterRef } from "./TableExporter";
import { TableExporter } from "./TableExporter";
import type { TableRowProps } from "./TableRow";
import { TableRow, TableRowDraggable, TableRowExpanded } from "./TableRow";
import * as tableSyntax from "./tableSyntax";

function RowsProvider<T>(props: { exportRows: ReadonlyArray<T>, onRowsChanged: (rows: T[]) => void }) {
  const handleRowsGetter = useCallback((onRowsChanged: (e: T[]) => void) => ({ rows }: { rows: T[] }) => {
    if (!RA.getEq(Eq.eqStrict).equals(rows, props.exportRows)) {
      window.setTimeout(() => onRowsChanged(rows));
    }
    return rows;
  }, [props.exportRows]);
  const handleRows = handleRowsGetter(props.onRowsChanged);
  return (
    <Plugin>
      {/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */}
      <Getter name="rows" computed={handleRows as unknown as ComputedFn} />
    </Plugin>
  );
}

const Exporter = <A, MetaData, ExportExtraColumns extends string[], RowParserOutput>(
  props: { exporter: O.Option<tableSyntax.TableExporter<A, MetaData, ExportExtraColumns, RowParserOutput>> }
) => {
  const exporterRef = useRef<TableExporterRef>(null);
  const [exportRows, setExportRows] = useState<ReadonlyArray<tableSyntax.TableRowModel<A, MetaData>>>([]);

  useStableEffect(() => {
    pipe(
      props.exporter,
      O.map(exporter => exporterRef.current !== null && exporter.setExporter(exporterRef.current)),
    );
  }, [props.exporter], Eq.tuple(O.getEq(Eq.eqStrict)));

  return pipe(
    props.exporter,
    mapOrEmpty((ep) =>
      <Plugin>
        <RowsProvider exportRows={exportRows} onRowsChanged={setExportRows} />
        <TableExporter
          ref={exporterRef}
          fileName={ep.fileName}
          rows={"rowsParser" in ep ? ep.rowsParser(exportRows) : exportRows}
          columns={tableSyntax.parseTableColumns<A, MetaData, ExportExtraColumns, RowParserOutput>(ep.columns)}
        />
      </Plugin>
    )
  );
};

const TableRootComponent = (p: PropsWithChildren<Grid.RootProps>) => <div {...klass("table-root")}>{p.children}</div>;

const TableContainer = (props: PropsWithChildren<{ isLgTable?: true, variant: TableVariant }>) => <div {...klassConditional(props.isLgTable ? "table-lg-breakpoint" : "table-md-breakpoint", O.none)(props.variant === "portal")}>{props.children}</div>;

type TableVariant = "portal" | "issuersites";

const TableContainerComponent = (tableError: boolean, variant: TableVariant, isHeaderless?: true) => (p: PropsWithChildren<unknown>) =>
  <div {...klass(
    variant === "portal" ? portalTable[".portal-table-container"] : "portal-table-sites",
    "w-100",
    isHeaderless ? "headerless" : O.none,
    tableError ? "table-error" : O.none,
  )}
  >
    {p.children}
  </div>;

function TableHeaderCellComponent(p: PropsWithChildren<TableHeaderRow.CellProps>) {
  return <TableHeaderCell
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    column={(p as tableSyntax.HeaderCellProps<unknown, unknown, unknown>).column}
    row={p.tableRow.row}
  />;
}

type TableComponents<A, MetaData> = {
  bodyComponent: (p: React.PropsWithChildren<object>) => JSX.Element;
  containerComponent: (p: React.PropsWithChildren<object>) => JSX.Element;
  rowComponent: (expandedRowIds: ReadonlyArray<number>, columnsCount: number, getRowStatus?: GetRowStatus) =>
    (p: React.PropsWithChildren<TableRowProps<A, MetaData>>) => JSX.Element;
  cellComponent: (p: DataCellProps<A, MetaData>) => JSX.Element;
  detailCellComponent: (p: PropsWithChildren<TableRowDetail.CellProps>) => JSX.Element;
  columns: tableSyntax.TableColumnState["columns"];
  columnExtensions: tableSyntax.TableColumnState["columnExtensions"];
};

type TableActionsPanelProps<SB extends string> = {
  sortColumns: Array<tableSyntax.SortColumnOption<SB>>;
  sortingSelected: Sorting[];
  onSortChanged: (value: SelectValueOnChange<SB>) => void;
  onSearchChanged: (s: string) => void;
  tableAction: O.Option<TableAction>;
  searchable: O.Option<[placeholder: O.Option<string>, value: string]>;
};

export const TableActionSearch = <SB extends string>(props: Pick<TableActionsPanelProps<SB>, "onSearchChanged" | "searchable">) => {
  const [searchVal, setSearchVal] = useStableO<string>(O.map(([, v]: [O.Option<string>, string]) => v)(props.searchable));
  const onSearchChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    pipe(e.target.value, O.fromPredicate((v) => v.length > 0), setSearchVal);
    props.onSearchChanged(e.target.value);
  }, [props, setSearchVal]);

  return mapOrEmpty(
    ([placeholder]: [O.Option<string>, string]) => {
      const placeholderText = `Search ${pipe(placeholder, O.getOrElse(() => ""))}`;
      return <InputRaw
        ariaLabelledById="search-input"
        errorComponent={O.none}
        labelComponent={O.none}
        labelOrAriaLabel={E.right("Search Input")}
        onChange={onSearchChanged}
        placeholder={O.some(placeholderText)}
        postfix={E.right(iconWithAction(searchIcon, "Search Icon", O.none))}
        type="text"
        value={searchVal}
      />;
    })(props.searchable);
};

export const TableActionSort = <SB extends string>(props: Pick<TableActionsPanelProps<SB>, "sortColumns" | "onSortChanged" | "sortingSelected">) => {
  const sortOptions = props.sortColumns.map(tableSyntax.sortLabelFormat);
  return trueOrEmpty(
    <div className="sort">
      <SelectRaw
        value={pipe(RA.head(props.sortingSelected), O.chain(c => pipe(sortOptions, RA.findFirst(d => d.value === tableSyntax.columnValueFormat(c)))))}
        labelOrAriaLabel={E.right("Sort Selection")}
        isSearchable={false}
        options={sortOptions}
        onChange={props.onSortChanged}
        error={O.none}
        placeholder={O.none}
        required={false}
      />
    </div>
  )(props.sortColumns.length > 0);
};

export function TableActionsPanel<SB extends string>(props: TableActionsPanelProps<SB>) {
  const showPanel = O.isSome(props.searchable) || O.isSome(props.tableAction) || props.sortColumns.length > 0;

  return (
    showPanel
      ? <div className="table-actions">
        <div className={"left-action-prepend"}>
          {pipe(props.tableAction,
            O.chain(Th.getLeft),
            mapOrEmpty((tableAction) => <div className="table-action">{tableAction}</div>)
          )}
        </div>
        <div className={"left-table-actions order-2 order-md-1"}>
          <TableActionSort
            sortingSelected={props.sortingSelected}
            onSortChanged={props.onSortChanged}
            sortColumns={props.sortColumns}
          />
        </div>
        <div className={"center-table-actions order-1 order-md-2"}>
          {pipe(props.tableAction,
            O.chain(Th.getRight),
            mapOrEmpty((tableAction) => <div className="table-action">{tableAction}</div>)
          )}
        </div>
        <div className={"right-table-actions order-3"}>
          <TableActionSearch
            searchable={props.searchable}
            onSearchChanged={props.onSearchChanged}
          />
        </div>
      </div>
      : <Empty />
  );
}

type TableRowExpandProps = {
  setExpandedRowIds: Dispatch<SetStateAction<number[]>>;
  expandedRowIds: number[];
};

type TableRowStatusProps = {
  getRowStatus?: GetRowStatus;
};

type TableBaseProps<
  A,
  MetaData,
  ExtraColumns extends string[],
  TCR extends tableSyntax.TableColumnRow<A, MetaData, ExtraColumns> = tableSyntax.TableColumnRow<A, MetaData, ExtraColumns>,
> = {
  data: ReadonlyArray<TCR["Row"]>;
  detailCell: O.Option<(row: TCR["Row"]) => ReactElement>;
  detailCellDisabled?: (row: TCR["Row"]) => boolean;
  collapseAllRows?: boolean;
  hiddenColumns?: ReadonlyArray<keyof tableSyntax.TableColumnRow<A, MetaData, ExtraColumns>["Columns"]>;
} & TableRowStatusProps;

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
const tableRowToModel = (p: TableRowDetail.ContentProps) => p.row;
const computedColumns = ({ tableColumns }: Getters) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  Array.isArray(tableColumns)
    ? tableColumns.reduce((acc: GridTableColumn[], curr: GridTableColumn) => (curr.column && curr.column.name === draggableColumn.name) ? [curr, ...acc] : [...acc, curr], [])
    : tableColumns;


function TableBase<
  A,
  MetaData,
  ExtraColumns extends string[],
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  TCR extends tableSyntax.TableColumnRow<A, MetaData, ExtraColumns> = tableSyntax.TableColumnRow<A, MetaData, ExtraColumns>,
>(
  props: React.PropsWithChildren<TableBaseProps<A, MetaData, ExtraColumns>> & TableComponents<A, MetaData> & TableRowExpandProps & TableRowStatusProps
): ReactElement {


  /*
   ******************************  WARNING  ***********************************
   *
   * THIS MONKEY PATCHES THE CONSOLE TO PREVENT THE `defaultProps` ERROR - JDL
   *
   * This is to be removed when devexpress is updated from the Reactive to the
   * new React based library or the Reactive library is updated to remove the
   * usage of `defaultProps` which is unlikely.
   *
   * This prevents an upgrade to React 19.
   */

  /* eslint-disable no-console */
  const originalConsoleError = console.error;

  console.error = (...args: unknown[]) => {
    if (typeof args[0] === "string" && args[0].includes("defaultProps")) {
      return;
    }

    originalConsoleError(...args);
  };

  useEffect(() => {
    return () => {
      console.error = originalConsoleError;
    };
    /* eslint-enable no-console */
  }, [originalConsoleError]);
  /*
   ***************************  END WARNING  **********************************
   */


  const toggleCellComponent = useCallback(
    (p: PropsWithChildren<Omit<TableRowDetail.ToggleCellProps, "row"> & { row: tableSyntax.TableRowModel<A, MetaData> }>) => <ExpandControlCell {...p} disabled={props.detailCellDisabled?.(p.row)} />,
    [props]
  );

  const tableRowDetail = useCallback((dcc: (row: TCR["Row"]) => ReactElement) =>
    <TableRowDetail
      cellComponent={props.detailCellComponent}
      rowComponent={TableRowExpanded(props.getRowStatus)}
      contentComponent={flow(tableRowToModel, dcc)}
      toggleCellComponent={toggleCellComponent}
    />,
    [props.detailCellComponent, toggleCellComponent, props.getRowStatus]
  );

  useEffect(() => {
    props.collapseAllRows && props.setExpandedRowIds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.collapseAllRows]);

  return (
    <Grid
      rootComponent={TableRootComponent}
      rows={props.data}
      columns={props.columns}
    >
      <WbrDataTypeProvider
        for={props.columnExtensions.reduce((acc: string[], curr) => curr.wbrEnabled ? [...acc, curr.columnName] : acc, [])}
      />
      {props.children}
      <TableGrid
        noDataRowComponent={NoDataRow}
        noDataCellComponent={NoDataCell(RA.isEmpty(props.data))}
        bodyComponent={props.bodyComponent}
        columnExtensions={props.columnExtensions}
        containerComponent={props.containerComponent}
        rowComponent={props.rowComponent(props.expandedRowIds, props.columns.length + (O.isSome(props.detailCell) ? 1 : 0), props.getRowStatus)}
        cellComponent={props.cellComponent}
      />
      <TableColumnVisibility hiddenColumnNames={props.hiddenColumns?.map(col => col.toString()) ?? []} />
      <TableHeaderRow cellComponent={TableHeaderCellComponent} />
      <RowDetailState
        expandedRowIds={props.expandedRowIds}
        onExpandedRowIdsChange={tableSyntax.onExpandedRowIdsChange(props.setExpandedRowIds)}
        defaultExpandedRowIds={[]}
      />
      {mapOrEmpty(tableRowDetail)(props.detailCell)}
      {/*
        This Plugin is a Getter for values in the tables
        For getters to work they must exist within the Grid component.
      */}
      <Getter
        name="tableColumns"
        computed={computedColumns}
      />
    </Grid>
  );
}

export type TableAction = Th.These<ReactElement, ReactElement>;

export type TableProps<
  SB extends tableSyntax.SortBy<keyof A>,
  A,
  MetaData,
  RowParserOutput,
  ExtraColumns extends string[],
  ExportExtraColumns extends string[],
  Params extends object,
> = TableBaseProps<A, MetaData, ExtraColumns>
  & tableSyntax.TableSharedProps<A, MetaData, ExtraColumns, ExportExtraColumns, RowParserOutput>
  & {
    variant?: TableVariant;
    searchable: O.Option<[placeholder: O.Option<string>, value: string, ignoreSearch?: true]>;
    paginate: O.Option<number>;
    tableAction: O.Option<TableAction>;
    onParamsChanged: tableSyntax.OnParamsChanged<SB, Params>;
    sortable: O.Option<SB>;
    store: O.Option<Store>;
    params?: Partial<Params>;
    hideActionsPanel?: true;
    isLgTable?: true;
    pageSize?: number;
    customActionsPanel?: (props: TableCustomActionPanelProps<SB>) => ReactElement;
  } & TableRowStatusProps;

export type TableCustomActionPanelProps<SB extends string> = Omit<TableActionsPanelProps<SB>, "tableAction">;

export const Table = <
  SB extends tableSyntax.SortBy<keyof A>,
  A,
  MetaData,
  RowParserOutput,
  ExtraColumns extends string[],
  ExportExtraColumns extends string[],
  Params extends object,
>(props: TableProps<
  SB,
  A,
  MetaData,
  RowParserOutput,
  ExtraColumns,
  ExportExtraColumns,
  Params
>
): ReactElement => {
  const config = useConfig();
  const isSortable = O.isSome(props.sortable);
  const isSearchable = O.isSome(props.searchable);
  const [searchVal, ignoreSearch] = pipe(
    props.searchable,
    O.fold(
      () => ["", false],
      ([, v, iS]) => [v, iS ?? false]
    ));
  const [expandedRowIds, setExpandedRowIds] = useState<tableSyntax.ExpandedRowIds>([]);

  const sortByVal: tableSyntax.SortByVal<SB, A> = useMemo(
    () => ({ sortBy: O.toUndefined(props.sortable) }),
    [props.sortable]
  );

  const columnSortList = useMemo(() =>
    isSortable ? tableSyntax.createColumnSortList<A, MetaData, SB, ExtraColumns>(props.columns, sortByVal.sortBy) : [],
    [isSortable, props.columns, sortByVal.sortBy]);

  const [sorting, setSorting] = useState<Sorting[]>(tableSyntax.defaultSortState<SB, A>(columnSortList, sortByVal.sortBy));

  useEffect(
    () => setSorting(tableSyntax.defaultSortState<SB, A>(columnSortList, sortByVal.sortBy)),
    [setSorting, columnSortList, sortByVal.sortBy]
  );

  const customSortColumnExtension = useMemo<IntegratedSorting.ColumnExtension[]>(() =>
    pipe(isSortable, b.fold(() => [], () => tableSyntax.formatCustomSortValueExtension(props.columns))
    ), [isSortable, props.columns]);


  const pagingPanel = useCallback((p: PropsWithChildren<PagingPanel.ContainerProps>) =>
    tableSyntax.pagingPanelOrEmpty<SB, A, Params>(sortByVal, props.onParamsChanged, props.paginate, searchVal, p, props.params || {}),
    [props, searchVal, sortByVal]
  );

  const { columns, columnExtensions } = tableSyntax.formatColumns(props.columns);

  const searchedData = useMemo(() => isSearchable && !ignoreSearch && searchVal.length > 0
    ? tableSearchFilterFn(config, props.columns, O.toUndefined(props.store), expandedRowIds)(searchVal)(props.data)
    : props.data,
    [config, expandedRowIds, isSearchable, ignoreSearch, searchVal, props.columns, props.store, props.data]
  );

  return (
    <TableContainer isLgTable={props.isLgTable} variant={props.variant ?? "portal"}>
      {pipe(
        O.fromNullable(props.customActionsPanel),
        O.fold(
          () => props.hideActionsPanel
            ? <Empty />
            : <TableActionsPanel
              onSearchChanged={tableSyntax.onSearchChanged<SB, A, Params>(sortByVal, props.onParamsChanged, props.params || {})}
              onSortChanged={tableSyntax.onSortChanged(columnSortList, setSorting, searchVal, props.onParamsChanged, props.params || {})}
              searchable={pipe(props.searchable, O.map(([p, v]) => [p, v]))}
              tableAction={props.tableAction}
              sortColumns={columnSortList}
              sortingSelected={sorting}
            />,
          AP =>
            <AP
              onSearchChanged={tableSyntax.onSearchChanged<SB, A, Params>(sortByVal, props.onParamsChanged, props.params || {})}
              onSortChanged={tableSyntax.onSortChanged(columnSortList, setSorting, searchVal, props.onParamsChanged, props.params || {})}
              searchable={pipe(props.searchable, O.map(([p, v]) => [p, v]))}
              sortColumns={columnSortList}
              sortingSelected={sorting}
            />
        )
      )}
      <TableBase
        columns={columns}
        detailCell={props.detailCell}
        cellComponent={TableDataCell(expandedRowIds)}
        detailCellComponent={DetailDataCell(false)}
        bodyComponent={TableBody}
        containerComponent={TableContainerComponent(false, props.variant ?? "portal", props.isHeaderless)}
        columnExtensions={columnExtensions}
        rowComponent={TableRow}
        data={searchedData}
        expandedRowIds={expandedRowIds}
        setExpandedRowIds={setExpandedRowIds}
        getRowStatus={props.getRowStatus}
        detailCellDisabled={props.detailCellDisabled}
        collapseAllRows={props.collapseAllRows}
        hiddenColumns={props.hiddenColumns}
      >
        <Plugin>
          {/*
            Plugin ordering is important, the state is evaluated from 1 => 2 => 3 ...etc.
            Thoroughly read through the notes before changing the order.

            Current ordering is as follows:
            1. Search all data by criteria
            2. Filter out values that don't match the filter state criteria
            3. Sort the results of steps 1 & 2
            4. Export the results that meet all the criteria and are sorted
            5. Paginate the results for viewing

            Notes:
            - Export must come after all table criteria or it will only have part of the criteria applied
            - Paginate must come after Export or it will only export the first page
          */}
          <SortingState
            sorting={sorting}
            onSortingChange={setSorting}
          />
          <IntegratedSorting columnExtensions={customSortColumnExtension} />
          <Exporter exporter={props.exporter} />
          {pipe(
            props.paginate,
            mapOrEmpty(pageNo => (
              <Plugin>
                <PagingState
                  currentPage={pageNo - 1}
                  defaultPageSize={props.pageSize ?? defaultPageSize}
                />
                <PagingPanel containerComponent={pagingPanel} />
                <IntegratedPaging />
              </Plugin>
            )
            )
          )}
        </Plugin>
      </TableBase>
    </TableContainer>
  );
};

type TableDraggableProps<
  A,
  MetaData,
  RowParserOutput,
  ExtraColumns extends string[],
  ExportExtraColumns extends string[],
> = TableBaseProps<A, MetaData, ExtraColumns>
  & tableSyntax.TableSharedProps<A, MetaData, ExtraColumns, ExportExtraColumns, RowParserOutput>
  & TableRowStatusProps & {
    isLgTable?: true;
    dragExplanation?: ReactChild;
  };

type TableDraggableRawProps<
  A,
  MetaData,
  RowParserOutput,
  ExtraColumns extends string[],
  ExportExtraColumns extends string[],
> = TableDraggableProps<A, MetaData, RowParserOutput, ExtraColumns, ExportExtraColumns> & {
  expandedRowIds: tableSyntax.ExpandedRowIds;
  setExpandedRowIds: React.Dispatch<SetStateAction<tableSyntax.ExpandedRowIds>>;
  onDragEnd: (rows: tableSyntax.OnDragEnd<A, MetaData>) => void;
  error: boolean;
};

export const TableDraggableRaw = <
  A,
  MetaData,
  RowParserOutput,
  ExtraColumns extends string[],
  ExportExtraColumns extends string[],
>(props: TableDraggableRawProps<A, MetaData, RowParserOutput, ExtraColumns, ExportExtraColumns>): ReactElement => {
  const sensors = useSensors(useSensor(PointerSensor));
  const { columns, columnExtensions } = tableSyntax.formatColumns(props.columns);

  return (
    <TableContainer isLgTable={props.isLgTable} variant="portal">
      {pipe(props.dragExplanation, O.fromNullable, mapOrEmpty(_ => <p {...klass("d-none-until-md", "mb-1")}>{_}</p>))}
      <p {...klass("d-lg-none", "mb-1")}>To reorder items in the table below, open this page on a device with a larger screen, such as a laptop or large tablet.</p>
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        collisionDetection={closestCenter}
        onDragEnd={flow(tableSyntax.onDragSortEnd(props.data, props.expandedRowIds), props.onDragEnd)}
      >
        <TableBase
          columns={[draggableColumn, ...columns]}
          detailCell={props.detailCell}
          cellComponent={TableDataCellDraggable(props.expandedRowIds)}
          detailCellComponent={DetailDataCell(true)}
          bodyComponent={TableBodyDraggable(pipe(props.data, RA.map(c => c.__rowId)))}
          containerComponent={TableContainerComponent(props.error, "portal", props.isHeaderless)}
          columnExtensions={columnExtensions}
          rowComponent={TableRowDraggable}
          setExpandedRowIds={props.setExpandedRowIds}
          expandedRowIds={props.expandedRowIds}
          data={props.data}
          getRowStatus={props.getRowStatus}
          hiddenColumns={props.hiddenColumns}
        >
          <Exporter exporter={props.exporter} />
        </TableBase>
      </DndContext>
    </TableContainer>
  );
};

export const TableDraggable = <
  A,
  MetaData,
  RowParserOutput,
  ExtraColumns extends string[],
  ExportExtraColumns extends string[],
  SortType extends SortItemsC | SortDocCategoriesC,
>(props: TableDraggableProps<A, MetaData, RowParserOutput, ExtraColumns, ExportExtraColumns> & { draggable: tableSyntax.ApiSort<A, MetaData, SortType>, setData: tableSyntax.SetData<A, MetaData>, sortOrderlens: tableSyntax.SortOrderLens<A, MetaData> }): ReactElement => {
  const [expandedRowIds, setExpandedRowIds] = useState<tableSyntax.ExpandedRowIds>([]);

  return (
    <TableDraggableRaw
      dragExplanation={props.dragExplanation}
      error={props.draggable.error}
      onDragEnd={tableSyntax.onDragApi<A, MetaData, SortType>(props.data, props.draggable, props.setData, setExpandedRowIds, props.sortOrderlens)}
      data={props.data}
      exporter={props.exporter}
      detailCell={props.detailCell}
      columns={props.columns}
      getRowStatus={props.getRowStatus}
      expandedRowIds={expandedRowIds}
      setExpandedRowIds={setExpandedRowIds}
      collapseAllRows={props.collapseAllRows}
      detailCellDisabled={props.detailCellDisabled}
      isHeaderless={props.isHeaderless}
      hiddenColumns={props.hiddenColumns}
      isLgTable={props.isLgTable}
    />
  );
};

// When draggable api is not avalible for your use case or you are using a form use this table
export const TableDraggableControlled = <
  A,
  MetaData,
  RowParserOutput,
  ExtraColumns extends string[],
  ExportExtraColumns extends string[],
>(props: TableDraggableProps<A, MetaData, RowParserOutput, ExtraColumns, ExportExtraColumns> & { setData: tableSyntax.SetData<A, MetaData>, error: boolean }) => {
  const [expandedRowIds, setExpandedRowIds] = useState<tableSyntax.ExpandedRowIds>([]);

  return <TableDraggableRaw
    data={props.data}
    dragExplanation={props.dragExplanation}
    exporter={props.exporter}
    detailCell={props.detailCell}
    expandedRowIds={expandedRowIds}
    setExpandedRowIds={setExpandedRowIds}
    columns={props.columns}
    getRowStatus={props.getRowStatus}
    collapseAllRows={props.collapseAllRows}
    detailCellDisabled={props.detailCellDisabled}
    isHeaderless={props.isHeaderless}
    onDragEnd={O.map(([newRows, newExpandedRows]) => {
      props.setData(newRows);
      setExpandedRowIds(newExpandedRows);
    })}
    hiddenColumns={props.hiddenColumns}
    error={props.error}
    isLgTable={props.isLgTable}
  />;
};
