import type { ReactElement } from "react";
import * as t from "io-ts";
import { singular } from "pluralize";
import type { CreativeWork, Event, Thing, WithContext } from "schema-dts";

import { type BLConfigWithLog } from "@scripts/bondlink";
import type { Markdown } from "@scripts/codecs/markdown";
import { constant, E, flow, identity, O, pipe, RA, RNEA } from "@scripts/fp-ts";
import {
  contact as contactFF,
  cusip6, emma,
  esgProgram as esgProgramFF,
  faq as faqFF,
  type FeatureU,
  financeTeam as teamFF,
  linksPage as linksPageFF,
  news as newsFF,
  projects as projectsFF,
} from "@scripts/generated/domaintables/featureFlags";
import type {
  PageU,
  PhotoEditableU,
} from "@scripts/generated/domaintables/pages";
import {
  aboutPage,
  bondArchive as bondArchivePage,
  bondProgramsPage,
  bondRatings,
  bondsPage,
  contact,
  CustomPageCU,
  documentsPage,
  esgProgram as esgProgramPage,
  faq as faqPage,
  financeTeam,
  homepage,
  infrastructureProjects,
  irmaLetter as irmaLetterPage,
  linksPage,
  news,
  newsEventsPage,
  resourcesPage,
  rfpsPage,
} from "@scripts/generated/domaintables/pages";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { NewsAndEventsData, NewsAndEventsDataC } from "@scripts/generated/models/issuerNews";
import type { IssuerHomepageData, IssuerHomepageDataC } from "@scripts/generated/models/issuerPageData";
import { type PageConfig, pageConfigC } from "@scripts/generated/models/pageConfig";
import type { RfpsSitesList, RfpsSitesListC } from "@scripts/generated/models/rfp";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { emmaName } from "@scripts/literals/emma";
import { type DataMetaBase, displayName, esgProgramMeta, programMeta, programsMeta, rfpMeta, rfpsMeta } from "@scripts/meta/dataMeta";
import type { KlassBase } from "@scripts/react/util/classnames";
import { oxfordCommaString, oxfordCommaStringN } from "@scripts/react/util/oxfordComma";
import type { RouteMeta } from "@scripts/routes/routing/ssr";
import { getEditableTitleOrDefault, getPageCustomDescriptionOrDefault } from "@scripts/routes/routing/ssr";
import { ifAnyEnabled, isFFEnabled } from "@scripts/syntax/featureFlags";
import { issuerShortNameOrName } from "@scripts/syntax/issuer";
import { coerceStringAsMarkdown } from "@scripts/syntax/markdown";
import { getPage } from "@scripts/syntax/pageConfig";
import { getCustomTitleO } from "@scripts/syntax/pageTitles";
import {
  makeSchemaOrgCreativeWork,
  type SchemaOrgOutput,
} from "@scripts/syntax/schemaOrg";
import { tagEq } from "@scripts/util/compare";

import { format } from "../base";
import { contactMeta, linksMeta, projectsMeta, resourcesMeta, teamMeta } from "../issuerportal/dataMeta";
import { makeBondSchemaOrgEventO, makeEventSchemaOrgEventO, makeRfpSchemaOrgEventO } from "./issuerSitesSchemaOrg";
type DescriptionFn<A> = (pages: ReadonlyArray<PageConfig<PageU>>, ffs: ClientFeatureFlags, issuer: Issuer) => A;

type TitleFn = (pages: ReadonlyArray<PageConfig<PageU>>, issuer: Issuer) => string;

type MakeSchemaOrg<A, T extends Thing = Thing> = (
  data: A,
  config: BLConfigWithLog,
  issuer: Issuer
) => SchemaOrgOutput<T>;

export type IssuerSitesRouteMeta<A, Output> = Omit<RouteMeta<A, Output>, "description" | "title"> & {
  description: DescriptionFn<Markdown>;
  descriptionMeta: (data: A) => DescriptionFn<string>;
  photoPage: PhotoEditableU;
  title: TitleFn;
  titleMeta: (data: A) => TitleFn;
  makeSchemaOrg: O.Option<MakeSchemaOrg<A>>;
  renderChrome: boolean;
  bodyKlass?: KlassBase;
};

export type IssuerSitesRouteMetaWithRender<A, O> = IssuerSitesRouteMeta<A, O> & {
  render: (props: A) => ReactElement<A>;
};

type DescriptionTextFn = (issuer: Issuer, page: O.Option<PageConfig<PageU>>, pages: ReadonlyArray<PageConfig<PageU>>, ffs: ClientFeatureFlags) => string;

const makeDescription = (page: PageU, makeDescriptionText: DescriptionTextFn): DescriptionFn<Markdown> =>
  (pages, ffs, issuer) => getPageCustomDescriptionOrDefault(
    page,
    (p) => coerceStringAsMarkdown(makeDescriptionText(issuer, p, pages, ffs)),
  )(pages);

export const makeDescriptionMeta = <A>(page: PageU, makeDescriptionText: (data: A) => DescriptionTextFn) => (data: A) => (pages: ReadonlyArray<PageConfig<PageU>>, ffs: ClientFeatureFlags, issuer: Issuer) => {
  return pipe(
    getPage(page)(pages),
    (p) => makeDescriptionText(data)(issuer, p, pages, ffs)
  );
};

const getPageTitleOrDefault = (pageMeta: DataMetaBase<string>) => flow(
  O.chain((_: PageConfig<PageU>) => _.title),
  O.getOrElse(() => pageMeta.type)
);

// HOME PAGE
const makeHomeDescription = (issuer: Issuer) =>
  `The ${issuerShortNameOrName(issuer)} website features bond offerings and ratings, financial documents, news & updates about our municipality, and other information about our municipal bond programs. Powered by BondLink.`;

export const homeMatch = SR.issuersitesIssuerControllerIndexRoute().match;
export type HomeUrlParams = typeof homeMatch._A;

export const home = (reqParams: SR.IssuersitesIssuerControllerIndexParams) => ({
  // The homepage doesn't have a description
  description: () => coerceStringAsMarkdown(""),
  descriptionMeta: () => (_ps, _fs, issuer) => makeHomeDescription(issuer),
  _tag: "home",
  propsCodec: SR.issuersitesIssuerControllerIndexCodecs.ssrInput,
  url: () => format(homeMatch, reqParams),
  title: (_, issuer) => issuer.name,
  titleMeta: () => () => "Bonds, Documents, Resources",
  photoPage: homepage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<IssuerHomepageData, t.OutputOf<IssuerHomepageDataC>>);

// RFP SUMMARY PAGE
export const rfpsMatch = SR.issuersitesRfpsControllerRfpsRoute().match;

export type RfpsUrlParams = typeof rfpsMatch._A;

const rfpsTitleFn = getEditableTitleOrDefault(rfpsPage, rfpsMeta);

export const rfps = (reqParams: SR.IssuersitesRfpsControllerRfpsParams) => {
  return {
    description: makeDescription(
      rfpsPage,
      (_, page) => `Get details and submission information about ${getPageTitleOrDefault(rfpsMeta)(page)}.`,
    ),
    descriptionMeta: makeDescriptionMeta(
      rfpsPage,
      () => (issuer, page) => `Get details and submission information about ${getPageTitleOrDefault(rfpsMeta)(page)} from ${issuerShortNameOrName(issuer)}.`
    ),
    _tag: "rfps",
    propsCodec: SR.issuersitesRfpsControllerRfpsCodecs.ssrInput,
    title: rfpsTitleFn,
    titleMeta: () => rfpsTitleFn,
    url: () => format(rfpsMatch, { ...reqParams }),
    photoPage: bondsPage,
    makeSchemaOrg: O.none,
    renderChrome: true,
  } as const satisfies IssuerSitesRouteMeta<RfpsSitesList, t.OutputOf<RfpsSitesListC>>;
};

// RFP PAGE
export const rfpMatch = SR.issuersitesRfpsControllerRfpRoute().match;
export type RfpUrlParams = typeof rfpMatch._A;

export type RfpPageDataC = typeof SR.issuersitesRfpsControllerRfpCodecs.ssrInput;

const rfpDesc: DescriptionTextFn = (issuer, page) =>
  `Get details about this ${getPageTitleOrDefault(rfpMeta)(page)} from ${issuerShortNameOrName(issuer)}, including project information, submission requirements, and other related content.`;

const rfpTitleFn = flow(
  getEditableTitleOrDefault(rfpsPage, rfpMeta),
  singular
);

export const rfp = (reqParams: SR.IssuersitesRfpsControllerRfpParams) => ({
  description: makeDescription(
    rfpsPage,
    rfpDesc
  ),
  descriptionMeta: makeDescriptionMeta(
    rfpsPage,
    () => rfpDesc
  ),
  _tag: "rfp",
  propsCodec: SR.issuersitesRfpsControllerRfpCodecs.ssrInput,
  url: () => format(rfpMatch, reqParams),
  title: rfpTitleFn,
  titleMeta: () => rfpTitleFn,
  photoPage: bondsPage,
  makeSchemaOrg: O.some((data, config, issuer) => RA.compact<WithContext<Event> | WithContext<CreativeWork>>([
    makeRfpSchemaOrgEventO(E.toUnion(data).rfp.data, config, issuer),
    O.some(makeSchemaOrgCreativeWork({ isAccessibleForFree: E.isRight(data) })),
  ])),
  renderChrome: false,
} as const satisfies IssuerSitesRouteMeta<t.TypeOf<RfpPageDataC>, t.OutputOf<RfpPageDataC>>);

// BONDS SUMMARY PAGE
export const bondsMatch = SR.issuersitesBondOfferingsControllerIndexRoute().match;

export type BondsPageDataC = typeof SR.issuersitesBondOfferingsControllerIndexCodecs.ssrInput;

const bondsTitleFn = () => "Bonds";

export const bonds = (reqParams: SR.IssuersitesBondOfferingsControllerIndexParams) => {
  return {
    description: makeDescription(
      bondsPage,
      () => "Get an overview of our bonds and ratings.",
    ),
    descriptionMeta: makeDescriptionMeta(
      bondsPage,
      () => (issuer) => `Get an overview of bonds and ratings from ${issuerShortNameOrName(issuer)}.`
    ),
    _tag: "bonds",
    propsCodec: SR.issuersitesBondOfferingsControllerIndexCodecs.ssrInput,
    title: bondsTitleFn,
    titleMeta: () => bondsTitleFn,
    url: () => format(bondsMatch, { ...reqParams }),
    photoPage: bondsPage,
    makeSchemaOrg: O.none,
    renderChrome: true,
  } as const satisfies IssuerSitesRouteMeta<t.TypeOf<BondsPageDataC>, t.OutputOf<BondsPageDataC>>;
};

// BOND ARCHIVE PAGE
export const bondArchiveMatch = SR.issuersitesBondOfferingsControllerArchivedBondsRoute().match;

export type BondArchivePageDataC = typeof SR.issuersitesBondOfferingsControllerArchivedBondsCodecs.ssrInput;

const bondArchiveTitleFn = () => "Bond Archive";

export const bondArchive = (reqParams: SR.IssuersitesBondOfferingsControllerArchivedBondsParams) => {
  return {
    description: makeDescription(
      bondArchivePage,
      () => "Sort, filter, and view archived bond offerings.",
    ),
    descriptionMeta: makeDescriptionMeta(
      bondArchivePage,
      () => (issuer) => `Sort, filter, and view archived bond offerings from ${issuerShortNameOrName(issuer)}.`
    ),
    _tag: "bonds",
    propsCodec: SR.issuersitesBondOfferingsControllerArchivedBondsCodecs.ssrInput,
    title: bondArchiveTitleFn,
    titleMeta: () => bondArchiveTitleFn,
    url: () => format(bondArchiveMatch, { ...reqParams }),
    photoPage: bondsPage,
    makeSchemaOrg: O.none,
    renderChrome: true,
  } as const satisfies IssuerSitesRouteMeta<t.TypeOf<BondArchivePageDataC>, t.OutputOf<BondArchivePageDataC>>;
};

// OFFERING PAGE
const offeringPageDescription = (issuer: Issuer) =>
  `Details and offering statement about this municipal bond offering from ${issuerShortNameOrName(issuer)}.`;

export const offeringPageMatch = SR.issuersitesBondOfferingsControllerOfferingRoute().match;

export type OfferingPageDataC = typeof SR.issuersitesBondOfferingsControllerOfferingCodecs.ssrInput;

const offeringPageTitleFn = () => "Bond Offering";

export const offeringPage = (reqParams: SR.IssuersitesBondOfferingsControllerOfferingParams) => ({
  description: makeDescription(
    bondsPage,
    offeringPageDescription
  ),
  descriptionMeta: makeDescriptionMeta(
    bondsPage,
    () => offeringPageDescription
  ),
  _tag: "offering-page",
  propsCodec: SR.issuersitesBondOfferingsControllerOfferingCodecs.ssrInput,
  url: () => format(offeringPageMatch, reqParams),
  title: offeringPageTitleFn,
  titleMeta: () => offeringPageTitleFn,
  photoPage: bondsPage,
  makeSchemaOrg: O.some((data, config, issuer) =>
    RA.compact<WithContext<Event> | WithContext<CreativeWork>>([
      makeBondSchemaOrgEventO(E.toUnion(data).offering.data, config, issuer),
      O.some(makeSchemaOrgCreativeWork({ isAccessibleForFree: E.isRight(data) })),
    ])
  ),
  renderChrome: false,
} as const satisfies IssuerSitesRouteMeta<t.TypeOf<OfferingPageDataC>, t.OutputOf<OfferingPageDataC>>);

// NEWS AND EVENTS PAGE
const makeNewsAndEventsDescription: DescriptionTextFn = (issuer) =>
  `Learn about the latest News & Events for ${issuerShortNameOrName(issuer)}, and sign up to receive news updates.`;

export const newsAndEventsMatch = SR.issuersitesAboutControllerNewsEventsRoute().match;
export type NewsAndEventsUrlParams = typeof newsAndEventsMatch._A;

export const newsPressReleasesSectionTitle = (pages: ReadonlyArray<PageConfig<PageU>>, defaultTitle: string) => pipe(
  pages,
  RA.findFirst(_ => tagEq().equals(_.page, news)),
  O.chain(_ => _.title),
  O.getOrElse(() => defaultTitle)
);

const newsAndEventsTitleFn = () => "News & Events";

export const newsAndEvents = (reqParams: SR.IssuersitesAboutControllerNewsEventsParams) => ({
  description: makeDescription(
    newsEventsPage,
    makeNewsAndEventsDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    newsEventsPage,
    () => makeNewsAndEventsDescription
  ),
  _tag: "news-and-events",
  propsCodec: SR.issuersitesAboutControllerNewsEventsCodecs.ssrInput,
  url: () => format(newsAndEventsMatch, reqParams),
  title: newsAndEventsTitleFn,
  titleMeta: () => newsAndEventsTitleFn,
  photoPage: aboutPage,
  makeSchemaOrg: O.some((data, config, issuer) => pipe(
    data.eventItems,
    RA.filterMap(flow(
      E.fold(
        eventData => makeEventSchemaOrgEventO(eventData, config, issuer),
        E.fold(
          bondData => makeBondSchemaOrgEventO(bondData.data, config, issuer),
          rfpData => makeRfpSchemaOrgEventO(rfpData.data, config, issuer),
        ))
    )
    )),
  ),
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<NewsAndEventsData, t.OutputOf<NewsAndEventsDataC>>);

// RATINGS PAGE
export type RatingsPageDataC = typeof SR.issuersitesBondOfferingsControllerRatingsCodecs.ssrInput;

export const ratingsMatch = SR.issuersitesBondOfferingsControllerRatingsRoute().match;

const ratingsTitleFn = () => "Ratings";

export const ratings = (reqParams: SR.IssuersitesBondOfferingsControllerRatingsParams) => {
  return {
    description: makeDescription(
      bondRatings,
      () => "View ratings for our bond programs from rating agencies such as Moody’s Investors Service, S&P Global Ratings, and Fitch Ratings. Ratings for individual bonds are shown on their respective offering pages."
    ),
    descriptionMeta: makeDescriptionMeta(
      bondRatings,
      () => (issuer) => `View ratings for ${issuerShortNameOrName(issuer)} and our bond programs from agencies such as Moody’s, S&P, Fitch, and Kroll.`
    ),
    _tag: "ratings",
    propsCodec: SR.issuersitesBondOfferingsControllerRatingsCodecs.ssrInput,
    title: ratingsTitleFn,
    titleMeta: () => ratingsTitleFn,
    url: () => format(ratingsMatch, { ...reqParams }),
    photoPage: bondsPage,
    makeSchemaOrg: O.none,
    renderChrome: true,
  } as const satisfies IssuerSitesRouteMeta<t.TypeOf<RatingsPageDataC>, t.OutputOf<RatingsPageDataC>>;
};

// PROGRAMS PAGE
export type ProgramsPageDataC = typeof SR.issuersitesBondProgramsControllerBondProgramsCodecs.ssrInput;

export const programsMatch = SR.issuersitesBondProgramsControllerBondProgramsRoute().match;

const programsTitleFn = getEditableTitleOrDefault(bondProgramsPage, programsMeta);

export const programs = (reqParams: SR.IssuersitesBondProgramsControllerBondProgramsParams) => ({
  description: makeDescription(
    bondProgramsPage,
    (_, page) => `Learn about our ${getPageTitleOrDefault(programsMeta)(page)}, including information about bond offerings, ratings, CUSIPs, and more.`,
  ),
  descriptionMeta: makeDescriptionMeta(
    bondProgramsPage,
    () => (issuer, page) => `Learn about ${getPageTitleOrDefault(programsMeta)(page)} from ${issuerShortNameOrName(issuer)}, including information about bond offerings, ratings, CUSIPs, and more.`,
  ),
  _tag: "programs",
  propsCodec: SR.issuersitesBondProgramsControllerBondProgramsCodecs.ssrInput,
  title: programsTitleFn,
  titleMeta: () => programsTitleFn,
  url: () => format(programsMatch, { ...reqParams }),
  photoPage: bondsPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<t.TypeOf<ProgramsPageDataC>, t.OutputOf<ProgramsPageDataC>>);

// PROGRAM PAGE
const programPageDataC = SR.issuersitesBondProgramsControllerBondProgramCodecs.ssrInput;
export type ProgramPageDataC = typeof programPageDataC;
export type ProgramPageData = t.TypeOf<ProgramPageDataC>;

const makeProgramDescription: DescriptionTextFn = (issuer, page) => pipe(
  getPageTitleOrDefault(programMeta)(page),
  singular,
  pageTitle => `Get Details about this ${pageTitle} from ${issuerShortNameOrName(issuer)}, including related bond offerings, ratings, CUSIPs, and more.`
);

export const programMatch = SR.issuersitesBondProgramsControllerBondProgramRoute().match;

const programTitleFn = flow(
  getEditableTitleOrDefault(bondProgramsPage, programMeta),
  singular
);

export const program = (reqParams: SR.IssuersitesBondProgramsControllerBondProgramParams) => ({
  description: makeDescription(
    bondProgramsPage,
    makeProgramDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    bondProgramsPage,
    () => makeProgramDescription
  ),
  _tag: "bond-program",
  propsCodec: programPageDataC,
  title: programTitleFn,
  titleMeta: () => programTitleFn,
  url: () => format(programMatch, { ...reqParams }),
  photoPage: bondsPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<ProgramPageData, t.OutputOf<ProgramPageDataC>>);

// FAQs PAGE
const faqPageDataC = SR.issuersitesResourcesControllerFaqCodecs.ssrInput;
export type FAQPageDataC = typeof faqPageDataC;
export type FAQPageData = t.TypeOf<FAQPageDataC>;

export const faqMatch = SR.issuersitesResourcesControllerFaqRoute().match;

const makefaqDescription: DescriptionTextFn = (issuer) => `View frequently asked questions (FAQ) for ${issuer.name}.`;

const faqTitleFn = () => "FAQ";

export const faq = (reqParams: SR.IssuersitesResourcesControllerFaqParams) => ({
  description: makeDescription(faqPage, makefaqDescription),
  descriptionMeta: makeDescriptionMeta(faqPage, () => makefaqDescription),
  _tag: "faq",
  propsCodec: faqPageDataC,
  title: faqTitleFn,
  titleMeta: () => faqTitleFn,
  url: () => format(faqMatch, { ...reqParams }),
  photoPage: resourcesPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<FAQPageData, t.OutputOf<FAQPageDataC>>);

// ABOUT PAGE
const aboutPageDataC = SR.issuersitesAboutControllerIndexCodecs.ssrInput;
export type AboutPageDataC = typeof aboutPageDataC;
export type AboutPageData = t.TypeOf<AboutPageDataC>;

export const aboutMatch = SR.issuersitesAboutControllerIndexRoute().match;

const aboutDescriptionSections: ReadonlyArray<[FeatureU, PageU, E.Either<string, DataMetaBase<string>>]> = [
  [esgProgramFF, esgProgramPage, E.right(esgProgramMeta)],
  [newsFF, news, E.left(news.name)],
  [projectsFF, infrastructureProjects, E.right(projectsMeta)],
  [teamFF, financeTeam, E.right(teamMeta)],
];

const aboutDescription: DescriptionTextFn = (issuer, _, pages, ffs) =>
  `Learn about ${issuer.name}` + pipe(
    aboutDescriptionSections,
    RA.filterMap(([f, p, defaultOrMeta]) => isFFEnabled(f)(ffs)
      ? O.some(O.getOrElse(() => pipe(defaultOrMeta, E.fold(identity, m => displayName(m))))(getCustomTitleO(p)(pages)))
      : O.none),
    RNEA.fromReadonlyArray,
    O.fold(() => ".", ss => ` including our ${oxfordCommaStringN(ss)}.`),
  );

export const about = (reqParams: SR.IssuersitesAboutControllerIndexParams) => ({
  description: makeDescription(
    aboutPage,
    aboutDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    aboutPage,
    () => aboutDescription
  ),
  _tag: "about",
  propsCodec: aboutPageDataC,
  title: () => "About",
  titleMeta: () => () => "Team, News, Projects",
  url: () => format(aboutMatch, { ...reqParams }),
  photoPage: aboutPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<AboutPageData, t.OutputOf<AboutPageDataC>>);

// ESG PROGRAM PAGE
export type EsgProgramPageDataC = typeof SR.issuersitesAboutControllerEsgProgramCodecs.ssrInput;

export const esgProgramMatch = SR.issuersitesAboutControllerEsgProgramRoute().match;
export const esgTitle = getEditableTitleOrDefault(esgProgramPage, esgProgramMeta);

export const esgDescription = makeDescription(
  esgProgramPage,
  () => "Learn about our environmental, social, and governance program, and how we bring those values to life with green bonds, sustainable projects, and more."
);

export const esgProgram = (reqParams: SR.IssuersitesAboutControllerEsgProgramParams) => {
  return {
    description: esgDescription,
    descriptionMeta: makeDescriptionMeta(
      esgProgramPage,
      () => (issuer) => `Learn about the environmental, social, and governance program from ${issuerShortNameOrName(issuer)}, and how we bring those values to life with green bonds, sustainable projects, and more.`
    ),
    _tag: "esg-program",
    propsCodec: SR.issuersitesAboutControllerEsgProgramCodecs.ssrInput,
    title: esgTitle,
    titleMeta: () => esgTitle,
    url: () => format(esgProgramMatch, { ...reqParams }),
    photoPage: aboutPage,
    makeSchemaOrg: O.none,
    renderChrome: true,
  } as const satisfies IssuerSitesRouteMeta<t.TypeOf<EsgProgramPageDataC>, t.OutputOf<EsgProgramPageDataC>>;
};

// Team Page
export const teamTitle = getEditableTitleOrDefault(financeTeam, teamMeta);

const teamPageDataC = SR.issuersitesAboutControllerTeamCodecs.ssrInput;
export type TeamPageDataC = typeof teamPageDataC;
export type TeamPageData = t.TypeOf<TeamPageDataC>;

export const teamMatch = SR.issuersitesAboutControllerTeamRoute().match;

const teamDescription: DescriptionTextFn = (issuer) =>
  `Learn about the team at ${issuerShortNameOrName(issuer)}.`;
export const team = (reqParams: SR.IssuersitesAboutControllerTeamParams) => ({
  description: makeDescription(
    financeTeam,
    teamDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    financeTeam,
    () => teamDescription
  ),
  _tag: "team",
  title: teamTitle,
  titleMeta: () => teamTitle,
  propsCodec: teamPageDataC,
  url: () => format(teamMatch, reqParams),
  photoPage: aboutPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
}) as const satisfies IssuerSitesRouteMeta<TeamPageData, t.OutputOf<TeamPageDataC>>;

// Projects Page

export const projectsTitle = getEditableTitleOrDefault(infrastructureProjects, projectsMeta);

const projectsPageDataC = SR.issuersitesAboutControllerProjectsCodecs.ssrInput;
export type ProjectsPageDataC = typeof projectsPageDataC;
export type ProjectsPageData = t.TypeOf<ProjectsPageDataC>;

export const projectsMatch = SR.issuersitesAboutControllerProjectsRoute().match;

const projectsDescription: DescriptionTextFn = (issuer, _) =>
  `Learn about ${getPageTitleOrDefault(projectsMeta)(_)} for ${issuerShortNameOrName(issuer)}.`;

export const projects = (reqParams: SR.IssuersitesAboutControllerProjectsParams) => ({
  description: makeDescription(
    infrastructureProjects,
    projectsDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    infrastructureProjects,
    () => projectsDescription
  ),
  _tag: "projects",
  propsCodec: projectsPageDataC,
  url: () => format(projectsMatch, reqParams),
  title: projectsTitle,
  titleMeta: () => projectsTitle,
  photoPage: aboutPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<ProjectsPageData, t.OutputOf<ProjectsPageDataC>>);

// DOCUMENTS PAGE
const documentCategoriesPageDataC = SR.issuersitesReportsControllerIndexCodecs.ssrInput;
export type DocumentCategoriesPageDataC = typeof documentCategoriesPageDataC;
export type DocumentCategoriesPageData = t.TypeOf<DocumentCategoriesPageDataC>;

export const documentCategoriesMatch = SR.issuersitesReportsControllerIndexRoute().match;
const documentCategoriesDescription: DescriptionTextFn = (issuer) =>
  `Browse documents from ${issuer.name} by category.`;

const documentTitle = constant("Documents");
export const documentCategories = (reqParams: SR.IssuersitesReportsControllerIndexParams) => ({
  description: makeDescription(
    documentsPage,
    documentCategoriesDescription
  ),
  descriptionMeta: makeDescriptionMeta(
    documentsPage,
    () => documentCategoriesDescription
  ),
  _tag: "document-categories",
  propsCodec: documentCategoriesPageDataC,
  title: documentTitle,
  titleMeta: () => documentTitle,
  url: () => format(documentCategoriesMatch, { ...reqParams }),
  photoPage: documentsPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
}) as const satisfies IssuerSitesRouteMeta<DocumentCategoriesPageData, t.OutputOf<DocumentCategoriesPageDataC>>;

// DOWNLOADS PAGE
const downloadsPageDataC = SR.issuersitesReportsControllerDownloadsCodecs.ssrInput;
export type DownloadsPageDataC = typeof downloadsPageDataC;
export type DownloadsPageData = t.TypeOf<DownloadsPageDataC>;

export const downloadsMatch = SR.issuersitesReportsControllerDownloadsRoute().match;

const downloadsDescription: DescriptionTextFn = (issuer) =>
  `Download documents from ${issuer.name}, sort and filter documents, and sign up to receive document updates.`;

const downloadsTitleFn = () => "Downloads";

export const downloads = (reqParams: SR.IssuersitesReportsControllerDownloadsParamsRaw) => ({
  description: makeDescription(
    documentsPage,
    downloadsDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    documentsPage,
    () => downloadsDescription
  ),
  _tag: "downloads",
  propsCodec: downloadsPageDataC,
  title: downloadsTitleFn,
  titleMeta: () => downloadsTitleFn,
  url: () => format(downloadsMatch, { ...reqParams }),
  photoPage: documentsPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<DownloadsPageData, t.OutputOf<DownloadsPageDataC>>);

// ARCHIVED DOWNLOADS PAGE

export const archivedDownloadsMatch = SR.issuersitesReportsControllerArchiveDownloadsRoute().match;

const archivedDownloadsDescription: DescriptionTextFn = (issuer) =>
  `Download archived documents from ${issuer.name}.`;

const archivedDocumentsTitleFn = () => "Archived Documents";

export const archivedDocuments = (reqParams: SR.IssuersitesReportsControllerDownloadsParamsRaw) => ({
  description: makeDescription(
    documentsPage,
    archivedDownloadsDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    documentsPage,
    () => archivedDownloadsDescription
  ),
  _tag: "archived-documents",
  propsCodec: downloadsPageDataC,
  title: archivedDocumentsTitleFn,
  titleMeta: () => archivedDocumentsTitleFn,
  url: () => format(archivedDownloadsMatch, { ...reqParams }),
  photoPage: documentsPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<DownloadsPageData, t.OutputOf<DownloadsPageDataC>>);

// LINKS PAGE
const linksPageDataC = SR.issuersitesResourcesControllerLinksCodecs.ssrInput;
export type LinksPageDataC = typeof linksPageDataC;
export type LinksPageData = t.TypeOf<LinksPageDataC>;

export const linksMatch = SR.issuersitesResourcesControllerLinksRoute().match;

const makeLinksDescription: DescriptionTextFn = (issuer) => `View additional resources from ${issuer.name}, including our contact information, ${emmaName} Links, Frequently Asked Questions.`;

const linksTitleFn = getEditableTitleOrDefault(linksPage, linksMeta);

export const links = (reqParams: SR.IssuersitesResourcesControllerLinksParams) => ({
  description: makeDescription(
    linksPage,
    makeLinksDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    linksPage,
    () => makeLinksDescription
  ),
  _tag: "links",
  propsCodec: linksPageDataC,
  title: linksTitleFn,
  titleMeta: () => linksTitleFn,
  url: () => format(linksMatch, { ...reqParams }),
  photoPage: resourcesPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<LinksPageData, t.OutputOf<LinksPageDataC>>);

// RESOURCES PAGE

const resourcesPageDataC = SR.issuersitesResourcesControllerIndexCodecs.ssrInput;
export type ResourcePageDataC = typeof resourcesPageDataC;
export type ResourcePageData = t.TypeOf<ResourcePageDataC>;

export const resourcesMatch = SR.issuersitesResourcesControllerIndexRoute().match;

export const emmaSectionTitle = `${emmaName} Links`;
export const faqSectionTitle = "Frequently Asked Questions";

export const resourcesHeaderCopy = (
  showEmmaSection: boolean,
  showFaqSection: boolean,
  showLinksPageSection: boolean,
  showContactUsSection: boolean,
  linksPageTitle: string,
  contactUsTitle: string,
  customPageTitles: ReadonlyArray<string>,
  issuer: Issuer
) => pipe(
  [
    RA.of("contact information"),
    showEmmaSection ? RA.of(emmaSectionTitle) : [],
    showFaqSection ? RA.of(faqSectionTitle) : [],
    showLinksPageSection ? RA.of(linksPageTitle) : [],
    showContactUsSection ? RA.of(contactUsTitle) : [],
    customPageTitles,
  ],
  RA.flatten,
  oxfordCommaString,
  _ => `View additional resources from ${issuer.name}, including our ${O.getOrElse(() => "")(_)}`
);

const makeResourcesDescription: DescriptionTextFn = (issuer, page, pages, iffs) => {
  const showEmmaSection = ifAnyEnabled([emma, cusip6])(iffs);
  const showFaqSection = isFFEnabled(faqFF)(iffs);
  const showLinksPageSection = isFFEnabled(linksPageFF)(iffs);
  const showContactUsSection = isFFEnabled(contactFF)(iffs);

  const linksPageTitle = getEditableTitleOrDefault(linksPage, linksMeta)(pages);
  const contactUsTitle = getEditableTitleOrDefault(contact, contactMeta)(pages);
  const customPageTitles = pipe(
    pages,
    RA.filter(pageConfigC(CustomPageCU).is),
    // Every Custom Page should have a title
    RA.filterMap(_ => _.title)
  );

  return resourcesHeaderCopy(showEmmaSection, showFaqSection, showLinksPageSection, showContactUsSection, linksPageTitle, contactUsTitle, customPageTitles, issuer);
};

const resourcesTitleFn = getEditableTitleOrDefault(resourcesPage, resourcesMeta);

export const resources = (reqParams: SR.IssuersitesResourcesControllerIndexParams) => ({
  description: makeDescription(
    resourcesPage,
    makeResourcesDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    resourcesPage,
    () => makeResourcesDescription
  ),
  _tag: "resources",
  propsCodec: resourcesPageDataC,
  title: resourcesTitleFn,
  titleMeta: () => resourcesTitleFn,
  url: () => format(resourcesMatch, { ...reqParams }),
  photoPage: resourcesPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<ResourcePageData, t.OutputOf<ResourcePageDataC>>);

// Roadshow player
export const roadshowPlayerDataC = SR.issuersitesRoadShowControllerRoadShowPlayerCodecs.ssrInput;
export type RoadshowPlayerDataC = typeof roadshowPlayerDataC;
export type RoadshowPlayerData = t.TypeOf<RoadshowPlayerDataC>;

export const roadshowPlayerMatch = SR.issuersitesRoadShowControllerRoadShowPlayerRoute().match;

export const roadshowPlayer = (reqParams: SR.IssuersitesRoadShowControllerRoadShowPlayerParams) => ({
  description: () => coerceStringAsMarkdown(""),
  descriptionMeta: () => (_ps, _fs, issuer) => `Roadshow slide presentation from ${issuer.name}`,
  _tag: "roadshow-player",
  propsCodec: roadshowPlayerDataC,
  url: () => format(roadshowPlayerMatch, reqParams),
  title: () => "Roadshow",
  titleMeta: ({ show }) => () => show.record.data.show.title,
  photoPage: bondsPage,
  makeSchemaOrg: O.none,
  renderChrome: false,
  bodyKlass: "roadshow-player",
} as const satisfies IssuerSitesRouteMeta<RoadshowPlayerData, t.OutputOf<RoadshowPlayerDataC>>);

// IRMA LETTER PAGE

export const irmaLetterPageDataC = SR.issuersitesReportsControllerIrmaLetterCodecs.ssrInput;
export type IrmaLetterPageDataC = typeof irmaLetterPageDataC;
export type IrmaLetterPageData = t.TypeOf<IrmaLetterPageDataC>;

export const irmaLetterMatch = SR.issuersitesReportsControllerIrmaLetterRoute().match;

const makeIrmaLetterDescription: DescriptionTextFn = (issuer: Issuer) =>
  `${irmaLetterPage.name} - ${issuer.name}`;

export const irmaLetter = (reqParams: SR.IssuersitesReportsControllerIrmaLetterParams) => ({
  description: makeDescription(
    irmaLetterPage,
    makeIrmaLetterDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    irmaLetterPage,
    () => makeIrmaLetterDescription
  ),
  _tag: "irma-letter",
  propsCodec: irmaLetterPageDataC,
  title: () => irmaLetterPage.name,
  titleMeta: () => () => irmaLetterPage.name,
  url: () => format(irmaLetterMatch, { ...reqParams }),
  photoPage: documentsPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<IrmaLetterPageData, t.OutputOf<IrmaLetterPageDataC>>);

// VIEW FILE PAGE

const viewFilePageDataC = SR.issuersitesReportsControllerViewFileCodecs.ssrInput;
export type ViewFilePageDataC = typeof viewFilePageDataC;
export type ViewFilePageData = t.TypeOf<ViewFilePageDataC>;

export const viewFileMatch = SR.issuersitesReportsControllerViewFileRoute().match;

const makeViewFileDescriptionMeta: (data: ViewFilePageData) => DescriptionTextFn = (data) => (issuer: Issuer) =>
  `${data.data.record.data.document.uploadResponse.viewName} - ${issuer.name}`;

export const viewFile = (reqParams: SR.IssuersitesReportsControllerViewFileParams) => ({
  description: makeDescription(
    documentsPage,
    // The view file page doesn't have a description
    () => "",
  ),
  descriptionMeta: makeDescriptionMeta(
    documentsPage,
    makeViewFileDescriptionMeta
  ),
  _tag: "view-file",
  propsCodec: viewFilePageDataC,
  title: () => "Downloads",
  titleMeta: () => () => "Downloads",
  url: () => format(viewFileMatch, { ...reqParams }),
  photoPage: documentsPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<ViewFilePageData, t.OutputOf<ViewFilePageDataC>>);

// NOT FOUND
const notFoundTitleFn = () => "Not Found";

export const notFound = {
  _tag: "NotFound",
  propsCodec: t.unknown,
  title: notFoundTitleFn,
  titleMeta: () => notFoundTitleFn,
  url: () => "/",
  description: () => coerceStringAsMarkdown(`Not Found`),
  descriptionMeta: () => () => `Not Found`,
  photoPage: homepage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<unknown, t.OutputOf<t.UnknownC>>;
