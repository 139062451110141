import { O, pipe, RA, type RNEA } from "@scripts/fp-ts";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { AnchorButton } from "@scripts/react/components/Anchor";
import { Grid, GridCol } from "@scripts/react/components/layout/Grid";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { type AboutPageData } from "@scripts/routes/routing/ssr/issuersites";

import { NewsCard } from "../../../components/NewsCard";
import { useIssuerSitesSelector } from "../../../state/store";

type NewsSectionProps = {
  news: RNEA.ReadonlyNonEmptyArray<AboutPageData["news"][number]>;
  title: string;
  sectionId: string;
};

export const NewsSection = (props: NewsSectionProps) => {
  const issuer = useIssuerSitesSelector("issuer");
  return (
    <AccentDividerSection title={O.some(props.title)} sectionId={props.sectionId}>
      <Grid klasses={"card-grid"} attrs={O.none}>
        {pipe(props.news, RA.takeLeft(3), RA.map(_ =>
          <GridCol cols={[".c-24"]} klasses={""} key={_.data.id} >
            <NewsCard news={_} />
          </GridCol>
        ))}
      </Grid>
      <AnchorButton
        externalLinkLocation="none"
        target="_self"
        route={{
          title: `View ${props.title} & Events`,
          route: SR.issuersitesAboutControllerNewsEvents({
            issuerId: issuer.id,
            issuerSlug: issuer.slug,
          }),
        }}
        variant="primary"
        klasses={"mt-2"}
      />
    </AccentDividerSection >
  );
};
