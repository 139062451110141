import type { Markdown as MarkdownType } from "@scripts/codecs/markdown";
import { markdownToHtml } from "@scripts/syntax/markdown";

import { useConfig } from "../context/Config";
import { type KlassProp, klassPropO } from "../util/classnames";
import type { WithHTMLAttrs } from "../util/dom";
import { Html } from "./Html";

export type MarkdownProps = Omit<WithHTMLAttrs<unknown>, "content"> & {
  content: MarkdownType;
  klasses?: KlassProp;
};

export const Markdown = (props: MarkdownProps) => {
  const config = useConfig();

  return <Html
    {...props}
    content={markdownToHtml(config)(props.content)}
    {...klassPropO("md-to-html react")(props.klasses)}
  />;
};
