import type { MarkdownTag } from "@scripts/codecs/markdown";
import { O } from "@scripts/fp-ts";
import type { Media } from "@scripts/generated/models/media";
import type { Officer } from "@scripts/generated/models/officer";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { phoneWithExtension } from "@scripts/syntax/phoneNumber";

import { klass } from "../util/classnames";
import { AnchorMailTo } from "./Anchor";
import { mapOrEmpty } from "./Empty";
import { Image } from "./Image";
import { AccentDividerSection } from "./layout/Section";
import { Markdown } from "./Markdown";

export const makeOfficerJumplink = (officer: WithStatusU<Officer>) => `officer-${officer.data.id}`;
export const getOfficerFullName = (officer: Officer) => `${officer.firstName} ${officer.lastName}`;

export const OfficerInfo = (props: {
  title: string;
  titleBold?: true;
  phoneWithExtension: O.Option<string>;
  email: O.Option<string>;
}) => <div {...klass("mb-1")}>
    {props.titleBold ? <h5 {...klass("mb-0")}>{props.title}</h5> : <div>{props.title}</div>}
    {mapOrEmpty((num: string) => <div>{num}</div>)(props.phoneWithExtension)}
    {mapOrEmpty((emailAddress: string) => <AnchorMailTo email={emailAddress} />)(props.email)}
  </div>;

const OfficerImage = (props: {
  media: O.Option<Media>;
  fullName: string;
}) => mapOrEmpty((m: Media) =>
  <Image alt={`Photo of ${props.fullName}`}
    src={m.uploadResponse.uri}
    klasses={["officer-card-image", "float-right", "w-40", "w-md-35", "pl-1", "mb-1"]}
  />
)(props.media);

export const OfficerSection = (props: { officer: WithStatusU<Officer> }) => {
  const {
    title,
    email,
    phoneNumber,
    phoneExtension,
    blurb,
    media,
  } = props.officer.data.record;

  const fullName = getOfficerFullName(props.officer.data.record);

  return <AccentDividerSection
    title={O.none}
    klasses={["floated-content"]}
    sectionId={makeOfficerJumplink(props.officer)}
  >
    <h2 {...klass("d-md-none")}>{fullName}</h2>
    <div>
      <OfficerImage media={media} fullName={fullName} />
      <h2 {...klass("title", "d-none", "d-md-block")}>{fullName}</h2>
      <OfficerInfo
        titleBold
        title={title}
        phoneWithExtension={phoneWithExtension(phoneNumber, phoneExtension)}
        email={email}
      />
      {mapOrEmpty((b: MarkdownTag) => <Markdown content={b} />)(blurb)}
    </div>
  </AccentDividerSection >;
};

export const OfficerModalSection = (props: {
  officer: WithStatusU<Officer>;
}) => {
  const {
    title,
    email,
    phoneNumber,
    phoneExtension,
    blurb,
    media,
  } = props.officer.data.record;

  const fullName = getOfficerFullName(props.officer.data.record);

  return <div>
    <OfficerImage media={media} fullName={fullName} />
    <h2 {...klass("title")}>{fullName}</h2>
    <OfficerInfo
      titleBold
      title={title}
      phoneWithExtension={phoneWithExtension(phoneNumber, phoneExtension)}
      email={email}
    />
    {mapOrEmpty((b: MarkdownTag) => <Markdown content={b} />)(blurb)}
  </div>;
};
