
import * as O from "fp-ts/lib/Option";

import { generateHash } from "@scripts/util/generateHash";

// S3 escapes all other characters and will double encode uri encoded strings. :|
export const encodeURIForS3 = (s: string): string =>
  s
    .replace(/\s*&\s*/g, " and ") // People love their ampersands so give 'em an and instead.
    .replace(/\s+/g, "-") // No whitespace
    .replace(/[^-~.\w]+/g, "-") // Only allowed chars
    .replace(/-+/, "-") // Cleanup extra ---
    .replace(/^-+/, "") // Trim start of -
    .replace(/-+$/, ""); // Trim end of -

export const generateKey = (hash: O.Option<string>) => (file: File, clientId: number): string => {
  const fname = file.name.split(".");
  const ext = fname.pop() ?? "";
  return `${clientId}/${encodeURIForS3(fname.join("."))}.${O.getOrElse(generateHash)(hash)}.${ext}`;
};
