import { E, O, pipe, RNEA } from "@scripts/fp-ts";
import type { FeaturedItemU } from "@scripts/generated/models/featuredItem";
import { Badge } from "@scripts/react/components/Badge";
import { FeaturedItemCard } from "@scripts/react/components/card/FeaturedItemCard";
import { Grid, GridCol } from "@scripts/react/components/layout/Grid";
import { useConfig } from "@scripts/react/context/Config";
import { klass, type KlassProp, klassPropO } from "@scripts/react/util/classnames";
import { urlInterface } from "@scripts/routes/urlInterface";
import { toFeaturedItemDetails } from "@scripts/syntax/featuredItems";

import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { useIssuerSitesSelector } from "../../state/store";

const defaultBadgeProps = {
  color: "accent-2-700",
  variant: "tall",
} as const;

const FeaturedItemsGrid = (props: {
  items: RNEA.ReadonlyNonEmptyArrayMaxN<FeaturedItemU, 4>;
  klass?: KlassProp;
  variant: "xs" | "md";
}) => {
  const config = useConfig();
  const issuer = useIssuerSitesSelector("issuer");

  return <Grid
    attrs={O.some(props.variant === "xs" ? ".grid-sa-15" : ".grid-sa-2")}
    klasses={klassPropO("")(props.klass).className}
  >{props.items.map(_ => {
    const fi = toFeaturedItemDetails(config, issuer, _);

    return <GridCol key={`${_._tag}-${fi.itemId}`} cols={[".c-24", ".c-md-12", ".c-xl-6"]} klasses={O.none}>
      <FeaturedItemCard
        klass={"h-100"}
        action={O.some(E.left({
          route: urlInterface("GET", fi.url),
          title: `View ${fi.routeTitleName}`,
        }))}
        badge={<>
          <Badge
            {...defaultBadgeProps}
            icon={O.some(fi.badgeIcon)}
            text={fi.badgeText}
          />
          <IssuerSitesLeafIcon klass="ml-025" taggedContent={fi.taggedContent} />
        </>}
        date={fi.date}
      >
        <div {...klass("font-sans-normal-500")}>{fi.header}</div>
      </FeaturedItemCard>
    </GridCol>;
  })}
  </Grid>;
};

export const FeaturedItems = (props: { featuredItems: RNEA.ReadonlyNonEmptyArray<FeaturedItemU> }) =>
  <section {...klass("mb-0", "pt-0")} id="anchor-featured-items container subtle no-header">{
    pipe(
      props.featuredItems,
      RNEA.firstN(4),
      firstFour =>
        <div {...klass("featured-items-container")}>
          <FeaturedItemsGrid items={firstFour} klass={"d-md-none"} variant="xs" />
          <FeaturedItemsGrid items={firstFour} klass={"d-none-until-md"} variant="md" />
        </div>)
  }</section>;
