import { type PropsWithChildren } from "react";

import { pipe, RA, RNEA } from "@scripts/fp-ts";
import type { LinkableU } from "@scripts/generated/models/linkable";
import { Anchor } from "@scripts/react/components/Anchor";
import { useConfig } from "@scripts/react/context/Config";
import { klass } from "@scripts/react/util/classnames";
import { urlInterface } from "@scripts/routes/urlInterface";

import { useIssuerSitesSelector } from "../../state/store";

const SecondaryAnchor = (props: PropsWithChildren<{
  text: string;
  url: string;
}>) => <>
    <h5>
      <Anchor
        externalLinkLocation="none"
        route={{
          title: props.text,
          route: urlInterface("GET", props.url),
        }}
        target="_blank"
      />
    </h5>
    {props.children}
  </>;

const SecondaryLinks = (props: { links: ReadonlyArray<LinkableU> }) => {
  const config = useConfig();

  return pipe(
    props.links,
    RA.map(_ => {
      switch (_._tag) {
        case "DownloadLink":
          return <SecondaryAnchor
            key={`${_._tag}-${_.mediaId}`}
            text={_.displayName}
            url={_.downloadUrl}
          />;
        case "ExternalLink":
          return <SecondaryAnchor
            key={`${_._tag}-${_.displayName}`}
            text={_.displayName}
            url={_.url}
          />;
        case "PageLink":
          return <SecondaryAnchor
            key={`${_._tag}-${_.displayName}`}
            text={_.displayName}
            url={_.url}
          >
            <p>{_.description}</p>
          </SecondaryAnchor>;
      }

      return config.exhaustive(_);
    })
  );
};

export const QuickLinks = () => {
  const navLinks = useIssuerSitesSelector("navLinks");

  return <div id="anchor-quick-links" {...klass("accent-border-top container")}>
    <h2>Quick Links</h2>
    <div {...klass("quick-links")}>
      <div {...klass("link-container")}>
        {pipe(
          navLinks,
          RNEA.map(([section, links]) => {
            return <div key={section.displayName} {...klass("links")}>
              <div {...klass("separator")}>
                <h3>
                  <Anchor
                    route={{
                      title: section.displayName,
                      route: urlInterface("GET", section.url),
                    }}
                    target="_self"
                  />
                </h3>
                <p>{section.description}</p>
                <SecondaryLinks links={links} />
              </div>
            </div>;
          })
        )}
      </div>
    </div>
  </div>;
};
