import * as t from "io-ts";
import { readonlyNonEmptyArray } from "io-ts-types";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

import { DisclaimerTypeCU } from "@scripts/generated/domaintables/disclaimerTypes";
import { PageCU, PhotoEditableCU } from "@scripts/generated/domaintables/pages";
import { bankC } from "@scripts/generated/models/bank";
import { clientFeatureFlagsC } from "@scripts/generated/models/clientFeatureFlags";
import { disclaimerC } from "@scripts/generated/models/disclaimer";
import { documentTypeC } from "@scripts/generated/models/document";
import { flashC } from "@scripts/generated/models/flash";
import { headerPhotoC } from "@scripts/generated/models/headerPhoto";
import { hrefC } from "@scripts/generated/models/href";
import { issuerC } from "@scripts/generated/models/issuer";
import { issuerPreferencesC } from "@scripts/generated/models/issuerPreferences";
import { LinkableCU, pageLinkC } from "@scripts/generated/models/linkable";
import { officerC } from "@scripts/generated/models/officer";
import { pageConfigC } from "@scripts/generated/models/pageConfig";
import { relatedIssuerWithIssuerC } from "@scripts/generated/models/relatedIssuer";
import { WithStatusCU } from "@scripts/generated/models/threadThrough";
import { userWithRolesC } from "@scripts/generated/models/user";
import type { NotificationStore } from "@scripts/react/state/notifications";
import { emptyNotificationStore } from "@scripts/react/state/notifications";

export const issuerSitesInitialStateC = t.type({
  user: optionFromNullable(userWithRolesC),
  userSubscribed: t.boolean,
  issuer: issuerC,
  iffs: clientFeatureFlagsC,
  disclaimers: t.readonlyArray(WithStatusCU(disclaimerC)),
  docTypes: t.readonlyArray(documentTypeC),
  exitDisclaimer: optionFromNullable(WithStatusCU(disclaimerC)),
  dueDisclaimer: t.readonlyArray(t.tuple([t.string, WithStatusCU(disclaimerC)])),
  footerDisclaimerTypes: t.readonlyArray(DisclaimerTypeCU),
  footerLinks: t.readonlyArray(WithStatusCU(hrefC)),
  prefs: optionFromNullable(issuerPreferencesC),
  navLinks: readonlyNonEmptyArray(t.tuple([pageLinkC, t.readonlyArray(LinkableCU)])),
  headerPhotos: t.readonlyArray(t.tuple([PhotoEditableCU, WithStatusCU(headerPhotoC)])),
  bank: optionFromNullable(bankC),
  relatedIssuers: t.readonlyArray(WithStatusCU(relatedIssuerWithIssuerC)),
  flash: t.readonlyArray(flashC),
  pages: t.readonlyArray(pageConfigC(PageCU)),
  bankFeatureFlags: clientFeatureFlagsC,
  primaryOfficer: optionFromNullable(WithStatusCU(officerC)),
});

export type IssuerSitesInitialState = t.TypeOf<typeof issuerSitesInitialStateC>;

export type IssuerSitesState = IssuerSitesInitialState & {
  notifications: NotificationStore;
};

export const emptyState = (initialState: IssuerSitesInitialState): IssuerSitesState => ({
  ...initialState,
  notifications: emptyNotificationStore,
});
