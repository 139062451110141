import type { PropsWithChildren } from "react";

import { Tooltip } from "@scripts/react/components/Tooltip";

export const NoSkipTooltip = (props: PropsWithChildren<{ showTooltip: boolean }>) =>
  props.showTooltip
    ? <Tooltip
      delay={[0, 0]}
      controlOptions={{
        type: "TooltipControlUser",
        visible: true,
      }}
      description={{
        type: "DescriptionContentElement",
        element: <>
          Skipping forward has been disabled.
          <br className="d-block mt-1" />
          However, you may skip to any part that you have already listened to or you may increase playback speed by using the menu on the bottom right.
        </>,
      }}
    >{props.children}</Tooltip>
    : props.children;
