import { type ReactElement, useState } from "react";

import type { SVGString } from "*.svg";

import { formatS3CdnUrl } from "@scripts/bondlink";
import { b, constVoid, O, pipe, RA } from "@scripts/fp-ts";
import { Button, type ButtonProps } from "@scripts/react/components/Button";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Svg } from "@scripts/react/components/Svg";
import { Tooltip } from "@scripts/react/components/Tooltip";
import { useConfig } from "@scripts/react/context/Config";
import type { AudioPlayerRefs } from "@scripts/react/hooks/useAudioPlayer";
import { type KlassProp, klassProp } from "@scripts/react/util/classnames";
import type { RoadshowPlayerData } from "@scripts/routes/routing/ssr/issuersites";
import { formatPlayerTime } from "@scripts/syntax/roadshow";

import chevronNextIcon from "@svgs/chevron-next.svg";
import chevronPreviousIcon from "@svgs/chevron-previous.svg";
import pauseIcon from "@svgs/pause.svg";
import playerIcon from "@svgs/player.svg";
import skipNextIcon from "@svgs/skip-next.svg";
import skipPreviousIcon from "@svgs/skip-previous.svg";
import soundOnIcon from "@svgs/sound-on.svg";

import { NoSkipTooltip } from "./NoSkipTooltip";
import type { SlidesState } from "./roadshowSyntax";

const IconButton = (props: Pick<ButtonProps, "onClick"> & {
  svg: SVGString;
  klass: KlassProp;
  disabled: boolean;
  ariaLabel: string;
}) =>
  <Button
    {...klassProp("roadshow-btn-white b-0 mt-0")(props.klass)}
    variant="primary"
    aria-label={props.ariaLabel}
    disabled={props.disabled}
    onClick={props.onClick}
  ><Svg src={props.svg} /></Button>;

const SeekOnClick = (props: {
  seekToTime: (t: number) => O.Option<number>;
  noSkipTooltip: boolean;
  setNoSkipTooltip: (b: boolean) => void;
  children: (f: (time: number) => void) => ReactElement;
}) =>
  <NoSkipTooltip showTooltip={props.noSkipTooltip}>
    {props.children(time => pipe(
      props.seekToTime(time),
      O.fold(
        () => {
          props.setNoSkipTooltip(true);
          window.setTimeout(() => props.setNoSkipTooltip(false), 5000);
        },
        constVoid,
      )
    ))}
  </NoSkipTooltip>;

export const Slide = (props: AudioPlayerRefs & SlidesState & { data: RoadshowPlayerData }) => {
  const config = useConfig();
  const [progressNoSkipTooltip, setProgressNoSkipTooltip] = useState(false);
  const [prevNoSkipTooltip, setPrevNoSkipTooltip] = useState(false);
  const [nextNoSkipTooltip, setNextNoSkipTooltip] = useState(false);
  const speeds = [0.7, 0.9, 1.0, 1.1, 1.2, 1.5, 2.0];
  const progress = pipe(props.duration, O.filter(_ => _ > 0), O.fold(() => 0, d => (props.seekTime / d) * 100));

  return <div className="slide-container container">
    <div className="main-content">
      <div className="main-slide-container">
        <div className="main-slide">
          {pipe(
            props.activeSlide,
            O.map(_ => _[0]),
            O.alt(() => RA.head(props.data.show.record.data.slides)),
            mapOrEmpty(slide => <img
              className="main-slide-image"
              src={formatS3CdnUrl(config)(slide.record.media.uploadResponse.uri)}
              alt={O.toUndefined(slide.record.media.altText)}
            />)
          )}
        </div>
      </div>
      <div className="playback-controls text-center inverted">
        {pipe(props.data.show.record.data.show.audio, b.fold(
          () => <>
            <span className="no-audio">Slide Show Presentation Only (No Audio)</span>
            <div>
              <div className="progress-buttons justify-content-center">
                <div className="d-flex align-items-center justify-content-center">
                  <SeekOnClick
                    seekToTime={props.seekToTime}
                    noSkipTooltip={prevNoSkipTooltip}
                    setNoSkipTooltip={setPrevNoSkipTooltip}
                  >
                    {seek => <IconButton
                      svg={chevronPreviousIcon}
                      klass="player-previous"
                      onClick={() => pipe(props.prevSlide, O.map(s => seek(s.record.startTime)))}
                      disabled={O.isNone(props.prevSlide)}
                      ariaLabel="Previous"
                    />}
                  </SeekOnClick>
                  {" "}
                  <div className="d-flex flex-direction-row">
                    <span id="slide-number">{pipe(props.activeSlide, O.fold(() => 1, ([, i]) => i + 1))}</span>
                    &nbsp;of&nbsp;
                    {props.data.show.record.data.slides.length}
                  </div>
                  {" "}
                  <SeekOnClick
                    seekToTime={props.seekToTime}
                    noSkipTooltip={nextNoSkipTooltip}
                    setNoSkipTooltip={setNextNoSkipTooltip}
                  >
                    {seek => <IconButton
                      svg={chevronNextIcon}
                      klass="player-next"
                      onClick={() => pipe(props.nextSlide, O.map(s => seek(s.record.startTime)))}
                      disabled={O.isNone(props.nextSlide)}
                      ariaLabel="Next"
                    />}
                  </SeekOnClick>
                </div>
              </div>
            </div>
          </>,
          () => <div>
            <div className="progress-container">
              <Button
                variant="primary"
                id="play-pause"
                className="roadshow-btn-white player-button"
                onClick={() => props.setPlaying(!props.playing)}
                aria-label={props.playing ? "Pause" : "Play"}
              ><Svg src={props.playing ? pauseIcon : playerIcon} /></Button>
              <div className="progress">
                <SeekOnClick
                  seekToTime={props.seekToTime}
                  noSkipTooltip={progressNoSkipTooltip}
                  setNoSkipTooltip={setProgressNoSkipTooltip}
                >
                  {seek => <div
                    className="progress-bar-container"
                    onClick={e => pipe(props.duration, O.map(d => {
                      const x = e.pageX - e.currentTarget.getBoundingClientRect().left;
                      seek(x / e.currentTarget.offsetWidth * d);
                    }))}
                  >
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${progress}%` }}
                      aria-valuenow={progress}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>}
                </SeekOnClick>
                <div className="progress-buttons">
                  <div className="d-flex align-items-center">
                    <SeekOnClick
                      seekToTime={props.seekToTime}
                      noSkipTooltip={prevNoSkipTooltip}
                      setNoSkipTooltip={setPrevNoSkipTooltip}
                    >
                      {seek => <IconButton
                        svg={skipPreviousIcon}
                        klass="player-previous"
                        onClick={() => pipe(props.prevSlide, O.map(s => seek(s.record.startTime)))}
                        disabled={O.isNone(props.prevSlide)}
                        ariaLabel="Previous"
                      />}
                    </SeekOnClick>
                    <SeekOnClick
                      seekToTime={props.seekToTime}
                      noSkipTooltip={nextNoSkipTooltip}
                      setNoSkipTooltip={setNextNoSkipTooltip}
                    >
                      {seek => <IconButton
                        svg={skipNextIcon}
                        klass="player-next"
                        onClick={() => pipe(props.nextSlide, O.map(s => seek(s.record.startTime)))}
                        disabled={O.isNone(props.nextSlide)}
                        ariaLabel="Next"
                      />}
                    </SeekOnClick>
                    <div className="time ml-05">
                      {formatPlayerTime(props.seekTime)}
                      {" / "}
                      {formatPlayerTime(O.getOrElse(() => props.data.show.record.data.show.duration)(props.duration))}</div>
                  </div>
                  <div>
                    <span className="d-flex align-items-center">
                      <span className="speed-container">
                        <Tooltip
                          delay="default"
                          appendTo="parent"
                          addClassToTargetNode="d-block"
                          description={{
                            type: "DescriptionContentElement",
                            element: <div className="speed-list">
                              {pipe(speeds, RA.map(speed =>
                                <div
                                  key={speed}
                                  onClick={() => props.setSpeed(speed)}
                                >{speed}x</div>))}
                            </div>,
                          }}
                        >
                          <span className="d-none d-md-inline">Speed:{" "}</span>{`${props.speed}x`}
                        </Tooltip>
                      </span>
                      <span className="volume-container d-inline-block">
                        <Tooltip
                          delay="default"
                          appendTo="parent"
                          description={{
                            type: "DescriptionContentElement",
                            element: <input
                              type="range"
                              min="0"
                              max="1.0"
                              step="0.1"
                              value={props.volume}
                              onChange={e => pipe(
                                O.tryCatch(() => parseFloat(e.currentTarget.value)),
                                O.filter(n => !isNaN(n)),
                                O.map(props.setVolume),
                              )}
                            />,
                          }}
                        >
                          <Button
                            variant="primary"
                            className="roadshow-btn-white b-0 player-volume"
                            onClick={constVoid}
                          >
                            <span aria-label="Sound on"><Svg src={soundOnIcon} /></span>
                          </Button>
                        </Tooltip>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>,
        ))}
      </div>
    </div>
  </div>;
};
