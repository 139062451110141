import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import type { TimeZoneU } from "@scripts/generated/domaintables/timeZones";
import type { DateWithOptionalTime } from "@scripts/generated/models/rfpBase";

import { type DateFormatter, toLocalDateTime } from "./date/joda";
import { formatTimeWithZone } from "./timeWithZone";

export const dateWithOptionalTimeToString =
  (dateFormatter: DateFormatter) =>
      (dwot: DateWithOptionalTime<TimeZoneU>): string => pipe(
        dwot.time,
        O.map(formatTimeWithZone),
        O.fold(
          () => dateFormatter(dwot.date),
          twz => dateFormatter(dwot.date) + " at " + twz,
        )
      );
export const dateWithOptionalTimeToLDT = (d: DateWithOptionalTime<TimeZoneU>) => pipe(
  d.time,
  O.fold(
    () => toLocalDateTime(d.date),
    t => d.date.atTime(t.time.hour, t.time.minute)
  )
);
