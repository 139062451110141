import Skeleton from "react-loading-skeleton";
import { identity, pipe } from "fp-ts/lib/function";

import { TE } from "@scripts/fp-ts";

import { useConfig } from "../context/Config";
import { DataLoader } from "./DataLoader";

const ViewerSkeleton = () => <Skeleton style={{ height: "65vh" }} />;

type ViewerProps = {
  documentId: string;
  documentUrl: string;
  workerSrc: string;
};

type ViewerImport = {
  init: (workerSrc: string) => void;
  Viewer: React.FC<ViewerProps>;
};

export type FileViewerProps = {
  url: string;
};

export const FileViewer = (p: FileViewerProps) => {
  const config = useConfig();

  const ViewerTE = (onError: (err: unknown) => void, onSuccess: (resp: ViewerImport) => void) => pipe(
    TE.tryCatch(() =>
      import(
        /* webpackChunkName: "document-viewer" */
        /* webpackMode: "lazy-once" */
        "document-viewer-ts"
      ),
      identity
    ),
    TE.biTap(
      e => {
        config.log.fatal("Failed to lazy load document-viewer", e);
        onError(e);
      },
      onSuccess
    ),
  );

  return (
    <DataLoader
      taskEither={ViewerTE}
      loading={ViewerSkeleton}
      errorType="inline"
      show403={false}
    >
      {dv => <dv.Viewer
        documentId={p.url}
        documentUrl={p.url}
        workerSrc={`${config.currentBaseUrl}/assets/scripts/pdfjs/pdf.worker.min.mjs`}
      />}
    </DataLoader>
  );
};
