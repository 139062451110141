import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import type { IssuerAndProgramRatingsByAgency } from "@scripts/generated/models/rating";
import * as ISR from "@scripts/generated/routers/sitesRouter";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { findIssuerRatingsPage, issuerRatingPageToTitle } from "@scripts/react/components/offering-pages/Ratings";
import { type DocumentDownloadRoute, RatingBlockSection } from "@scripts/react/components/ratings/RatingBlock";
import { elementId, type JumpLink } from "@scripts/react/components/SidebarLinks";
import { klass } from "@scripts/react/util/classnames";
import { ratings } from "@scripts/routes/routing/ssr/issuersites";
import { issuerRatingsJumplinkId, makeProgramRatingJumplinkId } from "@scripts/routes/routing/ssr/issuersitesJumplinkIds";

import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { makeJumplinkO } from "../../components/sidebar/sidebarLinksSyntax";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

export const RatingsPage = (props: IssuerAndProgramRatingsByAgency) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");

  const page = ratings({ issuerSlug: issuer.slug, issuerId: issuer.id });
  const description = page.description(pages, iffs, issuer);

  const issuerRatingsSectionTitle = pipe(
    pages,
    findIssuerRatingsPage,
    issuerRatingPageToTitle
  );

  const issuerRatingsDataO = pipe(
    props.issuerRatings,
    RNEA.fromReadonlyArray
  );

  const jumpLinks = pipe(
    [makeJumplinkO(issuerRatingsSectionTitle, issuerRatingsJumplinkId, O.isSome(issuerRatingsDataO))],
    RA.compact,
    RA.concat(props.programRatings.map(([program]): JumpLink => ({
      link: elementId(makeProgramRatingJumplinkId(program.id)),
      anchorContent: program.record.name,
    }))),
  );
  const documentDownloadRoute: DocumentDownloadRoute = (id) => ISR.issuersitesReportsControllerDownloadRedirect({ mediaId: id, issuerId: issuer.id, issuerSlug: issuer.slug });
  return <DirectSitesPageDescriptionLayout
    description={description}
    sidebarContent={
      <>
        <SidebarLinksSites
          headerLinkCustomAnchorContent={O.none}
          jumpLinks={jumpLinks}
          routeMeta={page}
        />
        <GetAlertsActionSection containerKlass={"d-none-until-md"} />
      </>
    }
  >
    <div {...klass("accent-border-top", "pt-0")}>
      {(O.isNone(issuerRatingsDataO) && RA.isEmpty(props.programRatings)) ? (
        <div {...klass("mt-1")}>There are currently no issuer or program-level ratings available.</div>
      ) : (
        <>
          {pipe(
            issuerRatingsDataO,
            mapOrEmpty((rs) =>
              <AccentDividerSection
                title={O.some(issuerRatingsSectionTitle)}
                sectionId={issuerRatingsJumplinkId}
              >
                <RatingBlockSection
                  ratings={rs}
                  name={O.none}
                  modalName={issuerRatingsSectionTitle}
                  issuer={issuer}
                  documentDownloadRoute={documentDownloadRoute}
                />
              </AccentDividerSection>
            )
          )}
            {props.programRatings.map(([program, rs]) =>
              <AccentDividerSection
                key={program.id}
                title={O.some(program.record.name)}
                sectionId={makeProgramRatingJumplinkId(program.id)}
              >
                <RatingBlockSection
                  ratings={rs}
                  name={O.none}
                  modalName={program.record.name}
                  issuer={issuer}
                  documentDownloadRoute={documentDownloadRoute}
                />
              </AccentDividerSection>
            )}
        </>
      )}
    </div>
  </DirectSitesPageDescriptionLayout>;
};
