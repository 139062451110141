import type { Action } from "redux";

import type { BLConfigWithLog } from "@scripts/bondlink";
import type { Disclaimer } from "@scripts/generated/models/disclaimer";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";

import type { IssuerSitesActions } from "./store";

export const dueDisclaimerRemove = (id: number): Action<"DUE_DISCLAIMER_REMOVE"> & { id: number } => ({
  type: "DUE_DISCLAIMER_REMOVE",
  id,
});

export type DueDisclaimerRemove = ReturnType<typeof dueDisclaimerRemove>;

export type DueDisclaimerAction = DueDisclaimerRemove;

export const dueDisclaimerReducer = (config: BLConfigWithLog) =>
  (state: ReadonlyArray<[string, WithStatusU<Disclaimer>]>, action: DueDisclaimerAction) => {
    switch (action.type) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      case "DUE_DISCLAIMER_REMOVE":
        return state.filter(dd => dd[1].data.id !== action.id);
    }
    return config.exhaustive(action.type);
  };

export const isDueDisclaimerAction = (action: Action<string> | IssuerSitesActions): action is DueDisclaimerAction => {
    return action.type.startsWith("DUE_DISCLAIMER");
  };
