import * as t from "io-ts";

import { _apiFetchWithCredRespUnsafe } from "@scripts/api/methods";
import type { BLConfigWithLog } from "@scripts/bondlink";
import * as V2Router from "@scripts/generated/routers/v2Router";

export const createS3Upload =
  (config: BLConfigWithLog) =>
    _apiFetchWithCredRespUnsafe(config)(V2Router.baseS3CreateMultipartUpload());

export const presignS3UploadParts =
  (config: BLConfigWithLog) =>
    _apiFetchWithCredRespUnsafe(config)(V2Router.baseS3PresignMultipartUploadParts());

export const completeS3Upload =
  (config: BLConfigWithLog) =>
    _apiFetchWithCredRespUnsafe(config)(V2Router.baseS3CompleteMultipartUpload());

export const listS3UploadParts =
  (config: BLConfigWithLog) =>
    _apiFetchWithCredRespUnsafe(config)(V2Router.baseS3ListMultipartUploadParts());

export const abortS3MultipartUpload =
  (config: BLConfigWithLog) =>
    _apiFetchWithCredRespUnsafe(config)(V2Router.baseS3AbortMultipartUpload());

export const postS3UploadSuccess =
  (config: BLConfigWithLog) =>
    _apiFetchWithCredRespUnsafe(config)(V2Router.baseS3Success());

/**
 * Values we require from Uppy in order to post s3 success data
 */
export const uploadedUppyFileResponse = t.type({
  name: t.string,
  response: t.type({
    body: t.type({
      bucket: t.string,
      key: t.string,
      location: t.string,
    }),
  }),
  size: t.number,
});

export type UploadedUppyFileResponse = t.TypeOf<typeof uploadedUppyFileResponse>;
