// This file was generated by postcss-transpiler. Do not edit by hand.

export const subheaderStandard = {
  ".subheader-standard": {
    css: ".subheader-standard",
    html: "subheader-standard",
    attrs: {
      ".draft-subheader": {
        css: ".draft-subheader",
        html: "draft-subheader"
      },
      ".subheader-h3": {
        css: ".subheader-h3",
        html: "subheader-h3"
      }
    },
    ".btn": {
      css: ".btn",
      html: "btn"
    },
    ".masthead": {
      css: ".masthead",
      html: "masthead",
      attrs: {
        ".deal-masthead": {
          css: ".deal-masthead",
          html: "deal-masthead"
        }
      },
      "h1": {
        css: "h1",
        html: "h1",
        attrs: {
          ".long-name": {
            css: ".long-name",
            html: "long-name"
          }
        }
      }
    },
    "h3": {
      css: "h3",
      html: "h3"
    },
    ".btn-link": {
      css: ".btn-link",
      html: "btn-link"
    },
    ".bl-react-select-wrapper": {
      css: ".bl-react-select-wrapper",
      html: "bl-react-select-wrapper",
      attrs: {
        ".button-select": {
          css: ".button-select",
          html: "button-select"
        }
      }
    },
    ".icon-circle-title": {
      css: ".icon-circle-title",
      html: "icon-circle-title",
      attrs: {
        ".custom-indicator-description": {
          css: ".custom-indicator-description",
          html: "custom-indicator-description"
        },
        ".small": {
          css: ".small",
          html: "small"
        },
        ".warning": {
          css: ".warning",
          html: "warning"
        },
        ".danger": {
          css: ".danger",
          html: "danger"
        },
        ".success": {
          css: ".success",
          html: "success"
        },
        ".disabled": {
          css: ".disabled",
          html: "disabled"
        }
      },
      ".data-point-row": {
        css: ".data-point-row",
        html: "data-point-row",
        "dd": {
          css: "dd",
          html: "dd"
        }
      },
      ".data-vertical": {
        css: ".data-vertical",
        html: "data-vertical",
        "dd": {
          css: "dd",
          html: "dd"
        }
      },
      "p": {
        css: "p",
        html: "p"
      },
      ".masthead": {
        css: ".masthead",
        html: "masthead",
        attrs: {
          ".deal-masthead": {
            css: ".deal-masthead",
            html: "deal-masthead"
          }
        },
        "h1": {
          css: "h1",
          html: "h1",
          attrs: {
            ".long-name": {
              css: ".long-name",
              html: "long-name"
            }
          }
        }
      },
      "h3": {
        css: "h3",
        html: "h3"
      }
    },
    ".p8n-control-simple": {
      css: ".p8n-control-simple",
      html: "p8n-control-simple",
      ".icon-circle-title": {
        css: ".icon-circle-title",
        html: "icon-circle-title",
        attrs: {
          ".pages": {
            css: ".pages",
            html: "pages"
          }
        },
        ".data-point-row": {
          css: ".data-point-row",
          html: "data-point-row",
          "dd": {
            css: "dd",
            html: "dd"
          }
        },
        ".data-vertical": {
          css: ".data-vertical",
          html: "data-vertical",
          "dd": {
            css: "dd",
            html: "dd"
          }
        },
        "p": {
          css: "p",
          html: "p"
        }
      }
    },
    ".roadshow-container": {
      css: ".roadshow-container",
      html: "roadshow-container",
      ".icon-circle-title": {
        css: ".icon-circle-title",
        html: "icon-circle-title",
        attrs: {
          ".slide-secondary-text": {
            css: ".slide-secondary-text",
            html: "slide-secondary-text"
          }
        },
        ".data-point-row": {
          css: ".data-point-row",
          html: "data-point-row",
          "dd": {
            css: "dd",
            html: "dd"
          }
        },
        ".data-vertical": {
          css: ".data-vertical",
          html: "data-vertical",
          "dd": {
            css: "dd",
            html: "dd"
          }
        },
        "p": {
          css: "p",
          html: "p"
        }
      }
    },
    ".icon-title-link": {
      css: ".icon-title-link",
      html: "icon-title-link"
    },
    ".title-link": {
      css: ".title-link",
      html: "title-link",
      "h5": {
        css: "h5",
        html: "h5"
      }
    },
    "h2": {
      css: "h2",
      html: "h2"
    },
    ".card-header": {
      css: ".card-header",
      html: "card-header",
      ".title-container": {
        css: ".title-container",
        html: "title-container",
        ".masthead": {
          css: ".masthead",
          html: "masthead",
          attrs: {
            ".deal-masthead": {
              css: ".deal-masthead",
              html: "deal-masthead"
            }
          },
          "h1": {
            css: "h1",
            html: "h1",
            attrs: {
              ".long-name": {
                css: ".long-name",
                html: "long-name"
              }
            }
          }
        },
        "h3": {
          css: "h3",
          html: "h3"
        },
        "h4": {
          css: "h4",
          html: "h4",
          attrs: {
            ".warning": {
              css: ".warning",
              html: "warning"
            },
            ".danger": {
              css: ".danger",
              html: "danger"
            },
            ".success": {
              css: ".success",
              html: "success"
            },
            ".disabled": {
              css: ".disabled",
              html: "disabled"
            }
          }
        }
      }
    }
  },
  ".masthead": {
    css: ".masthead",
    html: "masthead",
    attrs: {
      ".deal-masthead": {
        css: ".deal-masthead",
        html: "deal-masthead"
      }
    },
    ".subheader-standard": {
      css: ".subheader-standard",
      html: "subheader-standard",
      attrs: {
        ".subheader-h3": {
          css: ".subheader-h3",
          html: "subheader-h3"
        }
      },
      "h1": {
        css: "h1",
        html: "h1",
        attrs: {
          ".long-name": {
            css: ".long-name",
            html: "long-name"
          }
        }
      },
      ".icon-circle-title": {
        css: ".icon-circle-title",
        html: "icon-circle-title",
        "h1": {
          css: "h1",
          html: "h1",
          attrs: {
            ".long-name": {
              css: ".long-name",
              html: "long-name"
            }
          }
        }
      },
      ".card-header": {
        css: ".card-header",
        html: "card-header",
        ".title-container": {
          css: ".title-container",
          html: "title-container",
          "h1": {
            css: "h1",
            html: "h1",
            attrs: {
              ".long-name": {
                css: ".long-name",
                html: "long-name"
              }
            }
          }
        }
      }
    },
    ".subheader-draft": {
      css: ".subheader-draft",
      html: "subheader-draft",
      "h1": {
        css: "h1",
        html: "h1",
        attrs: {
          ".long-name": {
            css: ".long-name",
            html: "long-name"
          }
        }
      }
    }
  },
  ".p8n-control-simple": {
    css: ".p8n-control-simple",
    html: "p8n-control-simple",
    ".subheader-standard": {
      css: ".subheader-standard",
      html: "subheader-standard",
      ".icon-circle-title": {
        css: ".icon-circle-title",
        html: "icon-circle-title",
        attrs: {
          ".pages": {
            css: ".pages",
            html: "pages"
          }
        },
        ".data-point-row": {
          css: ".data-point-row",
          html: "data-point-row",
          "dd": {
            css: "dd",
            html: "dd"
          }
        },
        ".data-vertical": {
          css: ".data-vertical",
          html: "data-vertical",
          "dd": {
            css: "dd",
            html: "dd"
          }
        },
        "p": {
          css: "p",
          html: "p"
        }
      }
    }
  },
  ".roadshow-container": {
    css: ".roadshow-container",
    html: "roadshow-container",
    ".subheader-standard": {
      css: ".subheader-standard",
      html: "subheader-standard",
      ".icon-circle-title": {
        css: ".icon-circle-title",
        html: "icon-circle-title",
        attrs: {
          ".slide-secondary-text": {
            css: ".slide-secondary-text",
            html: "slide-secondary-text"
          }
        },
        ".data-point-row": {
          css: ".data-point-row",
          html: "data-point-row",
          "dd": {
            css: "dd",
            html: "dd"
          }
        },
        ".data-vertical": {
          css: ".data-vertical",
          html: "data-vertical",
          "dd": {
            css: "dd",
            html: "dd"
          }
        },
        "p": {
          css: "p",
          html: "p"
        }
      }
    }
  },
  ".data-point-row": {
    css: ".data-point-row",
    html: "data-point-row",
    ".p8n-control-simple": {
      css: ".p8n-control-simple",
      html: "p8n-control-simple",
      ".subheader-standard": {
        css: ".subheader-standard",
        html: "subheader-standard",
        ".icon-circle-title": {
          css: ".icon-circle-title",
          html: "icon-circle-title",
          attrs: {
            ".pages": {
              css: ".pages",
              html: "pages"
            }
          },
          "dd": {
            css: "dd",
            html: "dd"
          }
        }
      }
    },
    ".roadshow-container": {
      css: ".roadshow-container",
      html: "roadshow-container",
      ".subheader-standard": {
        css: ".subheader-standard",
        html: "subheader-standard",
        ".icon-circle-title": {
          css: ".icon-circle-title",
          html: "icon-circle-title",
          attrs: {
            ".slide-secondary-text": {
              css: ".slide-secondary-text",
              html: "slide-secondary-text"
            }
          },
          "dd": {
            css: "dd",
            html: "dd"
          }
        }
      }
    },
    ".subheader-standard": {
      css: ".subheader-standard",
      html: "subheader-standard",
      ".icon-circle-title": {
        css: ".icon-circle-title",
        html: "icon-circle-title",
        attrs: {
          ".custom-indicator-description": {
            css: ".custom-indicator-description",
            html: "custom-indicator-description"
          },
          ".small": {
            css: ".small",
            html: "small"
          }
        },
        "dd": {
          css: "dd",
          html: "dd"
        }
      },
      ".p8n-control-simple": {
        css: ".p8n-control-simple",
        html: "p8n-control-simple",
        ".icon-circle-title": {
          css: ".icon-circle-title",
          html: "icon-circle-title",
          attrs: {
            ".pages": {
              css: ".pages",
              html: "pages"
            }
          },
          "dd": {
            css: "dd",
            html: "dd"
          }
        }
      },
      ".roadshow-container": {
        css: ".roadshow-container",
        html: "roadshow-container",
        ".icon-circle-title": {
          css: ".icon-circle-title",
          html: "icon-circle-title",
          attrs: {
            ".slide-secondary-text": {
              css: ".slide-secondary-text",
              html: "slide-secondary-text"
            }
          },
          "dd": {
            css: "dd",
            html: "dd"
          }
        }
      }
    },
    ".subheader-draft": {
      css: ".subheader-draft",
      html: "subheader-draft",
      attrs: {
        ".single-text": {
          css: ".single-text",
          html: "single-text"
        }
      },
      ".label-container": {
        css: ".label-container",
        html: "label-container",
        "dd": {
          css: "dd",
          html: "dd"
        }
      }
    }
  },
  ".data-vertical": {
    css: ".data-vertical",
    html: "data-vertical",
    ".p8n-control-simple": {
      css: ".p8n-control-simple",
      html: "p8n-control-simple",
      ".subheader-standard": {
        css: ".subheader-standard",
        html: "subheader-standard",
        ".icon-circle-title": {
          css: ".icon-circle-title",
          html: "icon-circle-title",
          attrs: {
            ".pages": {
              css: ".pages",
              html: "pages"
            }
          },
          "dd": {
            css: "dd",
            html: "dd"
          }
        }
      }
    },
    ".roadshow-container": {
      css: ".roadshow-container",
      html: "roadshow-container",
      ".subheader-standard": {
        css: ".subheader-standard",
        html: "subheader-standard",
        ".icon-circle-title": {
          css: ".icon-circle-title",
          html: "icon-circle-title",
          attrs: {
            ".slide-secondary-text": {
              css: ".slide-secondary-text",
              html: "slide-secondary-text"
            }
          },
          "dd": {
            css: "dd",
            html: "dd"
          }
        }
      }
    },
    ".subheader-standard": {
      css: ".subheader-standard",
      html: "subheader-standard",
      ".icon-circle-title": {
        css: ".icon-circle-title",
        html: "icon-circle-title",
        attrs: {
          ".custom-indicator-description": {
            css: ".custom-indicator-description",
            html: "custom-indicator-description"
          },
          ".small": {
            css: ".small",
            html: "small"
          }
        },
        "dd": {
          css: "dd",
          html: "dd"
        }
      },
      ".p8n-control-simple": {
        css: ".p8n-control-simple",
        html: "p8n-control-simple",
        ".icon-circle-title": {
          css: ".icon-circle-title",
          html: "icon-circle-title",
          attrs: {
            ".pages": {
              css: ".pages",
              html: "pages"
            }
          },
          "dd": {
            css: "dd",
            html: "dd"
          }
        }
      },
      ".roadshow-container": {
        css: ".roadshow-container",
        html: "roadshow-container",
        ".icon-circle-title": {
          css: ".icon-circle-title",
          html: "icon-circle-title",
          attrs: {
            ".slide-secondary-text": {
              css: ".slide-secondary-text",
              html: "slide-secondary-text"
            }
          },
          "dd": {
            css: "dd",
            html: "dd"
          }
        }
      }
    },
    ".subheader-draft": {
      css: ".subheader-draft",
      html: "subheader-draft",
      attrs: {
        ".single-text": {
          css: ".single-text",
          html: "single-text"
        }
      },
      ".label-container": {
        css: ".label-container",
        html: "label-container",
        "dd": {
          css: "dd",
          html: "dd"
        }
      }
    }
  },
  ".subheader-draft": {
    css: ".subheader-draft",
    html: "subheader-draft",
    attrs: {
      ".header": {
        css: ".header",
        html: "header"
      },
      ".single-text": {
        css: ".single-text",
        html: "single-text"
      }
    },
    "svg": {
      css: "svg",
      html: "svg"
    },
    ".label-container": {
      css: ".label-container",
      html: "label-container",
      ".data-point-row": {
        css: ".data-point-row",
        html: "data-point-row",
        "dd": {
          css: "dd",
          html: "dd"
        }
      },
      ".data-vertical": {
        css: ".data-vertical",
        html: "data-vertical",
        "dd": {
          css: "dd",
          html: "dd"
        }
      },
      "p": {
        css: "p",
        html: "p"
      }
    },
    ".masthead": {
      css: ".masthead",
      html: "masthead",
      attrs: {
        ".deal-masthead": {
          css: ".deal-masthead",
          html: "deal-masthead"
        }
      },
      "h1": {
        css: "h1",
        html: "h1",
        attrs: {
          ".long-name": {
            css: ".long-name",
            html: "long-name"
          }
        }
      }
    },
    "h3": {
      css: "h3",
      html: "h3"
    }
  },
  ".subheader-documents": {
    css: ".subheader-documents",
    html: "subheader-documents",
    ".icon-title-link": {
      css: ".icon-title-link",
      html: "icon-title-link"
    }
  },
  ".subheader-with-subtitle": {
    css: ".subheader-with-subtitle",
    html: "subheader-with-subtitle",
    ".header-content-flex": {
      css: ".header-content-flex",
      html: "header-content-flex",
      "button": {
        css: "button",
        html: "button"
      }
    },
    ".icon-title-link": {
      css: ".icon-title-link",
      html: "icon-title-link"
    }
  },
  ".subheader-deal-details": {
    css: ".subheader-deal-details",
    html: "subheader-deal-details",
    ".deal-details-button-container": {
      css: ".deal-details-button-container",
      html: "deal-details-button-container"
    }
  }
};
