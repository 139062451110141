import { Fragment, useMemo } from "react";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as R from "fp-ts/lib/Record";
import V from "voca";

import { b, Mn } from "@scripts/fp-ts";
import type { DisclaimerTypeU } from "@scripts/generated/domaintables/disclaimerTypes";
import { document, general, offering } from "@scripts/generated/domaintables/disclaimerTypes";
import type { Disclaimer } from "@scripts/generated/models/disclaimer";
import type { Href } from "@scripts/generated/models/href";
import type { IssuerPreferences } from "@scripts/generated/models/issuerPreferences";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { AnchorUnsafe, AnchorUnsafeWithChildren } from "@scripts/react/components/Anchor";
import { ButtonLink } from "@scripts/react/components/Button";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { DisclaimerModal } from "@scripts/react/components/modal/Disclaimer";
import { SubscriptionToggleBtn } from "@scripts/react/components/SubscriptionToggle";
import { Svg } from "@scripts/react/components/Svg";
import { useConfig } from "@scripts/react/context/Config";
import { useModal } from "@scripts/react/util/useModal";
import { issuerShortNameOrName } from "@scripts/syntax/issuer";

import facebookIcon from "@svgs/facebook.svg";
import instagramIcon from "@svgs/instagram.svg";
import linkedInIcon from "@svgs/linkedin.svg";
import twitterIcon from "@svgs/twitter.svg";
import youtubeIcon from "@svgs/youtube.svg";

import { useIssuerSubscribe } from "../api/watchlist";
import { useIssuerSitesSelector } from "../state/store";

export const IssuerFooter = (props: {
  prefs: O.Option<IssuerPreferences>;
  disclaimers: ReadonlyArray<WithStatusU<Disclaimer>>;
  footerDisclaimerTypes: ReadonlyArray<DisclaimerTypeU>;
  footerLinks: ReadonlyArray<WithStatusU<Href>>;
}) => {
  const config = useConfig();
  const issuer = useIssuerSitesSelector("issuer");
  const user = useIssuerSitesSelector("user");
  const userSubscribedToIssuer = useIssuerSitesSelector("userSubscribed");

  const disclaimerTypes = [
    [general, useModal("Issuer footer general disclaimer modal (sites)")],
    [offering, useModal("Issuer footer offering disclaimer modal (sites)")],
    [document, useModal("Issuer footer documents disclaimer modal (sites)")],
  ] as const;
  const disclaimersByTypeId = useMemo(() => pipe(
    R.fromEntries(props.footerDisclaimerTypes.map(t => [t.id.toString(), t])),
    tpes => R.fromEntries(props.disclaimers
      .filter(d => R.has(d.data.record.disclaimerType.id.toString(), tpes))
      .map(d => [d.data.record.disclaimerType.id.toString(), d])),
  ), [props.disclaimers, props.footerDisclaimerTypes]);

  const socials = pipe(props.prefs, O.fold(
    () => [],
    prefs => pipe([
      O.map((url: string) => ({
        name: "Twitter",
        url,
        icon: twitterIcon,
      }))(prefs.twitterUrl),
      O.map((url: string) => ({
        name: "LinkedIn",
        url,
        icon: linkedInIcon,
      }))(prefs.linkedinUrl),
      O.map((url: string) => ({
        name: "Facebook",
        url,
        icon: facebookIcon,
      }))(prefs.facebookUrl),
      O.map((url: string) => ({
        name: "Instagram",
        url,
        icon: instagramIcon,
      }))(prefs.instagramUrl),
      O.map((url: string) => ({
        name: "YouTube",
        url,
        icon: youtubeIcon,
      }))(prefs.youtubeUrl),
    ],
      RA.compact
    )
  ));

  const makeOnSubscribe = useIssuerSubscribe();

  const shortNameOrName = issuerShortNameOrName(issuer);
  const hasSocials = RA.isNonEmpty(socials);

  const showSocialSection = O.isNone(issuer.bankId) || hasSocials;
  const hasDisclaimers = !R.isEmpty(disclaimersByTypeId);
  const hasFooterLinks = RA.isNonEmpty(props.footerLinks);

  const footerHasContent = Mn.concatAll(b.MonoidAny)([showSocialSection, hasDisclaimers, hasFooterLinks]);

  return trueOrEmpty(<footer>
    <div className="footer-issuer">
      <div className="container">
        <div className="grid grid-sx-1 grid-sx-md-3">
          {showSocialSection && <div className="c-md-12 flex-wrap">
              {O.isNone(issuer.bankId)
                && <div className="footer-block">
                  <h5>Get Issuer Alerts</h5>
                  <p>Add this issuer to your watchlist to get alerts about important updates.</p>
                  <SubscriptionToggleBtn
                    disableTooltip={true}
                    isSubscribed={userSubscribedToIssuer}
                    onSubscribe={makeOnSubscribe(
                      !userSubscribedToIssuer,
                      issuer.id,
                      user
                    )}
                  />
                </div>
              }
              {hasSocials
                && <div className="footer-block mt-025">
                  <h5>Follow {shortNameOrName} on social media</h5>
                  {socials.map(({ name, url, icon }) =>
                    <AnchorUnsafeWithChildren
                      href={url}
                      aria-label={`${name} account for ${shortNameOrName}`}
                      klasses="btn btn-social"
                      target="_blank"
                      key={name}
                      externalLinkLocation="none"
                    >
                      <Svg src={icon} />
                    </AnchorUnsafeWithChildren>
                  )}
                </div>
              }
            </div>
          }
          <div className="c-md-12">
            {hasDisclaimers && (
              <div className="footer-block">
                <h5>{issuer.name} Disclaimer</h5>
                {disclaimerTypes.map(([disclaimerType, [isModalOpen, openModal, closeModal]]) =>
                  <Fragment key={disclaimerType.id.toString()}>
                    {pipe(
                      R.lookup(disclaimerType.id.toString())(disclaimersByTypeId),
                      mapOrEmpty((disclaimer: WithStatusU<Disclaimer>) =>
                      <>
                          <p>
                            <ButtonLink className="btn-link" onClick={openModal}>
                              {V.capitalize(disclaimer.data.record.disclaimerType.typeName)} Disclaimer
                            </ButtonLink>
                          </p>
                          <DisclaimerModal
                            config={config}
                            disclaimer={disclaimer.data.record}
                            modalOpen={isModalOpen}
                            dismissAction={closeModal}
                          />
                        </>
                      )
                    )}
                  </Fragment>
                )}
              </div>
            )}
            {hasFooterLinks
              && <div className="footer-block">
                <h5>Additional Links</h5>
                {props.footerLinks.map(l =>
                  <p key={l.data.id}>
                    <AnchorUnsafe
                      target="_blank"
                      href={l.data.record.url}
                      title={l.data.record.text}
                      externalLinkLocation="none"
                      {...pipe(l.data.record.media, O.fold(() => ({}), () => ({ download: l.data.record.text })))}
                    >
                      {l.data.record.text}
                    </AnchorUnsafe>
                  </p>
                )}
              </div>
            }
          </div>
        </div >
      </div >
    </div >
  </footer>)(footerHasContent);
};
