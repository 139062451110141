import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { AnchorUnsafe } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { klass } from "@scripts/react/util/classnames";
import { links, type LinksPageData } from "@scripts/routes/routing/ssr/issuersites";
import { makeJumplinkId } from "@scripts/routes/routing/ssr/issuersitesJumplinkIds";

import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { makeJumplinkO } from "../../components/sidebar/sidebarLinksSyntax";
import { ContactUsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

const makeLinkSectionJumpLinkId = (linkSection: LinksPageData[number]) =>
  makeJumplinkId(`link-section-${linkSection.one.id}`);

const LinkWithDescription = (props: { link: LinksPageData[number]["many"][number] }) => {
  const issuer = useIssuerSitesSelector("issuer");

  const linkUrlInterface = SR.issuersitesResourcesControllerLink({
    issuerId: issuer.id,
    issuerSlug: issuer.slug,
    linkId: props.link.id,
  });

  return (
    <div {...klass("d-flex", "flex-col", "gap-05")}>
      <h5 {...klass("mb-0")}>
        <AnchorUnsafe
          externalLinkLocation="none"
          href={linkUrlInterface.url}
          target="_blank"
          title={props.link.record.name}
        />
      </h5>
      {pipe(
        props.link.record.description,
        mapOrEmpty((_) => <p {...klass("mb-0")}>{_}</p>)
      )}
    </div>
  );
};

const LinkSection = (props: { linkSection: LinksPageData[number] }) => {
  return (
    <AccentDividerSection
      title={O.some(props.linkSection.one.record.name)}
      sectionId={makeLinkSectionJumpLinkId(props.linkSection)}
    >
      <div {...klass("d-flex", "flex-col", "gap-2")}>
        {pipe(
          props.linkSection.many,
          RA.map((_) => <LinkWithDescription link={_} key={_.record.name} />)
        )}
      </div>
    </AccentDividerSection>
  );
};

export const LinksPage = (props: { data: LinksPageData }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");

  const linksPageMeta = links({ issuerId: issuer.id, issuerSlug: issuer.slug });
  const description = linksPageMeta.description(pages, iffs, issuer);

  const linkGroupJumpLinks = pipe(
    props.data,
    RA.map((linkGroup) => makeJumplinkO(linkGroup.one.record.name, makeLinkSectionJumpLinkId(linkGroup), true)),
    RA.compact
  );

  return (
    <DirectSitesPageDescriptionLayout
      description={description}
      sidebarContent={
        <>
          <SidebarLinksSites
            jumpLinks={linkGroupJumpLinks}
            headerLinkCustomAnchorContent={O.none}
            routeMeta={linksPageMeta}
          />
          <ContactUsActionSection containerKlass={"d-none-until-md"} />
        </>
      }
    >
      <div {...klass("accent-border-top", "pt-0")}>
        {pipe(
          RNEA.fromReadonlyArray(props.data),
          O.fold(
            () => <div {...klass("mt-1")}>There are currently no links to display.</div>,
            (data) =>
              <>
                {pipe(
                  data,
                  RA.map((_) => <LinkSection key={_.one.id} linkSection={_} />)
                )}
              </>
          )
        )}
      </div>
    </DirectSitesPageDescriptionLayout>
  );
};
