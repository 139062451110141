import { end, parse, Route, zero } from "fp-ts-routing";
import type * as t from "io-ts";

import { E, O, pipe } from "@scripts/fp-ts";
import type { NewsAndEventsData, NewsAndEventsDataC } from "@scripts/generated/models/issuerNews";
import type { IssuerHomepageData, IssuerHomepageDataC } from "@scripts/generated/models/issuerPageData";
import type { RfpsSitesList, RfpsSitesListC } from "@scripts/generated/models/rfp";
import type * as SR from "@scripts/generated/routers/sitesRouter";
import { NotFound } from "@scripts/react/components/error/NotFound";
import type {
  AboutPageData,
  AboutPageDataC,
  BondArchivePageDataC,
  BondsPageDataC,
  DocumentCategoriesPageData,
  DocumentCategoriesPageDataC,
  DownloadsPageData,
  DownloadsPageDataC,
  EsgProgramPageDataC,
  FAQPageData,
  FAQPageDataC,
  HomeUrlParams,
  IrmaLetterPageData,
  IrmaLetterPageDataC,
  IssuerSitesRouteMetaWithRender,
  LinksPageData,
  LinksPageDataC,
  NewsAndEventsUrlParams,
  OfferingPageDataC,
  ProgramPageDataC,
  ProgramsPageDataC,
  ProjectsPageData,
  ProjectsPageDataC,
  RatingsPageDataC,
  ResourcePageData,
  ResourcePageDataC,
  RfpPageDataC,
  RfpsUrlParams,
  RfpUrlParams,
  RoadshowPlayerData,
  RoadshowPlayerDataC,
  TeamPageData,
  TeamPageDataC,
  ViewFilePageData,
  ViewFilePageDataC,
} from "@scripts/routes/routing/ssr/issuersites";
import {
  about,
  aboutMatch,
  archivedDocuments,
  archivedDownloadsMatch,
  bondArchive,
  bondArchiveMatch,
  bonds,
  bondsMatch,
  documentCategories,
  documentCategoriesMatch,
  downloads,
  downloadsMatch,
  esgProgram,
  esgProgramMatch,
  faq,
  faqMatch,
  home,
  homeMatch,
  irmaLetter,
  irmaLetterMatch,
  links,
  linksMatch,
  newsAndEvents,
  newsAndEventsMatch,
  notFound,
  offeringPage,
  offeringPageMatch,
  program,
  programMatch,
  programs,
  programsMatch,
  projects,
  projectsMatch,
  ratings,
  ratingsMatch,
  resources,
  resourcesMatch,
  rfp,
  rfpMatch,
  rfps,
  rfpsMatch,
  roadshowPlayer,
  roadshowPlayerMatch,
  team,
  teamMatch,
  viewFile,
  viewFileMatch,
} from "@scripts/routes/routing/ssr/issuersites";

import { AboutPage } from "../pages/about/about-page/About";
import { NewsAndEvents } from "../pages/about/NewsAndEvents";
import { ProjectsPage } from "../pages/about/Projects";
import { BankOfferingPage } from "../pages/bank-offering/BankOfferingPage";
import { BankRfpPage } from "../pages/bank-rfp/BankRfpPage";
import { BondArchive } from "../pages/bonds/BondArchive";
import { Bonds } from "../pages/bonds/Bonds";
import { DirectOfferingPageWithChrome } from "../pages/direct-offering/DirectOfferingPage";
import { DirectRfpWithChrome } from "../pages/direct-rfp/DirectRfp";
import { Rfps } from "../pages/direct-rfp/Rfps";
import { DocumentCategoriesPage } from "../pages/documents/DocumentCategoriesPage";
import { DocumentsPage } from "../pages/documents/DocumentsPage";
import { IrmaLetterPage } from "../pages/documents/IrmaLetter";
import { ViewFilePage } from "../pages/documents/ViewFile";
import { EsgProgram } from "../pages/esg-program/EsgProgram";
import { Home } from "../pages/home";
import { ProgramPage } from "../pages/programs/Program";
import { ProgramsPage } from "../pages/programs/Programs";
import { RatingsPage } from "../pages/ratings/RatingsPage";
import { FaqPage } from "../pages/resources/FaqPage";
import { LinksPage } from "../pages/resources/LinksPage";
import { ResourcesPage } from "../pages/resources/Resources";
import { RoadshowPlayer } from "../pages/roadshow-player/RoadshowPlayer";
import { TeamPage } from "../pages/team/TeamPage";

const homeWithRender = (reqParams: SR.IssuersitesAboutControllerNewsEventsParams) => {
  return {
    ...home(reqParams),
    render: (pageProps) => <Home data={pageProps} {...reqParams} />,
  } as const satisfies IssuerSitesRouteMetaWithRender<IssuerHomepageData, t.OutputOf<IssuerHomepageDataC>>;
};

const newsAndEventsWithRender = (reqParams: SR.IssuersitesAboutControllerNewsEventsParams) => {
  return {
    ...newsAndEvents(reqParams),
    render: (pageProps) => <NewsAndEvents data={pageProps} {...reqParams} />,
  } as const satisfies IssuerSitesRouteMetaWithRender<NewsAndEventsData, t.OutputOf<NewsAndEventsDataC>>;
};

const rfpsWithRender = (reqParams: SR.IssuersitesRfpsControllerRfpsParams) => {
  return {
    ...rfps(reqParams),
    render: (pageProps) => <Rfps rfps={pageProps} {...reqParams} />,
  } as const satisfies IssuerSitesRouteMetaWithRender<RfpsSitesList, t.OutputOf<RfpsSitesListC>>;
};

const rfpWithRender = (reqParams: SR.IssuersitesRfpsControllerRfpParams) => {
  return {
    ...rfp(reqParams),
    render: (pageProps) => pipe(pageProps, E.fold(
      // Each variant of the RFP page renders its own chrome since it changes based on whether it's a BLP or direct issuer
      bankRfp => <BankRfpPage {...bankRfp} />,
      directRfp => <DirectRfpWithChrome {...directRfp} {...reqParams} />)),
  } as const satisfies IssuerSitesRouteMetaWithRender<t.TypeOf<RfpPageDataC>, t.OutputOf<RfpPageDataC>>;
};

const bondsWithRender = (reqParams: SR.IssuersitesBondOfferingsControllerIndexParams) => {
  return {
    ...bonds(reqParams),
    render: (d) => <Bonds {...d} />,
  } as const satisfies IssuerSitesRouteMetaWithRender<t.TypeOf<BondsPageDataC>, t.OutputOf<BondsPageDataC>>;
};

const bondArchiveWithRender = (reqParams: SR.IssuersitesBondOfferingsControllerArchivedBondsParams) => {
  return {
    ...bondArchive(reqParams),
    render: d => <BondArchive {...d} />,
  } as const satisfies IssuerSitesRouteMetaWithRender<t.TypeOf<BondArchivePageDataC>, t.OutputOf<BondArchivePageDataC>>;
};

const offeringWithRender = (reqParams: SR.IssuersitesBondOfferingsControllerOfferingParams) => ({
  ...offeringPage(reqParams),
  render: (offeringData) => pipe(offeringData, E.fold(
    // Each variant of the offering page renders its own chrome since it changes based on whether it's a BLP or direct issuer
    bankOffering => <BankOfferingPage {...bankOffering} />,
    directOffering => <DirectOfferingPageWithChrome {...directOffering} />)),
} as const satisfies IssuerSitesRouteMetaWithRender<t.TypeOf<OfferingPageDataC>, t.OutputOf<OfferingPageDataC>>);

const ratingsWithRender = (reqParams: SR.IssuersitesBondOfferingsControllerRatingsParams) => {
  return {
    ...ratings(reqParams),
    render: (pageProps) => <RatingsPage {...pageProps} />,
  } as const satisfies IssuerSitesRouteMetaWithRender<t.TypeOf<RatingsPageDataC>, t.OutputOf<RatingsPageDataC>>;
};

const programsWithRender = (reqParams: SR.IssuersitesBondProgramsControllerBondProgramsParams) => ({
  ...programs(reqParams),
  render: (pageProps) => <ProgramsPage programs={pageProps} />,
} as const satisfies IssuerSitesRouteMetaWithRender<t.TypeOf<ProgramsPageDataC>, t.OutputOf<ProgramsPageDataC>>);

const programWithRender = (reqParams: SR.IssuersitesBondProgramsControllerBondProgramParams) => ({
  ...program(reqParams),
  render: (p) => <ProgramPage programWithRelatedContent={p} />,
} as const satisfies IssuerSitesRouteMetaWithRender<t.TypeOf<ProgramPageDataC>, t.OutputOf<ProgramPageDataC>>);

const esgProgramWithRender = (reqParams: SR.IssuersitesAboutControllerEsgProgramParams) => ({
  ...esgProgram(reqParams),
  render: (p) => <EsgProgram data={p} />,
} as const satisfies IssuerSitesRouteMetaWithRender<t.TypeOf<EsgProgramPageDataC>, t.OutputOf<EsgProgramPageDataC>>);

const faqWithRender = (reqParams: SR.IssuersitesResourcesControllerFaqParams) => {
  return {
    ...faq(reqParams),
    render: (p) => <FaqPage faqs={p.faqs} />,
  } as const satisfies IssuerSitesRouteMetaWithRender<FAQPageData, t.OutputOf<FAQPageDataC>>;
};
const aboutWithRender = (reqParams: SR.IssuersitesAboutControllerIndexParams) => ({
  ...about(reqParams),
  render: (p) => <AboutPage {...p} />,
} as const satisfies IssuerSitesRouteMetaWithRender<AboutPageData, t.OutputOf<AboutPageDataC>>);

const projectsWithRender = (reqParams: SR.IssuersitesAboutControllerProjectsParams) => {
  return {
    ...projects(reqParams),
    render: (pageProps) => <ProjectsPage data={pageProps} {...reqParams} />,
  } as const satisfies IssuerSitesRouteMetaWithRender<ProjectsPageData, t.OutputOf<ProjectsPageDataC>>;
};

const downloadsWithRender = (reqParams: SR.IssuersitesReportsControllerDownloadsParamsRaw) => {
  return {
    ...downloads(reqParams),
    render: (pageProps) => <DocumentsPage data={pageProps} variant="downloads" />,
  } as const satisfies IssuerSitesRouteMetaWithRender<DownloadsPageData, t.OutputOf<DownloadsPageDataC>>;
};

const archivedDocumentsWithRender = (reqParams: SR.IssuersitesReportsControllerDownloadsParamsRaw) => {
  return {
    ...archivedDocuments(reqParams),
    render: (pageProps) => <DocumentsPage data={pageProps} variant="archive" />,
  } as const satisfies IssuerSitesRouteMetaWithRender<DownloadsPageData, t.OutputOf<DownloadsPageDataC>>;
};

const irmaLetterWithRender = (reqParams: SR.IssuersitesReportsControllerIrmaLetterParams) => {
  return {
    ...irmaLetter(reqParams),
    render: (pageProps) => <IrmaLetterPage data={pageProps} />,
  } as const satisfies IssuerSitesRouteMetaWithRender<IrmaLetterPageData, t.OutputOf<IrmaLetterPageDataC>>;
};

const viewFileWithRender = (reqParams: SR.IssuersitesReportsControllerViewFileParams) => {
  return {
    ...viewFile(reqParams),
    render: (pageProps) => <ViewFilePage data={pageProps} />,
  } as const satisfies IssuerSitesRouteMetaWithRender<ViewFilePageData, t.OutputOf<ViewFilePageDataC>>;
};

const teamWithRender = (reqParams: SR.IssuersitesAboutControllerTeamParams) => ({
  ...team(reqParams),
  render: (pageProps) => <TeamPage officers={pageProps} />,
}) as const satisfies IssuerSitesRouteMetaWithRender<TeamPageData, t.OutputOf<TeamPageDataC>>;

const resourcesWithRender = (reqParams: SR.IssuersitesResourcesControllerIndexParams) => ({
  ...resources(reqParams),
  render: (pageProps) => <ResourcesPage data={pageProps} />,
}) as const satisfies IssuerSitesRouteMetaWithRender<ResourcePageData, t.OutputOf<ResourcePageDataC>>;

const linksWithRender = (reqParams: SR.IssuersitesResourcesControllerLinksParams) => ({
  ...links(reqParams),
  render: (pageProps) => <LinksPage data={pageProps} />,
}) as const satisfies IssuerSitesRouteMetaWithRender<LinksPageData, t.OutputOf<LinksPageDataC>>;

const documentCategoriesWithRender = (reqParams: SR.IssuersitesReportsControllerIndexParams) => ({
  ...documentCategories(reqParams),
  render: (pageProps) => <DocumentCategoriesPage data={pageProps} />,
}) as const satisfies IssuerSitesRouteMetaWithRender<DocumentCategoriesPageData, t.OutputOf<DocumentCategoriesPageDataC>>;

const roadshowPlayerWithRender = (reqParams: SR.IssuersitesRoadShowControllerRoadShowPlayerParams) => ({
  ...roadshowPlayer(reqParams),
  render: (pageProps) => <RoadshowPlayer data={pageProps} />,
}) as const satisfies IssuerSitesRouteMetaWithRender<RoadshowPlayerData, t.OutputOf<RoadshowPlayerDataC>>;

const notFoundWithRender = {
  ...notFound,
  render: () => <NotFound route={O.none} />,
} as const satisfies IssuerSitesRouteMetaWithRender<unknown, t.OutputOf<t.UnknownC>>;

export type IssuerSitesPageMeta = typeof notFoundWithRender
  | ReturnType<typeof homeWithRender>
  | ReturnType<typeof newsAndEventsWithRender>
  | ReturnType<typeof rfpsWithRender>
  | ReturnType<typeof rfpWithRender>
  | ReturnType<typeof bondsWithRender>
  | ReturnType<typeof bondArchiveWithRender>
  | ReturnType<typeof offeringWithRender>
  | ReturnType<typeof ratingsWithRender>
  | ReturnType<typeof programsWithRender>
  | ReturnType<typeof programWithRender>
  | ReturnType<typeof esgProgramWithRender>
  | ReturnType<typeof faqWithRender>
  | ReturnType<typeof aboutWithRender>
  | ReturnType<typeof projectsWithRender>
  | ReturnType<typeof downloadsWithRender>
  | ReturnType<typeof archivedDocumentsWithRender>
  | ReturnType<typeof teamWithRender>
  | ReturnType<typeof linksWithRender>
  | ReturnType<typeof documentCategoriesWithRender>
  | ReturnType<typeof roadshowPlayerWithRender>
  | ReturnType<typeof irmaLetterWithRender>
  | ReturnType<typeof viewFileWithRender>
  | ReturnType<typeof resourcesWithRender>;

export type IssuerSitesPageTagU = IssuerSitesPageMeta["_tag"];

const router = zero<IssuerSitesPageMeta>()
  .alt(homeMatch.parser.map((params: HomeUrlParams) => homeWithRender(params)))
  .alt(newsAndEventsMatch.parser.map((params: NewsAndEventsUrlParams) => newsAndEventsWithRender(params)))
  .alt(rfpsMatch.parser.map((params: RfpsUrlParams) => rfpsWithRender(params)))
  .alt(rfpMatch.parser.map((params: RfpUrlParams) => rfpWithRender(params)))
  .alt(bondsMatch.parser.map(bondsWithRender))
  .alt(bondArchiveMatch.parser.map(bondArchiveWithRender))
  .alt(offeringPageMatch.parser.map(offeringWithRender))
  .alt(ratingsMatch.parser.map(ratingsWithRender))
  .alt(programMatch.parser.map(programWithRender))
  .alt(programsMatch.parser.map(programsWithRender))
  .alt(esgProgramMatch.parser.map(esgProgramWithRender))
  .alt(faqMatch.parser.map(faqWithRender))
  .alt(aboutMatch.parser.map(aboutWithRender))
  .alt(projectsMatch.parser.map(projectsWithRender))
  .alt(downloadsMatch.parser.map(downloadsWithRender))
  .alt(archivedDownloadsMatch.parser.map(archivedDocumentsWithRender))
  .alt(irmaLetterMatch.parser.map(irmaLetterWithRender))
  .alt(viewFileMatch.parser.map(viewFileWithRender))
  .alt(resourcesMatch.parser.map(resourcesWithRender))
  .alt(documentCategoriesMatch.parser.map(documentCategoriesWithRender))
  .alt(teamMatch.parser.map(teamWithRender))
  .alt(linksMatch.parser.map(linksWithRender))
  .alt(roadshowPlayerMatch.parser.map(roadshowPlayerWithRender))
  .alt(end.parser.map(() => notFoundWithRender));

export const parseLocation = (s: string): IssuerSitesPageMeta => parse(router, Route.parse(s), notFoundWithRender);
